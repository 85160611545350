import React, {useState, useEffect}  from 'react';
import { connect } from 'react-redux';
import {getAllFavPrograms,followcamps, addToCart, programFavourite,  getCartItemsNonLoginUser, getCartItems,} from '../../services/programService';
import { ProgramLoader } from '../reuse/SVG';
import { Empty, notification } from 'antd';
import {campDates, getProgramType,getGender, getgendersdisplay, gerFavpropgramdate} from '../../helper';
import { Link } from 'react-router-dom';
import {CardLoader} from '../reuse/SVG';
import { LogoWhite } from '../reuse/SVG';
import { Modal, Dropdown } from 'react-bootstrap';
import {getitemsincart} from '../../store/cart'
import Share from '../static/Share';


const Favorites = props => {
    const todayDate = new Date().getTime();
    const [loader,  setLoader]                   = useState(true);
    const [favlist, setFavList]                  = useState([]);
    const [addtocartModal, setaddtocartModal]    = useState(false);
    const [cartLoder, setCartLoder]              = useState(false);
    const [removeFavModal, setRemoveFavModal]    = useState(false);

    useEffect(() =>{
        const getfavlistdata = getAllFavPrograms()
        getfavlistdata.then((res)=>{
            setLoader(false);
            setFavList(res.data.responseData.result);
        }).catch((err)=>{
            setLoader(false);
        })
    },[])

    useEffect(() =>{
        const getfavlistdata = getAllFavPrograms()
        getfavlistdata.then((res)=>{
            setLoader(false);
            setFavList(res.data.responseData.result);
        }).catch((err)=>{
            setLoader(false);
        })
    },[cartLoder])


    const followCamps = async (campId, follow) => {
        setCartLoder(true);
        const followCamp = await followcamps({campId, follow});
        try{
            if(follow){
                let newfavlist = [...favlist];
                newfavlist.map((campes, index)=>{
                    if(campes.campId == campId){
                        newfavlist[index].isFollowed = false;
                    }
                })
                setFavList(newfavlist);
                setCartLoder(false);
                notification.open({
                    description:
                        'You started following this camp',
                });
                
            }else{
                let newfavlist = [...favlist];
                newfavlist.map((campes, index)=>{
                    if(campes.campId == campId){
                        newfavlist[index].isFollowed = true;
                    }
                })
                setFavList(newfavlist);
                setCartLoder(false);
                notification.open({
                    description:
                        'You started unfollow this camp',
                });
            }
            
        }
        catch(err){
            setCartLoder(false);
        } 
    }

    const addtocartAction = (programid) =>
    {
        let service = props.loggedIn ? getCartItems : getCartItemsNonLoginUser
        let programId = programid;
        let numberOfBooking = 1;
        let additional_exp_cart = [];
        let params = {programId, numberOfBooking}  
        addToCart(params).then(res=>{
            if(res?.data?.statusCode){
                        setaddtocartModal(true);
                        service().then(res=>{
                            if(res?.data?.statusCode){
                                props.getitemsincart(res.data.responseData.result.length);
                                autoRemoveFromFav(programid)
                            }
                        })
                   
            }
        });
    } 
    
    const removeFromFavList =(programId, index) =>{
        setCartLoder(true)
        let newfavlist = [...favlist];
        let params = {
            programId: programId,
            favourite: 0
        }
        programFavourite(params).then(res => {
            setCartLoder(false);
            newfavlist.splice(index,1);
            setFavList(newfavlist);
            setRemoveFavModal(true)
        })
    }


    const autoRemoveFromFav = (id) => {
        let params = {
            programId: id,
            favourite: 0
        }
        programFavourite(params).then(res => {
            getAllFavPrograms().then((res) => {
                setLoader(false);
                setFavList(res.data.responseData.result);
            }).catch((err)=>{
                setLoader(false);
            })
        })
    }


    return(
        <div className="body_container">

<Modal
                    dialogClassName="h_auto"
                    show={addtocartModal}
                    animation={false}
                    centered
                >
                <Modal.Body>
                    <div className="p-5">
                        <h3 className="text-center mb-4">Success!</h3>
                        <p className="text-center">Successfully added to cart </p>
                        <button onClick={()=>setaddtocartModal(false)}className="btn btn-primary btn-block mt-2">Ok</button>
                    </div>
                </Modal.Body>
                </Modal>


                <Modal
                    dialogClassName="h_auto"
                    show={removeFavModal}
                    animation={false}
                    centered
                >
                <Modal.Body>
                    <div className="p-5">
                        <h3 className="text-center mb-4">Success!</h3>
                        <p className="text-center">Successfully removed from favorites list </p>
                        <button onClick={()=>setRemoveFavModal(false)}className="btn btn-primary btn-block mt-2">Ok</button>
                    </div>
                </Modal.Body>
                </Modal>


            <div className="container">
                <div className="d-flex align-items-center justify-content-between section_header">
                    <h3>My Favorites</h3>
                    <div>
                        {/* <button className="btn btn-outline-primary rounded-0 mr-3"><i className="icon-share-fill" /> Share</button> */}
                        <Link className="btn btn-secondary rounded-0" to={{pathname: '/calendar', state: {"type": 1 }}}><i className="icon-calender-2" /> Calendar</Link>
                    </div>
                </div>
            </div>
            <div className="container">
            
            {
                 loader ?  <div className="program_loader"><ProgramLoader /></div> : 
                 (
                    <div className="row">
                        {cartLoder ? <div className="favloder_wrapper"><CardLoader/></div> : ''}
                    {
                        favlist.length > 0 ?
                        (
                        favlist.map((item, i) => (
                        <div key={i} className="col-lg-4 col-md-6 mb-5">
                            <div className="cm_card fav_card">
                                <div className="card_img_wrap">
                            
                                {item.isSpecialDeal ? <span className="SD_tag">Special Offers</span> :''}
                                    {
                                        item.isFollowed ?   
                                        <i onClick={() => followCamps(item.campId, 0)} className="icon-check" /> 
                                        : 
                                        <i onClick={() => followCamps(item.campId, 1)} className="icon-plus" />
                                    }
                                   <span className="btn_like active" onClick={()=>{removeFromFavList(item._id, i)}}><i className="icon-heart"></i></span>
                                    {
                                      item.programImage ? (<img src={item.programImage} />) : (<div className="no_img"><LogoWhite /></div>)   
                                    }
                                    
                                    <h4>{item.camps}</h4>
                                    <div className="camp_card_name">
                                        <h3>{gerFavpropgramdate(item.startDate, item.endDate)}  <Share pageUrl={`ProgramDetail=${item._id}`} /></h3>
                                    
                                    </div>
                                </div>
                                <div className="card_body">
                                    <div className="d-flex justify-content-between">
                                        <h3 title={item.programName}>{item.programName}</h3>

                                        <div className={`text-right dark__ ${item.isSpecialDeal  ? 'cm_spacial_price' : ''}`}>
                        
                                            <h3><span className="under_red">{item.registrationFee ? `Price : $${item.registrationFee.toFixed(2)}` : 'Free'}   </span></h3>
                                            {
                                            item.isSpecialDeal ? <h2>${item.specialDealsPrice.toFixed(2)}</h2>  : ''} 
                                            
                                            {/* {program.price ? <h2>${program.dealType == '1' ? parseInt(program.price).toFixed(2) : (program.registrationFee - (program.price/program.registrationFee) * 100).toFixed(2)}</h2> : ''} */}
                                            
                                        </div>


                                        {/* <div className="text-nowrap">Price: <h2>${item.registrationFee.toFixed(2)}</h2></div> */}
                                    
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p>{getProgramType(item.programType)}, <span className="text-nowrap">{`${item.checkInTime}-${item.checkOutTime}`}</span></p>
                                            <p>Age : {item.ageGroup}</p>
                                        </div>
                                        <div className="text-nowrap">
                                            <p>Gender : {getgendersdisplay(item.gender)}</p>
                                            <p className="text-right">EZPro <span className="EZPro_rating">{item.ezProRating} <i className="icon-star" /></span></p>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        
                                        {
                                            todayDate < item.dateLateRegisterClosed
                                                ? <button onClick={() => addtocartAction(item._id)} className="btn btn-primary btn-block mr-1 rounded-0">Add to cart</button> : todayDate < item.registerCloseDate ? <button onClick={() => addtocartAction(item._id)} className="btn btn-primary btn-block mr-1 rounded-0">Add to cart</button> : ''
                                        }

                                        <Link className="btn btn-info btn-block ml-1 rounded-0" to={`/camps/${item._id}`}>Details</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))) :
                        (
                            <div className="col-12 mb-5"><div className="cm_empty"><Empty description="No Favorite Programs Found" /></div></div>
                        )
                    }                    
                    </div> 
                 )
            }
            </div>
        </div>
    )
}

/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { loggedIn } = state.user;
    return {
        state,
        loggedIn
    };
}
  
const mapDispatchToProps = dispatch => ({
    //toggleMapViewModal: () => dispatch(toggleMapViewModal()),
    getitemsincart: (cartitems) => dispatch(getitemsincart(cartitems)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Favorites);


const loop = [1,2,3,4,5,6]