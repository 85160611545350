import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getUserDetailsAndPosts, deletePostService } from '../../services/feedService';
import FeedCard from './FeedCard';
import { ProgramLoader } from '../reuse/SVG';


const GetUserDetailsPosts = props => {
    const [userInfo, setUserInfo] = useState('');
    const [feeds, setFeeds] = useState([]);
    const [pageLoader, setPageLoader] = useState(false);

    const getDetail = () => {
        setPageLoader(true);
        let params = `?user=${props.match.params.id}`;
        getUserDetailsAndPosts(params).then(res => {
            setPageLoader(false);
            if(res?.data?.statusCode){
                setUserInfo(res.data.responseData.userInfo);
                setFeeds(res.data.responseData.result);
            }
        })
    }


    useEffect(() => {
        getDetail();
    }, [])


    const handleFeedDelete = postId => {
        let params = {
            postId,
            status: '3'
        }

        deletePostService(params).then(res => {
            if(res?.data?.statusCode){
                getDetail(); 
            }
        })
    }


    const handleCommentDelete = id => {
        
    }

    return(
        <div className="body_container cm_over_auto" style={{backgroundColor: '#F7F7F7'}}>
            {
                pageLoader ? (
                    <div className="program_loader"><ProgramLoader /></div>
                ) : (
                    <div className="container pt-5">
                        <div className="row">
                            <div className="col-lg-2" />
                            <div className="col-lg-8">
                                <div className="user_info">
                                    <img src={userInfo.profileImage ? userInfo.profileImage : require('../../assets/img/user.png')} />
                                    <h3>{userInfo ? userInfo.name : ''}</h3>
                                </div>

                                {feeds.map((feed, i) => <FeedCard
                                                            handleFeedDelete={handleFeedDelete}
                                                            handleCommentDelete={handleCommentDelete}
                                                            feed={feed}
                                                            key={feed._id} />)}
                            </div>
                        </div>
                    </div>
                )
            }


            
        </div>
    )
}

/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    return {
        state
    };
}
  
const mapDispatchToProps = dispatch => ({

});
  
export default connect(mapStateToProps, mapDispatchToProps)(GetUserDetailsPosts);