import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => (
    <div className="body_container">
        <div className="container text-center mt-5 mb-5">
            <img src={require('../assets/img/404.png')} width="300px" />
            <p className="mt-5">Unfortunately the page you are looking for has been moved or deleted</p>
            <Link to='/' className="btn btn-primary">Go to home</Link>
        </div>
    </div>
)

export default NotFoundPage;