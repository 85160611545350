
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { justifiAuthTokenService } from '../../services/userServices';
import { Modal } from 'react-bootstrap';



const Payouts = props => {

    const [access_token, set_access_token] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [activeId, setActiveId] = useState('');
    const payoutRef = useRef(null);

    
    const handlePayoutRowClicked = data => {
        setShowModal(true);
        setActiveId(data.detail.id);
    }
    useEffect(() => {
        const timeoutId = setTimeout(() => {
          const payoutsList = payoutRef.current;
          if (payoutsList) {
            payoutsList.addEventListener('payout-row-clicked', handlePayoutRowClicked);
    
          }
        }, 1000);
    
        return () => {
          clearTimeout(timeoutId);
          if (payoutRef.current) {
            payoutRef.current.removeEventListener('payout-row-clicked', handlePayoutRowClicked);
          }
        };
      }, []);
    


    const handleCloseModal = () => {
        setShowModal(false);
        setActiveId('');
    }


    useEffect(() => {
        if(props.subAccountId){
            let params = {subAccountId: props.subAccountId}
            justifiAuthTokenService(params).then(res => {
                if(res?.data?.statusCode){
                    set_access_token(res.data.responseData.access_token)
                }
            })
        }
            
    }, [props.subAccountId]);
    

    return (
        <div className='coupon_table_wrapper px-10'>
                {
                    access_token ? (
                        <justifi-payouts-list
                            auth-token={access_token}
                            account-id={props.subAccountId}
                            ref={payoutRef}
                        >
                        </justifi-payouts-list>
                    ) : ''
                }



                    <Modal
                        show={showModal}
                        animation={false}
                        dialogClassName="payment_modal"
                        size="lg"
                        centered
                    >
                        <Modal.Body>
                            <span className="modal_close" onClick={handleCloseModal}><i className="icon-cross" /></span>
                            <justifi-payout-details
                                auth-token={access_token}
                                payout-id={activeId}
                                ></justifi-payout-details>
                        </Modal.Body>
                    </Modal>

        </div>
    )

}


const mapStateToProps = state => {
    let { userProfile } = state.user;
    let { campCode, subAccountId } = userProfile;

    return {
        campCode,
        subAccountId
    };
}


export default connect(mapStateToProps)(Payouts);
