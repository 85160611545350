import * as actionType from './actionsTypes';

const initialState = {
    filterData: ''
}


/************************************************************************************/
// Program Reducer
/************************************************************************************/


const cabinReducer = (state = initialState, action) => {

    switch (action.type) {


        case actionType.FILTER_CABIN_DATA:
            return {
                ...state,
                filterData: action.payload
            }


        default:
            return state
    }
}




/************************************************************************************/
// All Program Actions
/************************************************************************************/


export const filterCabinParamsAction = payload => (dispatch => dispatch({ type: actionType.FILTER_CABIN_DATA, payload }))



export default cabinReducer;