import axios from "axios";

const ApiInstance = axios.create();

ApiInstance.interceptors.response.use(res => {
    if(false){
    //if(res?.data?.error?.errorCode == 22 || res?.data?.error?.errorCode == 52){
      localStorage.clear();
      const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      window.location.href = '/';
    }else {
        return res
    }
  }
);

export default ApiInstance;