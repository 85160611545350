import React,  { useEffect, useState } from 'react';
import {Collapse, Spinner} from 'react-bootstrap';
// import Placeholder from 'react-bootstrap/Placeholder';
import { getPaymentMethod } from '../../services/directorServices';
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { paraClass } from '../../helper';


const getCardTypeName = (type) => {
    const typeMap = {
        'american express': 'AmericanExpress',
        'visa electron': 'Visa',
        'visa': 'Visa',
        'mastercard': 'MasterCard',
        'discover': 'Discover',
        'china_unionpay': 'Unionpay',
        'diners club': 'DinersClub',
        'dinersclub': 'DinersClub'
    };

    return typeMap[type.toLowerCase()] || 'Visa';
}
const PaymentDetails = ({transactionId}) => {
    const [open, setOpen] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [isBankTransaction, setIsBankTransaction] =useState(false)
    const [error, setError] = useState('');

    const loadPaymentOptions = () => {
      if(!transactionId){
        setError("Transaction data not availabe.")
        return;
      }
        setError('');
        const params = `?transaction_id=${transactionId}`
        setLoading(true);
        // setTimeout(()=> setLoading(false), 1000)
        getPaymentMethod(params).then((res) => {
            if(res.data.statusCode){
              const paymentMethod = res.data.responseData.result.payment_method
              if(paymentMethod?.card){
                setPaymentDetails(paymentMethod?.card)
              }else if(paymentMethod?.bank_account){
                setIsBankTransaction(true)
                setPaymentDetails(paymentMethod?.bank_account)
              }
                setLoading(false);
            }
        }).catch(e =>{ 
          setError('Something went wrong while fetching payment details. Please Try again.')
          setLoading(false);
        });

    }
    useEffect(() => {
        if(open){
            loadPaymentOptions();
        }else{
          setPaymentDetails({})
          setError('');
        }
    }, [open])

    useEffect(() => {
        if(open){
          loadPaymentOptions()
        }
    }, [transactionId])
    
    return (
      <div style={{ border: '0.5px solid #000000', maxWidth:'800px'}}>
      <div 
        className='d-flex justify-content-between align-items-center px-2 text-white'
        style={{ backgroundColor: "#842014"}}
      >
        <span> Payment Details</span>
        <button
                className="btn p-0"
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
            >
          {open ? <BsChevronUp size={20} color={"ffffff"}/> : <BsChevronDown size={20} color={"ffffff"}/>}
        </button>
      </div>
       
        
        <Collapse in={open}>
        {error ?
          <div>
            <p className='text-danger'>{error}</p>
          </div>
        :( loading ? 
          <div className='d-flex justify-content-center my-2'>
              <Spinner animation="border" role="status" variant="danger">
                  <span className="visually-hidden"></span>
              </Spinner>
          </div>
          :
            <div>
              <div className={`${paraClass}  m-2`}>
                  <span className="color_black">Name:</span> 
                  {paymentDetails?.name ? paymentDetails?.name : "- - - -"}
              </div>
              <div className={`${paraClass}  m-2`}>
                  <span className="color_black">
                    {isBankTransaction ? "Account" : "Card" } Number:
                    </span>*******{paymentDetails.acct_last_four}
              </div>
              {paymentDetails.brand &&
                <div className={`${paraClass}  m-2`}>
                  <span className="color_black">Paid Using:</span>
                      <img 
                          style={{maxHeight:"33px", maxWidth:"43px"}}
                          src={paymentDetails.brand ? require(`../../assets/img/${getCardTypeName(paymentDetails.brand)}.svg`) : require(`../../assets/img/Visa.svg`)} 
                          alt=""
                      />
                </div>
              }
            </div>
            )
          }
        </Collapse>
      </div>
    );
  }

export default PaymentDetails

