import * as actionType from './actionsTypes';
import { resHandle } from '../helper';
import { allProgramList } from '../services/homeService';


const initialState = {
    allProgram: {
        featured: '',
        nearbyCamps: '',
        popularCamps: '',
        specialDeal: '',
        startingSoon: '',
    },
    error: ''
}


/************************************************************************************/
// Home Reducer
/************************************************************************************/


const homeReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionType.PROGRAM_ERROR:
            return {
                ...state,
                error: action.payload
            }


        case actionType.GET_ALL_PROGRAM_LIST:
            return {
                ...state,
                allProgram: action.payload
            }

            
        default:
            return state
    }
}




/************************************************************************************/
// All Program Actions
/************************************************************************************/



export const getAllProgramListAction = params => async dispatch => {
        
        try {
            let program = await allProgramList(params);
            let { status, data } = resHandle(program);
            if(status){
                    dispatch({ type: actionType.GET_ALL_PROGRAM_LIST, payload: data.result });
                    return status;
                }else{
                    dispatch({ type: actionType.PROGRAM_ERROR, payload: data });
                }
        } catch (error) {
            dispatch({ type: actionType.PROGRAM_ERROR, payload: error });
        }

}



export default homeReducer;