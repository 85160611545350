import * as actionType from './actionsTypes';
import { resHandle } from '../helper';
import { preList, filterPrograms, viewProgramDetail, camperList } from '../services/programService';


const initialState = {
    loader: true,
    preList: {
        camp: [],
        personnel: [],
        category: [],
        subcategory: [],
        city: [],
        program: []
    },
    fSubcategory: [],
    programs: [],
    programDetail: '',
    totalCount: 0,
    camperList: [],
    error: '',
    isChildAction: false,
    sPrograms: []
}


/************************************************************************************/
// Program Reducer
/************************************************************************************/


const programReducer = (state = initialState, action) => {

    switch (action.type) {


        case actionType.PRE_LIST:
            return {
                ...state,
                preList: action.payload
            }


        case actionType.ACTIVE_PROGRAM_LOADER:
            return {
                ...state,
                loader: true
            }



        case actionType.VIEW_PROGRAM_DETAIL:
            return {
                ...state,
                loader: false,
                programDetail: action.payload
            }


        case actionType.FILTER_PROGRAMS:
            return {
                ...state,
                loader: false,
                programs: action.payload
            }


        case actionType.PROGRAMS_TOTAL_COUNT:
            return {
                ...state,
                loader: false,
                totalCount: action.payload
            }


        case actionType.SET_SUB_CATEGORY:
            return {
                ...state,
                fSubcategory: action.payload
            }


        case actionType.CAMPER_LIST:
            return {
                ...state,
                camperList: action.payload
            }


        case actionType.PROGRAM_ERROR:
            return {
                ...state,
                loader: false,
                error: action.payload
            }


        case actionType.CHILD_ACTION:
            return {
                ...state,
                isChildAction: !state.isChildAction,
            }


        case actionType.S_PROGRAM_LIST:
            return {
                ...state,
                sPrograms: action.payload,
            }

            
        default:
            return state
    }
}




/************************************************************************************/
// All Program Actions
/************************************************************************************/


export const programLoaderActiveAction = () => (dispatch => dispatch({ type: actionType.ACTIVE_PROGRAM_LOADER }))

export const childAction = () => (dispatch => dispatch({ type: actionType.CHILD_ACTION }))

export const setSubCatAction = payload => (dispatch => dispatch({ type: actionType.SET_SUB_CATEGORY, payload }))

export const sProgramsAction = payload => (dispatch => dispatch({ type: actionType.S_PROGRAM_LIST, payload }))

export const getPreListAction = () => async dispatch => {
        
        try {
            let preListData = await preList();
            let { status, data } = resHandle(preListData);

            if(status){
                    dispatch({ type: actionType.PRE_LIST, payload: data.data });
                    return status;
                }else{
                    dispatch({ type: actionType.PROGRAM_ERROR, payload: data });
                }
        } catch (error) {
            dispatch({ type: actionType.PROGRAM_ERROR, payload: error });
        }

}


export const getCamperListAction = () => async dispatch => {
        
        try {
            let camperListData = await camperList();
            let { status, data } = resHandle(camperListData);
            if(status){
                    dispatch({ type: actionType.CAMPER_LIST, payload: data.result });
                    return status;
                }else{
                    dispatch({ type: actionType.PROGRAM_ERROR, payload: data });
                }
        } catch (error) {
            dispatch({ type: actionType.PROGRAM_ERROR, payload: error });
        }

}


export const filterProgramsAction = params => async dispatch => {
    dispatch({ type: actionType.ACTIVE_PROGRAM_LOADER })
        try {
            let programs = await filterPrograms(params);
            let { status, data } = resHandle(programs);
            if(status){
                    dispatch({ type: actionType.FILTER_PROGRAMS, payload: data.result });
                    dispatch({ type: actionType.PROGRAMS_TOTAL_COUNT, payload: data.totalCount });
                    return status;
                }else{
                    dispatch({ type: actionType.PROGRAM_ERROR, payload: data });
                }
        } catch (error) {
            dispatch({ type: actionType.PROGRAM_ERROR, payload: error });
        }

}



export const viewProgramAction = params => async dispatch => {
        dispatch({ type: actionType.ACTIVE_PROGRAM_LOADER })
        try {
            let preListData = await viewProgramDetail(params);
            let { status, data } = resHandle(preListData);
            if(status){
                    dispatch({ type: actionType.VIEW_PROGRAM_DETAIL, payload: data.result });
                    return status;
                }else{
                    dispatch({ type: actionType.PROGRAM_ERROR, payload: data });
                }
        } catch (error) {
            dispatch({ type: actionType.PROGRAM_ERROR, payload: error });
        }

}








export default programReducer;