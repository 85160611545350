import React, {useState, useEffect} from 'react';
import {ProgramLoader} from '../reuse/SVG';
import Campers from './campers';

import {profileInformation, updateProfile, deleteCamper} from '../../services/campersServices'

const Participants = () => {

    const[userCampersList, setUserCamperList]                   = useState([]);
    const[loader, setLoader]                                    = useState(true);


    const removeCamper=(camperId)=>{
        let camperlist =[...userCampersList];
        let query = `/?camperId=${camperId}`;
        deleteCamper(query).then(res=>{
                if(res?.data?.statusCode)
                {
                let newcamperlist =  camperlist.filter(item=> item._id !== camperId)
                setUserCamperList(newcamperlist);
                }
        })
    } 
    
    

    useEffect(()=>{
        profileInformation().then((res)=>{
            if(res.data.statusCode == 1){
                let profileInfo = res.data.responseData.result;
                setUserCamperList(profileInfo.campersData);
                setLoader(false);
            }
            else
            {
                setLoader(false);  
            }
        })
    },[])


    return(
        <React.Fragment>
              {loader ? <div className="page_loader"><ProgramLoader /></div> : ''}
        
            <Campers UserCamperList={userCampersList} RemoveCamper={removeCamper}/>

        </React.Fragment>
    )
}

export default Participants;