import React,{useState, useEffect} from 'react';
import AddCoundelor from '../counselor/addCounselor';
import {counselordetail} from '../../services/directorServices';
import { connect } from 'react-redux';
import {ProgramLoader} from '../reuse/SVG';

const CampPersonnelProfileEdit =(props)=>{
    
    
    const[counselorDetails, setCounselorDetails] = useState({});
    const[loading, setLoading]                   = useState(true);   


    useEffect(() => {
        let counselorId = props.userProfile._id;
        counselordetail(`/?counselorId=${counselorId}`).then((res) => {
            if(res.data.statusCode == 1){
                setCounselorDetails(res.data.responseData.result);
                setLoading(false)     
            }
        })
    },[])



    return(
        <div className="card mb-4 pro_wrapper">
        {loading ? <div className="page_loader"><ProgramLoader /></div> : ''}
        <h4 className="text-center mb-5">User Information</h4>
        <div className="row campPersonell_profile">
             <AddCoundelor Details={counselorDetails} EditfromPersonellProfile={true}/>
        </div>
        </div>
    )

}


const mapStateToProps = state => {
    let { userProfile, loggedIn } = state.user
    return {
        userProfile,
        loggedIn
    };
}

export default connect(mapStateToProps, null)(CampPersonnelProfileEdit);
