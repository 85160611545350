import React, { useEffect, useLayoutEffect, useState } from 'react'
import RegistarationComponent from '../reuse/Insurance/RegistrationCancellation/RegistarationComponent'
import InsuranceComponent from '../reuse/Insurance'
import MedicalInsuranceComponent from '../reuse/Insurance/GapMedical/MedicalInsuranceComponent'
import { Fade, Modal } from 'react-bootstrap'
import InsurancePaymentGateway from '../payment/InsurancePaymentGateway'
import { ProgramLoader } from '../reuse/SVG'
import { prepareQuoteData, totalPriceSum } from './bookingHelper'
import { INSURANCE } from '../../Enums/Enum'


const AddInsurance = ({ 
    allData, userEmail, programId, userId,
    camperId, programDetails, addedinsurance, 
    callBack, additionalExperience, showInsurance,  couponMoney }) => {
    const [acceptedInsuranceQuotes, setAcceptedInsuranceQuotes] = useState([]);
    const [insuranceTotal, setInsuranceTotal] = useState(0);
    const [insuranceCheckoutModal, setInsuranceCheckoutModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [item, setItem] = useState({});
    const [additionaExpCharges, seAdditonalexpCharges] = useState(0)
    

    useLayoutEffect(() => {
        let _otherCharges = 0;
        if(additionalExperience && additionalExperience.length> 0){
            _otherCharges = additionalExperience.reduce((_total, adEx) => Number(adEx.price) + _total, 0);
        }
        seAdditonalexpCharges(_otherCharges)
        const bookingAmount = allData?.totalAmount ? allData?.totalAmount : allData?.payment?.amount
        let f_campRegistrationFee = bookingAmount;
        if(!bookingAmount){
            f_campRegistrationFee = allData?.campers?.programCost ? allData?.campers.programCost : totalPriceSum(programDetails?.registrationFee, programDetails?.registerNonRefundFee)
         }
        const f_convenienceFee = allData?.campers?.programCost ? 0 : (programDetails?.f_convenienceFee || 0)
        const _item  = {
            _id: allData?.bookingId,
            campName: allData?.campers?.campName,
            f_campRegistrationFee,
            programId: allData?.campers?.programId,
            programName: allData?.campers?.programName,
            f_convenienceFee
        }
        setItem(_item)
    }, [])

    useEffect(() => {
        let timer;
        const checkComponentLoaded = () => {
            const offers = document.querySelectorAll('multi-offer');
            if (offers.length > 0) {
                timer = setTimeout(() => { setIsLoading(false); }, 1000)
            } else {
                // Retry checking after a delay
                setTimeout(checkComponentLoaded, 100);
            }
        };
        // Start the checking process
        checkComponentLoaded()
        return () => {
            clearTimeout(timer)
        }
    }, [])

    const updateInsuranceQuotes = () => {
        const timeOutId = setTimeout(() => {
            const offers = document.querySelectorAll('multi-offer');
            const _acceptedInsuranceOffers = [];
            const declinedInsuranceOffers = [];
            let _insuranceTotal = 0;

            offers.forEach(offer => {
                if (offer.quotes && offer.quotes.length > 0) {
                    offer.style.display = 'block';
                    offer.quotes.forEach(quoteDetails => {
                        const quoteData = prepareQuoteData(quoteDetails, offer)
                        if (quoteDetails.selectedState === 'ACCEPTED') {
                            quoteData.insuranceStatus = 1;
                            _acceptedInsuranceOffers.push(quoteData);
                            _insuranceTotal += (quoteDetails.quote.total / 100);
                        } else {
                            declinedInsuranceOffers.push(quoteData);
                        }
                    });
                } else {
                    offer.style.display = 'none';
                }
            });
            handleAddedInsurance(_acceptedInsuranceOffers, _insuranceTotal)
        }, 10)
        return () => {
            clearTimeout(timeOutId)
        }
    }
    const handleAddedInsurance = (_acceptedInsuranceOffers, _insuranceTotal) => {
        setAcceptedInsuranceQuotes(_acceptedInsuranceOffers);
        setInsuranceTotal(_insuranceTotal);
    }
    const handleInsurancePurchase = () => {
        setInsuranceCheckoutModal(true)
    }
    const handleInsuranceBooking = () => {
        setInsuranceCheckoutModal(false);
        callBack();
    }
    return (
        <>

            {
                userEmail &&
                <>
                    <h4 className="color_red">Insurance Options : </h4>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center' }}>
                        {showInsurance?.find(i => i.name === INSURANCE.REGISTRATION_CANCELLATION_INSURANCE)?.isShowInsurance && !(addedinsurance.includes(INSURANCE.REGISTRATION_CANCELLATION_INSURANCE)) &&
                            <Fade in={!isLoading}>
                                <InsuranceComponent
                                    client-id="live_OS3ZRXTLX720CFG3PYVR9NW7AC4RODA9"
                                    offerStateChange={updateInsuranceQuotes}
                                    product={INSURANCE.REGISTRATION_CANCELLATION_INSURANCE}
                                    style={{ width: '100%' }}
                                >
                                    <RegistarationComponent
                                        item={item}
                                        additionalExperienceTotal={additionaExpCharges}
                                        couponMoney={0}
                                        camper={allData?.campers}
                                        insuranceState={allData?.campers?.state}
                                        userEmail={userEmail}
                                        userName={allData?.campers.primaryName.split(" ")[0]}
                                        zipCode={allData?.campers?.zipCode}
                                        userLastName={allData?.campers.primaryName.split(" ").at(-1)}
                                        camperDetails={allData?.campers}
                                        camperId={camperId}
                                        startDate={new Date(parseInt(programDetails?.startDate)).toISOString().substring(0, 10)}
                                        endDate={new Date(parseInt(programDetails?.endDate)).toISOString().substring(0, 10)}
                                    />
                                </InsuranceComponent>

                            </Fade>
                        }
                        {showInsurance.find(i => i.name === INSURANCE.GAP_MEDICAL_INSURANCE).isShowInsurance && !(addedinsurance.includes(INSURANCE.GAP_MEDICAL_INSURANCE)) &&
                            <Fade in={!isLoading}>
                                <InsuranceComponent
                                    client-id="live_OS3ZRXTLX720CFG3PYVR9NW7AC4RODA9"
                                    offerStateChange={updateInsuranceQuotes}
                                    product={INSURANCE.GAP_MEDICAL_INSURANCE}
                                    style={{ display: 'none', width: '100%' }}
                                >
                                    <MedicalInsuranceComponent
                                        item={item}
                                        camper={allData?.campers}
                                        insuranceState={allData?.campers?.state}
                                        userEmail={userEmail}
                                        userName={allData?.campers.primaryName.split(" ")[0]}
                                        zipCode={allData?.campers?.zipCode}
                                        userLastName={allData?.campers.primaryName.split(" ").at(-1)}
                                        camperDetails={allData?.campers}
                                        camperId={camperId}
                                        startDate={new Date(parseInt(programDetails?.startDate)).toISOString().substring(0, 10)}
                                        endDate={new Date(parseInt(programDetails?.endDate)).toISOString().substring(0, 10)}
                                        userId={userId}
                                    />

                                </InsuranceComponent>
                            </Fade>
                        }
                    </div>
                    {
                        isLoading && <div className="program_loader"><ProgramLoader /></div>
                    }
                    {acceptedInsuranceQuotes.length > 0 &&
                        <div className='d-flex align-items-center justify-content-end'>
                            <span onClick={handleInsurancePurchase} className="btn btn-primary btn-sm px-4 mt-3">Purchase Insurance</span>
                        </div>
                    }
                </>
            }

            <Modal
                dialogClassName="h_auto"
                show={insuranceCheckoutModal}
                animation={false}
                backdrop="static"
                size='lg'
                centered
            >
                <Modal.Body className='shadow-lg'>
                    <span className="modal_close" onClick={() => setInsuranceCheckoutModal(false)}><i className="icon-cross" /></span>
                    {insuranceCheckoutModal ?
                        <InsurancePaymentGateway
                            insuranceQuotes={acceptedInsuranceQuotes}
                            insuranceAmount={insuranceTotal}
                            userId={userId}
                            bookingId={allData?.bookingId}
                            programId={programId}
                            userEmail={userEmail}
                            callback={handleInsuranceBooking}
                        /> : ''
                    }
                </Modal.Body>
            </Modal>


        </>
    )
}

export default AddInsurance
