import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';

import { getTremsFeedList } from '../../services/otherService';

const PrivacyPolicy = () => {
    const [termsFeeds, setTermsFeeds] = useState([]);
    useEffect(() => {
        let params = `?type=3&isWeb=1`;
        getTremsFeedList(params).then(data => setTermsFeeds(data.result))
    }, [])

    return(
        <div className="body_container tc_pp">
            <div className="container">
                {
                    termsFeeds.map((item, i) => (
                        <React.Fragment key={i}>
                            <h3 className="tc_pp_title">{item.title}</h3>
                            {item.image ? <div className="tc_pp_img"><img src={item.image} /></div> : ''}
                            {parse(item.desc)}
                        </React.Fragment>
                    ))
                }

                {
                    termsFeeds.length ? '' : <p className="text-center">No data</p>
                }
            </div>
        </div>
    )
}

export default PrivacyPolicy;