import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { unreadCountAction } from '../../store/chat';
import { changeInboxTabAction, handleNewMsgModalAction } from '../../store/inbox';
import Templates from './Templates';
import Automations from './Automations';
import ChatView from './ChatView';
import NewMsgModalComponent from './NewMsgModalComponent';


const InboxPage = props => {
    return(
        <div className="body_container">
            <div className="container">
                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Inbox</h4>

                    {
                        props?.userProfile?.UserType === 2 ? <div className='inbox_tabs'>
                            <span className={props.inboxTab === 1 ? 'active' : ''} onClick={() => props.changeInboxTabAction(1)}>Conversations</span>
                            <span className={props.inboxTab === 2 ? 'active' : ''} onClick={() => props.changeInboxTabAction(2)}>Automated Action</span>
                            <span className={props.inboxTab === 3 ? 'active' : ''} onClick={() => props.changeInboxTabAction(3)}>Templates</span>
                        </div> : ''
                    }
                    
                    <div className="btn_group">
                        <span className="btn btn-outline-primary btn-sm rounded-0" onClick={() => props.handleNewMsgModalAction(true)}><i className="icon-create" /> New Message</span>
                        {/* <span className="btn btn-secondary ml-3 btn-sm rounded-0"><i className="icon-users" /> Organize Group</span> */}
                    </div>
                </div>

                
                {props.inboxTab === 1 ? <ChatView /> : ''}
                {props.inboxTab === 2 ? <Automations /> : ''}
                {props.inboxTab === 3 ? <Templates /> : ''}

                {props.newMsgModal ? <NewMsgModalComponent /> : ''}

            </div>

        </div>
    )
}


const mapStateToProps = state => {
    let { userProfile } = state.user;
    let { bookingUserChat } = state.chat;
    let { inboxTab, newMsgModal } = state.inbox;
    return {
        userProfile,
        bookingUserChat,
        inboxTab,
        newMsgModal
    };
}
  
const mapDispatchToProps = dispatch => ({
    unreadCountAction: () => dispatch(unreadCountAction()),
    changeInboxTabAction: params => dispatch(changeInboxTabAction(params)),
    handleNewMsgModalAction: params => dispatch(handleNewMsgModalAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InboxPage);