import moment from 'moment';
import React from 'react'
import Slider from 'react-slick'
import {v4 as uuidv4} from 'uuid'
import { isNonEmptyArray } from '../../helper';
import SubProductInfo from './SubProductInfo';

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const ShowProductInfo = ({ activeProduct }) => {
    const getTotalpriceOfItem = (item) => {
        const _ItemPrice = (+item.itemPrice * item.quantity);
        const _totalSubItemsPrice = (item?.subItems?.length === 0) ? 0 : item?.subItems?.reduce((total, si) => total + (+si.price * +si.count), 0);
        return (_ItemPrice + _totalSubItemsPrice);
    }
    return ( 
            <div className='row'>
                <div className='col-lg-4 pb-5'>

                    {
                        activeProduct?.image?.length ? <Slider {...settings}>
                            {activeProduct?.image.map((img, i) => (
                                <img src={img} key={uuidv4()} className="img-fluid" />
                            ))}
                        </Slider> : ''
                    }
                </div>
                <div className='col-lg-8'>
                    <h3>{activeProduct.name}</h3>
                    <div className=''>
                        <div className="form-group mb-0">
                            <label>Size: </label>
                            <b className='ml-2'>{activeProduct.sizes}</b>
                        </div>

                        <div className={`form-group mb-0`}>
                            <label>Color: </label>
                            <b className='ml-2'>{activeProduct.color}</b>
                        </div>

                        <div className={`form-group mb-0`}>
                            <label>Item Count: </label>
                            <b className='ml-2'>{activeProduct.quantity}</b>
                        </div>

                        <div className={`form-group mb-0`}>
                            <label>Order Date: </label>
                            <b className='ml-2'>{moment(activeProduct.orderedDate).format('DD MMM, YYYY')}</b>
                        </div>

                        <div className={`form-group mb-0`}>
                            <label>Price : </label>
                            <b className='ml-2'>{activeProduct.itemPrice}</b>
                        </div>
                    </div>



                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <div className="product_price">
                            <h2><b>Total Price: ${getTotalpriceOfItem(activeProduct)?.toFixed(2)}</b></h2>
                        </div>
                    </div>

                    { isNonEmptyArray(activeProduct?.subItems) && <h4>Add-ons</h4> }
                        <div className="d-flex flex-column flex-sm-row flex-wrap align-content-stretch align-items-stretch">
                        {isNonEmptyArray(activeProduct?.subItems) &&
                            activeProduct?.subItems?.map((subItem, si) =>
                                < SubProductInfo subItem = {subItem}  key ={uuidv4()}  />
                            )
                        }
                    </div>
                </div>
            </div>
    )
}

export default ShowProductInfo
