import { combineReducers } from 'redux';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import uiReducer from './ui';
import userReducer from './user';
import feedReducer from './feed';
import pickupReducer from './pickup';
import homeReducer from './home';
import programReducer from './program';
import pFilterReducer from './pFilter';
import autoSchedulerReducer from './autoscheduler';
import cartReducer from './cart';
import calendarReducer from './calendar';
import dFolderReducer from './dFolder';
import chatReducer from './chat';
import cabinReducer from './cabin';
import allFilterStateReducer from './allFilterState';
import inboxReducer from './inbox';


const rootReducer = combineReducers({
    ui: uiReducer,
    user: userReducer,
    feed: feedReducer,
    pickup: pickupReducer,
    home: homeReducer,
    program: programReducer,
    pFilter: pFilterReducer,
    autoScheduler: autoSchedulerReducer,
    cartReducer: cartReducer,
    calendar: calendarReducer,
    dFolder: dFolderReducer,
    chat : chatReducer,
    cabin : cabinReducer,
    allFiterState : allFilterStateReducer,
    inbox : inboxReducer,
});

const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware
    )
);

export default store;

