import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { togglePickupUserModalAction, pickupTabAction } from '../../store/pickup';


const PickupUserModal = props => {
    const [pickupTab, setPickupTab] = useState(0);
    const [pickupTabErr, setPickupTabErr] = useState(0);

    const handleNext = () => {
        if(pickupTab){
            props.pickupTabAction(pickupTab)
        }else{
            setPickupTabErr('Please select user type')
        }
       
    }

    return(
        <Modal
            show={props.pickupUserModal}
            animation={false}
            dialogClassName="pickup_modal"
            size="lg"
            centered
        >
            <Modal.Body>
                <span className="modal_close" onClick={props.togglePickupUserModalAction}><i className="icon-cross" /></span>
                <div className="text-center p-5 pickup_modal_body">
                    <h3>Is the person you would like to authorize for pickup a _____?</h3>
    
                    <div className="d-flex justify-content-center">
                        <div className={`p-5 ${pickupTab === 1 ? 'active' : ''}`} onClick={() => (setPickupTab(1), setPickupTabErr(''))}>
                            <img src={require('../../assets/img/camps-user.png')} />
                            <h4>CampsEZ User</h4>
                        </div>
                        <div className={`p-5 ${pickupTab === 2 ? 'active' : ''}`} onClick={() => (setPickupTab(2), setPickupTabErr(''))}>
                            <img src={require('../../assets/img/non-user.png')} />
                            <h4>Non-User</h4>
                        </div>
                    </div>
    
                    <button className="btn btn-primary" onClick={handleNext}>Next</button>

                    {pickupTabErr ? <h6 className='cm_err mt-3'>{pickupTabErr}</h6> : ''}
                </div>
            </Modal.Body>
        </Modal>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { pickupUserModal, pickupTab } = state.pickup;
    return {
        pickupUserModal,
        pickupTab
    };
}
  
const mapDispatchToProps = dispatch => ({
    togglePickupUserModalAction: () => dispatch(togglePickupUserModalAction()),
    pickupTabAction: params => dispatch(pickupTabAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(PickupUserModal);