
import ApiInstance from './intercepter';
import API from '../config/api';


export const createPost = params => ApiInstance.post(API.CREATE_POST, params);

export const uploadToS3 = params => ApiInstance.post(API.UPLOAD_TO_S3, params);

export const getPostsService = params => ApiInstance.get(API.GET_POSTS + params);

export const createCommentService = params => ApiInstance.post(API.CREATE_COMMENT, params);

export const getCommentsService = params => ApiInstance.get(API.GET_COMMENTS + params);

export const postLikeService = params => ApiInstance.post(API.POST_LIKE, params);

export const getFriendsAndCamps = params => ApiInstance.get(API.GET_FRIENDS_AND_CAMPS + params);

export const getUserDetailsAndPosts = params => ApiInstance.get(API.GET_USER_DETAILS_AND_POSTS + params);

export const getFeedDetailService = params => ApiInstance.get(API.GET_FEED_DETAIL + params);

export const sendRequestService = params => ApiInstance.post(API.SEND_REQUEST, params);

export const deletePostService = params => ApiInstance.post(API.DELETE_POST, params);

export const deleteCommentService = params => ApiInstance.delete(API.DELETE_COMMENT + params);
