import * as actionType from './actionsTypes';

const initialState = {
    inboxTab: 1,
    newMsgModal: false,
    getChatUserListCalback: 0,
    activeUserChatWindow: {}
}

const inboxReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionType.INBOX_TAB: 
            return {
                ...state,
                inboxTab: action.payload 
            } 

        case actionType.NEW_MSG_MODAL: 
            return {
                ...state,
                newMsgModal: action.payload 
            } 
        case actionType.GET_CHAT_USER_LIST_CALBACK: 
            return {
                ...state,
                getChatUserListCalback: action.payload 
            } 
        case actionType.ACTIVE_USER_CHAT_WINDOW: 
            return {
                ...state,
                activeUserChatWindow: action.payload 
            } 

        default:
            return state
    }
}

export const changeInboxTabAction = (payload) => async dispatch => dispatch({ type: actionType.INBOX_TAB, payload });

export const handleNewMsgModalAction = (payload) => async dispatch => dispatch({ type: actionType.NEW_MSG_MODAL, payload });

export const getChatUserListCalbackAction = (payload) => async dispatch => dispatch({ type: actionType.GET_CHAT_USER_LIST_CALBACK, payload });

export const activeUserChatWindowAction = (payload) => async dispatch => dispatch({ type: actionType.ACTIVE_USER_CHAT_WINDOW, payload });




export default inboxReducer;