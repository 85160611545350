import React, { useState, useEffect } from 'react';

const DateSelector = ({ dateValue, onDateChange, disabledDate, dobErr, setDobErr }) => {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];
  const currentYear = new Date().getFullYear();
  const earliestYear = currentYear - 100;
  const latestYear = currentYear;

  const initializeState = (value) => {
    const date = value ? new Date(value) : new Date();
    return {
      day: date.getDate().toString(),
      month: months[date.getMonth()],
      year: date.getFullYear().toString(),
    };
  };


  const [day, setDay] = useState();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [days, setDays] = useState([]);

  useEffect(() => {
    const monthIndex = months.indexOf(month);
    const daysInMonth = new Date(year, monthIndex + 1, 0).getDate();
    setDays(Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString()));
  }, [month, year]);

  useEffect(() => {
    const newState = initializeState(dateValue);
    setDay(newState.day);
    setMonth(newState.month);
    setYear(newState.year);
  }, [dateValue]);

  const handleDayChange = (e) => {
    setDay(e.target.value);
    updateParentDate(year, month, e.target.value);
  };

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
    updateParentDate(year, e.target.value, day);
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
    updateParentDate(e.target.value, month, day);
  };



  
  const updateParentDate = (y, m, d) => {
    const monthIndex = months.indexOf(m);
    const dayInt = parseInt(d, 10);
    const yearInt = parseInt(y, 10);
    // Initialize newDate here but defer setting it due to validations
    let newDate;
    setDobErr('');
    // Validation for year
    if (y.length !== 4 || isNaN(yearInt)) {
      setDobErr(`Please enter the year`);
      return; // Exit the function early
    }

    // Validation for month
    if (monthIndex === -1) {
      setDobErr("Invalid month selected.");
      return; // Exit the function early
    }

    // Validation for day
    if (isNaN(dayInt) || dayInt < 1 || dayInt > 31) {
      setDobErr("Day must be between 1 and 31.");
      return; // Exit the function early
    }

    // Attempt to create the date
    newDate = new Date(y, monthIndex, dayInt);
    newDate.setHours(0, 0, 0, 0); // Normalize the time

    // Additional validation to check if the date exists in the calendar
    if (newDate.getMonth() !== monthIndex) {
      setDobErr("The selected date does not exist.");
      return; 
    }

    // Check if the date is disabled by the parent component
    if (disabledDate(newDate)) {
      setDobErr("Not a valid Date of Birth");
      return; 
    }

    // If all validations pass, clear the error and update the parent component
    
    onDateChange(newDate); // Update the parent component with the valid date
  };


  const yearOptions = Array.from({ length: latestYear - earliestYear + 1 }, (_, i) => `${earliestYear + i}`);

  return (
    <div className="datePickerContainer" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div style={{ display: 'flex', gap: '10px' }}>
        <select value={day} onChange={handleDayChange}>
          <option value="">Day</option>
          {days.map(d => <option key={d} value={d}>{d}</option>)}
        </select>
        <select value={month} onChange={handleMonthChange}>
          <option value="">Month</option>
          {
          months.map(m => <option key={m} value={m}>{m} </option>)
          }
        </select>
        <input
          list="yearList"
          value={year}
          onChange={handleYearChange}
          style={{
            width: '100px',
            padding: '8px 12px',
            fontSize: '16px',
            borderColor: '#ccc',
            borderRadius: '4px',
            outline: 'none',
            boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
          }}
        />
        <datalist id="yearList" >
          {yearOptions.map(y => <option key={y} value={y} style={{ backgroundColor: "red" }} />)}
        </datalist>
      </div>
      {dobErr && (
        <div className="errorMsg" style={{ color: 'red', marginTop: '10px' }}>
          {dobErr}
        </div>
      )}
    </div>
  );
};

export default DateSelector;
