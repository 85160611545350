import React from 'react';
import { Modal } from 'react-bootstrap';

const ConfirmDiloag = ({show, hideModal, ProgramName, action, Message, handleCrossButton}) => (
    <Modal show={show} centered>
        <Modal.Body>
            {handleCrossButton  && <span className="modal_close" onClick={handleCrossButton}><i className="icon-cross" /></span>}
            <p className="text-center">
            {Message}
            </p>
            <div className="d-flex justify-content-center mt-5 mb-4">
                <button onClick={hideModal} className="btn btn-light mr-4 pl-5 pr-5">No</button>
                <button className="btn btn-primary pl-5 pr-5" onClick={action}>Yes</button>
            </div>
        </Modal.Body>
    </Modal>
)

export default ConfirmDiloag;