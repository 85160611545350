import React, {  useState, useEffect } from 'react';

import UserListing from './userListing';
import {campsezUserList} from '../../services/authrisedPickupServeice';
import { ProgramLoader } from '../reuse/SVG';
import { Empty } from 'antd';
import PaginatedComponent from '../common/Pagination/PaginationComponent';


const CampsEZUser = props => {
    const [campsezUsers, setCampsezUsers] =useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);


    const getUserList = () => {
        let query = `/?limit=20&page=${page - 1}&search=${search}`;
        campsezUserList(query).then(res => {
            if(res?.data?.statusCode){
                let userList = res.data.responseData.result;
                setCampsezUsers(userList);
                setTotalCount(res.data.responseData.count);
                setLoading(false)
            }
        })
    }

    useEffect(()=>{
        getUserList()
    },[search, page])

    return(
        <div className="body_container">
            <div className="container">
                <div className="pickup_user_wrapper">
                    <h3>Authorized Pickup</h3>

                    {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text</p> */}

                    <div className="search_wrap">
                            <i className="icon-search" />
                            <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Search"
                                value={search}
                                onChange={e => (setSearch(e.target.value), setPage(1))} />
                        </div>

                    {loading ? <div className="program_loader"><ProgramLoader /></div> : campsezUsers.length ? <UserListing CampsezUsers={campsezUsers} /> : <div className="cm_empty mb-4 mt-4"><Empty description="No Users Found" /></div>}

                    <div className="d-flex justify-content-center mt-5">
                    <PaginatedComponent
                        activePage={page}
                        itemsCountPerPage={20}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={4}
                        onPageChange={setPage}/>
                </div>
                </div>
            </div>
        </div>
    )

}

  
export default CampsEZUser;
