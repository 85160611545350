import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Empty, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { userListService } from '../../services/otherService';
import { ProgramLoader } from '../reuse/SVG';
import { isEmptyOrWhitespace, isMoreThan1sAgo, isValidEmail, resHandle } from '../../helper';
import { fastSignup } from '../../services/authService';
import { counselordetail } from '../../services/directorServices';
import PaginatedComponent from '../common/Pagination/PaginationComponent';


const Users = props => {
    let history = useHistory();

    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [loader, setLoader] = useState(false);
    const [userSignupModal, setUserSignupModal] = useState(false);

    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [lastNameErr, setLastNameErr] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [resErr, setResErr] = useState('');
    const [permissions, setPermissions] = useState({});
    const [lastTypedTime, setLastTypedTime] = useState(new Date());

    const limit = 20;


    useEffect(() => {
        if(props.userProfile.UserType == 4){
            counselordetail(`/?counselorId=${props.userProfile._id}`).then((res) => {
                if(res.data.statusCode == 1){
                    setPermissions(res.data.responseData.result.permissions || {}) 
                }
            })
        }
    }, [props.userProfile])



    const handleClose = () => {
        setUserSignupModal(false);
        setName('');
        setLastNameErr('');
        setLastName('');
        setNameErr('');
        setEmail('');
        setEmailErr('');
        setResErr('');
    }



    const handleValidate = () => {
        let validate = true;

        if(name == ''){
            setNameErr('First name is required');
            validate = false
        }

        if(lastName == ''){
            setLastNameErr('Last name is required');
            validate = false
        }

        if(email == ''){
            setEmailErr('Email is required');
            validate = false
        }else if(!isValidEmail(email)){
            setEmailErr('Email not valid');
            validate = false
        }

        return validate;
    }


    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            let params = {
                deviceID: 'campsez',
                deviceToken: 'campsez',
                deviceTypeID: '3',
                UserType: 1,
                name,
                lastName,
                email,
                password:'CampsEZ@123',
                campCode: props.userProfile.campCode,
            }
            setLoader(true);
            fastSignup(params).then(res => {
                let { status, data } = resHandle(res);
                setLoader(false);
                if(status){
                    history.push(`/users/detail/${data.userId}`);
                        notification.open({
                            message: 'User',
                            description: `User created successfully`
                        });
                    }else{
                        setResErr(data)
                    }

    

            })
            
        }
    }


    const getList = () => {
        setLoader(true)
        let params = {
            type: 1,
            search: search.trim(),
            page: page - 1,
            limit,
            campCode: props.userProfile.campCode,
            isSearchInChild:true,  //TODO : make it dynamic.
        };
        setTotalCount(0);
        userListService(params).then(res => {
           if(res?.data?.statusCode){
            setTimeout(() => {
                setUsers(res.data.responseData.result);
                setTotalCount(res.data.responseData.count);
            }, 50)
          
           }

           setLoader(false);
       }).catch((e) => console.log(e) ).finally(() => setLoader(false))
    }
  
    useEffect(() => {
        let handler = setTimeout(() => {
                if (isMoreThan1sAgo(lastTypedTime)) {
                getList()
                }
            }, 1500);

      return () => {
        clearTimeout(handler);
      };
    }, [search, lastTypedTime, page]);
  
    const handleInputChange = (e) => {
        setSearch(e.target.value)
         setPage(1)
        setLastTypedTime(new Date());
    };



    useEffect(() => {
            if(props?.userProfile?.UserType === 2 || props?.userProfile?.UserType === 4){
                if(props?.userProfile?.UserType === 4){
                    if(permissions.addUser === 0){
                        history.push('/');
                    }
                }
            }else{
                history.push('/');
            }

    }, [props.userProfile, permissions.addUser])

    const handleRowClick = (id) => {
       const url =  `/users/detail/${id}`
       history.push(url);
    }
    return(
        <>
         <Modal show={loader} onHide={handleClose} centered>
            <Modal.Body className="transparent-modal">
                <div className="transparent program_loader">
                    <ProgramLoader />
                </div>
            </Modal.Body>
        </Modal>

        <div className="body_container d_users_list">
            <div className="pl-3 pr-3 mt-4">
                <div className='d-flex align-items-center justify-content-between'>
                    <div className="search_wrap">
                        <i className="icon-search" />
                        <input
                            type="text"
                            placeholder="Search..."
                            className="form-control"
                            value={search}
                            onChange={e => (handleInputChange(e))} />
                    </div> 

                    <div>
                        {props?.userProfile?.UserType == 4 && !permissions.addUser ? '' : <span className='btn btn-primary' onClick={() => setUserSignupModal(true)}>Create User</span>}
                    </div>
                </div>
            </div>

            <div className="table_responsive_wrap text-center p-3">
                {
                    <>             
                            <div className="table-responsive">
                    
                                <table className="table table-hover table-bordered table-striped no_t_texts">

                                    <thead>
                                        <tr>
                                            <th><span className="t_text">S.No</span></th>
                                            <th><span className="t_text">User Name</span></th>
                                            <th><span className="t_text">User Code</span></th>
                                            <th><span className="t_text">Camper Name</span></th>
                                            <th><span className="t_text">Email</span></th>
                                            <th><span className="t_text">Phone Number</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            users.map((user, i) => (
                                               
                                                <tr key={user._id} onClick={() => handleRowClick(user._id)} className='clickable-link'>
                                                    {/* <Link title="View User" to={`/users/detail/${user._id}`} style={{color: '#000'}}></Link>  </Link> */}
                                                    <td><span className="t_text">{((i + 1) + ((page - 1) * limit))}</span></td>
                                                    <td><span className="t_text">{user.name}</span></td>
                                                    <td><span className="t_text">{user.userCode}</span></td>
                                                    <td><span className="t_text">{Array.isArray(user.campersData) ? user.campersData.map(camper => (<b className='camper_name'>{camper.firstName}</b>)) : 'N/A'}</span></td>
                                                    <td><span className="t_text">{user.email}</span></td>
                                                    <td><span className="t_text">{user.phone}</span></td>
                                                    
                                                </tr>
                                              
                                            ))
                                        }
                                        
                                    
                                    </tbody>
                                </table>
                            </div>

                            {
                                users.length ? '' : <div className="mt-4 text-center cm_empty"><Empty description="Data Not Found" /></div>
                            }

                            {
                                users.length ? 
                                <div className="d-flex justify-content-center mt-5">
                                    <PaginatedComponent
                                        activePage={page}
                                        itemsCountPerPage={limit}
                                        totalItemsCount={totalCount}
                                        pageRangeDisplayed={3}
                                        onPageChange={setPage}/>
                                </div> 
                                : ''
                            }
                    </>
                }


            </div>









            <Modal show={userSignupModal} centered dialogClassName="remove_header_line">
                <Modal.Header>
                    <h3>Create New User</h3>
                    <span className="modal_close" onClick={handleClose}><i className="icon-cross" /></span>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="First Name"
                                className={`form-control ${nameErr ? 'is-invalid' : ''}`}
                                name="name"
                                value={name}
                                onChange={e => (setName(e.target.value), setNameErr(''), setResErr(''))} />
                            
                            {nameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{nameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                        </div>


                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Last Name"
                                className={`form-control ${lastNameErr ? 'is-invalid' : ''}`}
                                name="lastName"
                                value={lastName}
                                onChange={e => (setLastName(e.target.value), setLastNameErr(''), setResErr(''))} />
                            
                            {lastNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{lastNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                        </div>

                        <div className="form-group">
                            <input type="email" name="email" value="" style={{display: 'none'}} />
                            <input
                                type="email"
                                placeholder="Email"
                                className={`form-control ${emailErr ? 'is-invalid' : ''}`}
                                name="email"
                                autocomplete="nope"
                                value={email}
                                onChange={e => (setEmail(e.target.value), setEmailErr(''), setResErr(''))} />
                            
                            {emailErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{emailErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>

                        {resErr ? <p className="error_msg">{resErr}</p> : ''}

                        <button
                            type="submit"
                            disabled={loader}
                            className={`btn btn-primary rounded-0 btn-block mt-3 btn_anim ${loader ? 'show_anim' : ''}`}>{loader ? 'Loading...' : 'Sign Up'}</button>
                        </form>
                </Modal.Body>
            </Modal>
           
        </div>
        </>
    )
}


const mapStateToProps = state => {
    let { userProfile } = state.user;
    return {
      userProfile
    }
  }
  
  const mapDispatchToProps = dispatch => ({

  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(Users);
