import React, { useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

const SecureInput = ({value,name, onChange, error, maxlength, placeholder, type="text", iconTop=70, eyeColor=""}) => {
    const [isVisible, setIsVisible] = useState(false)
  return (
    <>
    <input
        type={isVisible ? type : "password"}
        className={`form-control ${error ? 'is-invalid' : ''}`}
        value={value}
        name={name}
        onChange={onChange}
        maxLength={maxlength}
        aria-label="Password field"
        placeholder={placeholder}
        style={{
            paddingRight: '30px',
            fontFamily: 'monospace',
            fontSize: '16px',
        }}
    />
        {!error &&
        <button
            type="button"
            onClick={() => setIsVisible(!isVisible)}
            style={{
                position: 'absolute',
                right: '15px',
                top: `${iconTop}%`,
                transform: 'translateY(-50%)',
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
            }}
        >
            {isVisible ? <FaEyeSlash color={eyeColor} size={20} /> : <FaEye size={20}  color={eyeColor}/>}
        </button>
        }
    </>
  
)
}

export default SecureInput
