import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import { getGalleryService } from '../../services/programService';
import { Empty } from 'antd';


const Gallery = props => {
    const [photos, setPhotos] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [show, setShow] = useState(false);
    const [currentImage, setCurrentImage] = useState('');
    const [currentIndex, setCurrentIndex] = useState('');


    const getPhotos = () => {
        let query = `?programId=${props.match.params.id}&pageNo=${pageNo}&limit=${10}`;

        getGalleryService(query).then(res => {
            if(res?.data?.statusCode){
                setPhotos(res.data.responseData.gallery)
            }
        })
    }


    useEffect(() => {
        getPhotos()
    }, [pageNo])


    const handleModalOpen = index => {
        setCurrentImage(photos[index]);
        setCurrentIndex(index);
        setShow(true)
    }

    const handlePrev = () => {
        if(currentIndex > 0){
            setCurrentImage(photos[currentIndex - 1]);
            setCurrentIndex(currentIndex - 1 );
        }
    }


    const handleNext = () => {
        if(currentIndex < photos.length - 1){
            setCurrentImage(photos[currentIndex + 1]);
            setCurrentIndex(currentIndex + 1 );
        }
    }


    return(
        <div className="content_wrapper booking_page ">
            <h2>Photos</h2>
            
            
            <div className="row">
                {
                    photos.length ? photos.map((photo, i) => (
                        <div className="col-lg-3 col-sm-6 gal_card">
                            <img src={photo} onClick={() => handleModalOpen(i)} />
                        </div>
                    )) : <div className='d-flex w-100 justify-content-center'><div className="cm_empty"><Empty description="No Data Found" /></div></div>
                }
            </div>





            <Modal className="gall_popup" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    {currentIndex > 0 ? <div className="gal_prev" onClick={handlePrev}></div> : ''}
                    
                    <img src={currentImage} />

                    {currentIndex < photos.length - 1 ? <div className="gal_next" onClick={handleNext}></div> : ''}
                    
                </Modal.Body>
            </Modal>
    

        </div>
    )
}

export default Gallery;
