import ApiInstance from './intercepter';
import API from '../config/api';


export const giftCardList = params => ApiInstance.post(API.GIFT_CARDS_LIST, params);


export const purchaseGiftCard = params => ApiInstance.post(API.PURCHASE_GIFT_CARD, params, {headers: {
    accessToken: localStorage.getItem('NonLoginaccessToken') 
  }});

export const giftCardDetailService = params => ApiInstance.get(`${API.GIFT_CARD_DETAIL}${params}`);
