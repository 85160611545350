import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import UserImg from '../../assets/img/user.png'
import { getUserHistroyService } from '../../services/campersServices';
import { resHandle } from '../../helper';
import { getUserDetailService } from '../../services/otherService';
import { ProgramLoader } from '../reuse/SVG';

const AboutActiveChatUser = props => {
    const [allBookings, setAllbookings] = useState([])
    // const [displayBookings, setDisplayBookings] = useState([])
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState('')
    const [loader, setLoader] = useState(false)

    const getUserData = async () => {
        setAllbookings([]);
        setUserData(null);
        const userId = props?.activeUserChatWindow?.userId[0]?._id;
        let query = `?userId=${userId}&campCode=${props?.userProfile?.campCode}`;
        try {
            setLoader(true)
            const [userDataResponse, userHistoryResponse] = await Promise.all([
                getUserDetailService(userId),
                getUserHistroyService(query)
            ]);
            const { status: userStatus, data: userData } = resHandle(userDataResponse)
            const { status: historyStaus, data: historyData } = resHandle(userHistoryResponse)
            if (userStatus) {
                setUserData(userData.result);
            } else {
               
                throw new Error("Error while fetching user details.")
            }
            if (historyStaus) {
                setAllbookings(historyData.result);
            } else {
                throw new Error("Error while fetching user History.")
            }
            setLoader(false)
        } catch (error) {
            setError("Failed to load user details")
            setLoader(false)
        }

    }

    useEffect(() => {
        if (props?.activeUserChatWindow?.userId[0]?._id) {
            getUserData()
        }
    }, [props])

    return (

        <div className="about_active_chat_user">

            <img src={props?.activeUserChatWindow?.userId[0]?.profileImage || UserImg} alt='profile' />
            <h4>{props?.activeUserChatWindow?.userId[0]?.name}</h4>
            {userData &&
                <div className='d-flex flex-column'>
                    <small>Phone: {userData?.phone}</small>
                    <small>E-mail: {userData?.email}</small>
                </div>
            }
            <hr />
            {loader ? <div className="program_loader "><ProgramLoader /></div> :
                <div className='user_chat_bookings scrollable-div p-2'>
                    {allBookings.length > 0 ?
                        allBookings.map(item => (
                            <div key={item._id}
                                className='dir_booking_card  d-flex flex-column align-items-start justify-content-start'
                                style={{ textAlign: 'start' }}
                            >
                                <p>Booking No. : {item?.bookingNumber}</p>
                                {item?.programs?.length ? item.programs.map((p, i) =>
                                    <React.Fragment key={p._id}>
                                        <p>Program : {p?.programName}</p>
                                        <p>participants: </p>
                                        <div className='d-flex flex-wrap'>
                                            {p.campers.map((cp) =>
                                                <Link
                                                    className='m-1'
                                                    key={cp?._id}
                                                    to={`/bookings/${item.bookingId}/${cp.camperId}/${p.programId}`}
                                                    target="_blank" 
                                                    rel="noopener noreferrer"
                                                >
                                                    <small className='p-2 rounded-pill text-white'> {cp.camperName}</small>
                                                </Link>
                                            )}
                                        </div>
                                    </React.Fragment>) : ''}
                            </div>
                        ))
                        :
                        <div>
                            {error ? <h6 className='text-danger'>{error}</h6> : <h6>No data Available</h6>}
                        </div>
                    }
                </div>
            }
        </div>
    )
}


const mapStateToProps = state => {
    let { userProfile } = state.user;
    let { activeUserChatWindow } = state.inbox;
    return {
        userProfile,
        activeUserChatWindow
    };
}

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(AboutActiveChatUser);