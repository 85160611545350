import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { getColors2, getSizes } from './OnlineStore';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Addon = ({ item, updateAddons, count, sizes, colors}) => {
    const [currentCount, setCurrentCount] = useState(count ? count : 0);
    const [countError, setCountError] = useState('');
    const [selectedSizeErr, setSelectedSizeErr] = useState('');
    const [selectedSize, setSelectedSize] = useState(sizes ? {label: sizes, value: sizes} : '');
    const [selectedColor, setSelectedColor] = useState(colors ? {label: colors, value: colors}: '');
    const [selectedColorErr, setSelectedColorErr] = useState('');
    const handleCountChange = (type) => {
        const _isValidCheck = (getSizes(item.sizes)?.length > 0);
        if (_isValidCheck && (!selectedColor || !selectedSize)) {
            !selectedColor && setSelectedColorErr('Please select color');
            !selectedSize && setSelectedSizeErr('Please select size');
        } else {
            switch (type) {
                case 'add':
                    if(currentCount < item.totalCount){
                        setCurrentCount(prev => prev + 1);    
                    }else{
                        setCountError(`only ${item.totalCount} products available in store`)
                    }
                    break; 
                case 'remove':
                    currentCount > 0 && setCurrentCount(prev => prev - 1);
                    setCountError('');
                    break;
                default:
                    break;
            }
        }

    }

    useEffect(() => {
        ((currentCount >=0) || selectedSize || selectedColor) && updateAddons(currentCount, item.id, selectedSize, selectedColor);
    }, [currentCount, selectedSize, selectedColor])


    return (
        <>
            <div className='product_card p-3 mr-3 ml-0'>
                
                {
                    item?.image?.length ? <img src={item.image[0]} className="img-fluid pointer mx-2" style={{ width: '150px', height: "150px" }} />
                        : ''
                }
                
                <div className='product_text m-0'>

                    <div className='pro_card_price'>
                        <p>Price: ${item?.price?.toFixed(2)}</p>
                        {item.categoryName ? <span className='p_cat_text'>{item.categoryName}</span> : ''}
                    </div>
                    <div style={{ width: '150px'}}>
                        <h3 style={{ color: item.textColor || '#000' }}>{item.title}</h3>
                    </div>
                </div>
                    {
                        (getSizes(item.sizes)?.length > 0) &&
                        <div className="form-group w-100">
                            <label>Size</label>
                            <Select
                                
                                className={`form-control ${selectedSizeErr ? 'is-invalid' : ''}`}
                                placeholder="Select Size"
                                value={selectedSize}
                                onChange={e => (setSelectedSize(e), setSelectedSizeErr(''), setSelectedColor(''))}
                                classNamePrefix="cm_select"
                                options={getSizes(item.sizes)} />

                            {selectedSizeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{selectedSizeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    }
                    {
                        (getColors2(item.sizes, selectedSize?.value)?.length > 0) &&
                        <div className={`form-group w-100 ml-2`}>
                            <label>Color</label>
                            <Select
                                isDisabled={(getSizes(item.sizes).length && !selectedSize.value) ? true : false}
                                className={`form-control ${selectedColorErr ? 'is-invalid' : ''}`}
                                placeholder="Select Color"
                                value={selectedColor}
                                onChange={e => (setSelectedColor(e), setSelectedColorErr(''))}
                                classNamePrefix="cm_select"
                                // options={getColors(item.sizes)?.length ? getColors(item.sizes) : []} />
                                options={getColors2(item.sizes, selectedSize.value)} />

                            {selectedColorErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{selectedColorErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    }
                    
                    <div className="quantity d-flex justify-content-center mb-4 mx-3">
                        <button onClick={() => handleCountChange('remove')} className="inc"></button>
                        <b>{currentCount}</b>
                        <button onClick={() => handleCountChange('add')} className="dec"></button>  
                    </div>
                    {countError ? <small className="error_msg"> {countError}</small> : ''}
            </div >
    </>
  )
}

export default Addon
