import React, {useState} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {isValidPassword } from '../../helper';
import {changePassword} from '../../services/campersServices';
import { Link } from "react-router-dom";
import {notification} from 'antd';

const ChangePassword = props => {

    const [oldPassword, setoldPassword]                          = useState('');
    const [oldPasswordError, setoldPasswordError]                = useState('');
    const [oldpasswordMatchError, setOldPasswordMatchError]      = useState('');
    const [password, setPassword]                                = useState('');
    const [passwordError, setPasswordError]                      = useState('');
    const [confrmpassword, setConfirmPassword]                   = useState('');
    const [confrmpasswordError, setConfirmPasswordError]         = useState('');
    const [passwordMatchError, setPasswordMatchError]            = useState('');
    const [btnloder, setBtnLoader]                               = useState(false);
    const [showPass, setShowPass]                                = useState(false);
    const [showPass2, setShowPass2]                              = useState(false);



const handelChange =(e) => {
    let name = e.target.name;
    let value = e.target.value;

    if(name == 'oldpassword'){
        setoldPassword(value);
        setoldPasswordError('')
    }
    if(name == 'newpassword'){
        setPassword(value);
        setPasswordError('')
    }
    if(name == 'confirmpassword'){
        setConfirmPassword(value);
        setConfirmPasswordError('')
    }
}

const handelSubmit = e => {
    e.preventDefault();

    let errocount = 0 ;
    
    if(oldPassword === undefined || oldPassword === ''){
        setoldPasswordError('Old password is required');
        errocount++
    }
    if(password === undefined || password === ''){
        setPasswordError('Password is required');
        errocount++
    }
    if(confrmpassword === undefined || confrmpassword === ''){
        setConfirmPasswordError('Confirm password is required');
        errocount++
    }else if(password !== confrmpassword){
        setConfirmPasswordError('Password Not Match');
        errocount++
    }
    
    if(!isValidPassword(password)){
        setPasswordError('Password needs to have at least one lower case, one uppercase, one number, one special character, and  must be atleast 8 characters but no more than 15. ');
        errocount++
    }

    if(errocount == 0){
        let params = {oldPassword, password};
        changePassword(params).then(res => { 
            if(res.data.statusCode == 0){
                setOldPasswordMatchError(true);
                setBtnLoader(false);  
            }else{
                setoldPassword('');
                setPassword('');
                setConfirmPassword('');
                setBtnLoader(false);
                notification.open({
                    message: 'Password',
                    description:
                      'Password Updated Successfully',
                  });  
            }

         })    
    }
    else
    {
        
        setBtnLoader(false);  
    }
   
}
    return(
        <div className="card mb-4 pro_wrapper">
            <h4 className="text-center mb-5">Change Password</h4>
                
            <div className="row">
                <div className="col-lg-3" />
                <div className="col-lg-6">

                    <form onSubmit={handelSubmit}>
                        <div className="form-group cm_top_info_space">
                            <label>Old Password</label>
                            <input
                                onChange={handelChange}
                                type="password"
                                className={`form-control ${oldPasswordError ? 'is-invalid' : ''}`}
                                value={oldPassword}
                                name="oldpassword"
                                placeholder="Enter Old Password" />

                                {oldPasswordError ? <OverlayTrigger placement="left" overlay={<Tooltip>{oldPasswordError}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>

                        <div className="form-group cm_top_info_space eye_wrapper with_label">
                            <label>New Password</label>
                            <input
                                onChange={handelChange}
                                type={showPass ? 'text' : 'password'}
                                className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                name="newpassword"
                                value={password}
                                placeholder="Enter New Password" />
                                
                            {passwordError ? <OverlayTrigger placement="left" overlay={<Tooltip>{passwordError}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            <i className={`icon-eye ${showPass ? 'slash' : ''} ${passwordError ? 'some_right_pos' : ''}`} onClick={() => setShowPass(!showPass)} />
                        </div>

                        <div className="form-group cm_top_info_space eye_wrapper with_label">
                            <label>Confirm New Password</label>
                            <input
                                type={showPass2 ? 'text' : 'password'}
                                onChange={handelChange}
                                className={`form-control ${confrmpasswordError ? 'is-invalid' : ''}`}
                                value={confrmpassword}
                                name="confirmpassword"
                                placeholder="Re-enter new password" />

                            {confrmpasswordError ? <OverlayTrigger placement="left" overlay={<Tooltip>{confrmpasswordError}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            <i className={`icon-eye ${showPass2 ? 'slash' : ''} ${confrmpasswordError ? 'some_right_pos' : ''}`} onClick={() => setShowPass2(!showPass2)} />
                        </div>

                        <div className="text-center mb-5">
                            <Link to="/profile/information" className="btn btn-light rounded-0 mr-2" style={{width: '130px'}}>Cancel</Link>
                            <button type="submit" onClick={()=>{setBtnLoader(true)}} className={`btn btn-primary rounded-0 ${btnloder ? 'btnLoader' : ''}`} style={{width: '130px'}}>Save</button>
                        </div>
                    </form>
                            




                </div>
            </div>
        </div>
    )
}

export default ChangePassword;