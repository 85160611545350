import React, { useState, useEffect, useRef } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import UserImg from '../../assets/img/user.png'
import { getChatHistoryService } from '../../services/inboxService';
import { convertTimeToCurrentTimeZone, getHtml, getRightTime, removeHtmlTags, resHandle } from '../../helper';
import { socket } from '../../services/socket';
import { getChatUserListCalbackAction } from '../../store/inbox';
import { createScheduleService, getTemplatesService, sendEmailSmsService } from '../../services/campersServices';
import { CustomDatePicker } from '../reuse/FormControls';
import { TimePicker, notification } from 'antd';
import MessageTypeBadge from './MessageTypeBadge';
import { unreadCountAction } from '../../store/chat';

const ChatBoxLayout = props => {
    const scrollRef = useRef();

    const [messages, setMessages] = useState([]);
    const [messageValue, setMessageValue] = useState('');
    const [messageValueErr, setMessageValueErr] = useState('');
    const [messageType, setMessageType] = useState(2);
    const [subject, setSubject] = useState('');
    const [templateList, settemplateList] = useState([]);
    const [templateId, setTemplateId] = useState('');
    const [page, setPage] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [count, setCount] = useState(0);
    const [lastPos, setLastPos] = useState(0);
    const [openUserId, setOpenUserId] = useState('');
    const [newMessage, setNewMessage] = useState('');
    const [scheduleSendModal, setScheduleSendModal] = useState(false);
    const [scheduleDate, setScheduleDate] = useState('');
    const [scheduleTime, setScheduleTime] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);



    useEffect(() => {
        socket.emit('join'); 
        setNewMessage('');
        socket.on('receiveMessage', data => {
            setNewMessage(data);
        });
    }, []);



    useEffect(() => {
        if(newMessage.senderId == openUserId){
            setMessages([...messages, newMessage]);
            setTimeout(() => {
                scrollRef.current.scroll({ top: scrollRef.current.scrollHeight, behavior: 'smooth' })
            }, 100)
        }
    }, [newMessage])




    const getMessages = getType => {
            let query = '';
            if(props?.activeUserChatWindow?.type === 1){
                query = `/?type=1&receiverId=${props?.activeUserChatWindow.userId[0]._id}&limit=15&page=${getType === 'isNew' ? 1 : page + 1}`;
            }else {
                query = `/?type=2&groupId=${props?.activeUserChatWindow.groupId}&limit=15&page=${getType === 'isNew' ? 1 : page + 1}`;
            }
            getChatHistoryService(query).then(res => {
            if(res?.data?.statusCode){
                if(getType === 'isNew'){
                    setMessages(res.data.responseData.result.reverse());
                }else{
                    setMessages([...res.data.responseData.result.reverse(), ...messages]);
                }
                
            
                setCount(res.data.responseData.count);
                if(page === 0){
                    scrollRef.current.scroll({ top: scrollRef.current.scrollHeight, behavior: 'smooth' })
                }

                setLastPos(scrollRef.current.scrollHeight);
                props.unreadCountAction();
                setTimeout(() => {
                    setIsLoaded(true);
                }, 400);

                if(messages.length){
                    scrollRef.current.scrollTop = lastPos
                }
            }


        })
    }


    useEffect(() => {
        if(props?.activeUserChatWindow?.type){
            setOpenUserId(props?.activeUserChatWindow.userId[0]._id);
            if(openUserId != props?.activeUserChatWindow.userId[0]._id){
                setPage(0);
                setMessages([]);
                setCount(0);
                setTimeout(() => {
                    getMessages('isNew');
                }, 100)
            }else{
                getMessages('');
            }
                      
            
        }

    }, [props.activeUserChatWindow, page])



    useEffect(() => {
        if(props?.activeUserChatWindow?.groupId || props?.activeUserChatWindow.userId[0]._id){
            socket.emit('joinRoom', {groupId: props.activeUserChatWindow.groupId});         
        }

    }, [props.activeUserChatWindow])




    const handleSendMsg = () => {
        if(removeHtmlTags(messageValue).trim() !== ''){
            setMessages([...messages, {
                message: messageValue,
                senderId: props.userProfile._id,
                timeStamp: new Date().getTime()
            }]); 
            let tempMsg = {
                'groupId': props.activeUserChatWindow.groupId,
                'receiverId': props.activeUserChatWindow._id,
                'senderId': props.userProfile._id,
                'message': messageValue,
                'timeStamp': new Date().getTime(),
                templateId: templateId.value || '',
                messageType,
                subject
            }
            socket.emit('sendMessage', tempMsg);

            if(messageType === 3 || messageType === 1){
                let params = {
                    receiverId: props.activeUserChatWindow._id || props.activeUserChatWindow.userId[0]._id,
                    message: messageValue,
                    templateId: templateId.value || '',
                    messageType,
                    subject
                }



                sendEmailSmsService(params).then(res => {
                    //console.log(res, 'asdfgdsadfgbfds')
                })


            }


            setMessageValue('');
            setSubject('');
            props.getChatUserListCalbackAction(1);

            setTimeout(() => {
                scrollRef.current.scroll({ top: scrollRef.current.scrollHeight, behavior: 'smooth' })
            }, 100)

        }
    }


    useEffect(() => {
        if(props.userProfile.campCode){
            getTemplatesService(`?campCode=${props.userProfile.campCode}`).then(res => {
                let { status, data } = resHandle(res);
                if(status){
                    settemplateList(data.result);
                }
            })
        }

       
    },[props.userProfile])


    const handleMsgScroll = e => {
        if(isLoaded){
            if(count > messages.length){
                if(e.currentTarget.scrollTop < 50){
                    setIsLoaded(false);
                    setPage(page + 1);
                } 
            }
        }
        
    }


    const handleCLose = () => {
        setScheduleSendModal(false);
        setScheduleDate('');
        setScheduleTime('');
    }


    const openScheduleSendModal = () => {
        setScheduleSendModal(true);
    }


    const handleScheduleDate = e => {
        setScheduleDate(e)
    }


    const handleScheduleTime = e => {
        setScheduleTime(e)
    }




    const handleValidate = () => {
        let validate = true;
        
        // if(messageType.length == 0){
        //     setMessageTypeErr('Message type is required');
        //     validate = false;
        // }

        // if(removeHtmlTags(message) == '' || removeHtmlTags(message).trim() == ''){
        //     setMessageErr('Message is required');
        //     validate = false;
        // }

        // if(messageType == 3){
        //     if(subject == '' || subject == 'undefined' || subject == null){
        //         setSubjectErr('Subject is required');
        //         validate = false;
        //     }
        // }

        // if((otherUserId == '' || otherUserId == 'undefined' || otherUserId == null) && (programId == '' || programId == 'undefined' || programId == null)){
        //     setReciverErr('Please select user or program');
        //     validate = false;
        // }

        return validate;
    }




    const submitSchedule = () => {
        if(handleValidate()){
            setBtnLoader(true);

            let _scheduleTime = '';

            if(scheduleDate && scheduleTime){
                _scheduleTime = `${moment(scheduleDate).format('YYYY-MM-DD')}${getRightTime(scheduleTime)}`;
            }

            let params = {
                messageType: JSON.stringify(messageType),
                templateId: templateId.value, 
                groupId: props.activeUserChatWindow.groupId,
                message: messageValue,
                scheduleDateTime: _scheduleTime,
                subject,
                trigger: 0,
            }

            createScheduleService(params).then(res => {
                setBtnLoader(false);
                if(res.data.statusCode){
                    handleCLose();
                    setMessageValue('');
                    props.getChatUserListCalbackAction(1);
                    notification.open({
                        description:
                          'Message created successfully',
                      });
                }
            })
    
    
            
        } 
    }



    return(
        <div className="chat_box_layout">
            <div className='chat_box__header'>
                <img src={props?.activeUserChatWindow?.userId[0]?.profileImage || UserImg} alt=''/>
                <h4>{props?.activeUserChatWindow?.userId[0]?.name}</h4>
            </div>

            <div className='cm_chats_box' ref={scrollRef} onScroll={handleMsgScroll}>

            {
                                                
                messages.length ? messages.map((message, i) => (
                <React.Fragment key={i}>                                  
                {
                    message.senderId === props.userProfile._id ? 
                    <div id={message.chatId} key={message.timeStamp} className="chat_msg me_user">
                        <div className="cm_name_m">
                            <time>
                                {convertTimeToCurrentTimeZone(message.timeStamp, props?.userProfile?.timezone,'hh:mm a, (MMM Do YYYY)')} 
                            </time>
                            {/*//todo: revert this line to show badge on message.*/}
                            {/* <MessageTypeBadge type={message.type}/> */}
                        </div>
                        <div className="msg_text">{message.message ? getHtml(message.message) : ''} </div>
                    </div>
                    :
                    <div id={message.chatId} key={message.timeStamp}  className="chat_msg other_user">
                        <img className="chat_user_img" src={message.profileImage ? message.profileImage : UserImg} alt=''/>
                        <div className="cm_name_m">
                            <time>
                                {convertTimeToCurrentTimeZone(message.timeStamp, props?.userProfile?.timezone,'hh:mm a, (MMM Do YYYY)')} 
                            </time>
                            {/*//todo: revert this line to show badge on message.*/}
                            {/* <MessageTypeBadge type={message.type}/> */}
                        </div>
                        <div className="msg_text">{message.message ? getHtml(message.message) : ''} </div>
                    </div>

                }
                </React.Fragment>
            )) : ''
            }

                
            </div>


           <div className='typing_wrapper'>
            
            <div className="d-flex justify-content-between align-items-center mb-3">


                <div className='d-flex justify-content-between align-items-center'>
                    <div className="check_box_wrapper cm_dark_check mr-4">
                        <input
                            onChange={() => (setMessageType(1))}
                            type="checkbox"
                            checked={messageType === 1} />
                        <span className="check_box"><span></span> Phone Text</span>
                    </div>
                    <div className="check_box_wrapper cm_dark_check mr-4">
                    <input
                            onChange={() => (setMessageType(2))}
                            type="checkbox"
                            checked={messageType === 2} />
                        <span className="check_box"><span></span> App Message</span>
                    </div>
                    <div className="check_box_wrapper cm_dark_check">
                    <input
                            onChange={() => (setMessageType(3))}
                            type="checkbox"
                            checked={messageType === 3} />
                        <span className="check_box"><span></span> Email</span>
                    </div>
                </div>



                <div>

                <ReactSelect
                    placeholder="Select Template"
                    value={templateId}
                    classNamePrefix="cm_select"
                    onChange={e => (setTemplateId(e), setMessageValue(e.body), setSubject(e.subject))}
                    className={`form-control ${ false ? 'is-invalid' : '' }`}
                    options={templateList.map(i => ({...i, label: i.name, value: i._id}))} />
                </div>
            </div>


            {
                messageType === 3 ? <div className="form-group mb-3">
                                        <input
                                            placeholder='Subject'
                                            type='text'
                                            value={subject}
                                            onChange={e => setSubject(e.target.value)}
                                            className={`form-control`}/>
                                    </div> : ''
            }

            <div className='typing_wrapper_inner'>

            <ReactQuill
                className={`inbox_editer ${messageValueErr ? 'is-invalid' : ''}`}
                theme="snow"
                value={messageValue}
                placeholder="type a message"
                onChange={e => (setMessageValue(e), setMessageValueErr(''), setTemplateId(''))} />

            </div>


            <div className='d-flex justify-content-end align-items-center mt-2'>
                <Dropdown as={ButtonGroup}>
                    <button onClick={handleSendMsg} className="btn btn-primary">Send Now</button>
                    <Dropdown.Toggle split variant="primary" id="dropdown-custom-2" />
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={openScheduleSendModal} eventKey="1">Schedule send</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>  
            </div>



           </div>


           <Modal
                show={scheduleSendModal}
                animation={false}
                dialogClassName="folder_model"
                size="md"
                centered
            >
                <Modal.Body>
                    <span className="modal_close" onClick={handleCLose}><i className="icon-cross" /></span>
                    <div className="p-5">
                        <h5 className='mb-4'>Pick date & time</h5>

                        <div className="form-group">
                            <label>Date</label>
                            <CustomDatePicker
                                value={scheduleDate}
                                error={''}
                                disabledDate={current => current && current < moment.utc(new Date().getTime() - 86400000 )}
                                change={handleScheduleDate} />
                        </div>

                        <div className="form-group">
                            <label>Time</label>
                            <TimePicker
                                use12Hours
                                format="h:mm a"
                                onChange={handleScheduleTime}
                                className={`form-control`}/>
                        </div>

                        <div className='d-flex justify-content-end mt-5'>
                            <button className='btn btn-dark btn-sm px-4' onClick={handleCLose}>Cancel</button>
                            <button className={`btn btn-primary btn-sm px-4 ml-3 ${btnLoader ? 'btnLoader' : ''}`} disabled={btnLoader} onClick={submitSchedule}>Schedule send</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

           


        </div>
    )
}



const mapStateToProps = state => {
    let { userProfile } = state.user;
    let { activeUserChatWindow } = state.inbox;
    return {
        userProfile,
        activeUserChatWindow
    };
}
  
const mapDispatchToProps = dispatch => ({
    getChatUserListCalbackAction: params => dispatch(getChatUserListCalbackAction(params)),
    unreadCountAction: () => dispatch(unreadCountAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatBoxLayout);