import React, { useState, useEffect } from 'react';
import { Collapse } from 'antd';

import { getfaqListService, faqFeedbackService } from '../../services/otherService';
import { Fragment } from 'react';

const { Panel } = Collapse;


const SupportFAQs = props => {

        const [faqs, setFaqs] = useState([]);
        const [thank, setThank] = useState('');
        
        useEffect(() => {
            let params = `?type=1`;
            getfaqListService(params).then(data => {
                setFaqs(data.result)
            })
        }, [])


        const addFeedback = (faqId, type) => {
            let params = {
                faqId, type
            }
            setThank({...thank, [faqId]: true})
            faqFeedbackService(params).then(res => {
                
            })
        }

    return(
        <div className="body_container tc_pp">
            <div className="container">
                {
                    faqs.map((item, i) => (
                        <React.Fragment key={i}>
                            <h3 className="tc_pp_title">{item.heading}</h3>

                            <Collapse>
                                {item.list ? item.list.map((itm, i) => (
                                    <Panel header={itm.question} key={i}>
                                        <p>{itm.answer}</p>
                                        <div className="faq_action">
                                            {thank[itm._id] ? <Fragment><b>Thank you for your feedback.</b></Fragment> : <Fragment>
                                                <b>Is this FAQ helpful?</b> 
                                            <button onClick={() => addFeedback(itm._id, 1)} className="btn btn-primary btn-sm">Yes</button>
                                            <button onClick={() => addFeedback(itm._id, 2)} className="btn btn-dark btn-sm">No</button></Fragment>}
                                            
                                        </div>
                                    </Panel>
                                )) : ''}
                            </Collapse>
                        </React.Fragment>
                    ))
                }

                {
                    faqs.length ? '' : <p className="text-center">No data</p>
                }
            </div>
        </div>
    )
}

export default SupportFAQs;