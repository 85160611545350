import * as actionType from './actionsTypes';

const initialState = {
    ActivityFolderName: '',
}


/************************************************************************************/
// dFolder Reducer
/************************************************************************************/


const dFolderReducer = (state = initialState, action) => {

    switch (action.type) {


        case actionType.PICKUP_USER_MODAL:
            return {
            ...state,
            pickupUserModal: !state.pickupUserModal
        }        

        default: return state;
    }
}




/************************************************************************************/
// All dFolder Actions
/************************************************************************************/


// export const togglePickupUserModalAction = () => (dispatch => dispatch({ type: actionType.PICKUP_USER_MODAL }))


// export const pickupTabAction = payload => (dispatch => dispatch({ type: actionType.PICKUP_TAB, payload }))

export default dFolderReducer;