import React, { useReducer, Fragment, useRef, useEffect, useState } from 'react';
import { jsPDF } from "jspdf";
import SignaturePad from 'react-signature-canvas';
import moment from 'moment';
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";

import { uploadMedia, userPDFCreate } from '../../services/campersServices';
import { ProgramLoader } from '../reuse/SVG';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  

const Waiver2 = () => {
    let query = useQuery();

    let camperId = query.get("camperId");
    let userId = query.get("userId");

    const signatureRef3 = useRef();
    const signatureRef4 = useRef();
    const [showSucssModal, setShowSucssModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [userInput, setUserInput] = useReducer((state, newState) => ({...state, ...newState}),
        {
            wl_1: '',
            wl_2: '',
            wl_3: '',
            wl_4: '',
            wl_5: '',
            wl_6: '',
            wl_7: '',
            wl_8: '',
            wl_9: 'Lacrosse',
            wl_10: 'Lacrosse',
            wl_11: 'Lacrosse',
            wl_12: 'Lacrosse',
            wl_13: 'Lacrosse',
            wl_14: 'Lacrosse',
            wl_15: 'Lacrosse',
            wl_16: '',
            wl_17: moment().format("MMM Do YYYY"),
            wl_18: '',
            wl_19: moment().format("MMM Do YYYY"),
        }
    );



    const handleChange = e => {
        setUserInput({[e.target.name]: e.target.value});
    }

    const pdfGen = () => {
        setLoader(true);
        const doc = new jsPDF();

        
        doc.addImage(require('../../assets/img/ttsd-logo.jpg'), "JPEG", 15, 10, 20, 20);
        doc.setFont('helvetica', "bold");
        doc.setFontSize(10);
        doc.text(" Tigard-Tualatin School District", 100, 20, null, null, "center");
        doc.text(" WAIVER OF LIABILITY AND HOLD HARMLESS FOR COMMUNICABLE", 100, 27, null, null, "center");
        doc.text(" DISEASES INCLUDING COVID-19", 100, 34, null, null, "center");
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal")
        doc.text("Student Name:", 15, 50, null, null);
        doc.text(userInput.wl_1, 43, 50, null, null);
        doc.line(40, 50, 190, 50);
        doc.text("Grade:", 15, 57, null, null);
        doc.text(userInput.wl_2, 32, 57, null, null);
        doc.line(30, 57, 97, 57);
        doc.text("Home Phone:", 100, 57, null, null);
        doc.text(userInput.wl_3, 127, 57, null, null);
        doc.line(125, 57, 190, 57);
        doc.text("Address:", 15, 64, null, null);
        doc.text(userInput.wl_4, 32, 64, null, null);
        doc.line(30, 64, 190, 64);
        doc.text("Parent(s)/Guardian(s) Names:", 15, 71, null, null);
        doc.text(userInput.wl_5, 65, 71, null, null);
        doc.line(63, 71, 190, 71);
        doc.text("Parent/ Guardian phone: Work:", 15, 78, null, null);
        doc.text(userInput.wl_6, 67, 78, null, null);
        doc.line(65, 78, 100, 78);
        doc.text("Home:", 102, 78, null, null);
        doc.text(userInput.wl_7, 117, 78, null, null);
        doc.line(115, 78, 150, 78);
        doc.text("Other:", 152, 78, null, null);
        doc.text(userInput.wl_8, 167, 78, null, null);
        doc.line(165, 78, 190, 78);
        doc.text("The novel coronavirus (“COVID-19”), has been declared a worldwide pandemic by the World Health Organization.", 15, 85, null, null);
        doc.text("COVID-19 is extremely contagious and is believed to spread mainly from person-to-person contact. While rules,", 15, 92, null, null);
        doc.text("guidance, and personal discipline may reduce this risk, the risk of serious illness and death does exist.", 15, 99, null, null);
        doc.setFont('helvetica', "bold");
        doc.setFontSize(9);
        doc.text("Tigard-Tualatin School District (“District”) cannot completely mitigate the transfer of communicable diseases like", 15, 106, null, null);
        doc.line(15, 107, 187, 107);
        doc.text("COVID-19 especially when involved in", 15, 113, null, null);
        doc.text(userInput.wl_9, 100, 113, null, null);
        doc.text("Participation in", 150, 113, null, null);
        doc.line(15, 114, 175, 114);
        doc.text("includes possible exposure to and illness, injury, or death from infectious ", 78, 120, null, null);
        doc.text(userInput.wl_10, 15, 120, null, null);
        doc.line(15, 121, 190, 121);
        doc.text("diseases including COVID-19.", 15, 128, null, null);
        doc.line(15, 129, 60, 129);
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal")
        doc.text("In consideration for providing my child the opportunity to participate in__________________________________and", 15, 138, null, null);
        doc.text(userInput.wl_11, 150, 138, null, null);
        doc.text("any related transportation to and from__________________________________events, both my child and I", 15, 144, null, null);
        doc.text(userInput.wl_12, 100, 144, null, null);
        doc.text("voluntarily agree to waive and discharge any and all claims against District and release it from liability for any", 15, 150, null, null);
        doc.text("exposure to or illness or injury from an infectious disease including COVID-19, including claims for any negligent", 15, 156, null, null);
        doc.text("actions of the District or its employees or agents, to the fullest extent allowed by law, for myself, my child, our", 15, 162, null, null);
        doc.text("estates, our heirs, our administrators, our executors, our assignees, and our successors.", 15, 168, null, null);
        doc.text("I also agree to release, exonerate, discharge and hold harmless the District, its Board of Directors, the individual", 15, 174, null, null);
        doc.text("members thereof, and all officers, agents, employees, volunteers, and representatives from all liability, claims,", 15, 180, null, null);
        doc.text("causes of action, or demands, including attorney fees, fines, fees, or other costs (e.g. medical costs) arising out of", 15, 186, null, null);
        doc.text("any exposure to or illness or injury from an infectious disease including COVID-19, which may result from or in", 15, 192, null, null);
        doc.text("connection with my child’s participation in__________________________________.", 15, 198, null, null);
        doc.text(userInput.wl_13, 100, 198, null, null);
        doc.text("I further certify and represent that I have the legal authority to waive, discharge, release, and hold harmless the", 15, 208, null, null);
        doc.text("released parties on behalf of myself and the above-named student.", 15, 214, null, null);
        doc.setFont('helvetica', "bold");
        doc.setFontSize(9);
        doc.text("I certify that I have read this document in its entirety and fully understand its contents. In exchange for the", 15, 224, null, null);
        doc.text("opportunity to participate in__________________________________, the above-named student and I freely and ", 15, 230, null, null);
        doc.text(userInput.wl_14, 65, 230, null, null);
        doc.text("voluntarily assume all risks of such hazards and notwithstanding such, release District from all liability for any loss", 15, 236, null, null);
        doc.text("regardless of cause, and claims arising from the student's participation in__________________________________.", 15, 242, null, null);
        doc.text(userInput.wl_15, 140, 242, null, null);
        doc.text("__________________________________________________ ___________________", 40, 252, null, null);
        doc.text("Student Signature Date", 40, 256, null, null);
        doc.text("Date", 135, 256, null, null);
       // doc.text(data.wl_16, 40, 252, null, null);
       if(userInput.wl_16){
        doc.addImage(userInput.wl_16, "JPEG", 60, 252);
       }
        doc.text(userInput.wl_17, 135, 252, null, null);
        doc.text("__________________________________________________ ___________________", 40, 266, null, null);
        doc.text("Parent/Legal Guardian Signature Date", 40, 272, null, null);
        doc.text("Date", 135, 272, null, null)
        //doc.text(data.wl_18, 40, 266, null, null);
        if(userInput.wl_18){
            doc.addImage(userInput.wl_18, "JPEG", 60, 266);
        }
        doc.text(userInput.wl_19, 135, 266, null, null);
     


        

        let cmFile = new File([doc.output('blob')], 'waiver', {type: 'application/pdf'});

        const data = new FormData();
        data.append('file', cmFile);
        uploadMedia(data).then(res => {
            if(res.data.responseData.result.fileUrl!==undefined){
               let obj = {
                userId,
                camperId,
                fileUrl: res.data.responseData.result.fileUrl,
                fileType: '2'
               }

               userPDFCreate(obj).then(res => {
                if(res?.data?.statusCode){
                    setLoader(false);
                    setShowSucssModal(true);
                }
               })
            }  
        })

    }

    const handleSignatureRef3Save = name => {
        if(userInput[name] == ''){
            setUserInput({[name]: signatureRef3.current.getTrimmedCanvas().toDataURL('image/png')})
        }
    }
    const handleSignatureRef4Save = name => {
        if(userInput[name] == ''){
            setUserInput({[name]: signatureRef4.current.getTrimmedCanvas().toDataURL('image/png')})
        }
    }

  
    return (
        <div className="pdf_box pdf_box_in_mob">
            <Modal show={showSucssModal} centered>
            <Modal.Body>
                <p className="text-center">
                    Your PDF upload successfully. Please go to the App
                </p>
            </Modal.Body>
        </Modal>

                        <div className="with_logo">
                                <img src={require('../../assets/img/ttsd-logo.jpg')} />
                                <p> Tigard-Tualatin School District</p>
                                <h3> WAIVER OF LIABILITY AND HOLD HARMLESS FOR COMMUNICABLE <br />DISEASES INCLUDING COVID-19</h3>
                            </div>

                            <p>Student Name:
                                <input
                                    type="text"
                                    value={userInput.wl_1}
                                    name="wl_1"
                                    onChange={handleChange}
                                />
                            </p>

                            <p>Grade: 
                                <input
                                    type="text"
                                   value={userInput.wl_2}
                                    name="wl_2"
                                    onChange={handleChange} />
                            Home Phone: 
                                <input
                                    type="text"
                                    value={userInput.wl_3}
                                    name="wl_3"
                                    onChange={handleChange} /></p>
                            <p>Address:
                                <input
                                    type="text"
                                    value={userInput.wl_4}
                                    name="wl_4"
                                    onChange={handleChange}
                                /></p>
                            <p>Parent(s)/Guardian(s) Names:
                                <input
                                    type="text"
                                    value={userInput.wl_5}
                                    name="wl_5"
                                    onChange={handleChange}
                                /></p>

                            <p>Parent/ Guardian phone: Work: 
                                <input
                                    type="text"
                                    value={userInput.wl_6}
                                    name="wl_6"
                                    onChange={handleChange} />
                            Home: 
                                <input
                                    type="text"
                                    value={userInput.wl_7}
                                    name="wl_7"
                                    onChange={handleChange} />
                            Other: 
                                <input
                                    type="text"
                                    value={userInput.wl_8}
                                    name="wl_8"
                                    onChange={handleChange} />
                                    </p>

                            <p>The novel coronavirus (“COVID-19”), has been declared a worldwide pandemic by the World Health Organization. COVID-19 is extremely contagious and is believed to spread mainly from person-to-person contact. While rules, guidance, and personal discipline may reduce this risk, the risk of serious illness and death does exist. </p>

                            <p>
                                <b>Tigard-Tualatin School District (“District”) cannot completely mitigate the transfer of communicable diseases like </b>
                                <b>COVID-19 especially when involved in</b>
                                <input
                                    type="text"
                                    value={userInput.wl_9}
                                    name="wl_9"
                                    onChange={handleChange} />
                                <b>Participation in</b>
                                <input
                                    type="text"
                                    value={userInput.wl_10}
                                    name="wl_10"
                                    onChange={handleChange} />
                                <b>includes possible exposure to and illness, injury, or death from infectious </b>
                                <b>diseases including COVID-19.</b>
                            </p>

                            <p>In consideration for providing my child the opportunity to participate in
                            <input
                                type="text"
                                value={userInput.wl_11}
                                name="wl_11"
                                onChange={handleChange} />
                                and<br />
                                any related transportation to and from
                                <input
                                    value={userInput.wl_12}
                                    name="wl_12"
                                    onChange={handleChange} />
                                    events, both my child and I<br />voluntarily agree to waive and discharge any and all claims against District and release it from liability for any exposure to or illness or injury from an infectious disease including COVID-19, including claims for any negligent actions of the District or its employees or agents, to the fullest extent allowed by law, for myself, my child, our estates, our heirs, our administrators, our executors, our assignees, and our successors. 
                            </p>

                            <p>I also agree to release, exonerate, discharge and hold harmless the District, its Board of Directors, the individual members thereof, and all officers, agents, employees, volunteers, and representatives from all liability, claims, causes of action, or demands, including attorney fees, fines, fees, or other costs (e.g. medical costs) arising out of any exposure to or illness or injury from an infectious disease including COVID-19, which may result from or in connection with my child’s participation in 
                                <input
                                    type="text"
                                    value={userInput.wl_13}
                                    name="wl_13"
                                    onChange={handleChange} />
                            </p>

                            <p>I further certify and represent that I have the legal authority to waive, discharge, release, and hold harmless the released parties on behalf of myself and the above-named student.</p>

                            <p><b>I certify that I have read this document in its entirety and fully understand its contents. In exchange for the opportunity to participate in</b>
                            <input
                                type="text"
                                value={userInput.wl_14}
                                name="wl_14"
                                onChange={handleChange} />.
                            <b>the above-named student and I freely and</b><br />
                            <b>voluntarily assume all risks of such hazards and notwithstanding such, release District from all liability for any loss</b><br />
                            <b>regardless of cause, and claims arising from the student's participation in</b>
                            <input
                                type="text"
                                value={userInput.wl_15}
                                name="wl_15"
                                onChange={handleChange}
                                style={{border: '0', borderBottom: '1px solid #000', outline: 'none'}} />
                            </p>


                            <div className=" mt-4">
                                <p>
                                    {/* <input
                                    type="text"
                                    value={userInput.wl_16}
                                    name="wl_16"
                                    onChange={handleChange}
                                    style={{width: '350px'}} /> */}

                            <div className="sign_box" style={{display: 'block'}}>
                                <SignaturePad
                                    ref={signatureRef3}
                                    canvasProps={{width: 365, height: 70, className: 'sigCanvas'}} />


                                <div className="sign_box_clear">
                                   {userInput.wl_16 ? '' : <button disabled={userInput.wl_16 ? true : false} className="btn btn-primary btn-sm btn_1" onClick={() => handleSignatureRef3Save('wl_16')}>Save</button> }
                                    <button className="btn btn-dark btn-sm btn_2" onClick={() => (signatureRef3.current.clear(), setUserInput({wl_16: ''}))}>Clear</button>
                                </div> 
                            </div>
                                    Student Signature</p>
                                <p> <input
                                    type="text"
                                    value={userInput.wl_17}
                                    name="wl_17"
                                    disabled={true}
                                    onChange={handleChange} />
                                    Date</p>
                            </div>

                            <div className="">
                                <p>
                                    {/* <input
                                    type="text"
                                    value={userInput.wl_18}
                                    name="wl_18"
                                    onChange={handleChange}
                                    style={{width: '350px'}} /> */}

                            <div className="sign_box" style={{display: 'block'}}>
                                <SignaturePad
                                    ref={signatureRef4}
                                    canvasProps={{width: 365, height: 70, className: 'sigCanvas'}} />


                                <div className="sign_box_clear">
                                   {userInput.wl_18 ? '' : <button disabled={userInput.wl_18 ? true : false} className="btn btn-primary btn-sm btn_1" onClick={() => handleSignatureRef4Save('wl_18')}>Save</button> }
                                    <button className="btn btn-dark btn-sm btn_2" onClick={() => (signatureRef4.current.clear(), setUserInput({wl_18: ''}))}>Clear</button>
                                </div> 
                            </div>
                                    SParent/Legal Guardian Signature</p>
                                <p> <input
                                    type="text"
                                    value={userInput.wl_19}
                                    disabled={true}
                                    name="wl_19"
                                    onChange={handleChange} />
                                    Date</p>
                            </div>

    
    
    <div className="d-flex mt-3">
        <button className="btn btn-dark w-100">Cancel</button>
        <button onClick={pdfGen} className="btn btn-primary w-100 ml-3">Save</button>
    </div>

    {loader ? <div className="page_loader"><ProgramLoader /></div> : ''}
</div>

  )
}

export default Waiver2;