import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { LogoWhite } from '../reuse/SVG';
import { getCityByGeoLocation } from '../../services/otherService';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Math.floor(window.innerWidth / 250),
    slidesToScroll: Math.floor(window.innerWidth / 250),
    centerMode: true,
    responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
      ]
};



const Card = props => (
    <div className="starting_soon_card">
        <Link to={`/camps/${props.data._id}`}>
          {
            props.data.programImage ? <img src={props.data.programImage} /> : <div className="no_img"><LogoWhite /></div>
          }
            
            <h3>{props.data.camps}</h3>
            <div className="cm_content">
                <h3>{props.data.programName}</h3>
                <small>Age : {props.data.ageGroup}</small>
                <span className="cm_price">{props?.data?.registrationFee ? `$${props?.data?.registrationFee.toFixed(2)}` : 'Free'}</span>
            </div>
        </Link>
    </div>
)


const StartingSoonCarousel = props => {
  //const [city, setCity] = useState('');



  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(position => {
  //         let params = {
  //           lat: position.coords.latitude,
  //           long: position.coords.longitude
  //         }
  //         getCityByGeoLocation(params).then(res => {
  //           setCity(res)
  //         })

  //       })
  // }  
  // }, [])
  

    return(
        <div className="camp_type_carousel_wrapper">
            <div className="container">
              {/* <h2 className="section_title_left">Starting Soon in {city}</h2> */}
              <h2 className="section_title_left">Starting Soon</h2>
            </div>

            {
               props.list && (props.list.data.length >= 6) ? <Slider {...settings}>
                                                                {props.list && props.list.data.map((item, i) => <Card key={i} {...props} data={item} />)}
                                                            </Slider> : <div className="row d-flex justify-content-center">{ props.list && props.list.data.map((item, i) => <div className="col" key={i}><Card key={item} {...props} data={item} /></div>)}</div>
            }

            
        </div>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
  let { programDetail, preList } = state.program;
  let { lat, long } = state.pFilter;
  return {
      programDetail,
      preList,
      lat,
      long
  };
}

export default connect(mapStateToProps)(StartingSoonCarousel);