import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { handleNewMsgModalAction, getChatUserListCalbackAction, changeInboxTabAction } from '../../store/inbox';
import { Modal } from 'react-bootstrap';
import { CustomDatePicker, InputError } from '../reuse/FormControls';
import { TimePicker, notification } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';
import Select from 'react-select';
import { getAllUserListService, getAllUserListForDirectorService, createNewScheduleService } from '../../services/inboxService';
import UserImg from '../../assets/img/user.png'
import { getAllPrgramWithId } from '../../services/programService';
import { getTemplatesService } from '../../services/campersServices';
import { getRightTime, removeHtmlTags, resHandle } from '../../helper';
import {socket} from '../../services/socket';



const NewMsgModalComponent = props => {
    const [programsList, setLrogramsList] = useState([]);
    const [templateList, settemplateList] = useState([]);

    const [btnLoader, setBtnLoader] = useState(false);

    const [userSearch, setUserSearch] = useState('');
    const [users, setUsers] = useState([]);
 

    const [messageType, setMessageType] = useState('');
    const [otherUserId, setOtherUserId] = useState('');
    const [programId, setProgramId] = useState('');
    const [scheduleDate, setScheduleDate] = useState('');
    const [scheduleTime, setScheduleTime] = useState('');
    const [templateId, setTemplateId] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');
    const [saveAsDefualt, setSaveAsDefualt] = useState('');


    const [messageTypeErr, setMessageTypeErr] = useState([]);
    const [reciverErr, setReciverErr] = useState('');
    const [messageErr, setMessageErr] = useState('');
    const [subjectErr, setSubjectErr] = useState('');



    useEffect(() => {
        socket.on('connect', data => {
           socket.emit('join');
        });
    }, []);

    const handleScheduleDate = e => {
        setScheduleDate(e)
    }


    const handleScheduleTime = e => {
        setScheduleTime(e)
    }



    const handleClose = () => {
        props.handleNewMsgModalAction(false);
    }





    const getAllUserList = () => {
        if(props.userProfile.UserType == 1){
            let query = `?pageNo=1&limit=15&search=${userSearch}`;
            getAllUserListService(query).then(res => {
                if(res?.data?.statusCode){
                    setUsers(res.data.responseData.result)
                }
            })

        }else {
            let query = `?pageNo=1&limit=15&campCode=${props.userProfile.campCode}&search=${userSearch}`;
            getAllUserListForDirectorService(query).then(res => {
                if(res?.data?.statusCode){
                    setUsers(res.data.responseData.result) 
                }
            })
        }  
    }


    useEffect(() => {
        getAllUserList()
    }, [userSearch])





    useEffect(() => {
        if(props.userProfile.campCode){
            getAllPrgramWithId(`?campCode=${props.userProfile.campCode}`).then(res => {
                if(res?.data?.statusCode){
                    setLrogramsList(res.data.responseData.result)
                }
            })

            getTemplatesService(`?campCode=${props.userProfile.campCode}`).then(res => {
                let { status, data } = resHandle(res);
                if(status){
                    settemplateList(data.result);
                }
            })
        }

       
    },[props.userProfile])




   
    const handleValidate = () => {
        let validate = true;
        
        if(messageType.length == 0){
            setMessageTypeErr('Message type is required');
            validate = false;
        }

        if(message == '' || removeHtmlTags(message) == '' || removeHtmlTags(message).trim() == ''){
            setMessageErr('Message is required');
            validate = false;
        }

        if(messageType == 3){
            if(subject == '' || subject == 'undefined' || subject == null){
                setSubjectErr('Subject is required');
                validate = false;
            }
        }

        if((otherUserId == '' || otherUserId == 'undefined' || otherUserId == null) && (programId == '' || programId == 'undefined' || programId == null)){
            setReciverErr('Please select user or program');
            validate = false;
        }

        return validate;
    }


    



    const handleSubmit = () => {
        if(handleValidate()){
            setBtnLoader(true);

            let _scheduleTime = '';

            if(scheduleDate && scheduleTime){
                _scheduleTime = `${moment(scheduleDate).format('YYYY-MM-DD')}${getRightTime(scheduleTime)}`;
            }

            let params = {
                messageType,
                programId: programId.value, 
                templateId: templateId.value, 
                otherUserId: otherUserId.value,
                message,
                trigger: 0,
                scheduleDateTime: _scheduleTime,
                subject,
                saveAsDefault: false
            }


            createNewScheduleService(params).then(res => {
                setBtnLoader(false);
                if(res.data.statusCode){
                    handleClose();
                    props.getChatUserListCalbackAction(1);
                    props.changeInboxTabAction(1)
                    notification.open({
                        description:
                          'Message created successfully',
                      });
                }
            })
    
    
            
        }
        
    }





    useEffect(() => {
        if(templateId.value){
            setMessage(templateId.body);   
        }

        if(templateId.subject){
            setSubject(templateId.subject)
        }else{
            setSubject('')
        }
    }, [templateId])


    useEffect(() => {
        if(saveAsDefualt){
            localStorage.setItem('saveAsDefualt', messageType)
        }
 
    }, [saveAsDefualt])

    useEffect(() => {
        let _messageType = localStorage.getItem('saveAsDefualt');
        if(_messageType){
            setMessageType(_messageType);
        }
    }, [])




    return(
        <Modal
                size="lg"
                show={props.newMsgModal}
                dialogClassName="remove_header_line"
                animation={false}
                centered>

                <Modal.Header>
                    <Modal.Title>New Message</Modal.Title>
                    <span className="modal_close" onClick={handleClose}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>
                
                            <form className='p-4'>


                            <div className="form-group">
                                    <p className="a_label">Message Type</p>

                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className="check_box_wrapper cm_dark_check">
                                            <input
                                                onChange={() => (setMessageType(1), setMessageTypeErr(''))}
                                                type="checkbox"
                                                checked={messageType == 1} />
                                            <span className="check_box"><span></span> Phone Text</span>
                                        </div>
                                        <div className="check_box_wrapper cm_dark_check">
                                        <input
                                                onChange={() => (setMessageType(2), setMessageTypeErr(''))}
                                                type="checkbox"
                                                checked={messageType == 2} />
                                            <span className="check_box"><span></span> App Message</span>
                                        </div>
                                        <div className="check_box_wrapper cm_dark_check">
                                        <input
                                                onChange={() => (setMessageType(3), setMessageTypeErr(''))}
                                                type="checkbox"
                                                checked={messageType == 3} />
                                            <span className="check_box"><span></span> Email</span>
                                        </div>
                                        
                                        <div className="check_box_wrapper cm_dark_check">
                                            <input
                                                disabled={!messageType}
                                                onChange={() => setSaveAsDefualt(saveAsDefualt ? 0 : 1)}
                                                type="radio"
                                                checked={saveAsDefualt} />
                                            <span className="check_box"><span></span> Save above as default</span>
                                        </div>
                                    </div>

                                    {messageTypeErr ? <h6 className='cm_err'>{messageTypeErr}</h6> : ''}
                                </div>



                                <div className='row'>
                                    <div className='col'>
                                        <div className="form-group">
                                            <label>To User</label>
                                            <Select
                                                placeholder="Select User"
                                                value={otherUserId}
                                                onInputChange={e => (setUserSearch(e), setProgramId(''), setReciverErr(''))}
                                                classNamePrefix="cm_select"
                                                onChange={e => (setOtherUserId(e))}
                                                className={`form-control ${ false ? 'is-invalid' : '' }`}
                                                //options={users.map(i => ({label: <div className='drop_with_img'><img src={i.profileImage || UserImg} /> <span>{i.name} <small>{i.email}</small></span></div>, value: i._id}))} />
                                                options={users.map(i => ({label: `${i.name} ${i.email}`, value: i._id}))} />
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className="form-group">
                                            <label>To Program</label>
                                            <Select
                                                placeholder="Programs"
                                                value={programId}
                                                classNamePrefix="cm_select"
                                                onChange={e => (setProgramId(e), setOtherUserId(''), setReciverErr(''))}
                                                className={`form-control ${ false ? 'is-invalid' : '' }`}
                                                options={programsList.map(i => ({label: i.programName, value: i._id}))} />
                                        </div>
                                    </div>
                                </div>

                                {reciverErr ? <h5 className='cm_err text-center position-relative' style={{bottom: '20px'}}>{reciverErr}</h5> : ''}


                                


                                <div className="form-group">
                                    <p className="a_label">Schedule Send for Later</p>

                                    <div className='row'>
                                        <div className='col'>
                                            <div className="form-group">
                                                <label>Date</label>
                                                <CustomDatePicker
                                                    value={scheduleDate}
                                                    error={''}
                                                    disabledDate={current => current && current < moment.utc(new Date().getTime() - 86400000 )}
                                                    change={handleScheduleDate} />
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className="form-group">
                                                <label>Time</label>
                                                <TimePicker
                                                    use12Hours
                                                    format="h:mm a"
                                                    onChange={handleScheduleTime}
                                                    className={`form-control`}/>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>


                                {
                                    messageType == 3 ? <div className="form-group">
                                                            <label>Subject</label>
                                                            <input
                                                                type='text'
                                                                value={subject}
                                                                onChange={e => (setSubject(e.target.value), setSubjectErr(''))}
                                                                className={`form-control ${subjectErr ? 'is-invalid' : ''}`} />
                                                                 <InputError error={subjectErr} />
                                                        </div> : ''
                                }



                                


                                    


                                <div className="form-group">
                                    <div className='d-flex justify-content-sm-between align-items-center mb-3'>
                                        <p className='a_label mb-0'>Message</p>
                                        <div className='w-50'>
                                        <Select
                                            placeholder="Select Template"
                                            value={templateId}
                                            classNamePrefix="cm_select"
                                            onChange={e => (setTemplateId(e))}
                                            className={`form-control`}
                                            options={templateList.map(i => ({...i, label: i.name, value: i._id}))} />
                                        </div>
                                    </div>
                                    <div className={messageErr ? 'error_feild' : ''}>
                                        <ReactQuill
                                            className={`inbox_editer ${0 ? 'is-invalid' : ''}`}
                                            theme="snow"
                                            value={message}
                                            onChange={e => (setMessage(e), setTemplateId(''), setMessageErr(''))} />

                                        {messageErr ? <h6 className='cm_err'>{messageErr}</h6> : ''}
                                    </div>
                                    
                                </div>
                                        



                            

                                <div className="text-center">
                                    <span onClick={handleSubmit} disabled={btnLoader} className={`btn btn-primary pl-5 pr-5 ${btnLoader ? 'btnLoader' : ''}`}>Send</span>
                                </div>
                            </form>

                </Modal.Body>
            </Modal>
    )
}


const mapStateToProps = state => {
    let { userProfile } = state.user;
    let { newMsgModal } = state.inbox;
    return {
        userProfile,
        newMsgModal
    };
}
  
const mapDispatchToProps = dispatch => ({
    handleNewMsgModalAction: params => dispatch(handleNewMsgModalAction(params)),
    getChatUserListCalbackAction: params => dispatch(getChatUserListCalbackAction(params)),
    changeInboxTabAction: params => dispatch(changeInboxTabAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewMsgModalComponent);