import React, {useState} from 'react';
import {campDates, getProgramType} from '../../helper';
import {camplockdata,campunlockdata,campremove,campfavourite } from '../../services/asService';
import {CardLoader} from '../reuse/SVG';
import { Link } from 'react-router-dom';
import { LogoWhite } from '../reuse/SVG';
 
const CampData  = (props) => {
   const { ageGroup, camps, checkInTime, checkOutTime, created, endDate,ezProRating, gender,isFavourite, isLocked,programId,programImage, programName, programType, registrationFee, startDate, startweekno } =  props.Details;   
   const [lockunlock, setLockUnlock] = useState(isLocked);
   const [isFavouritecamp, setIsFavourite] = useState(isFavourite);
   const [hideelement, setElementVisiblity] = useState('block');
   const [loading, setLoadningAction] = useState(false);
   
   const handelLockUnclock = async (parm , programId) =>{
    setLoadningAction(true);
   let campnum = props.Campnum-1;
    if(parm)
       {   
       const localdata =await campunlockdata({programId}); 
       try{
        setLockUnlock(false);
        props.handelCampsAction(props.WeekmainIndex, campnum, false,'lockunlock');
        setLoadningAction(false);
        }
        catch(err){}
       }
       else
       {
        const localdata =await camplockdata({programId});
        try{
           setLockUnlock(true);
           props.handelCampsAction(props.WeekmainIndex, campnum, true, 'lockunlock');  
           setLoadningAction(false);  
        }
        catch(err){}      
       }
    }   
   
    const  setElementVisiblityAction = async (programId) =>{
    let campnum = props.Campnum-1;
    setLoadningAction(true);  
    setElementVisiblity('none');
    const removecamp =  await campremove({programId}); 
    try{
    setElementVisiblity('none');
    props.handelCampsAction(props.WeekmainIndex, campnum, true, 'remove');
    setLoadningAction(false);
    }
    catch(err){}   
   }

   const  setIsFavouriteAction = async (programId, favourite) =>{
    setLoadningAction(true);
    let campnum = props.Campnum-1;
    const favCamp = await campfavourite({programId,favourite}); 
     try{
        setIsFavourite(favourite);
        setLoadningAction(false);
        props.handelCampsAction(props.WeekmainIndex, campnum, favourite, 'favourite');          
     }
     catch(err){}  
}   
   
   return(
        <div className="camp_card" style={{display : hideelement}}>
            {loading ? <CardLoader/>: ''}
            <Link to={`/camps/${programId}`} className="card_link" />
            <div className="as_lock_wrap">
            <span className="as_count">{props.Campnum}</span>
            { lockunlock ? (<i className="icon-lock" test={programId} onClick={()=>handelLockUnclock(lockunlock,programId)} />) : (<i className="icon-unlock" onClick={()=>handelLockUnclock(lockunlock,programId)} test={programId} />)  }
            </div>


        <div className="camp_card_head">
            {props.Details.isSpecialDeal ? <span className="SD_tag">Special Offers</span> : <h4>{camps}</h4>}
            {props.Details.isOnline ? <span className="SD_tag cm_online_tag">Online</span>: ''}
            {
              isFavouritecamp ? ( 
                <span className= "btn_like active" style={{right: '110px'}} onClick={()=>setIsFavouriteAction(programId, 0)}><i className="icon-heart" /></span>
              )
              :
              <span className= "btn_like" style={{right: '110px'}} onClick={()=>setIsFavouriteAction(programId, 1)}><i className="icon-heart" /></span> 
            }
            
            <span className="btn_remove" onClick={()=>setElementVisiblityAction(programId,)}>Remove</span>
        </div>
        {
                programImage ? <img src={programImage} /> : <div className="no_img"><LogoWhite /></div>
        }
       
        <div className="camp_card_content">
            <h3>{programName}</h3>
            <div className="row no-gutters">
                <div className="col-md-8">
                    <p>Program Date : {campDates(startDate, endDate)}</p>
                    <p>Type of Program : {getProgramType(programType)} <span className="cm_horizental_space" /> Time : {checkInTime} - {checkOutTime}</p>
                    <p>Gender : {gender==1 && 'Male' || gender==2 && 'Female' || gender==3 && 'co-ed'} <span className="cm_horizental_space" /> Age Group : {ageGroup}</p>
                </div>
                <div className="col-md-4 text-right">
                    <h3>Price : ${registrationFee ? registrationFee.toFixed(2) : ''}</h3>
                    <p>EZPro <span className="EZPro_rating">{ezProRating} <i className="icon-star"></i></span></p>
                </div>
            </div>
        </div>
    </div>
    )
}


export default CampData;