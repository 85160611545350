import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CampCard from './CampCard';
import MainFilter from './MainFilter';
import { getProgramListAction,  weekListdataAction,getSearchProgramlistAction} from '../../store/autoscheduler';
import {autoscheduleraddtocart} from '../../services/asService';
import { ProgramLoader, CardLoader } from '../reuse/SVG';
import { Modal } from 'react-bootstrap';
import 'react-date-range/dist/styles.css'; // main css ;
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Empty } from 'antd';
import PaginatedComponent from '../common/Pagination/PaginationComponent';

const AutoScheduler = props => {
    const[page, setPage]                     = useState(1);
    const[WeekData, setWeekData]             = useState([]);
    const[programList, setProgramList]       = useState([]);
    const[filters, setFilters]               = useState([]);
    const[loader, setLoader]                 = useState(true);
    const[weeknumber, setWeeknumber]         = useState();
    const[weekIndex, setWeekIndex]           = useState();
    const[cartLoder, setCartLoder]           = useState(false);
    const[warningModel, setWarningModel]     = useState(false);
    const[successModel, setSuccessModel]     = useState(false);

    useEffect(() => {
        props.getProgramListAction();
    }, [])

    useEffect( () => {
        setWeekData();
        setPage(1);
        setProgramList(props.programList);
        setFilters(props.filters);
        setLoader(props.loader);
    }, [props.programList])

    useEffect( () => {
        if(props.weekdata!==undefined && props.weekdata.programs.length>0)
        {
        if(programList.length>0)
        {    
        let newpropgramlist = [...programList];
        newpropgramlist[props.weekdata.weekindex]['programs'] = props.weekdata.programs;
        setProgramList(newpropgramlist);    
        }    
    }
        setLoader(props.loader);
    }, [props.weekdata]) 
    
    const indexOfLastTodo = page * 2;
    const indexOfFirstTodo = indexOfLastTodo - 2;
    const currentPrograms = programList.slice(indexOfFirstTodo, indexOfLastTodo);  
    
    const handelCampsAction =(weekindex, campindex, status, type) =>{   
        let newpropgramlist = [...programList];
        
        if(type=='lockunlock')
        {
        newpropgramlist[weekindex]['programs'][campindex].isLocked=status;
        }
        if(type=='favourite')
        {  
        newpropgramlist[weekindex]['programs'][campindex].isFavourite=status;
        } 
        if(type=='remove')
        {
           newpropgramlist[weekindex]['programs'].splice(campindex,1); 
        }                
        setProgramList(newpropgramlist);
    }
  
const addAutoSchedulartoCart = () => {
    setCartLoder(true);
    let programIds = [];
    programList.map((weekdata=>{
        if(weekdata.programs.length>0)
        {
            weekdata.programs.map(progr=>{
                if(progr.isLocked){ programIds.push(progr.programId) }
            })
        }
    }))
    
    if(programIds.length>0)
    {
        let params = {"programIds": JSON.stringify(programIds)}
        autoscheduleraddtocart(params).then((res)=>{
            setSuccessModel(true);
            setCartLoder(false);
        })
        
    }
    else
    {
        setWarningModel(true);
        setCartLoder(false)
    }
}   
   
    return(
        <div className="body_container">

            <Modal
                    dialogClassName="h_auto"
                    show={successModel}
                    animation={false}
                    centered
                >
                <Modal.Body>
                    <div className="p-5">
                        <h3 className="text-center mb-4">Success!</h3>
                        <p className="text-center">Successfully added to cart </p>
                        <button onClick={()=>setSuccessModel(false)}className="btn btn-primary btn-block mt-2">Ok</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                    dialogClassName="h_auto"
                    show={warningModel}
                    animation={false}
                    centered
                >
                <Modal.Body>
                    <div className="p-5">
                        <p className="text-center">No locked program pesent in your auto scheduler </p>
                        <button onClick={()=>setWarningModel(false)}className="btn btn-primary btn-block mt-2">Ok</button>
                    </div>
                </Modal.Body>
            </Modal>




            {cartLoder ? <div className="favloder_wrapper"><CardLoader/></div> : ''}
            <div className="container">
                <div className="d-flex align-items-center justify-content-between section_header">
                    <h2>Auto Scheduler</h2>

                    <div><button onClick={addAutoSchedulartoCart} className="btn btn-secondary pl-5 pr-5"> Add Auto Schedule to Cart</button></div>
                </div>
            </div>

            
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <MainFilter hideCategory={true} filters={filters} ClearAll={() => {setWeeknumber(); setProgramList([]); }} SubmitForm ={(params)=>{setLoader(true); setProgramList([]); setWeeknumber(); props.searchListdataAction(params)}} />
                    </div>
                    <div className="col-lg-8">                       
                        {
                           loader ?  <div className="program_loader"><ProgramLoader /></div> : 
                         (   
                        <div className="cat_btn_group">    
                        {
                            programList.length>0 && ( <span className={ weeknumber ? "mr-2 btn btn-outline-dark" :"mr-2 btn btn-outline-dark active" } onClick={() => {setWeekData(); setWeeknumber(); setWeekIndex(); setPage(1) }}>All</span>)
                        }
                       
                            {
                                programList.length>0 && programList.map((programItems, i) => {
                                 
                                    if(programItems.programs.length>0)
                                    {
                                        return (<span  key={programItems.stamp}
                                            onClick={ ()=> {setWeekData(programItems); setWeeknumber(i+1); setWeekIndex(i+1) } } className={weeknumber == i+1 ? "mr-2 btn btn-outline-dark active" :"mr-2 btn btn-outline-dark"}>Week {i+1}</span>)          
                                    }
                                    else
                                    {
                                        return (<span  key={programItems.stamp}
                                            className="mr-2 btn btn-outline-dark cm_disabled ">Week {i+1}</span>) 
                                    }
                                    
                                    
                                })   
                            } 
                        </div>
                         )}
                        <div className="camps_list" style={{paddingLeft: '30px'}}>
                            {
                                loader ? '' :
                                (
                                WeekData ? (
                                <CampCard  key={WeekData.weekStart} Camps={WeekData.programs} Weekstart={  WeekData.stamp } Weekend={WeekData.endstamp} Weeknumber={ WeekData.weekId} WeekIndex={weekIndex} findNew={(weekindex, weekstamp) =>{props.weekListdataAction(weekindex, weekstamp);} } handelCampsAction={(weekid,campid,status,type) => handelCampsAction(weekid,campid,status,type)}   />
                                ):
                                                                                          
                                programList.length> 0 ? currentPrograms.map((programItems, i) =>( <CampCard  key={i}   Camps={ programItems.programs} Weekstart={ programItems.stamp } Weekend={programItems.endstamp} Weeknumber={ programItems.weekId} WeekIndex={(indexOfLastTodo-1)+i} findNew={(weekindex, weekstamp) => {props.weekListdataAction(weekindex, weekstamp);}} handelCampsAction={(weekid,campid,status,type) => handelCampsAction(weekid,campid,status,type)}  />  )) :<div className="cm_empty"><Empty description="No Programs Found" /> </div>
                                )
                            }    
                        </div>
                         
                       {
                             WeekData || programList.length==0 ? '' :(
                                <div className="d-flex justify-content-center mt-5">
                                    <PaginatedComponent
                                        activePage={page}
                                        itemsCountPerPage={2}
                                        totalItemsCount={programList.length}
                                        pageRangeDisplayed={4}
                                        onPageChange={setPage}/>
                                </div> 
                             ) 

                         }   




                    </div>
                </div>
            </div>
        </div>
    )
}

/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { programs, filters,weekdata, loader } = state.autoScheduler;
    return {
        programList:programs,
        filters,
        weekdata,
        loader
    };
}
  
const mapDispatchToProps = dispatch => ({
    getProgramListAction: () => dispatch(getProgramListAction()),
    weekListdataAction: (weekindex, weekstamp) => dispatch(weekListdataAction(weekindex, weekstamp)),
    searchListdataAction: (parms) => dispatch(getSearchProgramlistAction(parms)),
    
});
  
export default connect(mapStateToProps, mapDispatchToProps)(AutoScheduler);
