import React, {useState, useEffect} from 'react';
import {profileInformation} from '../../services/campersServices';
import {ProgramLoader} from '../reuse/SVG';

const MyRewards = () => {

  const [rewards, setRewards] = useState();
  const [loading, setLoding] =  useState(true);  



  useEffect(()=>{

    profileInformation().then((res)=>{
        if(res?.data?.statusCode)
        {
            setRewards(res.data.responseData.result.rewards);
            setLoding(false);
        }
        else
        {
            setLoding(false);  
        }
    })

  },[])


    return(
        <div className="content_wrapper booking_page">
            <h2>My Rewards</h2>
           <div className="card">
           {loading ? <div className="page_loader"><ProgramLoader /></div> : ''}
            <div className="rewards_points">Total Rewards Point : <span>{rewards}</span></div>
            {
                    // loop.map(item => (
                    //     <div className="reward_item" key={item}>
                    //         <img src={require('../../assets/img/asocialted.png')} />
                    //         <div className="reward_exp">exp. feb 10</div>
                    //         <h3>10% off on your next Booking</h3>
                    //         <p>Daily login bonus $40 max discount</p>
                    //         <div className="copy_code">INABFR <i className="icon-copy" /></div>
                    //     </div>
                    // ))
                }
           </div>

        </div>
    )
}

export default MyRewards;


const loop = [1,2,3]