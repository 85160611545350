import * as actionType from './actionsTypes';

const initialState = {
    pickupUserModal: false,
    pickupTab: 0
}


/************************************************************************************/
// Pickup Reducer
/************************************************************************************/


const pickupReducer = (state = initialState, action) => {

    switch (action.type) {


        case actionType.PICKUP_USER_MODAL:
            return {
            ...state,
            pickupUserModal: !state.pickupUserModal
        }


        case actionType.PICKUP_TAB:
            return {
            ...state,
            pickupUserModal: false,
            pickupTab: action.payload
        }

        

        default: return state;
    }
}




/************************************************************************************/
// All Pickup Actions
/************************************************************************************/


export const togglePickupUserModalAction = () => (dispatch => dispatch({ type: actionType.PICKUP_USER_MODAL }))


export const pickupTabAction = payload => (dispatch => dispatch({ type: actionType.PICKUP_TAB, payload }))

export default pickupReducer;