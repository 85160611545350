import React from 'react'
import { calculateInsuranceAmount } from '../../../../helper'

const RegistarationComponent = ({item,insuranceState,
                                camperId, userEmail, userName, zipCode, 
                                userLastName, camperDetails,startDate, endDate, 
                                couponMoney=0,noOfCampers=1, additionalExperienceTotal=0}) => {
    return (
        <registration-cancellation
            key={`gap-medical-insurance-offer-${item._id}-${camperId}`}
            customer-state={insuranceState}
            customer-email-address={userEmail}
            customer-first-name={userName.split(' ')[0]}
            customer-last-name={userName.split(' ').length > 1 ? userName.split(' ').at(-1) : userLastName}
            customer-city="NA"
            customer-address="NA"
            customer-postal-code={zipCode}
            data-unique-id={item._id}
            data-context-name={item.campName}
            data-context-name-id={camperId}
            data-context-event={item.programName}
            data-context-event-id={item.programId}
            participant-first-name={camperDetails.firstName}
            participant-last-name={camperDetails?.lastName}
            event-start-date={startDate}
            event-end-date={endDate}
            insurable-amount={calculateInsuranceAmount(item.f_campRegistrationFee, item.f_convenienceFee, noOfCampers, additionalExperienceTotal, couponMoney)}
            coverage-context-description={`${item.campName} - ${item.programName}`}
        ></registration-cancellation>)
}

export default RegistarationComponent
