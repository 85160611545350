import * as actionType from './actionsTypes';
import { resHandle } from '../helper';
import { unreadCountService } from '../services/inboxService';

const initialState = {
    chatMessages: [],
    groupId :'',
    unreadCount: '',
    bookingUserChat: ''
}


const chatReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionType.ADD_MESSAGE:
            let oldmessage = [...state.chatMessages];
            let groupId = state.groupId;
            if(action.payload.groupId == groupId){
                if(oldmessage.length > 0){
                    oldmessage.push(action.payload)
                }else if(action.payload.receiverId !== null){
                    return {
                        ...state,
                        chatMessages: action.payload.messag
                    }
                }else{
                    oldmessage = action.payload
                }

                return {
                    ...state,
                    chatMessages: oldmessage
                }
           }else{
                return {
                ...state,
                chatMessages: oldmessage
                }    
            }

        case actionType.GET_MESSAGE: 
        
            return {
                ...state,
                chatMessages: action.payload.messages,
                groupId:action.payload.groupId   
            } 

        case actionType.GET_UNREAD_COUNT: 
            return {
                ...state,
                unreadCount: action.payload, 
            } 

        case actionType.BOOKING_USER_CHAT: 
            return {
                ...state,
                bookingUserChat: action.payload, 
            } 
        default:
            return state
    }
}

export const Addmessages = (messages) => async dispatch => 
{
    dispatch({ type: actionType.ADD_MESSAGE, payload: messages });
}

export const getmessages = (messages) => async dispatch => 
{
    dispatch({ type: actionType.GET_MESSAGE, payload: messages });
}


export const bookingUserChatAction = payload => async dispatch => dispatch({ type: actionType.BOOKING_USER_CHAT, payload });




export const unreadCountAction = () => async dispatch => {
    try {
        let countData = await unreadCountService();
        let { status, data } = resHandle(countData);
        if(data){
            dispatch({ type: actionType.GET_UNREAD_COUNT, payload: data.count});
        }else{
            dispatch({ type: actionType.GET_UNREAD_COUNT, payload: ''});
        }
    } catch (error) {
        console.log(error, 'error')
    }
}



export default chatReducer;