import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Modal } from 'react-bootstrap';

import { changeInboxTabAction } from '../../store/inbox';
import ChatUserList from './ChatUserList';
import AboutActiveChatUser from './AboutActiveChatUser';
import ChatBoxLayout from './ChatBoxLayout';
import UserImg from '../../assets/img/user.png'


const ChatView = props => {


    return(
        <div className='chat_view_page'>
            <ChatUserList />
            {
                props?.activeUserChatWindow?.userId ? 
                <Fragment>
                    <ChatBoxLayout />
                    <AboutActiveChatUser />
                </Fragment> : 
                <div className="chat_box_layout" style={{width: 'calc(100% - 295px)', marginRight: 0}}>
                    <div className="chat_welcom_inner">
                        <img src={props.userProfile?.profileImage ? props.userProfile?.profileImage : UserImg} alt={`${props.userProfile?.name}`} />
                        <h4>Welcome back {props.userProfile?.name}</h4>
                    </div>
                </div>
            }   

        </div>
    )
}


const mapStateToProps = state => {
    let { userProfile } = state.user;
    let { inboxTab, activeUserChatWindow } = state.inbox;
    return {
        userProfile,
        inboxTab,
        activeUserChatWindow
    };
}
  
const mapDispatchToProps = dispatch => ({
    changeInboxTabAction: params => dispatch(changeInboxTabAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatView);

