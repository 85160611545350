import * as actionType from './actionsTypes';

const initialState = {
    keywordSearch: '',
    gender: '',
    ageGroup: '',
    grade: '',
    startDate: '',
    endDate: '',
    programType: '',
    weekDay: '',
    city: '',
    radius:'',
    flat:'',
    flong:'',
    priceRange: '',
    ezProRating: '',
    categories: [],
   // lat: '',
    //long: '',
    pageNo: 1,
    weekDays: [],
    subCategories: [],
    campers: [],
    limit: 10,
    filterType: '',
    maxDistance: '',
    camperslistselected:[],
    camperDisplay: [],
    advanceSearch: false,
    isOnline:null,
    isDistrictCamps: false
}



const pFilterReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionType.F_KEYWORDSEARCH:
            return {
                ...state,
                keywordSearch: action.payload
            }

        case actionType.F_GENDER:
            return {
                ...state,
                gender: action.payload
            }
        
        case actionType.F_AGE_GROUP:
            return {
                ...state,
                ageGroup: action.payload
            }

        case actionType.F_GRADE:
            return {
                ...state,
                grade: action.payload
            }

        case actionType.F_DATES:
            return {
                ...state,
                startDate: action.payload[0],
                endDate: action.payload[1]
            }

        case actionType.F_PROGRAM_TYPE:
            return {
                ...state,
                programType: action.payload
            }


        case actionType.F_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            }

        case actionType.F_SUB_CATEGORIES:
            return {
                ...state,
                subCategories: action.payload
            }



        case actionType.F_ADVANCE_SEARCH:
            return {
                ...state,
                advanceSearch: !state.advanceSearch
            }

        case actionType.F_CITY:
            return {
                ...state,
                city: action.payload
            }

        case actionType.F_RADIUS:
            return {
                ...state,
                radius: action.payload
            }
        
        case actionType.F_FILTER_LAT_LONG:
            return {
                ...state,
                flat: action.payload.lat,
                flong: action.payload.lng
            }           

        case actionType.F_PRICE_RANGE:
            return {
                ...state,
                priceRange: action.payload
            }


        case actionType.F_FILTER_TYPE:
            return {
                ...state,
                pageNo: 1,
                filterType: action.payload
            }


        case actionType.F_WEEK_DAY:
            return {
                ...state,
                weekDays: action.payload
            }


        case actionType.F_EZ_PRO_RATING:
            return {
                ...state,
                ezProRating: action.payload
            }


        case actionType.F_MAX_DISTANCE:
            return {
                ...state,
                maxDistance: action.payload
            }


        case actionType.F_CAMPER_DISPLAY_LIST:
            return {
                ...state,
                camperDisplay: action.payload
            }


        case actionType.F_LAT_LONG:
            return {
                ...state,
                lat: action.payload[0],
                long: action.payload[1]
            }


        case actionType.F_PAGE_NO:
            return {
                ...state,
                pageNo: action.payload
            }


        case actionType.F_CAMPERS:
            return {
                ...state,
                campers: action.payload
            }


        case actionType.CLEAR_SINGLE_FILTER:
            return {
                ...state,
                [action.payload]: ''
            }

        case actionType.CLEAR_DATE_FILTER:
            return {
                ...state,
                startDate: '',
                endDate: '',
            }
        case actionType.IS_ONLINE:
            return {
                    ...state,
                    isOnline: action.payload
                    
                } 
                
        case actionType.IS_DISTRICT_CAMP:
            return {
                    ...state,
                    isDistrictCamps: !state.isDistrictCamps
                    
                } 
        
        case actionType.CLEAR_ALL_FILTER:
            return {
                ...state,
                keywordSearch: '',
                gender: '',
                ageGroup: '',
                grade: '',
                startDate: '',
                endDate: '',
                programType: '',
                weekDay: '',
                city: '',
                priceRange: '',
                ezProRating: [],
                categories: [],
                flat:'',
                flong:'',
              //  lat: '',
                //long: '',
                weekDays: [],
                subCategories: [],
                campers: [],
                filterType: '',
                maxDistance: '',
                camperDisplay: [],
                advanceSearch: false,
                isOnline:null,
                radius: ''
            }
        
            
        default:
            return state
    }
}


/************************************************************************************/
// All pFilter Actions
/************************************************************************************/

export const fSearchAction = payload => (dispatch => dispatch({ type: actionType.F_KEYWORDSEARCH, payload }))

export const fGenderAction = payload => (dispatch => dispatch({ type: actionType.F_GENDER, payload }))

export const fAgeGroupAction = payload => (dispatch => dispatch({ type: actionType.F_AGE_GROUP, payload }))

export const fGrageAction = payload => (dispatch => dispatch({ type: actionType.F_GRADE, payload }))

export const fDatesAction = payload => (dispatch => dispatch({ type: actionType.F_DATES, payload }))

export const fProgramTypeAction = payload => (dispatch => dispatch({ type: actionType.F_PROGRAM_TYPE, payload }))

export const fCategoriesAction = payload => (dispatch => dispatch({ type: actionType.F_CATEGORIES, payload }))

export const fSubCategoriesAction = payload => (dispatch => dispatch({ type: actionType.F_SUB_CATEGORIES, payload }))

export const fCityAction = payload => (dispatch => dispatch({ type: actionType.F_CITY, payload }))

export const fRadiusAction = payload => (dispatch => dispatch({ type: actionType.F_RADIUS, payload }))

export const filterLatLongAction = payload => (dispatch) => 
{
  dispatch({ type: actionType.F_FILTER_LAT_LONG, payload })
} 

export const fPriceRangeAction = payload => (dispatch => dispatch({ type: actionType.F_PRICE_RANGE, payload }))

export const fFilterTypeAction = payload => (dispatch => dispatch({ type: actionType.F_FILTER_TYPE, payload }))

export const fWeekDayAction = payload => (dispatch => dispatch({ type: actionType.F_WEEK_DAY, payload }))

export const fLatLongAction = payload => (dispatch => dispatch({ type: actionType.F_LAT_LONG, payload }))

export const fEzProEatingAction = payload => (dispatch => dispatch({ type: actionType.F_EZ_PRO_RATING, payload }))

export const fMaxDistanceAction = payload => (dispatch => dispatch({ type: actionType.F_MAX_DISTANCE, payload }))

export const fPageNoAction = payload => (dispatch => dispatch({ type: actionType.F_PAGE_NO, payload }))

export const fCampersAction = payload => (dispatch => dispatch({ type: actionType.F_CAMPERS, payload }))

export const fCampersListAction = payload => (dispatch => dispatch({ type: actionType.F_CAMPERS_LIST, payload }))

export const fCamperDisplayAction = payload => (dispatch => dispatch({ type: actionType.F_CAMPER_DISPLAY_LIST, payload }))

export const fAdvanceSearchAction = () => (dispatch => dispatch({ type: actionType.F_ADVANCE_SEARCH }))

export const clearSingleFitlerAction = payload => (dispatch => dispatch({ type: actionType.CLEAR_SINGLE_FILTER, payload }))

export const clearDateFitlerAction = () => (dispatch => dispatch({ type: actionType.CLEAR_DATE_FILTER }))

export const clearAllFitlerAction = () => (dispatch => dispatch({ type: actionType.CLEAR_ALL_FILTER }))

export const isDistrictCampAction = () => (dispatch => dispatch({ type: actionType.IS_DISTRICT_CAMP }))

export const fIsonlineOffline = payload => (dispatch => dispatch({ type: actionType.IS_ONLINE,payload }))


export default pFilterReducer;