import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { notification } from 'antd';

import {
    CustomNumber,
} from '../reuse/FormControls';
import { invoiceListService, refundPolicyService, getRefundPolicyService } from '../../services/otherService';
import Coupan from './Coupan';
import PaymentsList from './PaymentsList';
import Payouts from './Payouts';

const Payments = props => {
    const [tab, setTab] = useState(1);
    const [invoices, setInvoices] = useState([]);
    const [refunds, setRefunds] = useState([1, 2, 3, 4, 5, 6]);
    const [refundAvailable, setRefundAvailable] = useState(2);
    const [refundType, setRefundType] = useState(2);
    const [cancellationAvailable, setCancellationAvailable] = useState(2);
    const [cancellationType, setCancellationType] = useState(2);
    const [cancellationValue, setCancellationValue] = useState('');

    const [r_100, setR_100] = useState('');
    const [r_75, setR_75] = useState('');
    const [r_50, setR_50] = useState('');
    const [r_25, setR_25] = useState('');

    const [days_1, setDays_1] = useState(100);
    const [days_2, setDays_2] = useState(75);
    const [days_3, setDays_3] = useState(50);
    const [days_4, setDays_4] = useState(25);


    const getInvoiceList = () => {
        let query = `?campCode=${props.campCode}`;

        invoiceListService(query).then(res => {
            if (res.data.statusCode) {
                setInvoices(res.data.responseData.result)
            }
        })
    }


    const getRefundPolicyData = () => {
        let query = `?campCode=${props.campCode}`;

        getRefundPolicyService(query).then(res => {
            if (res.data.statusCode) {
                setCancellationAvailable(res.data.responseData.result.cancellationAvailable);
                setCancellationType(res.data.responseData.result.cancellationType);
                setCancellationValue(res.data.responseData.result.cancellationValue);
                setRefundAvailable(res.data.responseData.result.refundAvailable);
                setRefundType(res.data.responseData.result.refundType);
                if(res.data.responseData.result.refundOptions.length){
                    setR_100(res.data.responseData.result.refundOptions[0].percent);
                    setR_75(res.data.responseData.result.refundOptions[1].percent);
                    setR_50(res.data.responseData.result.refundOptions[2].percent);
                    setR_25(res.data.responseData.result.refundOptions[3].percent);
                    setDays_1(res.data.responseData.result.refundOptions[0].days);
                    setDays_2(res.data.responseData.result.refundOptions[1].days);
                    setDays_3(res.data.responseData.result.refundOptions[2].days);
                    setDays_4(res.data.responseData.result.refundOptions[3].days);
                }



            }
        })
    }

    useEffect(() => {
        getInvoiceList();
        getRefundPolicyData();
    }, [])


    const submitRefundPolicy = () => {
        let params = {
            campCode: props.campCode,
            refundAvailable,
            refundType,
            refundOptions: JSON.stringify([
                { percent: r_100, days: days_1 },
                { percent: r_75, days: days_2 },
                { percent: r_50, days: days_3 },
                { percent: r_25, days: days_4 }
            ]),
            cancellationAvailable,
            cancellationType,
            cancellationValue
        }

        refundPolicyService(params).then(res => {
            if (res.data.statusCode) {
                // setRefundAvailable(2);
                // setRefundType(2);
                // setCancellationAvailable(2);
                // setCancellationType(2);
                // setCancellationValue('');
                // setR_100('');
                // setR_75('');
                // setR_50('');
                // setR_25('');
                getRefundPolicyData();
                notification.open({
                    description:
                        'Cancellation policy update successfull',
                });
            }
        })

    }



    return (
        <div className="body_container">

            <div className="container">

                <div className="d-flex section_header">
                    <h2 className="m-0">Payment</h2>
                </div>


                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center page_card_header cm_border_0">
                            <button className={`rounded-0 pl-5 pr-5 mr-3 btn btn-outline-dark ${tab == 1 ? 'active' : ''}`} onClick={() => setTab(1)}>Invoices</button>
                            <button className={`rounded-0 pl-5 pr-5 mr-3 btn btn-outline-dark ${tab == 2 ? 'active' : ''}`} onClick={() => setTab(2)}>Refunds</button>
                            <button className={`rounded-0 pl-5 pr-5 mr-3 btn btn-outline-dark ${tab == 3 ? 'active' : ''}`} onClick={() => setTab(3)}>Cancellation Policy</button>
                            <button className={`rounded-0 pl-5 pr-5 mr-3 btn btn-outline-dark ${tab == 4 ? 'active' : ''}`} onClick={() => setTab(4)}>Coupon</button>
                            <button className={`rounded-0 pl-5 pr-5 mr-3 btn btn-outline-dark ${tab == 5 ? 'active' : ''}`} onClick={() => setTab(5)}>Payments</button>
                            <button className={`rounded-0 pl-5 pr-5 btn btn-outline-dark ${tab == 6 ? 'active' : ''}`} onClick={() => setTab(6)}>Payouts</button>
                        </div>

                        {
                            (tab == 3 || tab == 4 || tab == 5 || tab == 6) ? '' : <div className="partner-form search_wrap" style={{ maxWidth: '100%' }}>
                                <i className="icon-search" />
                                <input type="text" className="form-control form-control-lg" placeholder="Search" />
                            </div>
                        }

                    </div>
                </div>



                {tab == 1 ? <div className="row">
                    {invoices.map(item => (
                        <div className="col-md-4" key={item._id}>
                            <a href={item.invoiceUrl} target="_blank" className="cm_card_link">
                                <div className="p_card">
                                    <img src={require('../../assets/img/pdf_icon.svg')} />
                                    <div className="p_card_body">
                                        <p className="color_black">Camp Name : {item.campName}</p>
                                        <p>Dated : {moment(item.created).format('MMMM DD, YYYY')}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div> : ''}


                {tab == 2 ? <div className="camp_list">
                    {/* {refunds.map((item, i) => <CampCard />)}    */}
                </div> : ''}



                {tab == 3 ? <div className="cm_cancellation">
                    <h3>Refund & Cancellation Terms </h3>

                    <div className="check_box_wrapper cm_dark_check">
                        <input
                            type="radio"
                            name="refundAvailable"
                            value={2}
                            checked={refundAvailable == 2 ? true : false}
                            onChange={e => setRefundAvailable(parseInt(e.target.value))} />
                        <span className="check_box"><span></span> No Refund Available</span>
                    </div>

                    <div className="check_box_wrapper cm_dark_check">
                        <input
                            type="radio"
                            name="refundAvailable"
                            value={1}
                            checked={refundAvailable == 1 ? true : false}
                            onChange={e => setRefundAvailable(parseInt(e.target.value))} />
                        <span className="check_box"><span></span> Yes Refund will be :</span>
                    </div>

                    {
                        refundAvailable == 1 ? <div className="pl-5 mt-4">
                            <div className="check_box_wrapper cm_dark_check">
                                <input
                                    type="radio"
                                    name="refundType"
                                    value={2}
                                    checked={refundType == 2 ? true : false}
                                    onChange={e => setRefundType(parseInt(e.target.value))} />
                                <span className="check_box"><span></span> Original form of payment</span>
                            </div>

                            <div className="check_box_wrapper cm_dark_check">
                                <input
                                    type="radio"
                                    name="refundType"
                                    value={1}
                                    checked={refundType == 1 ? true : false}
                                    onChange={e => setRefundType(parseInt(e.target.value))} />
                                <span className="check_box"><span></span> Camp Only redeemable voucher</span>
                            </div>



                                <div className="pl-5 mt-4">
                                    <ul>
                                        <li>For <CustomNumber
                                            value={days_1}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            onChange={e => setDays_1(e)}
                                            min={0} /> refund booking must be cancelled
                                            <CustomNumber
                                                value={r_100}
                                                onChange={e => setR_100(e)}
                                                min={0} />  days prior to program start date. </li>


                                        <li>For <CustomNumber
                                            value={days_2}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            onChange={e => setDays_2(e)}
                                            min={0} /> refund booking must be cancelled
                                            <CustomNumber
                                                value={r_75}
                                                onChange={e => setR_75(e)}
                                                min={0} />  days prior to program start date. </li>


                                        <li>For <CustomNumber
                                            value={days_3}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            onChange={e => setDays_3(e)}
                                            min={0} /> refund booking must be cancelled
                                            <CustomNumber
                                                value={r_50}
                                                onChange={e => setR_50(e)}
                                                min={0} />  days prior to program start date. </li>


                                        <li>For <CustomNumber
                                            value={days_4}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            onChange={e => setDays_4(e)}
                                            min={0} /> refund booking must be cancelled
                                            <CustomNumber
                                                value={r_25}
                                                onChange={e => setR_25(e)}
                                                min={0} />  days prior to program start date. </li>

                                    </ul>
                                </div>



                            {/* <div className="pl-5 mt-4">
                                        <ul>
                                            <li>For <span className="border_box">100%</span> refund booking must be cancelled 
                                            <CustomNumber
                                                value={r_100}
                                                onChange={e => setR_100(e)}
                                                min={0} />  days prior to program start date. </li>
                                            <li>For <span className="border_box">75%</span> refund booking must be cancelled 
                                            <CustomNumber
                                                value={r_75}
                                                onChange={e => setR_75(e)}
                                                min={0} />  days prior to program start date. </li>
                                            <li>For <span className="border_box">50%</span> refund booking must be cancelled 
                                            <CustomNumber
                                                value={r_50}
                                                onChange={e => setR_50(e)}
                                                min={0} />  days prior to program start date. </li>
                                            <li>For <span className="border_box">25%</span> refund booking must be cancelled 
                                            <CustomNumber
                                            value={r_25}
                                                onChange={e => setR_25(e)}
                                                min={0} />  days prior to program start date. </li>
                                            
                                        </ul>
                                    </div> */}
                        </div> : ''
                    }






                    <h3>Cancellation by {props.campCode ? props?.preList?.camp.filter(e => e.campCode == props.campCode ? e : '')[0]?.campName : ''}</h3>

                    <div className="check_box_wrapper cm_dark_check">
                        <input
                            type="radio"
                            name="cancellationAvailable"
                            value={2}
                            checked={cancellationAvailable == 2 ? true : false}
                            onChange={e => setCancellationAvailable(parseInt(e.target.value))} />
                        <span className="check_box"><span></span> No Refund Available</span>
                    </div>

                    <div className="check_box_wrapper cm_dark_check">
                        <input
                            type="radio"
                            name="cancellationAvailable"
                            value={1}
                            checked={cancellationAvailable == 1 ? true : false}
                            onChange={e => setCancellationAvailable(parseInt(e.target.value))} />
                        <span className="check_box"><span></span> Yes Refund will be :</span>
                    </div>

                    {
                        cancellationAvailable == 1 ? <div className="pl-5 mt-4">
                            <div className="check_box_wrapper cm_dark_check">
                                <input
                                    type="radio"
                                    name="cancellationType"
                                    value={2}
                                    checked={cancellationType == 2 ? true : false}
                                    onChange={e => setCancellationType(parseInt(e.target.value))} />
                                <span className="check_box"><span></span> Original form of payment</span>
                            </div>

                            <div className="check_box_wrapper cm_dark_check">
                                <input
                                    type="radio"
                                    name="cancellationType"
                                    value={1}
                                    checked={cancellationType == 1 ? true : false}
                                    onChange={e => setCancellationType(parseInt(e.target.value))} />
                                <span className="check_box"><span></span> Camp Only redeemable voucher</span>
                            </div>

                            <div className="pl-5 mt-4">
                                    <p>Refund amount will be
                                        <CustomNumber
                                            value={cancellationValue}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            onChange={e => setCancellationValue(e)}
                                            min={0} />  In the case of a cancellation prompted by low enrollment or other extenuating circumstances. </p>
                                </div> 
                        </div> : ''
                    }


                    <div className="text-center mb-5">
                        <span className="btn btn-primary pl-5 pr-5 rounded-0" onClick={submitRefundPolicy}>Submit</span>
                    </div>

                </div> : ''}



   


            </div>


            {tab == 4 ?  <Coupan /> : ''}
            {tab == 5 ?  <PaymentsList /> : ''}
            {tab == 6 ?  <Payouts /> : ''}


        </div >

    )
}


const mapStateToProps = state => {
    let { userProfile } = state.user;
    let { preList } = state.program;
    let { campCode } = userProfile;

    return {
        campCode,
        preList
    };
}


export default connect(mapStateToProps)(Payments);