import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import { DatePicker } from 'antd';

import { resendotpEmailModal, toggleAuthModal } from '../../store/ui';
import { accountTypes, businessTypes, isValidEmail } from '../../helper';
import { fastSignupAction } from '../../store/user';
import moment from 'moment';


const DirectorSignup = props => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [campCode, setCampCode] = useState('');
    const [dob, setDob] = useState('');
    const [address_line1, setAddress_line1] = useState('');
    const [address_city, setAddress_city] = useState('');
    const [address_state, setAddress_state] = useState('');
    const [address_postal_code, setAddress_postal_code] = useState('');
    const [address_country, setAddress_country] = useState('');
    const [ssn, setSsn] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountType, setAccountType] = useState('');
    const [routing_number, setRouting_number] = useState('');
    const [account_number, setAccount_number] = useState('');
    const [account_nickname, setAccount_nickname] = useState('');
    const [account_owner_name, setAccount_owner_name] = useState('');
    const [subAccountName, setSubAccountName] = useState('');
    const [url, setUrl] = useState('');
    const [type, setType] = useState('');
    const [industry, setIndustry] = useState('');


    const [nameErr, setNameErr] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [campCodeErr, setCampCodeErr] = useState('');
    const [dobErr, setDobErr] = useState('');
    const [address_line1Err, setAddress_line1Err] = useState('');
    const [address_cityErr, setAddress_cityErr] = useState('');
    const [address_stateErr, setAddress_stateErr] = useState('');
    const [address_postal_codeErr, setAddress_postal_codeErr] = useState('');
    const [address_countryErr, setAddress_countryErr] = useState('');
    const [ssnErr, setSsnErr] = useState('');
    const [accountNameErr, setAccountNameErr] = useState('');
    const [accountTypeErr, setAccountTypeErr] = useState('');
    const [routing_numberErr, setRouting_numberErr] = useState('');
    const [account_numberErr, setAccount_numberErr] = useState('');
    const [account_nicknameErr, setAccount_nicknameErr] = useState('');
    const [account_owner_nameErr, setAccount_owner_nameErr] = useState('');
    const [urlErr, setUrlErr] = useState('');
    const [typeErr, setTypeErr] = useState('');
    const [industryErr, setIndustryErr] = useState('');
    const [subAccountNameErr, setSubAccountNameErr] = useState('');





    const handleValidate = () => {
        let validate = true;


        if(name == ''){
            setNameErr('Name is required');
            validate = false
        }

        if(email == ''){
            setEmailErr('Email is required');
            validate = false
        }else if(!isValidEmail(email)){
            setEmailErr('Email not valid');
            validate = false
        }

        if(campCode == ''){
            setCampCodeErr('Camp code is required');
            validate = false
        }

        if(dob == '' || dob == 'undefined' || dob == null){
            setDobErr('Date of birth is required');
            validate = false
        }

        if(address_line1 == '' || address_line1 == 'undefined' || address_line1 == null){
            setAddress_line1Err('Address line is required');
            validate = false
        }

        if(address_city == '' || address_city == 'undefined' || address_city == null){
            setAddress_cityErr('Address city is required');
            validate = false
        }

        if(address_state == '' || address_state == 'undefined' || address_state == null){
            setAddress_stateErr('Address state is required');
            validate = false
        }

        if(address_postal_code == '' || address_postal_code == 'undefined' || address_postal_code == null){
            setAddress_postal_codeErr('Address postal code is required');
            validate = false
        }

        if(address_country == '' || address_country == 'undefined' || address_country == null){
            setAddress_countryErr('Address country is required');
            validate = false
        }

        if(ssn == '' || ssn == 'undefined' || ssn == null){
            setSsnErr('Address ssn is required');
            validate = false
        }

        if(accountName == '' || accountName == 'undefined' || accountName == null){
            setAccountNameErr('Account name is required');
            validate = false
        }

        if(accountName == '' || accountName == 'undefined' || accountName == null){
            setAccountNameErr('Account name is required');
            validate = false
        }

        if(accountType == '' || accountType == 'undefined' || accountType == null){
            setAccountTypeErr('Account type is required');
            validate = false
        }

        if(routing_number == '' || routing_number == 'undefined' || routing_number == null){
            setRouting_numberErr('Account routing number is required');
            validate = false
        }

        if(account_number == '' || account_number == 'undefined' || account_number == null){
            setAccount_numberErr('Account number is required');
            validate = false
        }

        if(account_nickname == '' || account_nickname == 'undefined' || account_nickname == null){
            setAccount_nicknameErr('Account nickname is required');
            validate = false
        }

        if(account_owner_name == '' || account_owner_name == 'undefined' || account_owner_name == null){
            setAccount_owner_nameErr('Account owner name is required');
            validate = false
        }

        if(url == '' || url == 'undefined' || url == null){
            setUrlErr('URL is required');
            validate = false
        }

        if(type == '' || type == 'undefined' || type == null){
            setTypeErr('Type is required');
            validate = false
        }

        if(industry == '' || industry == 'undefined' || industry == null){
            setIndustryErr('Industry is required');
            validate = false
        }

        if(subAccountName == '' || subAccountName == 'undefined' || subAccountName == null){
            setSubAccountNameErr('Sub account name is required');
            validate = false
        }

        return validate;
    }



    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            let params = {
                deviceID: 'campsez',
                deviceToken: 'campsez',
                deviceTypeID: '3',
                UserType: 2,
                name,
                email,
                campCode,
                subAccountName,
                address: JSON.stringify({
                    line: address_line1,
                    city: address_city,
                    state : address_state,
                    postal_code : address_postal_code,
                    country : address_country,
                    ssn : ssn,
                }),
                dob: JSON.stringify({
                    month: moment(dob).format('MM'),
                    day: moment(dob).format('DD'),
                    year: moment(dob).format('YYYY')
                }),
                account: JSON.stringify({
                    name: accountName,
                    type : accountType.value,
                    routing_number : routing_number,
                    account_number: account_number,
                    account_nickname: account_nickname,
                    account_owner_name: account_owner_name,
                }),
                business: JSON.stringify({
                    url: url,
                    type: type.value,
                    industry: industry,
                }),

            }



            props.fastSignupAction(params);
            props.resendotpEmailModal(email);
        }
    }


    return(
        <Modal
            dialogClassName="onboarding_form_wraaper"
            show={props.isDirecterAuth}
            animation={false}
            size="xl"
            centered >

            <div className='onboarding_form'>

                <h4>Sign Up as Staff Personnel?</h4>

                <div className='row'>
                    <div className='col-lg-4'>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="First Name"
                                className={`form-control ${nameErr ? 'is-invalid' : ''}`}
                                value={name}
                                onChange={e => (setName(e.target.value), setNameErr(''))} />
                                
                            {nameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{nameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className="form-group">
                            <input type="email" name="email" value="" style={{display: 'none'}} />
                            <input
                                type="email"
                                placeholder="Email"
                                className={`form-control ${emailErr ? 'is-invalid' : ''}`}
                                autocomplete="nope"
                                value={email}
                                onChange={e => (setEmail(e.target.value), setEmailErr(''))} />
                            
                            {emailErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{emailErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Org Code"
                                className={`form-control ${campCodeErr ? 'is-invalid' : ''}`}
                                value={campCode}
                                onChange={e => (setCampCode(e.target.value), setCampCodeErr(''))} />

                            {campCodeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{campCodeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            
                                
                        </div>
                    </div>


                </div>



                <h4>Business Details</h4>
               
                <div className='row'>


                    <div className='col-lg-4'>
                        <div className="form-group">
                            <input
                                type="url"
                                placeholder="URL"
                                className={`form-control ${urlErr ? 'is-invalid' : ''}`}
                                value={url}
                                onChange={e => (setUrl(e.target.value), setUrlErr(''))} />
                                
                            {urlErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{urlErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                        </div>
                    </div>


                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <Select
                                value={type}
                                onChange={e => (setType(e), setTypeErr(''))}
                                placeholder="Business Type"
                                className={`form-control ${typeErr ? 'is-invalid' : ''}`}
                                classNamePrefix="cm_select"
                                options={businessTypes} />

                            {typeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{typeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    </div>


                    <div className='col-lg-4'>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Industry"
                                className={`form-control ${industryErr ? 'is-invalid' : ''}`}
                                value={industry}
                                onChange={e => (setIndustry(e.target.value), setIndustryErr(''))} />
                                
                            {industryErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{industryErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                        </div>
                    </div>

                 
                    
                    </div>


                    <h4>Date of birth</h4>
                    <div className='row'>


                    <div className='col-lg-4'>
                        <div className="form-group">
                            <DatePicker
                                className={`form-control ${dobErr ? 'is-invalid' : ''}`}
                                format="MM/DD/YYYY"
                                placeholder="Date of birth"
                                onChange={e => (setDob(e._d), setDobErr(''))} />

                            {dobErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{dobErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    </div>

                    </div>


                    <h4>Address Details</h4>
                    <div className='row'>


                    <div className='col-lg-4'>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Address Line 1"
                                className={`form-control ${address_line1Err ? 'is-invalid' : ''}`}
                                value={address_line1}
                                onChange={e => (setAddress_line1(e.target.value), setAddress_line1Err(''))} />

                            {address_line1Err ? <OverlayTrigger placement="left" overlay={<Tooltip>{address_line1Err}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                
                        </div>
                    </div>


                    <div className='col-lg-4'>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Address City"
                                className={`form-control ${address_cityErr ? 'is-invalid' : ''}`}
                                value={address_city}
                                onChange={e => (setAddress_city(e.target.value), setAddress_cityErr(''))} />

                            {address_cityErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{address_cityErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                
                        </div>
                    </div>



                    <div className='col-lg-4'>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Address State"
                                className={`form-control ${address_stateErr ? 'is-invalid' : ''}`}
                                value={address_state}
                                onChange={e => (setAddress_state(e.target.value), setAddress_stateErr(''))} />

                            {address_stateErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{address_stateErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                
                        </div>
                    </div>


                    <div className='col-lg-4'>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Address Postal Code"
                                className={`form-control ${address_postal_codeErr ? 'is-invalid' : ''}`}
                                value={address_postal_code}
                                onChange={e => (setAddress_postal_code(e.target.value), setAddress_postal_codeErr(''))} />

                            {address_postal_codeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{address_postal_codeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                
                        </div>
                    </div>


                    <div className='col-lg-4'>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Address Country"
                                className={`form-control ${address_countryErr ? 'is-invalid' : ''}`}
                                value={address_country}
                                onChange={e => (setAddress_country(e.target.value), setAddress_countryErr(''))} />

                            {address_countryErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{address_countryErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                
                        </div>
                    </div>




                    <div className='col-lg-4'>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="SSN"
                                className={`form-control ${ssnErr ? 'is-invalid' : ''}`}
                                value={ssn}
                                onChange={e => (setSsn(e.target.value), setSsnErr(''))} />

                            {ssnErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{ssnErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                
                        </div>
                    </div>

    




                    


                </div>


                <h4>Account Details</h4>

                <div className='row'>
                    <div className='col-lg-4'>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Bank Name"
                                className={`form-control ${accountNameErr ? 'is-invalid' : ''}`}
                                value={accountName}
                                onChange={e => (setAccountName(e.target.value), setAccountNameErr(''))} />

                            {accountNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{accountNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                
                        </div>
                    </div>


                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <Select
                                value={accountType}
                                onChange={e => (setAccountType(e), setAccountTypeErr(''))}
                                placeholder="Account Type"
                                className={`form-control ${accountTypeErr ? 'is-invalid' : ''}`}
                                classNamePrefix="cm_select"
                                options={accountTypes} />

                            {accountTypeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{accountTypeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    </div>

                    {/* <div className='col-lg-4'>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Account Type"
                                className={`form-control ${accountTypeErr ? 'is-invalid' : ''}`}
                                value={accountType}
                                onChange={e => (setAccountType(e.target.value), setAccountTypeErr(''))} />

                            {accountTypeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{accountTypeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                
                        </div>
                    </div> */}


                    <div className='col-lg-4'>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Routing Number"
                                className={`form-control ${routing_numberErr ? 'is-invalid' : ''}`}
                                value={routing_number}
                                onChange={e => (setRouting_number(e.target.value), setRouting_numberErr(''))} />

                            {routing_number ? <OverlayTrigger placement="left" overlay={<Tooltip>{routing_number}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                
                        </div>
                    </div>


                    <div className='col-lg-4'>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Account Number"
                                className={`form-control ${account_numberErr ? 'is-invalid' : ''}`}
                                value={account_number}
                                onChange={e => (setAccount_number(e.target.value), setAccount_numberErr(''))} />

                            {account_numberErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{account_numberErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                
                        </div>
                    </div>


                    <div className='col-lg-4'>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Account Nickname"
                                className={`form-control ${account_nicknameErr ? 'is-invalid' : ''}`}
                                value={account_nickname}
                                onChange={e => (setAccount_nickname(e.target.value), setAccount_nicknameErr(''))} />

                            {account_nicknameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{account_nicknameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                
                        </div>
                    </div>


                    <div className='col-lg-4'>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Account Owner Name"
                                className={`form-control ${account_owner_nameErr ? 'is-invalid' : ''}`}
                                value={account_owner_name}
                                onChange={e => (setAccount_owner_name(e.target.value), setAccount_owner_nameErr(''))} />

                            {account_owner_nameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{account_owner_nameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                
                        </div>
                    </div>


                    <div className='col-lg-4'>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Sub Account Name"
                                className={`form-control ${subAccountNameErr ? 'is-invalid' : ''}`}
                                value={subAccountName}
                                onChange={e => (setSubAccountName(e.target.value), setSubAccountNameErr(''))} />

                            {subAccountNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{subAccountNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                
                        </div>
                    </div>

                </div>

                <div>
                    {props.loading ? '' : <span className='btn btn-primary' onClick={handleSubmit}>Submit</span>}
                    
                </div>
                {props.error ? <p className="error_msg">{props.error}</p> : ''}

            </div>

          

            </Modal>
    )
}
    



/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { authModal, isLoginModal, isDirecterAuth } = state.ui;
    let { error, loading } = state.user;
    return {
        authModal,
        isLoginModal,
        isDirecterAuth,
        loading,
        error
    };
}
  
const mapDispatchToProps = dispatch => ({
    toggleAuthModal: params => dispatch(toggleAuthModal(params)),
    resendotpEmailModal: params => dispatch(resendotpEmailModal(params)),
    fastSignupAction: params => dispatch(fastSignupAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(DirectorSignup);