import * as actionType from './actionsTypes';
import { getCartItemsNonLoginUser, getCartItems } from '../services/programService';
import { getProductsCartCountService } from '../services/onlineStoreService';

const initialState = {
    cartitems: 0,
    productCartCount: 0,
    loader:true
}

/************************************************************************************/
// Cart Reducer
/************************************************************************************/


const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADD_TO_CART:
            return {
                ...state,  
                loader:false        
            }
        case actionType.REMOVE_FROM_CART:
                return {
                    ...state,
                    loader:false     
                }
        case actionType.GET_ITEMS_IN_CART:
            return{
                ...state,
                cartitems: action.payload
            }

        case actionType.PRODUCT_CART_COUNT:
            return{
                ...state,
                productCartCount: action.payload
            }

        default:
            return state
    }
}


/************************************************************************************/
// Cart Actions
/************************************************************************************/

export const addtocart = (programId, numberofCampers) =>  dispatch => {
    let cartdata = {
        "programID": programId,
        "numberofCampers": numberofCampers
    }
    dispatch({ type: actionType.ADD_TO_CART, payload: cartdata });
}

export const getitemsincart = (cartdata) => dispatch=>{
    dispatch({ type: actionType.GET_ITEMS_IN_CART, payload: cartdata });
}

export const productCartCountAction = (data) => dispatch => {
    dispatch({ type: actionType.PRODUCT_CART_COUNT, payload: data });
}

export default cartReducer;