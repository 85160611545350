import React from 'react';

const SiteDown = ({ UIData }) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100 bg-light"
    style={{ maxHeight: "75vh" }}
    >
      <div className="text-center">
        <div className="cm_logo_large"><img src={require(`../../assets/img/logo.svg`)} alt="" /></div>
        <h1 className="display-4 mb-4" style={{ color: "#842014" }}>
          {UIData?.title ? UIData?.title : "We're Currently Down"}
        </h1>
        <p className="lead">
          {UIData?.content ?
            UIData?.content :
            "Our site is currently experiencing technical difficulties. Please bear with us while we work on getting it back up and running"
          }
        </p>
        <hr/>
        <small className="text-muted">
          Try refreshing the page or check back later. We apologize for the inconvenience.
        </small>
        <br/>
        <button className="btn btn-primary mt-4" onClick={() => window.location.reload()}>
          Refresh Page
        </button>
      </div>
    </div>
  );
};

export default SiteDown;