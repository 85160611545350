import React, {  useEffect, useState } from 'react';
import { connect } from 'react-redux';

import 'react-quill/dist/quill.snow.css';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { changeInboxTabAction } from '../../store/inbox';
import ReactSelect from 'react-select';
import { getAllPrgramWithId } from '../../services/programService';
import { getTemplatesService, createScheduleService, getcheduleListService, editScheduleService, deleteScheduleService } from '../../services/campersServices';
import { getTriggerType, resHandle, triggerDropdownList } from '../../helper';
import moment from 'moment';
import { notification } from 'antd';
import ConfirmDiloag from '../modals/ConfirmDiloag';
import PaginatedComponent from '../common/Pagination/PaginationComponent';




const Automations = props => {
    const [programsList, setProgramsList] = useState([]);
    const [templateList, settemplateList] = useState([]);
    const [messageType, setMessageType] = useState([]);
    const [messageTypeErr, setMessageTypeErr] = useState([]);
    const [triggerFrequency, setTriggerFrequency] = useState(1);
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [menuIsOpen, setMenuIsOpen] = useState();

    const [showModal, setShowModal] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);

    const [automationName, setAutomationName] = useState('');
    const [programId, setProgramId] = useState([]);
    const [templateId, setTemplateId] = useState('');
    const [trigger, setTrigger] = useState('');
    const [totalDays, setTotalDays] = useState('');
    const [dayOfWeek, setDayOfWeek] = useState('');

    const [automationNameErr, setAutomationNameErr] = useState('');
    const [programIdErr, setProgramIdErr] = useState('');
    const [templateIdErr, setTemplateIdErr] = useState('');
    const [triggerErr, setTriggerErr] = useState('');
    const [triggerFrequencyErr, setTriggerFrequencyErr] = useState('');
    const [deleteItem, setDeleteItem] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [activeAutomation, setActiveAutomation] = useState();
    const [secondaryContact, setSecondaryContact] = useState(0);

    const limit = 10;



    useEffect(() => {
        if(props.campCode){
            getAllPrgramWithId(`?campCode=${props.campCode}`).then(res => {
                if(res?.data?.statusCode){
                    setProgramsList(res.data.responseData.result)
                }
            })

            getTemplatesService(`?campCode=${props.campCode}`).then(res => {
                let { status, data } = resHandle(res);
                if(status){
                    settemplateList(data.result);
                }
            })
        }

       
    },[props.campCode])




    const closeModal = () => {
        setShowModal(false);
        setAutomationName('');
        setProgramId([]);
        setTemplateId('');
        setTrigger('');
        setTotalDays('');
        setDayOfWeek('');
        setIsEditMode(false);
        setActiveAutomation('');
        setDeleteItem({});
    }




    const getcheduleList = () => {
        let query = `?page=${page - 1}&limit=${limit}&campCode=${props.campCode}`;

        getcheduleListService(query).then(res => {
            if(res.data.statusCode){
                setList(res.data.responseData.result);
                setTotalCount(res.data.responseData.count);
            }
        })
    }


    useEffect(() => {
        getcheduleList()
    }, [page])



    const handleValidate = () => {
        let validate = true;
        
        if(automationName === ''){
            setAutomationNameErr('Name is required');
            validate = false
        }

        if(programId === ''){
            setProgramIdErr('Program is required');
            validate = false
        }

        if(templateId === ''){
            setTemplateIdErr('Template is required');
            validate = false
        }

        if(trigger === ''){
            setTriggerErr('Trigger is required');
            validate = false
        }


        return validate;
    }



    const handleSubmit = () => {
        if(handleValidate()){
            setBtnLoader(true);

            let _programId = [];
            if(programId.length){
                _programId = programId.map( i => i.value);
            }

            let params = {
                automationName,
                programId: _programId, 
                templateId: templateId?.value, 
                trigger,
                totalDays,
                messageType,
                sendToSecondaryContact:secondaryContact
            }
            if((trigger === 11 || trigger === 5)){
                (params.triggerType = triggerFrequency.value) 
            }
            if(isEditMode){
                params.messageProgramId = activeAutomation;
            }


            let _service = isEditMode ? editScheduleService : createScheduleService;
    
    
            _service(params).then(res => {
                setBtnLoader(false);
                if(res.data.statusCode){
                    closeModal();
                    getcheduleList();
                    notification.open({
                        description:
                          'Automation updated successfully',
                      });
                }
            })
        }
        
    }



    const handleTriggerChange = val => {
        setTrigger(val);
        setTriggerErr('');
        setTriggerFrequency(1);
    }




    const openEditModal = item => {
        setIsEditMode(true);
        setShowModal(true);
        setActiveAutomation(item._id)

        setProgramId(item.programs || []);
        if(item.templateName){
            setTemplateId({label: item.templateName, value: item.templateId})
        }
        setMessageType(item.messageType)
        setAutomationName(item.automationName);
        setTrigger(item.trigger);
        setSecondaryContact(item.sendToSecondaryContact)
        setTotalDays(item.totalDays);
        if(item.trigger ===11){
            setTriggerFrequency(...triggerDropdownList.filter(option => option.value === item.triggerType));     
        }else if(item.trigger ===5){
            setTriggerFrequency(...triggerDropdownList.filter(option => option.value === item.triggerType));
        }
    }



    const handleDeleteItem = () => {

        let params = {
            messageProgramId: deleteItem._id
        }
        deleteScheduleService(params).then(res => {
            let { status, data } = resHandle(res);
            if(status){
                setDeleteItem({});
                getcheduleList();
                notification.open({
                    message: 'Template',
                    description:
                      'Automation deleted successfully',
                  });
               }
        })  

    }



    const handleProgramSelect = e => {
        
        if(e === null){
            setProgramId([]);
        }else{
            let isAll = e.find(i => i.value === 'All');

            if(isAll){
                setProgramId([{label: 'Send to all Live', value: 'All'}])
            }else{
                setProgramId(e);
            }
        }
        setProgramIdErr('');
    }
    const handleTriggerFrequencySelect = e => {   
        if(e === null){
            setTriggerFrequency(null);
        }else{
            setMenuIsOpen(false)
            setTriggerFrequency(e);
        }
        setTriggerFrequencyErr('');
    }

    const customFilterOption = (option, rawInput) => option.value !== triggerFrequency?.value;


    const handleMessageTypes = (type) => {
        if(messageType.includes(type)) {
         setMessageType(prev => prev.filter(m  => m !== type))
        }else{
            const newMessageType = [type, ...messageType];
            setMessageType(newMessageType);
        } 
        setMessageTypeErr('')
    }
    const handleSecondaryContact = (contact) => {
        setSecondaryContact(prev => (prev === 1) ? 0: 1);
    }


    return(
        <div className='inner_inbox_page'>

            <ConfirmDiloag
                show={deleteItem._id}
                hideModal={() => setDeleteItem({})}
                action={handleDeleteItem}
                Message={["Are You Sure you want to delete this automation?"]} /> 

            <div className="d-flex align-items-center justify-content-between mb-3">
                <h6>Automations</h6>
                <span onClick={() => setShowModal(true)} className="btn btn-primary ml-3 btn-sm rounded"> Add Automation</span>
            </div>
            
            <div className='inbox_data_list'>
                {
                    list.map((item, i) => (
                        <div key={i} className='inbox_data_item'>
                            <div className='inbox_data_item_left'>
                                <h5>{((i + 1) + ((page - 1) * limit))}</h5>
                                <p><b>Name</b>: <span>{item.automationName}</span></p>
                                <p><b>Template</b>: <span>{item.templateName}</span></p>
                                <p><b>Program</b>: {item?.programs?.length ? item.programs.map(p => <span className='p_name'>{p.label}</span>) : 'N/A'} </p>
                            </div>

                            <div className='inbox_data_item_right'>
                                <p><b>Trigger</b>: <span>{getTriggerType(item.trigger, item.totalDays, item.triggerType)}</span></p>
                                <p><b>Created At:</b> <span>{moment(item.created).format('DD MMM, YYYY')}</span></p>
                            </div>

                            <div className='inbox_data_action'>
                                <span className='btn btn-dark btn-sm' onClick={() => openEditModal(item)}><i className='icon-pancil' /> Edit</span>
                                <span className='btn btn-danger btn-sm' onClick={() => setDeleteItem(item)}><i className='icon-delete' /> Delete</span>
                            </div>


                        </div>
                    ))
                }
            </div>

            {
                list.length ? 
                <div className="d-flex justify-content-center mt-5">
                    <PaginatedComponent
                        activePage={page}
                        itemsCountPerPage={limit}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={4}
                        onPageChange={setPage}/>
                </div>  : ''

            }
            <Modal
                size="lg"
                show={showModal}
                dialogClassName="remove_header_line"
                animation={false}
                centered>

                <Modal.Header>
                    <Modal.Title>{isEditMode ? 'Edit' : 'Create'} Automation</Modal.Title>
                    <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>
                <div className="form-group">
                                    <p className="a_label">Automation Type</p>

                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className="check_box_wrapper cm_dark_check">
                                            <input
                                                onChange={() => (handleMessageTypes(1))}
                                                type="checkbox"
                                                checked={messageType.includes(1)} />
                                            <span className="check_box"><span></span> Phone Text</span>
                                        </div>
                                        <div className="check_box_wrapper cm_dark_check">
                                        <input
                                                onChange={() => (handleMessageTypes(2))}
                                                type="checkbox"
                                                checked={messageType.includes(2)} />
                                            <span className="check_box"><span></span> App Message</span>
                                        </div>
                                        <div className="check_box_wrapper cm_dark_check">
                                        <input
                                                onChange={() => (handleMessageTypes(3))}
                                                type="checkbox"
                                                checked={messageType.includes(3)} />
                                            <span className="check_box"><span></span> Email</span>
                                        </div>
                                        
                                    </div>

                                    {messageTypeErr ? <h6 className='cm_err'>{messageTypeErr}</h6> : ''}
                                </div>
                
                    <div className="form-group">
                        <input
                            type="text"
                            value={automationName}
                            onChange={e => (setAutomationName(e.target.value), setAutomationNameErr(''))}
                            className={`form-control ${ automationNameErr ? 'is-invalid' : '' }`}
                            placeholder="Automation Name" />

                        {automationNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{automationNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                    </div>




                    <div className="form-group">
                         <ReactSelect
                            placeholder="Template"
                            value={templateId}
                            classNamePrefix="cm_select"
                            onChange={e => (setTemplateId(e), setTemplateIdErr(''))}
                            className={`form-control ${ templateIdErr ? 'is-invalid' : '' }`}
                            options={templateList.map(i => ({label: i.name, value: i._id}))} />

                        {templateIdErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{templateIdErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                    </div>


                    <div className="form-group">
                         <ReactSelect
                            placeholder="Programs"
                            value={programId}
                            closeMenuOnSelect={false}
                            isMulti
                            classNamePrefix="cm_select"
                            onChange={handleProgramSelect}
                            className={`form-control ${ programIdErr ? 'is-invalid' : '' }`}
                            options={[{label: 'Send to all Live', value: 'All'}, ...programsList.map(i => ({label: i.programName, value: i._id}))]} />

                        {programIdErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{programIdErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                    </div>
                    <ul className='triggers_list'>
                            <li className="check_box_wrapper">
                                <input
                                    type="checkbox"
                                    value={1}
                                    checked={secondaryContact === 1}
                                    onChange={handleSecondaryContact}
                                    name='trigger' />
                                <span className="check_box"><span />Send to secondary contact as well</span> 
                            </li>
                    </ul>

                    <div className="form-group">
                        <p className='a_label'>Trigger</p>

                        <ul className='triggers_list'>
                            <li className="check_box_wrapper">
                                <input
                                    type="radio"
                                    value={1}
                                    checked={trigger === 1}
                                    onChange={() => handleTriggerChange(1)}
                                    name='trigger' />
                                <span className="check_box"><span /> Send {trigger === 1 ? <input className='cn_input_num' type='number' value={totalDays} onChange={e => setTotalDays(e.target.value)} /> : <input className='cn_input_num' type='number' disabled value="" />} days after registration</span> 
                            </li>

                            <li className="check_box_wrapper">
                                <input
                                    type="radio"
                                    value={2}
                                    checked={trigger === 2}
                                    onChange={() => handleTriggerChange(2)}
                                    name='trigger' />
                                <span className="check_box"><span /> Send {trigger === 2 ? <input className='cn_input_num' type='number' value={totalDays} onChange={e => setTotalDays(e.target.value)} /> : <input className='cn_input_num' type='number' disabled value="" />} days prior to program start date </span> 
                            </li>

                            <li className="check_box_wrapper">
                                <input
                                    type="radio"
                                    value={3}
                                    checked={trigger === 3}
                                    onChange={() => handleTriggerChange(3)}
                                    name='trigger' />
                                <span className="check_box"><span /> Send when user has overdue payment (weekly)</span> 
                            </li>

                            <li className="check_box_wrapper">
                                <input
                                    type="radio"
                                    value={4}
                                    checked={trigger === 4}
                                    onChange={() => handleTriggerChange(4)}
                                    name='trigger' />
                                <span className="check_box"><span /> Send when user completes final payment</span> 
                            </li>

                            <li className="check_box_wrapper">
                                <input
                                    type="radio"
                                    value={5}
                                    checked={trigger === 5}
                                    onChange={() => handleTriggerChange(5)}
                                    name='trigger' />
                                <span className="check_box"><span /> Send </span> 
                                    {trigger === 5 ? 
                                        <span className={`select_frequecy select_frequecy_dropdown`}>
                                            <ReactSelect
                                                placeholder="frequency"
                                                value={triggerFrequency}
                                                closeMenuOnSelect={true}
                                                onChange={handleTriggerFrequencySelect}
                                                options={triggerDropdownList} 
                                                filterOption ={customFilterOption}
                                            />                              
                                        </span>:   
                                        <span className={`select_frequecy`}>frequency</span>
                                    } 
                                {triggerFrequencyErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{programIdErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </li>

                            <li className="check_box_wrapper">
                                <input
                                    type="radio"
                                    value={6}
                                    checked={trigger === 6}
                                    onChange={() => handleTriggerChange(6)}
                                    name='trigger' />
                                <span className="check_box"><span /> Send once new program goes live </span> 
                            </li>

                            <li className="check_box_wrapper">
                                <input
                                    type="radio"
                                    value={7}
                                    checked={trigger === 7}
                                    onChange={() => handleTriggerChange(7)}
                                    name='trigger' />
                                <span className="check_box"><span /> Send when program Information is Updated</span> 
                            </li>

                            <li className="check_box_wrapper">
                                <input
                                    type="radio"
                                    value={8}
                                    checked={trigger === 8}
                                    onChange={() => handleTriggerChange(8)}
                                    name='trigger' />
                                <span className="check_box"><span /> Send when registration is closed</span> 
                            </li>

                            <li className="check_box_wrapper">
                                <input
                                    type="radio"
                                    value={9}
                                    checked={trigger === 9}
                                    onChange={() => handleTriggerChange(9)}
                                    name='trigger' />
                                <span className="check_box"><span /> Send when new coupon is created</span> 
                            </li>
                        <li className="check_box_wrapper">
                                <input
                                    type="radio"
                                    value={11}
                                    checked={trigger === 11}
                                    onChange={() => handleTriggerChange(11)}
                                    name='trigger' />
                                <span className="check_box"><span /> Send</span> 
                                {trigger === 11 ? 
                                   <span className={`select_frequecy select_frequecy_dropdown`}>
                                   <ReactSelect
                                        placeholder="frequency"
                                        value={triggerFrequency}
                                        closeMenuOnSelect={true}
                                        classNamePrefix=""
                                        onChange={handleTriggerFrequencySelect}
                                        options={triggerDropdownList} 
                                        filterOption ={customFilterOption}
                                   />                                
                               </span>
                                :   
                                 <span className={`select_frequecy`}>
                                    frequency                      
                                </span>
                                } 
                                <span>to Non-Approved Participants </span> 
                                {triggerFrequencyErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{programIdErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </li>
                        </ul>

                        <div className='text-center'>
                            {triggerErr ? <h6 className='cm_err'>{triggerErr}</h6> : ''}
                        </div>
                    </div>




                    <div className="text-center">
                        <button onClick={handleSubmit} style={{minWidth: '130px'}} disabled={btnLoader} className={`btn btn-primary pl-5 pr-5 ${btnLoader ? 'btnLoader' : ''}`}>Save</button>
                    </div>

                </Modal.Body>
            </Modal>




        </div>
    )
}


const mapStateToProps = state => {
    let { inboxTab } = state.inbox;
    let campCode = state.user.userProfile.campCode;
    return {
        inboxTab,
        campCode
    };
}
  
const mapDispatchToProps = dispatch => ({
    changeInboxTabAction: params => dispatch(changeInboxTabAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Automations);
