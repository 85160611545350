import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Empty } from 'antd';
import Slider from 'react-slick';
import Select from 'react-select';

import { toggleMapViewModal } from '../../store/ui';
import { filterProgramsAction, programLoaderActiveAction } from '../../store/program';
import { fFilterTypeAction, fPageNoAction, isDistrictCampAction } from '../../store/pFilter';
import CampCard from './CampCard';
import MainFilter from '../home/MainFilter';
import Filters from './Filters';
import { ProgramLoader } from '../reuse/SVG';
import { resHandle } from '../../helper';
import { getDistrictsService } from '../../services/programService';
import setting from '../../config/settings';
import ChessForSuccess from '../../assets/img/district/chess-for-success.jpg';
import ChessForSuccessColor from '../../assets/img/district/chess-for-success-color.png';
import PaginatedComponent from '../common/Pagination/PaginationComponent';


const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }
    ]
};


const DistrictCamps = props => {
    const [page, setPage] = useState(1);
    const [activeDistrict, setActiveDistrict] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [districtList, setDistrictList] = useState([]);
    const [schoolName, setSchoolName] = useState('');
    const [schoolNamesList, setSchoolNamesList] = useState([]);


    const getDistricts = () => {
        let query = `?isProgramExist=1`;
        getDistrictsService(query).then(res => {
            let { status, data } = resHandle(res);
            if(status){
                setDistrictList(data.result || []);
            }
        })
    }


    useEffect(() => {
        getDistricts()
    }, [])



    useEffect(() => {
        props.programLoaderActiveAction()
    }, [])

    useEffect(() => {
        let { 
            keywordSearch,
            gender,
            ageGroup,
            grade,
            startDate,
            endDate,
            programType,
            city,
            radius,
            priceRange,
            ezProRating,
            categories,
            flat,
            flong,            
            lat,
            long,
            pageNo,
            limit,
            weekDays,
            campers,
            subCategories,
            filterType,
            maxDistance,
            isOnline,
        } = props;
    
        let params = { 
            userId: props.userProfile ? props.userProfile._id : '',
            keywordSearch,
            gender,
            ageGroup,
            grade,
            startDate,
            endDate,
            programType,
            city,
            radius,
            priceRange,
            ezProRating,
            flat,
            flong,            
            lat,
            long,
            pageNo,
            limit,
            filterType,
            maxDistance,
            weekDays: weekDays.length ? JSON.stringify(weekDays) : '',
            categories: categories.length ? JSON.stringify(categories) : '',
            subCategories: subCategories.length ? JSON.stringify(subCategories) : '',
            campers: campers.length ? JSON.stringify(campers) : '',
            isOnline,
            campId: setting.api.districtCamp,
            districtId,
            schoolName: schoolName.value
        }

        props.filterProgramsAction(params);
    }, [props.pageNo, props.subCategories, props.filterType, props.maxDistance, districtId, schoolName])


    const handlePageChange = page => {
        props.fPageNoAction(page);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }


    useEffect(() => {
        props.isDistrictCampAction();
        return () => props.isDistrictCampAction()
    }, [])





    return(
        <div className="body_container district_camps">
            <div className="container">
                <div className="row">

                    <div className="col-lg-4">
                        <div className='district_logo'>
                            <img src={ChessForSuccess} />
                        </div>
                        <MainFilter hideCategory={true} isDistrictFilter={true} />
                        {/* <Filters /> */}
                    </div>

                    <div className="col-lg-8">
                        <div className='district_logo_color'>
                            <img src={ChessForSuccessColor} />
                            <div>
                            <button disabled={props.programs.length ? false : true} title={props.programs.length ? '' : 'No program available'} onClick={props.toggleMapViewModal} className="btn btn-secondary pl-5 pr-5"><i className="icon-map" /> Map View</button>
                            </div>
                        </div>
                        <p>Chess for Success offers opportunities for youth to grow in school and in life.  Using chess as a tool for building community, connection, and confidence.  Chess for Success partners with many high-poverty schools and provides curriculum and supplies so that students can receive two hours per week of chess instruction and practice.   Chess for Success also hosts their own regional tournaments.  We are excited to have you join, and fall in love with the beautiful game!</p>
                        


                        <h4 className='mb-4'>Select Appropriate District:</h4>
                        
                        {districtList.length > 4 ? districtList.length ? <Slider {...settings}>
                            {districtList.map((item, i) => <div className={`district_slide ${districtId == item._id ? 'active' : ''}`} key={item._id} onClick={() => (setDistrictId(item._id), setSchoolName(''), setSchoolNamesList(item.schoolNames || []))}>
                                <div className='district_slide_img'><img src={item.image} /></div>
                                <h5>{item.name}</h5></div>)}
                        </Slider> : '' : districtList.length ? <div className='district_slide_wraaper'>
                            {districtList.map((item, i) => <div className={`district_slide ${districtId == item._id ? 'active' : ''}`} key={item._id} onClick={() => (setDistrictId(item._id), setSchoolName(''), setSchoolNamesList(item.schoolNames || []))}>
                                <div className='district_slide_img'><img src={item.image} /></div>
                                <h5>{item.name}</h5></div>)}
                        </div> : ''}


                        {
                            schoolNamesList.length ? <div className='school_fliter'>
                            <Select
                                placeholder="Filter by School Name"
                                onChange={e => setSchoolName(e)}
                                value={schoolName}
                                classNamePrefix="cm_select"
                                className={`form-control`}
                                options={schoolNamesList.map(i => ({label: i, value: i}))} />
                            </div> : ''
                        }
                        
                        

                 


                        <div className="camps_list" id="camps_lists">
                            {
                                props.loader ? 
                                    <div className="program_loader"><ProgramLoader /></div> 
                                    :
                                    props.programs.length ? props.programs.map(program => <CampCard key={program._id} program={program} />) : <div className="cm_empty"><Empty description="Please broaden your search by entering fewer fields in the search box." /> </div>
                            }
                        </div>

                        {
                            props.programs.length ? 
                            <div className="d-flex justify-content-center mt-5">
                                <PaginatedComponent
                                    activePage={props.pageNo}
                                    itemsCountPerPage={props.limit}
                                    totalItemsCount={props.totalCount}
                                    pageRangeDisplayed={4}
                                    onPageChange={handlePageChange}/>
                            </div>  : ''
                        }

                        
                        



                    </div>
                </div>
            </div>




        </div>
    )
}

/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let {programs, loader, totalCount} = state.program;
    let {userProfile} = state.user;

    let { 
        keywordSearch,
        gender,
        ageGroup,
        grade,
        startDate,
        endDate,
        programType,
        weekDay,
        city,
        radius,
        priceRange,
        ezProRating,
        categories,
        flat,
        flong,
        lat,
        long,        
        pageNo,
        limit,
        weekDays,
        subCategories,
        advanceSearch,
        filterType,
        campers,
        maxDistance,
        isOnline
    } = state.pFilter;

    return {
        loader,
        programs,
        keywordSearch,
        gender,
        ageGroup,
        grade,
        startDate,
        endDate,
        programType,
        weekDay,
        city,
        radius,
        priceRange,
        ezProRating,
        categories,
        flat,
        flong,        
        lat,
        long,
        pageNo,
        limit,
        weekDays,
        subCategories,
        advanceSearch,
        filterType,
        campers,
        totalCount,
        maxDistance,
        userProfile,
        isOnline
    };
}
  
const mapDispatchToProps = dispatch => ({
    toggleMapViewModal: () => dispatch(toggleMapViewModal()),
    programLoaderActiveAction: () => dispatch(programLoaderActiveAction()),
    isDistrictCampAction: () => dispatch(isDistrictCampAction()),
    filterProgramsAction: params => dispatch(filterProgramsAction(params)),
    fPageNoAction: params => dispatch(fPageNoAction(params)),
    fFilterTypeAction: params => dispatch(fFilterTypeAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(DistrictCamps);
