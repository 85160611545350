import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import Login from '../auth/Login';
import Signup from '../auth/Signup';
import { toggleAuthModal } from '../../store/ui';


const AuthModal = props => (
    <Modal
        dialogClassName="auth_modal"
        show={props.authModal}
        animation={false}
        size="lg"
        centered
    >
        <Modal.Body className="cm_blck_bg">
            <span className="modal_close" onClick={() => props.toggleAuthModal(0)}><i className="icon-cross" /></span>
            {props.isLoginModal ? <Login /> : <Signup />}
        </Modal.Body>
    </Modal>
)


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { authModal, isLoginModal } = state.ui;
    return {
        authModal,
        isLoginModal
    };
}
  
const mapDispatchToProps = dispatch => ({
    toggleAuthModal: params => dispatch(toggleAuthModal(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(AuthModal);