import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import {Empty, notification} from 'antd';

import FeedCard from './FeedCard';
import { toggleFeedModalAction, newFeedAction } from '../../store/feed';
import { viewLoginModalAction } from '../../store/ui';
import { checkIsCounselorAction } from '../../store/user';
import { getPostsService, getFriendsAndCamps, sendRequestService, deletePostService } from '../../services/feedService';
import { followcamps } from '../../services/programService';
import {counselordetail} from '../../services/directorServices';
import { ProgramLoader, RoundLoader } from '../reuse/SVG';
import { updateFields } from '../../helper';


const Feeds = props => {
    const [page, setPage] = useState(0);
    const [feeds, setFeeds] = useState([]);
    const [totalCount, setTotalCount] = useState(1);
    const [loader, setLoader] = useState(true);
    const [friends, setFriends] = useState([]);
    const [camps, setCamps] = useState([]);
    const [followingCamp, setFollowingCamp] = useState([]);
    const [searchFriend, setSearchFriend] = useState('');
    const [searchCamp, setSearchCamp] = useState('');
    const [frendListModal, setFrendListModal] = useState(false);
    const [campsListModal, setCampsListModal] = useState(false);
    const [limit, setLimit] = useState(100);
    const [multipleLoader, setMultipleLoader] = useState('');
    const [unFollowCampsListModal, setUnFollowCampsListModal] = useState(false);
    const [permissions, setPermissions] = useState({});
    const [searchFollowCamps, setSearchFollowCamps] = useState('');



    const closeModal = () => {
        setFrendListModal(false);
        setCampsListModal(false);
        setUnFollowCampsListModal(false);
    };


    const getPostList = () => {
        let params = '';
        if(props.userProfile){
            params = `?userId=${props?.userProfile?._id || ''}&page=${props.newFeed ? 1 : page + 1}&limit=${8}`;
        }else{
            params = `?page=${page + 1}&limit=${8}`;
        }
            setLoader(true);
            getPostsService(params).then(res => {
                if(res?.data?.statusCode){
                    if(props.newFeed){
                        setFeeds(res.data.responseData.result);
                    }else{
                        setFeeds([...feeds, ...res.data.responseData.result]);
                    }
                    setTotalCount(res.data.responseData.totalCount);
                    setLoader(false);
                    props.newFeedAction(false);
                }else {
                    setLoader(false);
                }
            })
    
    }

    useEffect(() => {
        getPostList();
    }, [page])


    useEffect(() => {
        if(props.newFeed){
            getPostList();
        }
    }, [props.newFeed])





    const getCampsData = () => {
        let params = `?userId=${props.userProfile ? props.userProfile._id : ''}&searchCamp=${searchCamp}&limit=${limit}&page=${page}`;
        getFriendsAndCamps(params).then(res => {
            if(res?.data?.statusCode){
                setCamps(res.data.responseData.suggestedCamp);
            }
        })
    }


    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            getCampsData();
        }, 2000)
    
        return () => clearTimeout(_setTimeout)
    }, [searchCamp])


    const getFriendsData = () => {
        let params = `?userId=${props.userProfile ? props.userProfile._id : ''}&searchFriend=${searchFriend}&limit=${limit}&page=${page}`;
        setFriends([]);
        getFriendsAndCamps(params).then(res => {
            if(res?.data?.statusCode){
                setFriends(res.data.responseData.usersInfo.result || []);
            }
        })
    }




    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            getFriendsData();
        }, 2000)
    
        return () => clearTimeout(_setTimeout)
    }, [searchFriend])



    const getAllData = () => {
        let params = `?userId=${props.userProfile ? props.userProfile._id : ''}&searchCamp=${searchFollowCamps}&limit=${limit}&page=${page}`;
        getFriendsAndCamps(params).then(res => {
            if(res?.data?.statusCode){
                setFollowingCamp(res.data.responseData.followingCamp);
            }
        })
    }


    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            getAllData();
        }, 2000)
    
        return () => clearTimeout(_setTimeout)
    }, [searchFollowCamps])




    const sendfriendReq = friendId => {
        if(props.loggedIn){
            setMultipleLoader({[friendId] : true});
            let params = { friendId };
    
            sendRequestService(params).then(res => {
                getFriendsData();
                getAllData();
                notification.open({
                    duration: 2,
                    message: 'Friend Request',
                    description:
                      'Request Sent Successfully',
                });
                setMultipleLoader({[friendId] : false});
            })
        }else{
            props.viewLoginModalAction();
        }
        

    }



    const handleFollow = (camp, act) => {
        if(props.loggedIn){
            setMultipleLoader({[camp._id] : true});
            let params = {
                campId: camp._id,
                follow: act
            }
    
            followcamps(params).then(res => {
                getAllData();
                getCampsData();
                notification.open({
                    duration: 2,
                    message: 'Follow Camp',
                    description: `${act ? 'Follow' : 'Unfollow'} Camp Successfully`,
                });
    
                setMultipleLoader({[camp._id] : false});
            })
        }else{
            props.viewLoginModalAction();
        }
        
    }


    const handleFeedDelete = postId => {
        let params = {
            postId,
            status: '3'
        }

        deletePostService(params).then(res => {
            if(res?.data?.statusCode){
                setFeeds([]);
                let params2 = `?page=${page + 1}&limit=${8}`;
                setLoader(true);
                    getPostsService(params2).then(res => {
                        setLoader(false);
                        if(res?.data?.statusCode){
                            setFeeds(res.data.responseData.result);
                            setTotalCount(res.data.responseData.totalCount);
                            
                        }else {
                            setLoader(false);
                        }
                    })
            }
        })
    }




    useEffect(() => {
        if(props.userProfile){
            counselordetail(`/?counselorId=${props.userProfile._id}`).then(res => {
                if(res?.data?.statusCode){
                    props.checkIsCounselorAction(res.data.responseData.result);
                    setPermissions(res.data.responseData.result.permissions || {})
                    let temp_user = JSON.parse(localStorage.getItem('user'));
                    const updatedUserDetails = updateFields(temp_user, res.data.responseData.result, ["timezone"]);
                    localStorage.setItem('user', JSON.stringify(updatedUserDetails)); 
                }
            })
        }
    }, [])






    return(
        <div className="body_container cm_over_auto" style={{backgroundColor: '#F7F7F7'}}>
            <div className="container pt-5">
                <div className="sticky_wrapper">

                    <div className="col_lg_8">
                      
                        {
                            (props?.userProfile?.UserType === 4 && permissions.feedsByStaff !== 1) ? '' : props.loggedIn ? (
                                <div className="create_post_card" onClick={props.toggleFeedModalAction}>
                                    <h2><i className="icon-create" /> Create Post</h2>
                                    <div className="own_avtor">
                                        <img src={props.userProfile ? props.userProfile.profileImage ? props.userProfile.profileImage : require('../../assets/img/user.png') : require('../../assets/img/user.png')} alt=''/>
                                        <h3>What’s on your mind?</h3>
                                    </div>
                                    <span className="btn btn-outline-primary"><i className="icon-gallery" /> Photos/Videos</span>
                                </div>
                            ) : ''
                        }
                        
                        {feeds.map((feed, i) => <FeedCard
                                                    handleFeedDelete={handleFeedDelete}
                                                    feed={feed}
                                                    key={feed._id} />)}
                        
                        {totalCount === feeds.length ? 
                        <div className="no_more_data">No more feeds</div>
                        :
                        loader ? <div className="program_loader"><ProgramLoader /></div> : <div className="text-center mb-4"><button className="btn btn-primary btn-lg" onClick={() => setPage(page + 1)}>View More</button></div>
                        }

                    </div>




                    <div className="col_lg_4 cm_stiky">
                        <div className="card mb-5">
                            {
                                props.userProfile ? <div className="own_avtor">
                                <img src={props.userProfile ? props.userProfile.profileImage ? props.userProfile.profileImage : require('../../assets/img/user.png') : require('../../assets/img/user.png')} />
                                <h3>{props.userProfile.name}</h3>
                            </div> : ''
                            }
                            



                            <div className="cm_list_wrapper">
                                <div className="d-flex justify-content-between">
                                    <h4>Find Friends</h4>
                                    <span className="cm_link" onClick={() => setFrendListModal(true)}>See all</span>
                                </div>

                                <div className="search_wrap">
                                    <i className="icon-search" />
                                    <input
                                        type="text"
                                        placeholder="Search by friends name"
                                        className="form-control"
                                        value={searchFriend}
                                        onChange={e => setSearchFriend(e.target.value)} />

                                </div>


                                <div className="peoples users_list">
                                    {
                                        friends?.length ? (
                                            <ul className="cm_icon_space">
                                                {
                                                    friends.slice(0, 3).map(item => (
                                                    <li key={item._id}>
                                                        <Link to={`/user/${item._id}`} className="d-flex align-content-center">
                                                            <img src={item.profileImage ? item.profileImage : require('../../assets/img/user.png')} />
                                                            <h5 className="mt-2">{item.name}</h5>
                                                        </Link>  
                                                        <button className="btn btn-primary btn-sm" onClick={() => sendfriendReq(item._id)} disabled={multipleLoader[item._id]}>Add Friend</button>
                                                        {multipleLoader[item._id] ? <span className="min_loader"><RoundLoader /></span> : ''}
                                                    </li>
                                                    ))
                                                }
                                            </ul>
                                        ) : (
                                            <div className="cm_empty"><Empty description="No Data Found" /></div>
                                        ) 
                                    }
                                </div>
                                
                            </div>




                            <div className="cm_list_wrapper">
                                <div className="d-flex justify-content-between">
                                    <h4>Suggestion for you</h4>
                                    <span className="cm_link" onClick={() => setCampsListModal(true)}>See all</span>
                                </div>

                                <div className="search_wrap">
                                    <i className="icon-search" />
                                    <input
                                        type="text"
                                        placeholder="Search by name"
                                        className="form-control"
                                        value={searchCamp}
                                        onChange={e => setSearchCamp(e.target.value)}/>
                                </div>


                                <div className="peoples">
                                    

                                    {
                                        camps?.length ? (
                                            <ul className="cm_icon_space">
                                                {
                                                    camps.slice(0, 3).map(item => (
                                                        <li key={item._id}>
                                                            <h5>{item.campName}</h5>
                                                            <p>{item.followersCount} Followers</p>
                                                            
                                                            {multipleLoader[item._id] ? <span className="min_loader text-right"><RoundLoader /></span> : <i className="icon-button share_btn" onClick={() => handleFollow(item, true)} />}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        ) : (
                                            <div className="cm_empty"><Empty description="No Data Found" /></div>
                                        ) 
                                    }
                                </div>
                                
                            </div>



                            <div className="cm_list_wrapper">
                                <div className="d-flex justify-content-between mb-4">
                                    <h4>Following</h4>
                                    <span className="cm_link" onClick={() => setUnFollowCampsListModal(true)}>See all</span>
                                </div>


                                <div className="peoples">
                                    <ul className="cm_btn_space cm_mm_loader">
                                       {
                                           followingCamp.length ? followingCamp.slice(0, 3).map(item => (
                                            <li key={item._id}>
                                                <h5>{item.campName}</h5>
                                                <p>{item.followersCount} Followers</p>
                                                <button className="btn btn-primary btn-sm" onClick={() => handleFollow(item, false)} disabled={multipleLoader[item._id]}>Unfollow</button>
                                                {multipleLoader[item._id] ? <span className="min_loader"><RoundLoader /></span> : ''}

                                                
                                            </li>
                                           )) : <div className="cm_empty"><Empty description="No Data Found" /></div>
                                       }


                                    </ul>
                                </div>
                                
                            </div>



                        </div>
                    </div>
                </div>
            </div>





            <Modal
                dialogClassName="theme_modal"
                show={frendListModal}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="modal_header">
                        <h3>Find Friends</h3>
                        <span className="modal_close" onClick={closeModal}><i className="icon-cross"/></span>
                    </div>

                    <div className="search_wrap">
                        <i className="icon-search" />
                        {/* <input
                            type="text"
                            placeholder="Search by friends name"
                            className="form-control"
                            value={searchCamp}
                            onChange={e => setSearchCamp(e.target.value)} /> */}

                        <input
                            type="text"
                            placeholder="Search by friends name"
                            className="form-control"
                            value={searchFriend}
                            onChange={e => setSearchFriend(e.target.value)} />
                    </div>


                    <div className="peoples users_list">
                        <ul className="cm_icon_space">
                            {
                                friends.length ? friends.map(item => (
                                <li key={item._id}>
                                    <img src={item.profileImage ? item.profileImage : require('../../assets/img/user.png')} />
                                    <h5>{item.name}</h5>  
                                    <button className="btn btn-primary btn-sm" onClick={() => sendfriendReq(item._id)} disabled={multipleLoader[item._id]}>Add Friend</button>
                                    {multipleLoader[item._id] ? <span className="min_loader"><RoundLoader /></span> : ''}
                                </li>
                                )) : <div className="cm_empty"><Empty description="No Data Found" /></div>
                            }
                        </ul>
                    </div>


                </Modal.Body>
            </Modal>





            <Modal
                dialogClassName="theme_modal"
                show={campsListModal}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="modal_header">
                        <h3>Camps Suggestion</h3>
                        <span className="modal_close" onClick={closeModal}><i className="icon-cross"/></span>
                    </div>

                    <div className="search_wrap">
                        <i className="icon-search" />
                        <input
                            type="text"
                            placeholder="Search by friends name"
                            className="form-control"
                            value={searchCamp}
                            onChange={e => setSearchCamp(e.target.value)} />
                    </div>


                    <div className="peoples">
                        <ul className="cm_icon_space">
                            {
                                camps.length ? camps.map(item => (
                                <li key={item._id}>
                                    <h5>{item.campName}</h5>
                                    <p>{item.followersCount} Followers</p>
                                    {multipleLoader[item._id] ? <span className="min_loader text-right"><RoundLoader /></span> : <i className="icon-button share_btn" onClick={() => handleFollow(item, true)} />}
                                </li>
                                )) : <div className="cm_empty"><Empty description="No Data Found" /></div>
                            }
                        </ul>
                    </div>


                </Modal.Body>
            </Modal>






            <Modal
                dialogClassName="theme_modal"
                show={unFollowCampsListModal}
                animation={false}
                centered
            >
                <Modal.Body>
                    <div className="modal_header">
                        <h3>Following Camps</h3>
                        <span className="modal_close" onClick={closeModal}><i className="icon-cross"/></span>
                    </div>

                    <div className="search_wrap">
                        <i className="icon-search" />
                        <input
                            type="text"
                            placeholder="Search by friends name"
                            className="form-control"
                            value={searchFollowCamps}
                            onChange={e => setSearchFollowCamps(e.target.value)} />
                    </div>


                    <div className="peoples">
                        <ul className="cm_icon_space">
                            {
                                followingCamp.length ? followingCamp.map(item => (
                                <li key={item._id}>
                                    <h5>{item.campName}</h5>
                                    <p>{item.followersCount} Followers</p>
                                    <button className="btn btn-primary btn-sm" onClick={() => handleFollow(item, false)} disabled={multipleLoader[item._id]}>Unfollow</button>
                                    {multipleLoader[item._id] ? <span className="min_loader"><RoundLoader /></span> : ''}

                                    
                                </li>
                                )) : <div className="cm_empty"><Empty description="No Data Found" /></div>
                            }
                        </ul>
                    </div>


                </Modal.Body>
            </Modal>



        </div>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { feedModal, newFeed } = state.feed;
    let { loggedIn, userProfile } = state.user;
    return {
        feedModal,
        newFeed,
        loggedIn,
        userProfile
    };
}
  
const mapDispatchToProps = dispatch => ({
    toggleFeedModalAction: () => dispatch(toggleFeedModalAction()),
    newFeedAction: params => dispatch(newFeedAction(params)),
    checkIsCounselorAction: params => dispatch(checkIsCounselorAction(params)),
    viewLoginModalAction: () => dispatch(viewLoginModalAction()),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Feeds);