import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { newsDetailService } from '../../services/otherService';

const Footer = props => {
    const [list, setList] = useState([]);

    const getNewsDetail = () => {
        newsDetailService('').then(res => {
            if(res?.data?.statusCode){
                setList(res.data.responseData.news);
            }
        })
    }

    useEffect(() => {
        getNewsDetail();
    }, [])

    return(
        <footer>
            <div className="container pt-5 pb-5">
                <div className="row">
                    <div className="col-lg-4 pt-3 pb-3 pr-5">
                        <div className="text-center mb-4">
                            <img src={require('../../assets/img/logo-mini.svg')} />
                        </div>
                        
                        <p>Reliable, secure, friendly service, every time.
                            Allowing Families to register easily.</p>
                        <p>
                            <a href="#" className="white"><i className="icon-phone" /> +1 (971)-245-3061</a>
                            {props.isTeamsEZ ? <a href="mailto: support@teamsez.com" className="white"><i className="icon-email " /> support@teamsez.com</a> : <a href="mailto: support@campsez.com" className="white"><i className="icon-email " /> support@campsez.com</a>}
                            
                        </p>
                    </div>
                    <div className="col-lg-8 pt-3 pb-3">
                        <div className="row">
                            <div className="col-md-4 pt-3 pb-3">
                                <h3>About</h3>
                                <ul>
                                    <li><Link to='/about-us'>About Us</Link></li>
                                    <li><Link to='/career'>Careers With Us</Link></li>
                                    <li><Link to='/terms-and-conditions'>Terms & Conditions</Link></li>
                                    <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                                    <li><Link to='/faq'>Support & FAQs</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-4 pt-3 pb-3">
                                <h3>Partner Services</h3>
                                <ul>
                                    <li><Link to='/list-camp'>List Your Program</Link></li>
                                    <li><Link to='/advertising'>Advertising</Link></li>
                                    <li><Link to='/partner'>Become A Partner</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-4 pt-3 pb-3">
                                <h3><Link style={{color: '#fff'}} to="/news-and-events">Recent News & Events</Link></h3>
                                <ul>
                                    {list.map(item => <li key={item._id}><a href={`/news-and-events/${item._id}`}>- {item.title}</a></li>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer_middle">
                <div className="container">
                    <div className="row d-flex align-items-center">

                        <div className="col-lg-6 pt-2 pb-2">
                            <img src={require('../../assets/img/logo.svg')} />
                            <p className="mt-3">Download the app and book on the go</p>
                            <div className="apps_links">
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.vic.campsez"><img src={require('../../assets/img/google-play.png')} /></a>
                                <a target="_blank" href="https://apps.apple.com/us/app/campsez/id1521620775"><img src={require('../../assets/img/app-store.png')} /></a>
                            </div>
                        </div>

                        <div className="col-lg-6 pt-2 pb-2 cm_left_border">
                            {/* <h4 className="color_red mb-3"><i className="icon-rewards" /> <span className="font_raleway">CampsEZ</span> Rewards</h4> */}
                            <h4 className="color_red mb-3"><i className="icon-rewards" /> Rewards</h4>
                            <p>Join now for our latest offers and special deals!</p>
                        </div>

                    </div>
                </div>
            </div>

            <div className="container d-flex justify-content-between copyright_wrapper">
                <div className="pt-2 pb-2"><span className="font_raleway">{props.isTeamsEZ ? 'TeamsEZ' : 'CampsEZ'}</span> © {new Date().getFullYear()} All Rights Reserved.</div>
                <div className="d-flex pt-2 pb-2 social_links">
                <a target="_blank" href="https://www.facebook.com/CampsEZ"><i className="icon-facebook" /></a>
                                <a target="_blank" href="https://www.instagram.com/camps_ez/"><i className="icon-instagram" /></a>
                                <a target="_blank" href="https://www.youtube.com/channel/UCpe_I1IlZ5zyOSp7jnH7_3A"><i className="icon-youtube" /></a>
                                <a target="_blank" href="https://twitter.com/Camps_EZ" ><i className="icon-twiter" /></a>
                                <a target="_blank" href="https://www.linkedin.com/company/campsez/about/?viewAsMember=truehttps://www.linkedin.com/company/campsez/about/?viewAsMember=true"><i className="icon-linkedin" /></a>
                </div>
            </div>


            <Helmet>
                    <script>
                        {`
                            var AddShoppersWidgetOptions = { 'loadCss': false, 'pushResponse': false };
                            (!function(){
                                var t=document.createElement("script");
                                t.type="text/javascript",
                                t.async=!0,
                                t.id="AddShoppers",
                                t.src="https://shop.pe/widget/widget_async.js#5fc91b300ff4c31655eae406",
                                document.getElementsByTagName("head")[0].appendChild(t)
                            }());
                        `}
                        </script>
            </Helmet>
        </footer>
    )
}


/*************************************************************
                        Store Connect
*************************************************************/

const mapStateToProps = state => {
    let { isTeamsEZ } = state.ui;
    return {
        isTeamsEZ
    };
}
  
  
export default connect(mapStateToProps)(Footer);