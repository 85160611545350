function calculateGrade(genderData, gradeData) {
    let data = [];
    for (let i = 0; i < genderData.length; i++) {
        if (gradeData.includes(genderData[i].currentGrade)) {
            data.push(genderData[i])
        }
    }
    return data
}

function calculateAge(age, gradeData) {
    let data = [];
    for (let i = 0; i < gradeData.length; i++) {
        if (age.includes(gradeData[i].age)) {
            data.push(gradeData[i])
        }
    }
    return data
}

function calculateNonAge(age, gradeData) {
    let data = [];
    for (let i = 0; i < gradeData.length; i++) {
        if (!age.includes(gradeData[i].age)) {
            data.push(gradeData[i])
        }
    }
    return data
}

function calculateNonGrade(genderData, gradeData) {
    var merged = [].concat.apply([], gradeData);
    let data = [];
    for (let i = 0; i < genderData.length; i++) {
        if (!merged.includes(genderData[i].currentGrade)) {
            data.push(genderData[i])
        }
    }
    return data
}

function calculateDataAccorfingToAge(age, gradeData) {
    let ageObj = {};
    for (const [key, value] of Object.entries(gradeData)) {
        ageObj[key] = calculateAge(age, value);

    }
    return ageObj
}

function calculateDataNonAccorfingToAge(age, gradeData) {
    let ageObj = {};
    for (const [key, value] of Object.entries(gradeData)) {
        ageObj[key] = calculateNonAge(age, value);
    }
    return ageObj

}

function getDataAccordingToGrade(genderData, gradeData, age) {
    if (!age) {
        let gradeObj = {};
        for (let i = 0; i < gradeData.length; i++) {
            gradeObj[gradeData[i].join("")] = calculateGrade(genderData, gradeData[i]);
        }
        gradeObj["otherGrade"] = calculateNonGrade(genderData, gradeData);
        return gradeObj
    }
    else {
        let gradeObj = {};
        for (let i = 0; i < gradeData.length; i++) {
            gradeObj[gradeData[i].join("")] = calculateGrade(genderData, gradeData[i]);
        }
        gradeObj["otherGrade"] = calculateNonGrade(genderData, gradeData);
        let result = calculateDataAccorfingToAge(age, gradeObj)
        return result
    }
}

function getDataNonAccordingToGrade(genderData, gradeData, age) {
    let gradeObj = {};
    for (let i = 0; i < gradeData.length; i++) {
        gradeObj[gradeData[i].join("")] = calculateGrade(genderData, gradeData[i]);
    }
    gradeObj["otherGrade"] = calculateNonGrade(genderData, gradeData);
    var merged = [].concat.apply([], age);
    let result = calculateDataNonAccorfingToAge(merged, gradeObj)
    return result
}

function getDataAccordingToAge(genderData, ageData) {
    let ageObj = {};
    for (let i = 0; i < ageData.length; i++) {
        ageObj[ageData[i].join()] = calculateAge(ageData[i], genderData);
    }
    var merged = [].concat.apply([], ageData);
    ageObj["otherAge"] = calculateNonAge(merged, genderData);
    return ageObj
}

function cabinLogic(studentArray, inputs) {
    let maleObj = {};
    let femaleObj = {};
    let transObj = {}
    let maleData = [];
    let femaleData = [];
    let transData = [];
    if (inputs.gender && inputs.grade && inputs.age) {
        maleData = studentArray.filter(item => item.gender === 1)
        femaleData = studentArray.filter(item => item.gender === 2)
        transData = studentArray.filter(item => item.gender === 3)

        for (let i = 0; i < inputs.age.length; i++) {
            maleObj[inputs.age[i].join()] = getDataAccordingToGrade(maleData, inputs.grade, inputs.age[i]);
            femaleObj[inputs.age[i].join()] = getDataAccordingToGrade(femaleData, inputs.grade, inputs.age[i]);
            transObj[inputs.age[i].join()] = getDataAccordingToGrade(transData, inputs.grade, inputs.age[i]);
        }
        maleObj["otherAgeMale"] = getDataNonAccordingToGrade(maleData, inputs.grade, inputs.age,);
        femaleObj["otherAgeFemale"] = getDataNonAccordingToGrade(femaleData, inputs.grade, inputs.age);
        transObj["otherAgeTrans"] = getDataNonAccordingToGrade(transData, inputs.grade, inputs.age);
        let obj = {
            maleObj: maleObj,
            femaleObj: femaleObj,
            transObj: transObj,
        }
        return obj
    }
    else if (inputs.gender && inputs.grade && !inputs.age) {
        maleData = studentArray.filter(item => item.gender === 1)
        femaleData = studentArray.filter(item => item.gender === 2)
        transData = studentArray.filter(item => item.gender === 3)
        maleObj = getDataAccordingToGrade(maleData, inputs.grade);
        femaleObj = getDataAccordingToGrade(femaleData, inputs.grade);
        transObj = getDataAccordingToGrade(transData, inputs.grade);
        let obj = {
            maleObj: maleObj,
            femaleObj: femaleObj,
            transObj: transObj,
        }
        return obj
    }
    else if (inputs.gender && inputs.age && !inputs.grade) {
        maleData = studentArray.filter(item => item.gender === 1)
        femaleData = studentArray.filter(item => item.gender === 2)
        transData = studentArray.filter(item => item.gender === 3)
        maleObj = getDataAccordingToAge(maleData, inputs.age);
        femaleObj = getDataAccordingToAge(femaleData, inputs.age);
        transObj = getDataAccordingToAge(transData, inputs.age);
        let obj = {
            maleObj: maleObj,
            femaleObj: femaleObj,
            transObj: transObj,
        }
        return obj
    }
    else if (inputs.age && inputs.grade && !inputs.gender) {
        for (let i = 0; i < inputs.age.length; i++) {
            maleObj[inputs.age[i].join()] = getDataAccordingToGrade(studentArray, inputs.grade, inputs.age[i]);
        }
        maleObj["otherAge"] = getDataNonAccordingToGrade(studentArray, inputs.grade, inputs.age);
        return maleObj
    }
    else if (inputs.gender && !inputs.age && !inputs.grade) {

        maleData = studentArray.filter(item => item.gender === 1)
        femaleData = studentArray.filter(item => item.gender === 2)
        transData = studentArray.filter(item => item.gender === 3)
        let obj = {
            maleObj: maleData,
            femaleObj: femaleData,
            transObj: transData,
        }
        return obj
    }
    else if (inputs.grade && !inputs.age && !inputs.gender) {
        let gradeData = getDataAccordingToGrade(studentArray, inputs.grade);
        return gradeData
    }
    else if (inputs.age && !inputs.gender && !inputs.grade) {
        let ageData = getDataAccordingToAge(studentArray, inputs.age)
        return ageData
    }else {
        return studentArray;
    }
}


export default cabinLogic;