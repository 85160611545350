import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';

import { campDates, getgendersdisplay, getProgramType, getprogramdatesinCart } from '../../helper';
import { programFavourite } from '../../services/programService';
import { LogoWhite, StoreIcon } from '../reuse/SVG';
import Share from '../static/Share';


const getProgramPriceByDates = (allPrices, selectedDateByProgram) => {
    const _res = allPrices.filter(el => selectedDateByProgram.find((id) => el._id == id));
    let _totalPrice = 0;
    if(_res.length){
        _res.map(i => _totalPrice += i.standardPrice)
    }

    if(_totalPrice > 0){
        return `Price: $${_totalPrice.toFixed(2)}`
    }else{
        return 'Free'
    }
}


const BookingCard = props => {
    const [isFavourite, setIsFavourite] = useState('');
    const [programId, setProgramId] = useState('');
    const [bookingType, setBookingType] = useState('');
    const [bookingId, setBookingId] = useState('');
    const [programImage, setProgramImage] = useState('');
    const [programName, setProgramName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [programType, setProgramType] = useState('');
    const [checkInTime, setCheckInTime] = useState('');
    const [checkOutTime, setCheckOutTime] = useState('');
    const [gender, setGender] = useState('');
    const [ageGroup, setAgeGroup] = useState('');
    const [registrationFee, setRegistrationFee] = useState(0);
    const [bookingDate, setBookingDate] = useState('');
    const [bookingNumber, setBookingNumber] = useState('');
    const [pdf, setPdf] = useState('');
    const [byDates, setByDates] = useState(2);
    const [additionalDates, setAdditionalDates] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);


    useEffect(() => {
            setIsFavourite(props.data.isFavourite); 
            setProgramId(props.data.programId); 
            setBookingType(props.bookingType); 
            setBookingId(props.data._id); 
            setProgramImage(props.data.programImage); 
            setProgramName(props.data.programName); 
            setStartDate(props.data.startDate); 
            setEndDate(props.data.endDate); 
            setProgramType(props.data.programType); 
            setCheckInTime(props.data.checkInTime); 
            setCheckOutTime(props.data.checkOutTime); 
            setGender(props.data.gender); 
            setAgeGroup(props.data.ageGroup); 
            setRegistrationFee(props.data.registrationFee == 'NaN' ? 0 : props.data.registrationFee); 
            setBookingDate(props.data.bookingDate); 
            setBookingNumber(props.data.bookingNumber); 
            setPdf(props.data.pdf); 
            setByDates(props.data.byDates);
            setAdditionalDates(props.data.additionalDates);
            setSelectedDates(props.data.selectedDates);


    }, [props.bookingType])


    const handleFavourite = (e) => {
        e.preventDefault()
        setIsFavourite(!isFavourite);
        let params = {
            programId,
            favourite: !isFavourite
        }
        programFavourite(params).then(res => {
            
        })
    }


        const handlePrint = (e, index) => {
          let allTabs = document.querySelectorAll(`.booking_page .booking_card`);
          for (let n = 0; n < allTabs.length; ++n) {
            allTabs[n].className = "card booking_card";
          }
          let elm = document.getElementById(`__print_${index}`);
          elm.className = "card booking_card booking_card_print";
          setTimeout(() => window.print(), 0)
        };



        const getSelectedDates = (_all, _sel) => {
            const _res = _all.filter(el => _sel.find((id) => el._id == id));
            return _res.map(i => (
            <span className='cm_selected_date' key={i._id}>
                {getprogramdatesinCart(i.startDate,i.endDate)}
            </span>
            ));
        }

    return(
        <div className="card booking_card" id={`__print_${programId}`}>
            <Link className="text-reset" to={`/booking/${bookingId}/${programId}`}>
            <div className="booking_icon_btns hide_on_print">
                {bookingType === 2 ? <Fragment>
                    {pdf ? <a href={pdf} download><i className="icon-doc-check" /></a> : ''}
                    
                    <i className={`icon-heart${isFavourite ? '-fill' : ''}`} onClick={handleFavourite} />
                    <Share pageUrl={`Booking=${bookingId}&ProgramId=${programId}`} />
                    
                </Fragment> : <i className="icon-heart" />}
            </div>
                {programImage ? <img src={programImage} alt="program"/> : <div className="no_img"><LogoWhite /></div>}
                
                
                <h3>{programName}</h3>
            
            
            
            <div className="cm_about_booking">
                {byDates === 1 ? (
                     <p>{getSelectedDates(additionalDates, selectedDates)}</p>
                ) : (
                    <p>Program Dates : {programId === "621d3620c9cc80756fe0a171" ? "TBA" : campDates(startDate, endDate)}</p>
                 
                )}
                
                <p>Program Type : {programType ? getProgramType(programType) : ''} &nbsp; &nbsp; Time : {checkInTime} - {checkOutTime} </p>
                <p>Age : {ageGroup} &nbsp; &nbsp; Gender : {gender ? getgendersdisplay(gender) : ''}</p>

                <div className="booking_price">
                    <Link to={`/online-store`}>                       
                        <button className='btn btn-secondary btn-sm d-flex align-items-center'>
                            <StoreIcon /><b className='ml-1'>Online Camp Store</b>
                        </button>
                    </Link>
                    {/* {byDates == 1 ? (
                        <p><span>{getProgramPriceByDates(additionalDates, selectedDates)}</span></p>
                    ) : (
                        <p><span>{registrationFee ? `Price: $${parseFloat(registrationFee).toFixed(2)}` : 'Free'}</span></p>
                    )} */}
                    <p><span>{registrationFee ? `Price: $${parseFloat(registrationFee).toFixed(2)}` : 'Free'}</span></p>
                    
                </div>
            </div>

            <div className="mt-3 booking_btns hide_on_print">
                <div className="booking_date_numner">
                    <p>Booking date: {moment(bookingDate).format('DD MMMM, YYYY')}</p>
                    <p>Booking Number : {bookingNumber}</p>
                </div>

                {
                    bookingType === 2 ? <Fragment>
                                            {/* <button onClick={e => handlePrint(e, programId)} className="btn btn-sm btn-primary rounded-0"><i className="icon-print" /> Print</button> */}
                                        </Fragment>
                                        :
                                        <Fragment>
                                            
                                            <Link to={`/booking/${bookingId}/${programId}`} className="mr-2 btn-sm btn btn-primary rounded-0">Rate & Review</Link>
                                            <Link to={`/profile/bookings/${programId}`} className="btn btn-sm btn-primary rounded-0"><i className="icon-gallery" /> See photos</Link>
                                        </Fragment>
                }
                
            </div>
            </Link>
        </div> 
    )
}

export default BookingCard;