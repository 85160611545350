import React, {useState,useEffect} from 'react';
import { getHtml } from '../../../helper';

const  AddtionalExpForNonLogin =(props)=>{
const item                                          = props.Details;
const [addtioanexpcount, setAdditionexpCount]       = useState(1);
const [check, setCheck]                             = useState();
const [checkboxcheced, setCheckboxcheced]           = useState(false);
const [listingstyle, setlistingStyle]               = useState();

useEffect(()=>{
   if(props.SelectCamper || props.SelectCamper==0)
   {
    let preadditionexp = props.CamperAddtionalexp[props.SelectCamper].additionalExperience;
    
    if(preadditionexp===undefined)
    {
        setCheck('No');
        setCheckboxcheced(false);
    }
    else
    {
        let preaddtional = props.CamperAddtionalexp[props.SelectCamper].additionalExperience.filter(items => items.addtionexpId == item._id); 
        if(preaddtional.length)
        {
            setCheck(preaddtional[0].quantity);
            setCheckboxcheced(true);
        }
        else
        {
            setCheck('No');
            setCheckboxcheced(false);
        }   
    }
    setAdditionexpCount(1);
}
   
},[props])


const increseAdditionqut = () =>{
   let preval = addtioanexpcount;
   if(item.quantity)
   {
    let checktotal = item.quantity+preval;
    if(checktotal!==item.numberOfBooking){preval++}else{props.SetMaxquantity(true)}
   }
   else
   {
   if(preval !== item.numberOfBooking)
   {
    preval++;
   }
   else
   {
    props.SetMaxquantity(true) 
   }
}   
    setAdditionexpCount(preval)
}
const discreseAddtionqut =() =>{
    let preval = addtioanexpcount;
    if(preval !== 1)
    {
     preval--;
    }
    setAdditionexpCount(preval)
}



if(check=='No')
{
    return(
        <li key={item} test="No" style={{display: listingstyle}}>
                        <p><b>Title</b>: <div>{getHtml(item.title)}</div></p>
                        <p><b>Price</b>: ${item.price}</p>
                        <p><b>Description </b>: <div>{getHtml(item.description)}</div></p>
                        <div className="quantity mb-3">
                            <span className="inc"  onClick ={discreseAddtionqut}/>
                            <b>{addtioanexpcount}</b>
                            <span className="dec" onClick ={increseAdditionqut} />
                         </div>
                        
                        <div className="check_box_wrapper ml-auto">
                            <input onChange={(e) => {props.Addtonalexptocamper(e, item._id, props.SelectCamper, addtioanexpcount);  setCheckboxcheced(true)} } type="checkbox" checked={checkboxcheced} />                             
                            <span className="check_box"><span /></span>                          
                        </div>
                    </li>
    )
}
else
{
    return (
<li key={item} test="Yes" style={{display: listingstyle}}>
                        <p><b>Title</b>: {item.title}</p>
                        <p><b>Price</b>: ${item.price}</p>
                        <p><b>Description </b>: {item.description}</p>
                        <div className="quantity mb-3">
                            <span className="inc"  onClick ={() => props.addAdditionalExp(item._id, props.SelectCamper,"decrement")}/>
                            <b>{check}</b>
                            <span className="dec" onClick ={() => props.addAdditionalExp(item._id, props.SelectCamper,"increment")} />
                         </div>
                        
                        <div className="check_box_wrapper ml-auto">
                            <input onChange={(e) => {props.Addtonalexptocamper(e,item._id, props.SelectCamper, addtioanexpcount);  setCheckboxcheced(false)} } type="checkbox" checked={checkboxcheced}  /> <span className="check_box"><span /></span>                          
                        </div>
                    </li>
    )
}

}
export default AddtionalExpForNonLogin;







