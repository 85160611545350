import {io} from "socket.io-client";
import setting from '../config/settings';

let url = setting.api.socketurl;


export const socket  = io(url, {
    query:{accessToken: localStorage.getItem('accessToken')},
    transports: ['websocket']
});



