import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid';
import { useHistory, useLocation } from 'react-router-dom';

import { toggleActivityModal } from '../../store/ui';
import { openActivityAction } from '../../store/calendar';
import { getActivityService } from '../../services/activityService';
import { removeAmPm } from '../../helper';
import addsCampImg from '../../assets/img/adds-camp-img.png';

const dateFormat = 'YYYY/MM/DD';

const EventCalendar  = props => {
  let history = useHistory();
  let location = useLocation();

  const [date, setDate] = useState(new Date().getTime());
  const [activity, setactivity] = useState([]);
  const [programDates, setProgramDates] = useState([]);

  const calendarRef = React.createRef();

  const getActivity = () => {
    let params = `?type=${location.state.type}&date=${date}`
    getActivityService(params).then(res => {
      if(res?.data?.statusCode){
        let tempArr = [];
        if(location.state.type == 0){
          tempArr = res.data.responseData.activity;
        }
        if(location.state.type == 1){
          tempArr = res.data.responseData.data;
        }
        if(location.state.type == 3){
          tempArr = res.data.responseData.data;
        } 

        if(location.state.type){
        let tempProgram = res.data.responseData.data.map(item => ({a: item.startDate, b: item.endDate, c: item.programName}));
        let allDates = []
        tempProgram.map(item => {
          for (var d = new Date(item.a); d <= new Date(item.b); d.setDate(d.getDate() + 1)) {
            allDates.push(parseInt(moment.utc(d).format('D')));
          }
        });
        
        setProgramDates(allDates);
        }
        setactivity(tempArr);
      }
    })
  }

  useEffect(() => {
    getActivity();
  }, [props.activityModal, date])




  const handleDateChange = e => {
    setDate(e._d.getTime());

    let calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(moment(e._d.getTime()).format('YYYY-MM-DD')); 
  }

  const fcActionsNext = () => {
    let c_date = new Date(date);
    c_date.setDate(c_date.getDate() + 7);
    setDate(c_date.getTime());

    let calendarApi = calendarRef.current.getApi();
    calendarApi.next()
  }
  const fcActionPrev = () => {
    let c_date = new Date(date);
    c_date.setDate(c_date.getDate() - 7);
    setDate(c_date.getTime());

    let calendarApi = calendarRef.current.getApi();
    calendarApi.prev()
  }


  const handleMonthChange = e => {
    setDate(e._d.getTime());
    let calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(moment(e._d.getTime()).format('YYYY-MM-DD')); 
  }


  const handleEventClick = e => {
    e.jsEvent.preventDefault();
    let [eventId, type] = e.event.url.split(' ');

    if(type == 0){
      history.push(`/camps/${eventId}`)
    }

    if(type == 1){
      let tempArr = activity.filter(item => item._id == eventId)[0];
      props.openActivityAction(tempArr);
      props.toggleActivityModal();
    }
  }



  const handleNewActivity = () => {
    props.openActivityAction('');
    props.toggleActivityModal();
  }



  return (
    <div className="body_container bg_light">



            <div className="container mt-5 mb-5">
                <div className="d-flex cm_calender_flex">

                    <div className="cm_cal_left">
                    <DatePicker
                      dropdownClassName="dat_calander"
                      open={true}
                      picker="week"
                      onChange={handleDateChange}
                      format={dateFormat}
                      value={moment(moment(date).format('YYYY/MM/DD'), dateFormat)}
                      onPanelChange={handleMonthChange}
                      dateRender={current => {
                        let tempArr = activity.map(item => parseInt(moment.utc(item.date ? item.date : item.startDate).format('D')));
                        return (
                          <div className={location.state.type ? `${programDates.includes(current.date()) ? 'acive_program_dates' : ''}` : `${tempArr.includes(current.date()) ? 'acive_cell' : ''} `}>
                            {current.date()}
                          </div>
                        );
                      }}
                      />


                    <div className="adds_card">
                      <img src={addsCampImg} className="img-fluid" />
                      <div className="adds_title">
                        <h4>Get your camp </h4>
                      <div>
                      </div><h4>Gear! </h4></div>
                    </div>
                    </div>


                    <div className="cm_cal_right">
                      {location.state.type ? '' : <button onClick={handleNewActivity} className="btn btn-secondary activity_btn"><i className="icon-plus" /> Add Activity</button>}
                   
                          <div className="fc_actions">
                            <button onClick={fcActionPrev}><span className="fc-icon fc-icon-chevron-left" /></button>
                            <button onClick={fcActionsNext}><span className="fc-icon fc-icon-chevron-right" /></button>
                          </div>
                          <div className="cal_inner_hieght">
                            <FullCalendar
                              allDaySlot={false}
                              eventClassNames="cal_card"
                              slotDuration="01:00"
                              plugins={[timeGridPlugin]}
                              weekends={true}
                              ref={calendarRef}
                              eventClick={handleEventClick}
                              events={
                                activity.length && activity.map(item => ({
                                  title:  item.name ? item.name : item.programName,
                                  start: `${moment.utc(item.date ? item.date : item.startDate).format('YYYY-MM-DD')}T${removeAmPm(item.startTime ? item.startTime : item.checkInTime)}`,
                                  end: `${moment.utc(item.date ? item.date : item.startDate).format('YYYY-MM-DD')}T${removeAmPm(item.endTime ? item.endTime : item.checkOutTime)}`,
                                  backgroundColor: item.name ? '#FDF1DD' : '#842014',
                                  textColor: item.name ? '#F9A720' : '#ffffff',
                                  borderColor: item.name ? '#F9A72D' : '#842014',
                                  url: `${item._id} ${item.name ? 1 : 0}`,
                                }))
                              }
                              
                  
                            />

                            
                          </div>
                      </div>
                </div>
            </div>


        </div>
  );
}


const mapStateToProps = state => {
  let { activityModal } = state.ui;
  return {
    activityModal
  }
}

const mapDispatchToProps = dispatch => ({
  toggleActivityModal: () => dispatch(toggleActivityModal()),
  openActivityAction: params => dispatch(openActivityAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventCalendar);
