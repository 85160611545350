import React, {useState, useEffect} from 'react';
import {bookingCamperFolderDetails, addremovecamperfromfolder, getcamperfolderlist} from '../../services/directorServices';
import { Link, useLocation} from 'react-router-dom';
import { ProgramLoader } from '../reuse/SVG';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { notification } from 'antd';
const FolderDetailsBookingCamper = (props) => {
    
    const [camperList, setCamperList]                 = useState([]); 
    const [loading, setLoading]                       = useState(true);
    const [removefrmfolder, setremovefrmfolder]       = useState(false);
    const [camperId, setCamperID]                     = useState();
    let location                                      = useLocation();
    const [addmore, setAddmore]                       = useState(false);
    const [selectAll, setSelectAll]                   = useState(false);
    const [campersIds, setCampersIds]                 = useState([]);
    const [newCamperList, setNewCamperList]           = useState([]);
    const [campersWarning, setCampersWarning]         = useState(false); 
    const [btnloder, setBtnLoader]                    = useState(false); 
    const [folderName, setFolderName]                 = useState(''); 

    useEffect(()=>{
        let params = `/?folderId=${props.match.params.id}`;
        bookingCamperFolderDetails(params).then(res => {
            if(res?.data?.statusCode){
                setCamperList(res.data.responseData.result)
                setFolderName(res.data.responseData.folderName)
                setLoading(false)
            }
            else{
                setLoading(true)
            }
        })
    },[])


    const removeFromFolderAction =()=>{
        let params ={"folderId":props.match.params.id, "camperIds": JSON.stringify([camperId]), "type": 2}
        addremovecamperfromfolder(params).then(res=>{
            setremovefrmfolder(false);
            if(res?.data?.statusCode)
            {
               
                let camplist = camperList.filter(item => item._id !==camperId);
                setCamperList(camplist);
                notification.open({
                    description:
                      'Participant successfully Removed from folder',
                  });
            }
            else
            {
                notification.open({
                    description:
                      'Please try after some time',
                  });
            }
        })
    }
   
    
    /*************Add more action******* */


    const addMoreAction = () =>{
        setAddmore(true);
        setLoading(true);
        let params = `/?folderId=${props.match.params.id}`;
        getcamperfolderlist(params).then(res=>{
            setLoading(false);
            if(res?.data?.statusCode)
            {
                
                setNewCamperList(res.data.responseData.result)   
            }
            else
            {
                setAddmore(false);
                notification.open({
                    description:
                      'Please try after some time',
                  });
            }
        })
    }

    const selectAllCamper = () =>{
        let listcamper = [...campersIds];
        listcamper.length = 0;
        newCamperList.map(item=>{
            listcamper.push(item.camperId);
        })
        setCampersIds(listcamper);
        setCampersWarning(false)
    }

    const deselectAllCamper = () =>{
        let listcamper = [...campersIds];
        listcamper.length = 0;
        setCampersIds(listcamper)
    }

    const handelCamper = (e) =>{
        let listcamper = [...campersIds];
        setCampersWarning(false);
        if(listcamper.includes(e.target.value))
        {
            setSelectAll(false)
            let newcamperlist = listcamper.filter(item => item!==e.target.value) 
            setCampersIds(newcamperlist)
        }
        else
        {
            listcamper.push(e.target.value);
            if(listcamper.length==camperList.length){ setSelectAll(true)}
            setCampersIds(listcamper)
        }
    }  




    const addMoreToFolder = () =>{
        setBtnLoader(true);
        setLoading(true)
        if(campersIds.length > 0)
        {
            let params ={"folderId":props.match.params.id, "camperIds": JSON.stringify(campersIds), "type": 1}
            addremovecamperfromfolder(params).then(res=>{
                setremovefrmfolder(false);
                if(res?.data?.statusCode)
                {
                    
                    let params2 = `/?folderId=${props.match.params.id}`;
                    bookingCamperFolderDetails(params2).then((res)=>{
                        if(res?.data?.statusCode)
                        {
                            setCamperList(res.data.responseData.result)
                            setLoading(false)
                            setAddmore(false); 
                            setCampersWarning(false);
                            setSelectAll(false); 
                            setCampersIds([]);    
                        }
                        else
                        {
                            setLoading(true)
                        }
                    })


                    notification.open({
                        description:
                          'Participant successfully Added to folder',
                      });
                }
                else
                {
                    notification.open({
                        description:
                          'Please try after some time',
                      });
                }
            })
            
        }
        else
        {
            setCampersWarning(true);
            setBtnLoader(false);
            setLoading(false)
        }   
    }





    return(
        <div className="body_container pb-4 director_wrapper">    
            
            
            <Modal
            show={removefrmfolder}
            animation={false}
            dialogClassName="folder_model"
            size="lg"
            centered
            >
            <Modal.Body>
                <span className="modal_close" onClick={()=>{setCamperID(false); setremovefrmfolder(false)}}><i className="icon-cross" /></span>
                <div className="text-center p-5 pickup_modal_body">
                    <p>Are you sure you want to remove this camper from folder</p>
                </div>
                <div className="text-center p-5"> 
                <button  onClick={()=>{setCamperID(''); setremovefrmfolder(false)}} style={{width: '120px'}} className="btn btn-light rounded-0">No</button>
                <button  onClick={removeFromFolderAction} style={{width: '120px'}} className="btn btn-primary ml-3">Yes</button>
                </div>
            </Modal.Body>
            </Modal>   

            
            <div className="container">
                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Folder : {folderName}</h4>
                    {
                            addmore ? 
                            selectAll ?
                            <button className="btn btn-primary" onClick={()=>{deselectAllCamper(); setSelectAll(false)}}> Deselect All </button>
                            :
                            <button className="btn btn-primary" onClick={()=>{selectAllCamper(); setSelectAll(true)}}> Select All </button>
                          :
                          <div className="btn_group">
                            <span onClick={()=>{addMoreAction()}} className="btn btn-primary mr-3">Add More</span>
                            <Link to={`/bookings`} className="btn btn-primary">Back</Link>
                         </div>    
                        }
                </div>

                <div className="row">
                 <div className="col-lg-1" />
                     
                     
                        {
                        !addmore ?  
                     <div className="col-lg-10">
                     {
                     loading ? <div className="program_loader"><ProgramLoader /></div> :    
                     camperList.length > 0 ?
                            <div className="camp_list">
                                {
                                    camperList.map((camper,index)=>(
                                        <div className="d-flex align-items-center camper_card">
                                        <div className="mr-3">
                                            <img src={camper.profileImage !== '' ? camper.profileImage : require('../../assets/img/user.png')} /> 
                                        </div> 
                                
                                        <div className="d-camper-info">
                                            <Link className="link_color_black" to={`/bookings/${camper.bookingId}/${camper._id}/${camper.programId}`}>    
                                                <div className="d-flex infos_icons">
                                                {camper?.gardianPdf ? <div className="chcek_icon" /> : ''}
                                                <b>Name: {camper.camperName}</b>
                                                    (<span>{camper.gender==1 && 'M' || camper.gender==2 && 'F' || camper.gender==3 && 'O'}
                                                    {camper.medical =='None' || camper.medical =='"None"' ? '' : <i className="icon-hospital" />}
                                                    {camper.allergy =='None' || camper.allergy =='"None"' ? '' : <i className="icon-fish" />}
                                                    {camper.allergyDeadly==1 && <i className="icon-deadly-allergy" />}
                                                    {camper.allowDocumentCamper==1 && <i className="icon-handshake" />}
                                                    </span>
                                                    )
                                                </div>
                                                <p className="mb-0">Age: {camper.age} &nbsp; &nbsp; Booking Number: {camper.bookingNumber} &nbsp; &nbsp; Booking Date: 12/12/2019 </p>
                                            </Link>
                                        </div> 
                                        <div className="ml-auto">
                                            <div className="check_box_wrapper cm_dark_check">
                                                    <button className="btn btn-primary" onClick={()=>{ setremovefrmfolder(true); setCamperID(camper._id) }}> Remove from folder</button>   
                                           
                                            </div>  
                                        </div> 
                                    </div>     
                                    ))
                                }
                            </div>
                            :
                            <div className="camp_list">
                            No Campers Found
                            </div>   
                            }  
                    </div>

                     :
                     
                     <div className="col-lg-10">
                            {
                             loading ? <div className="program_loader"><ProgramLoader /></div> :      
                            newCamperList.length > 0 ?
                            <div className="camp_list">
                                {
                                    campersWarning ? <p className="waring_text">Please select the camper First</p> : ''  
                                }
                                {
                                    newCamperList.map((camper,index)=>(
                                        <div className="d-flex align-items-center camper_card">
                                        <div className="mr-3">
                                            <img src={camper.profileImage !== '' ? camper.profileImage : require('../../assets/img/user.png')} /> 
                                        </div> 
                                
                                        <div className="d-camper-info">
                                            <Link className="link_color_black" to={`/bookings/${camper.bookingId}/${camper.camperId}/${camper.programId}`}>
                                                
                                                <div className="d-flex infos_icons">
                                                {camper?.gardianPdf ? <div className="chcek_icon" /> : ''}
                                                <b>Name: {camper.camperName}</b>
                                                    (<span>{camper.gender==1 && 'M' || camper.gender==2 && 'F' || camper.gender==3 && 'O'}
                                                    {camper.medical =='None' || camper.medical =='"None"' ? '' : <i className="icon-hospital" />}
                                                    {camper.allergy =='None' || camper.allergy =='"None"' ? '' : <i className="icon-fish" />}
                                                    {camper.allergyDeadly==1 && <i className="icon-deadly-allergy" />}
                                                    {camper.allowDocumentCamper==1 && <i className="icon-handshake" />}
                                                    </span>
                                                    )
                                                </div>
                                                <p className="mb-0">Age :{camper.age} Booking Number: {camper.bookingNumber}  </p>
                                            </Link>
                                        </div> 
                                        <div className="ml-auto cm_mob_abs2">
                                            <div className="check_box_wrapper cm_dark_check">
                                                <input type="checkbox" value={camper.camperId} onChange={(e)=>{handelCamper(e)}} checked={campersIds.includes(camper.camperId)}></input>
                                                <span className="check_box"><span></span></span>
                                            </div>  
                                        </div> 
                                    </div>     
                                    ))
                                }
                                <div className="col-sm-12 text-center mb-5">
                                    <span className="btn btn-light rounded-0 mr-2" onClick={()=>{setAddmore(false); setCampersWarning(false);setSelectAll(false); setCampersIds([]); }} style={{width: '130px'}}>Cancel</span>
                                    <button  onClick={()=>{  addMoreToFolder() }} className={`btn btn-primary rounded-0 ${btnloder ? 'btnLoader' : ''}`} style={{width: '130px'}}>Submit</button>
                                </div>
                            </div>
                            :
                            <div className="camp_list">
                            No Campers Found
                            </div>   
                            }
                    
                      </div>   
                }



                </div>            

            </div>
        </div>
    )
}

export default FolderDetailsBookingCamper;