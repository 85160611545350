import React, {useState, useEffect} from 'react';
import moment from 'moment';
import { LogoWhite, CardLoader} from '../reuse/SVG';
import {programFavourite} from '../../services/programService';
import Share from '../static/Share';


const getProgramPriceByDates = (allPrices) => {
    if(allPrices.length){
        if(allPrices[0].standardPrice > 0){
            return `Price: $${allPrices[0].standardPrice.toFixed(2)}`
        }else {
            return 'Free'
        } 
    }
}


const AssociatedCard = props => {
    const [id, setId]                                 = useState('');
    const [programImage, setProgramImage]             = useState('');
    const [programName, setProgramName]               = useState('');
    const [startDate, setStartDate]                   = useState('');
    const [endDate, setEndDate]                       = useState('');
    const [ageGroup, setAgeGroup]                     = useState('');
    const [gender, setGender]                         = useState('');
    const [isfavorite, setIsFavorite]                 = useState();
    const [loading, setloading]                       = useState(false);

    useEffect(() => {
        setId(props.data._id);
        setProgramImage(props.data.programImage);
        setProgramName(props.data.programName);
        setStartDate(props.data.startDate);
        setEndDate(props.data.endDate);
        setAgeGroup(props.data.ageGroup);
        setGender(props.data.gender);
        setIsFavorite(props.data.isFavourite)
    }, [])


    const handleFavourite = (programId, favourite) => {
        setloading(true);
        let params = {
            programId: programId,
            favourite: favourite
        }

        programFavourite(params).then(res => {
            setloading(false);
            setIsFavorite(favourite);
        })
    }


    return(
        <div className="associated_sm_card">
            {props.data.isSpecialDeal ? <span className="SD_tag">Special Offers</span> : ''}
            {loading ? <CardLoader/> : ''}
            <a href={`/camps/${id}`} className="associated_P_link" />
            {programImage ? <img src={programImage} /> : <div className="no_img"><LogoWhite /></div>}
            {props.UserID ? isfavorite ?  <i  onClick={()=>handleFavourite(id, 0)} className="icon-heart-fill" /> : <i onClick={()=>handleFavourite(id, 1)} className="icon-heart" /> : ''}
            <Share pageUrl={`ProgramDetail=${id}`} />
            <h3 className='max_2_line'>{programName}</h3>
            <p>Program Dates : {id == "621d3620c9cc80756fe0a171" ? "TBA" : `${moment.utc(startDate).format('MMM')} ${moment.utc(startDate).format('DD')}-${moment.utc(endDate).format('DD')}, ${moment.utc(endDate).format('YYYY')}`}</p>
            <p>Age : {ageGroup}</p>
            <p>Gender : 
                {gender == 1 ? 'Male' : ''}
                {gender == 2 ? 'Female' : ''}
                {gender == 3 ? 'Co-Ed' : ''}
            </p>
            <div className="card_price">
                <div className={`text-right dark__ ${props.data.isSpecialDeal  ? 'cm_spacial_price' : ''}`}>
                    {props.data.byDates == 1 ? <h3>{getProgramPriceByDates(props.data.additionalDates)}</h3> : <h3><span className="under_red"> {props.data.registrationFee ? `Price : $${(props.data.registrationFee + (props.data.registerNonRefundFee || 0)).toFixed(2)}` : 'Free'}</span></h3>}
                    {props.data.isSpecialDeal ? <h2>${props.data.specialDealsPrice.toFixed(2)}</h2>  : ''}  
                </div>
            </div>
        </div>
    )
}

export default AssociatedCard;
