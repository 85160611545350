import React from 'react';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import { connect } from 'react-redux';

import { fCategoriesAction } from '../../store/pFilter';
import { setSubCatAction } from '../../store/program';

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    centerMode: false,
    slidesToShow: Math.floor(window.innerWidth / 270),
    slidesToScroll: Math.floor(window.innerWidth / 270),
    responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
      ]
};



const CampTypeCarousel = props => {
  let history = useHistory();

  const handleCatPage = (cat) => {
    let catArr = [cat._id];
    let subcatlistwithname = { "categoryname": cat.name, "catlist": cat.subCategory }
    let subCatArr = [subcatlistwithname];
    props.setSubCatAction(subCatArr);
    props.fCategoriesAction(catArr);
    history.push("/camps");
  }


    return(
        <div className="camp_type_carousel_wrapper">
            <div className="container">
                <h2 className="section_title_left">Browse by type</h2>
                <p>Choose your favorite category and find out what’s new!</p>
            </div>


            <Slider {...settings}>
                {props.category.map((item, i) => (
                  <div className="cm_card full_img_card pointer" key={item._id} onClick={(e) => handleCatPage(item)}>
                    <img src={item.bannerImage} />
                    <div className="card_content">
                        <h3>{item.name}</h3>
                    </div>
                </div>
                ))}
            </Slider>


        </div>
    )
}


const mapStateToProps = state => {
  let { preList, fSubcategory } = state.program;
  
  let { category } = preList;
  return {
    fSubcategory,
    category
  };
}

const mapDispatchToProps = dispatch => ({
  fCategoriesAction:           params => dispatch(fCategoriesAction(params)),
  setSubCatAction:             params => dispatch(setSubCatAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampTypeCarousel);
