import React, { useReducer, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import useResizeObserver from 'use-resize-observer';
import { Button } from 'react-bootstrap';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Initial state for the reducer
const initialState = {
  isDesktop: true,
  numPages: null,
  pageNumber: 1,
};

// Reducer function to manage the state
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DEVICE_TYPE':
      return { ...state, isDesktop: action.payload };
    case 'SET_NUM_PAGES':
      return { ...state, numPages: action.payload, pageNumber: 1 }; // Reset to page 1 on new document load
    case 'SET_PAGE_NUMBER':
      return { ...state, pageNumber: action.payload };
    default:
      return state;
  }
};

const PdfViewer = ({ fileUrl }) => {
  const [{ isDesktop, numPages, pageNumber }, dispatch] = useReducer(reducer, initialState);
  const { ref, width = 1 } = useResizeObserver();

  useEffect(() => {
    const updateDeviceType = () => {
      const isMobile = /Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent);
      dispatch({ type: 'SET_DEVICE_TYPE', payload: !isMobile });
    };

    updateDeviceType();
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    dispatch({ type: 'SET_NUM_PAGES', payload: numPages });
  };

  const handleSliderChange = (e) => {
    dispatch({ type: 'SET_PAGE_NUMBER', payload: Number(e.target.value) });
  };

  return (
    <>
      {isDesktop ? (
        // Render embed tag for desktop view
        <embed
          src={fileUrl}
          type="application/pdf"
          width="100%"
          height="100%"
          frameBorder="0"
          className="embed-responsive"
        />
      ) : (
        // Render react-pdf for mobile/tablet views
        <div ref={ref} style={{ width: '100%', height: '100%' }}>
          <Document 
            file={fileUrl} 
            onLoadSuccess={onDocumentLoadSuccess}
            style={{ width: '100%', height: '100%' }}
          >
            <Page pageNumber={pageNumber} width={width} />
          </Document>

          {/* Slider for page navigation */}
          <div className="d-flex justify-content-center mt-3">
            <input
              type="range"
              min={1}
              max={numPages || 1}
              value={pageNumber}
              onChange={handleSliderChange}
              className="form-range"
              style={{ width: '90%' }} 
            />
            <span className="ml-3">{pageNumber} / {numPages}</span>
          </div>

          {/* Download Button */}
          <div className="d-flex justify-content-end mr-2">
            <Button 
              variant="outline-primary"
              href={fileUrl} 
              download 
            >
              Download PDF
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default PdfViewer;