import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Empty } from 'antd';
import { toggleMapViewModal } from '../../store/ui';
import { filterProgramsAction, programLoaderActiveAction } from '../../store/program';
import { fFilterTypeAction, fPageNoAction, isDistrictCampAction } from '../../store/pFilter';
import CampCard from './CampCard';
import { ProgramLoader } from '../reuse/SVG';
import { resHandle } from '../../helper';
import { getDistrictsService } from '../../services/programService';
import setting from '../../config/settings';
import sportInternationalImage from '../../assets/img/sport-international.png';
import PaginatedComponent from '../common/Pagination/PaginationComponent';


const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }
    ]
};


const SportsInternationalCamps = props => {
    const [page, setPage] = useState(1);
    const [activeDistrict, setActiveDistrict] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [districtList, setDistrictList] = useState([]);
    const [schoolName, setSchoolName] = useState('');
    const [schoolNamesList, setSchoolNamesList] = useState([]);

    useEffect(() => {
        const script = document.createElement('script');
        script.text = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '160201611266653');
        fbq('track', 'PageView');
        `;
    
        document.body.appendChild(script);
        return () => {
          document.body.removeChild(script);
        };
      }, []);


    const getDistricts = () => {
        let query = `?isProgramExist=1`;
        getDistrictsService(query).then(res => {
            let { status, data } = resHandle(res);
            if(status){
                setDistrictList(data.result || []);
            }
        })
    }


    useEffect(() => {
        getDistricts()
    }, [])



    useEffect(() => {
        props.programLoaderActiveAction()
    }, [])

    useEffect(() => {
        let { 
            keywordSearch,
            gender,
            ageGroup,
            grade,
            startDate,
            endDate,
            programType,
            city,
            radius,
            priceRange,
            ezProRating,
            categories,
            flat,
            flong,            
            lat,
            long,
            pageNo,
            limit,
            weekDays,
            campers,
            subCategories,
            filterType,
            maxDistance,
            isOnline,
        } = props;
    
        let params = { 
            userId: props.userProfile ? props.userProfile._id : '',
            keywordSearch,
            gender,
            ageGroup,
            grade,
            startDate,
            endDate,
            programType,
            city,
            radius,
            priceRange,
            ezProRating,
            flat,
            flong,            
            lat,
            long,
            pageNo,
            limit,
            filterType,
            maxDistance,
            weekDays: weekDays.length ? JSON.stringify(weekDays) : '',
            categories: categories.length ? JSON.stringify(categories) : '',
            subCategories: subCategories.length ? JSON.stringify(subCategories) : '',
            campers: campers.length ? JSON.stringify(campers) : '',
            isOnline,
            campId: setting.api.sportInternationalCamp,
        }

        props.filterProgramsAction(params);
    }, [props.pageNo, props.subCategories, props.filterType, props.maxDistance, districtId, schoolName])


    const handlePageChange = page => {
        props.fPageNoAction(page);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }


    useEffect(() => {
        props.isDistrictCampAction();
        return () => props.isDistrictCampAction()
    }, [])





    return(
        <div className="body_container sport_international">
            <div className="container">
                <div className="row">

                    {/* <div className="col-lg-4">
                        <div className='district_logo text-left'>
                            <img src={sportInternationalImage} />
                        </div>
                        <MainFilter hideCategory={true} isDistrictFilter={true} />
                    </div> */}

                    <div className="col-lg-2" />

                    <div className="col-lg-8">
                        <div className='district_logo_color'>
                            <img src={sportInternationalImage} />
                            <div>
                            <button disabled={props.programs.length ? false : true} title={props.programs.length ? '' : 'No program available'} onClick={props.toggleMapViewModal} className="btn btn-secondary pl-5 pr-5"><i className="icon-map" /> Map View</button>
                            </div>
                        </div>
                        <p>Founded in 1983, we host the best teaching football programs in the nation having graduated over 110,000 athletes, many currently playing at the college level. This will be our 40th successful year of football instruction.</p>
                        <p>If you are an experienced football player or just starting football, our Football Academy is an experience you will never forget!</p>
                        


                       
                        

                 


                        <div className="camps_list" id="camps_lists">
                            {
                                props.loader ? 
                                    <div className="program_loader"><ProgramLoader /></div> 
                                    :
                                    props.programs.length ? props.programs.map(program => <CampCard key={program._id} program={program} />) : <div className="cm_empty"><Empty description="Please broaden your search by entering fewer fields in the search box." /> </div>
                            }
                        </div>

                        {
                            props.programs.length ? 
                            <div className="d-flex justify-content-center mt-5">
                            <PaginatedComponent
                                activePage={props.pageNo}
                                itemsCountPerPage={props.limit}
                                totalItemsCount={props.totalCount}
                                pageRangeDisplayed={4}
                                onPageChange={handlePageChange}/>
                        </div>  : ''
                        }
                 



                    </div>
                </div>
            </div>


          
<a className='providesupport' href="https://vm.providesupport.com/0c628irp4d8370wooul2pwrdfy" target="_blank"><img src="https://image.providesupport.com/image/0c628irp4d8370wooul2pwrdfy/current"  alt="Live chat" title="Click to chat"/></a>


    


            
        </div>
    )
}

/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let {programs, loader, totalCount} = state.program;
    let {userProfile} = state.user;

    let { 
        keywordSearch,
        gender,
        ageGroup,
        grade,
        startDate,
        endDate,
        programType,
        weekDay,
        city,
        radius,
        priceRange,
        ezProRating,
        categories,
        flat,
        flong,
        lat,
        long,        
        pageNo,
        limit,
        weekDays,
        subCategories,
        advanceSearch,
        filterType,
        campers,
        maxDistance,
        isOnline
    } = state.pFilter;

    return {
        loader,
        programs,
        keywordSearch,
        gender,
        ageGroup,
        grade,
        startDate,
        endDate,
        programType,
        weekDay,
        city,
        radius,
        priceRange,
        ezProRating,
        categories,
        flat,
        flong,        
        lat,
        long,
        pageNo,
        limit,
        weekDays,
        subCategories,
        advanceSearch,
        filterType,
        campers,
        totalCount,
        maxDistance,
        userProfile,
        isOnline
    };
}
  
const mapDispatchToProps = dispatch => ({
    toggleMapViewModal: () => dispatch(toggleMapViewModal()),
    programLoaderActiveAction: () => dispatch(programLoaderActiveAction()),
    isDistrictCampAction: () => dispatch(isDistrictCampAction()),
    filterProgramsAction: params => dispatch(filterProgramsAction(params)),
    fPageNoAction: params => dispatch(fPageNoAction(params)),
    fFilterTypeAction: params => dispatch(fFilterTypeAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(SportsInternationalCamps);
