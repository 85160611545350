import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { toggleAddFolderModal } from '../../store/ui';
import { createFolderService } from '../../services/otherService';


const AddFolder = props => {
    const [folderName, setFolderName] = useState('');
    const [folderNameError, setFolderNameError] = useState('');

    const handleSubmit = () => {
        let validate = true;
        if(folderName == ''){
            setFolderNameError('Folder name is requird');
            validate = false;
        }

        if(validate){
            let params = {folderName, type: props.folderType};

            createFolderService(params).then(res => {
                
            })
        }

        


    } 



    return(
        <Modal show={props.addFolderModal} centered>
            <Modal.Body>
                <span className="modal_close" onClick={props.toggleAddFolderModal}><i className="icon-cross" /></span>
                <h3 className="text-center mb-4">Create a Folder</h3>

                <div className="form-group">
                    <label>Folder Name</label>
                    <input type="text" onChange={e => setFolderName(e.target.value)} className="form-control" />
                </div>
                

                <div className="d-flex justify-content-center mt-5 mb-4">
                    <button onClick={handleSubmit} className="btn btn-primary btn-block">Done</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { addFolderModal, folderType } = state.ui;
    return {
        addFolderModal,
        folderType
    };
}

const mapDispatchToProps = dispatch => ({
    toggleAddFolderModal: () => dispatch(toggleAddFolderModal()),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(AddFolder);