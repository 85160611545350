import ApiInstance from './intercepter';
import API from '../config/api';
import Axios from 'axios';

Axios.defaults.headers.common['accessToken'] = localStorage.getItem('accessToken');
Axios.defaults.headers.common['Authorization'] = `${API.AUTH}`;

export const getUserRequestList = params => ApiInstance.get(API.USER_FRIEND_REQUEST_LIST+params);
export const acceptrejectfriendrequest = params => ApiInstance.post(API.ACCEPT_REJECT_FRIEND_REQUEST, params);
export const allTypeUserlist = params => ApiInstance.get(API.GET_ALL_TYPE_USER+params);
export const getUserFreindList = params => ApiInstance.get(API.GET_USER_FRIEND_LIST + params);
export const groupCreate = params => ApiInstance.post(API.GROUP_CREATE, params);
export const getinbox = params => ApiInstance.get(API.GET_INBOX);
export const getchathistory = params => ApiInstance.get(API.CHAT_HISTORY+params);
export const inviteCounselorService = params => ApiInstance.post(API.INVITE_COUNSELOR, params);
export const reInviteCounselorService = params => ApiInstance.post(API.RE_INVITE_COUNSELOR, params);
export const editCampDetailService = params => ApiInstance.post(API.EDIT_CAMP_DETAIL, params);
export const getCampDetailService = params => ApiInstance.get(API.GET_CAMP_DETAIL + params);
export const onBoardingService = params => ApiInstance.post(API.ON_BOARDING_JUSTIFY, params);




export const justifiAuthTokenService = (params) => Axios.post(API.JUSTIFI_AUTH_TOKEN, params);