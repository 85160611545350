import * as actionType from './actionsTypes';

const initialState = {
    feedModal: false,
    newFeed: false
}


/************************************************************************************/
// Feed Reducer
/************************************************************************************/


const feedReducer = (state = initialState, action) => {

    switch (action.type) {


        case actionType.FEED_MODAL:
            return {
            ...state,
            feedModal: !state.feedModal
        }


        case actionType.NEW_FEED:
            return {
            ...state,
            newFeed: action.payload
        }

        

        default: return state;
    }
}




/************************************************************************************/
// All UI Actions
/************************************************************************************/


export const toggleFeedModalAction = () => (dispatch => dispatch({ type: actionType.FEED_MODAL }))

export const newFeedAction = payload => (dispatch => dispatch({ type: actionType.NEW_FEED, payload }))

export default feedReducer;