import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const GenericTooltip = ({ type = 'info', tooltipText, icon }) => {
  const getVariant = () => {
    switch (type) {
      case 'danger':
        return 'red';
      case 'warning':
        return 'orange';
      case 'info':
      default:
        return 'blue';
    }
  };

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{tooltipText}</Tooltip>}
    >
      <span style={{ color: getVariant(), cursor: 'pointer' }}>
        {icon}
      </span>
    </OverlayTrigger>
  );
};

export default GenericTooltip;