import * as actionType from './actionsTypes';
import { resHandle } from '../helper';
import { programListdata ,weekListdata, programSearchListdata} from '../services/asService';

const initialState = {
    programs: [],
    filters:[],
    loader:true,
    error: '',
    hideitems:[]
}

/************************************************************************************/
// Auto Scheduler Reducer
/************************************************************************************/


const autoSchedulerReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionType.AS_PROGRAM_LIST:
            return {
                ...state,
                programs: action.payload.programs,
                filters: action.payload.filters,
                loader:false        
            }

        case actionType.AS_WEEKDATA_LIST:
                return {
                    ...state,
                    weekdata: action.payload,
                    loader:false     
                }  
        case actionType.AS_PROGRAM_ERROR:
            return {
                ...state,
                error: action.payload,
                programs: []
            }       
        default:
            return state
    }
}




/************************************************************************************/
// All Program Actions
/************************************************************************************/


export const getProgramListAction = (parms) => async dispatch => {
    let programList = await programListdata(parms);
    try {
        let { status, data } = resHandle(programList);
        let payloadData = {"programs":  data.result.data, "filters": data.result.filterData };
        if(status){
                dispatch({ type: actionType.AS_PROGRAM_LIST, payload: payloadData });
                return status;
            }else{
                dispatch({ type: actionType.AS_PROGRAM_ERROR, payload: data });
            }
    } catch (error) {
        dispatch({ type: actionType.AS_PROGRAM_ERROR, payload: error });
    }

}



export const getSearchProgramlistAction = (parms) => async dispatch => {
    let programList = await programSearchListdata(parms);
    try {
        let { status, data } = resHandle(programList);
        let payloadData = {"programs":  data.result.data, "filters": data.result.filterData };
        if(status){
                dispatch({ type: actionType.AS_PROGRAM_LIST, payload: payloadData });
                return status;
            }else{
                dispatch({ type: actionType.AS_PROGRAM_ERROR, payload: data });
            }
    } catch (error) {
        dispatch({ type: actionType.AS_PROGRAM_ERROR, payload: error });
    }

}




export const weekListdataAction = (weekindex, weekstamp) => async dispatch => {
    let programList = await weekListdata(weekstamp);
    try {
        let { status, data } = resHandle(programList);
        let payloadData = {"programs":  data.result.programs, "weekindex":weekindex };
        if(status){
                dispatch({ type: actionType.AS_WEEKDATA_LIST, payload: payloadData });
                return status;
            }else{
                dispatch({ type: actionType.AS_WEEKDATA_LIST, payload: payloadData });
            }
    } catch (error) {
        dispatch({ type: actionType.AS_PROGRAM_ERROR, payload: error });
    }

}

export default autoSchedulerReducer;