import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { compose, withProps, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";
import { Empty, AutoComplete } from 'antd';
import { toggleMapViewModal } from '../../store/ui';
import { fLatLongAction, fSearchAction, fPageNoAction } from '../../store/pFilter';
import { filterProgramsAction } from '../../store/program';
import Filters from '../camps/Filters';
import { LogoWhite } from '../reuse/SVG';
import {MAP} from 'react-google-maps/lib/constants';
import { Link } from 'react-router-dom';
import settings from '../../config/settings';


const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");
const Option = AutoComplete.Option;

const MapComponent = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCdDH9dkoLecpVX7gNRSkYMMV1eUfQ7VE0&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `calc(100vh - 70px)` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  lifecycle({
    componentWillMount() {
      
      const refs = {}
      this.setState({
        markers: [],
        onMapMounted: ref => {
          refs.map = ref;
          const bounds = new window.google.maps.LatLngBounds();
          if(refs.map==='undefined' || refs.map === null){
          }else{
          refs.mapObject = ref.context[MAP]; 
          refs.map.props.children[0].forEach((child) => {
              if (child.type === Marker) {
                  bounds.extend(new window.google.maps.LatLng(child.props.position.lat, child.props.position.lng));
                  
              }
          })
          refs.map.fitBounds(bounds);
        }


        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          })
        },
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: (e) => {
          var lat = parseFloat(e.target.getAttribute("let"));
          var lng = parseFloat(e.target.getAttribute("long"));
          var panPoint = new window.google.maps.LatLng(lat,lng);
          const bounds = new window.google.maps.LatLngBounds({lat, lng }, { lat, lng});
          refs.map.panTo(panPoint);
          refs.mapObject.setZoom(14);
        },
      })
    },
  }),
  withScriptjs,
  withGoogleMap
)(props =>
{
  
  return(
  <GoogleMap
    ref={props.onMapMounted}
    onClick={props.getLatLog}
    Zoom={15}
    center={props.center}
    defaultOptions={{
      streetViewControl: false,
      scaleControl: false,
      mapTypeControl: false,
      panControl: true,
      zoomControl: true,
      rotateControl: true,
      fullscreenControl: false
    }}
  >
    {
  props.programs.length ?
  props.programs.map(program => {
    return(      
      <Marker
        key={program._id}
        position={{lng: program.geoLocation ? program.geoLocation[0] : 0, lat: program.geoLocation ? program.geoLocation[1] : 0}}
        icon={{ url: props.currentCard == program._id ? require('../../assets/img/mapIcon_2.svg') : require('../../assets/img/mapIcon.svg') }}
        onClick={() => props.showMapProgramCard(program._id)}
      >
      
        {
          props.currentCard == program._id ? <InfoWindow>
                                                <div className="program_sm_card map_program_card">
                                                 <Link to={`/camps/${program._id}`}>
                                                    {program.programImage ? <img src={program.programImage} /> : <div className="no_img"><LogoWhite /></div>}
                                                      <h4>{program.programName}</h4>
                                                      <div className="rate_wrap">
                                                          <div>0</div>
                                                          
                                                          <p>Very Good</p>
                                                          <span>0 review</span>
                                                      </div>
                                                      <p>Price: <strong>{program.registrationFee ? `$${(program.registrationFee + (program.registerNonRefundFee || 0))}` : 'Free' }</strong></p>
                                                      <p>Age Group : {program.ageGroup}</p>
                                                 </Link>
                                              </div>
                                            </InfoWindow> : ''
        }        
      </Marker>
    )
  }) : ''
}

<div className="map_search_seraper">
                      <i className="icon-search" />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search on map"
                        value={props.searchValue ? props.searchValue : ''}
                        onChange={e => props.handelSearchFilter(e)} />
                        <ul style={{display: props.listingdisplay}} className="cm_map_opt">
                          {
                             props.programs.map(program => (
                              <li onClick={(e)=>{props.onPlacesChanged(e); props.handelSearchValue(e)}} let={program.geoLocation[1]} long={program.geoLocation[0]} programname={program.programName}> {program.programName} </li>
                             )) 
                          }
                        </ul>
</div>

  </GoogleMap>
); 
})




const MapViewModal = props => {
  const url = window.location.href;
    const [toggleProgram, setToggleProgram] = useState(true);
    const [currentCard, setCurrentCard] = useState('');
    const [programs, setPrograms] = useState([]);
    const [filterprograms, setFilterPrograms] = useState([]);
    const [listingdisplay, setListingdisplay] =  useState('none');
    const [searchValue, setsearchValue] = useState();

    const getLatLog = e => {
      let latLong = [e.latLng.lat(), e.latLng.lng()];
      props.fLatLongAction(latLong);
    }

    const showMapProgramCard = id => {
      setCurrentCard(id)
    }


    const handleMouseEnter = id => {
      setCurrentCard(id)
    }

    useEffect(() => {
      let { 
        lat,
        long,
        keywordSearch,
      } = props;
  
      let params = { 
        userId: props.userProfile ? props.userProfile._id : '',
        keywordSearch,
        lat,
        long,
        specialDeals: url.includes('special-offers'),
        isMapView: true
      }

      const currentURL = window.location.href;

      if (currentURL.includes('sports-international-camps')) {
        params.campId = settings.api.sportInternationalCamp;
      }

      if (currentURL.includes('district-camps')) {
        params.campId = settings.api.districtCamp;
      }

     props.filterProgramsAction(params);
  }, [props.keywordSearch])
 
  const handelSearchFilter = (e) =>{
    setsearchValue(e.target.value);
  let newList = [];
  let currentList =[];
  if (e.target.value !== "") {
      currentList = programs;
      newList = currentList.filter(item => {
      const lc = item['programName'].toLowerCase();
      const filter = e.target.value.toLowerCase();
      return lc.includes(filter);
  });
  setListingdisplay('block');
} else {
    newList = programs;
    setListingdisplay('none');
}
  setFilterPrograms(newList);
}

const handelSearchValue =(e) =>{
  var programname = e.target.getAttribute("programname");
  setsearchValue(programname);
  setListingdisplay('none');
}

const handlePageChange = page => {
  props.fPageNoAction(page);
}


    return(
        <Modal
            dialogClassName="map_modal"
            show={props.mapViewModal}
            animation={false}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Body>            
                <span className="modal_close" onClick={props.toggleMapViewModal}><i className="icon-cross" /></span>
                
                <div className={`map_view_wraper ${toggleProgram ? 'cm_open' : ''}`}>
                    <div className="map_view_filters">
                        <Filters />
                    </div>
                    <div className="program_list">
                        <div className="toggle_prog_list" onClick={() => setToggleProgram(!toggleProgram)} />
                        <div className="program_list_inner">
                            {

                              filterprograms.length > 0 ?(
                                filterprograms.length ?
                                filterprograms.map(program => (
                                    <div onMouseEnter={() => handleMouseEnter(program._id)} className="program_sm_card" key={program._id}>
                                       <Link to={`/camps/${program._id}`}>
                                        {
                                            program.programImage ? <img src={program.programImage} /> : <div className="no_img"><LogoWhite /></div>
                                          }
                                        <h4>{program.programName}</h4>
                                        <div className="rate_wrap">
                                            <div>0</div>
                                              <p>Very Good</p>
                                              <span>0 review</span>
                                        </div>
                                        <p>Price: <strong>{program.registrationFee ? `$${(program.registrationFee + (program.registerNonRefundFee || 0))}` : 'Free' }</strong></p>
                                        <p>Age Group : {program.ageGroup}</p>
                                       </Link>
                                    </div>
                                )) : <div className="cm_empty"><Empty description="Please broaden your search by entering fewer fields in the search box." /> </div>

                              ): 
                              (
                              props.programs.length ?
                              <Fragment>
                                {
                                  props.programs.map(program => (
                                    <div onMouseEnter={() => handleMouseEnter(program._id)} className="program_sm_card"  key={program._id}>
                                      <Link to={`/camps/${program._id}`}>
                                      {
                                          program.programImage ? <img src={program.programImage} /> : <div className="no_img"><LogoWhite /></div>
                                        }
                                      <h4>{program.programName}</h4>
                                      <div className="rate_wrap">
                                          <div>0</div>
                                            <p>Very Good</p>
                                            <span>0 review</span>
                                      </div>
                                      <p>Price: <strong>{program.registrationFee ? `$${(program.registrationFee + (program.registerNonRefundFee || 0))}` : 'Free' }</strong></p>
                                      <p>Age Group : {program.ageGroup}</p>
                                      </Link>
                                    </div>
                                  ))
                                }
                              </Fragment> : <div className="cm_empty"><Empty description="Please broaden your search by entering fewer fields in the search box." /> </div>
                            
                          )
                          }
                        </div>
                    </div>

                    <div className="cm_map">
                        <MapComponent
                          isMarkerShown
                          getLatLog={getLatLog}
                          programs={filterprograms.length > 0 ? filterprograms: props.programs}
                          showMapProgramCard={showMapProgramCard}
                          currentCard={currentCard}
                          handelSearchFilter = {handelSearchFilter}
                          listingdisplay={listingdisplay}
                          handelSearchValue={handelSearchValue}
                          searchValue ={searchValue}
                          />
                    </div>

                </div>

            </Modal.Body>
        </Modal>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { mapViewModal } = state.ui;
    let {programs, totalCount} = state.program;
    let { lat, long, keywordSearch, pageNo, limit } = state.pFilter;
    let {userProfile} = state.user;
    return {
      mapViewModal,
      programs,
      lat,
      long,
      keywordSearch,
      pageNo,
      limit,
      totalCount,
      userProfile
    };
}

  
const mapDispatchToProps = dispatch => ({
    toggleMapViewModal: () => dispatch(toggleMapViewModal()),
    fLatLongAction: params => dispatch(fLatLongAction(params)),
    fSearchAction: params => dispatch(fSearchAction(params)),
    fPageNoAction: params => dispatch(fPageNoAction(params)),
    filterProgramsAction: params => dispatch(filterProgramsAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(MapViewModal);