import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import api from '../../../../config/api';


function GapMedicalInsuranceComponent(props) {
    const {campCode, programId, userId, ...otherProps} = props;
    const [campDetails, setCampDetails] = useState();
  
    const getProgramDetails = async () => {
      let query = `?programId=${programId}`; 
      const accessToken = localStorage.getItem('accessToken');
  
      const res = await Axios.get(api.VIEW_PROGRAM + query, {
        headers: {
          'accessToken': accessToken,
          'Authorization': api.AUTH
        }
      });
  
      if(res?.data?.statusCode 
          && res.data.responseData.result.subCategory.length === 1) {
              
        setCampDetails(res.data.responseData.result);
      }
    }
  
    useEffect(() => {
        getProgramDetails();
    }, []);
  
    return campDetails ? (
        <gap-medical {...otherProps} coverage-type={campDetails.categoryName === 'Dance' ? 'DANCE' : campDetails.subCategory[0].name} ></gap-medical>
    ) : null;
  }

export default GapMedicalInsuranceComponent
