import ApiInstance from './intercepter';
import API from '../config/api';


export const addActivityService = params => ApiInstance.post(API.ADD_ACTIVITY, params);

export const getActivityService = params => ApiInstance.get(API.GET_ACTIVITY + params);

export const editActivityService = params => ApiInstance.post(API.EDIT_ACTIVITY, params);

export const directorAddActivityService = params => ApiInstance.post(API.DIRECTOR_CREATE_ACTIVITY, params);

export const directorActivityListService = params => ApiInstance.get(API.DIRECTOR_ACTIVITY_LIST + params);

export const getDirectorActivityService = params => ApiInstance.get(API.GET_DIRECTOR_ACTIVITY + params);

export const editDirectorActivityService = params => ApiInstance.post(API.EDIT_DIRECTOR_ACTIVITY, params);

export const createActivityFolderService = params => ApiInstance.post(API.CREATE_ACTIVITY_FOLDER, params);

export const deleteActivityFolderService = params => ApiInstance.delete(API.DELETE_ACTIVITY_FOLDER+params);

export const activityfoderdetails = params => ApiInstance.get(API.ACTIVITY_FOLDERS_DETAILS+params);

export const updatefolderactivity = params => ApiInstance.post(API.UPDATE_ACTIVITY_FOLDERS,params);

export const deleteactivity = params => ApiInstance.delete(API.DELETE_ATIVITY+params);

export const addmoreActivity = params => ApiInstance.get(API.ADDMORE_ACTIVITY_TO_FOLDER+ params);