import * as actionType from './actionsTypes';

const initialState = {
    fmedical: '',
    tsize: '',
    ssize: '',
    fallergy: '',
    fgender: '',
    fage: '',

}

const allFilterStateReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionType.F_MEDICAL_BOOKING_FILTER: return {...state, fmedical: action.payload}
        case actionType.F_TSIZE_BOOKING_FILTER: return {...state, tsize: action.payload}
        case actionType.F_SSIZE_BOOKING_FILTER: return {...state, ssize: action.payload}
        case actionType.F_FALLERGY_BOOKING_FILTER: return {...state, fallergy: action.payload}
        case actionType.F_AGE_BOOKING_FILTER: return {...state, fage: action.payload}
        case actionType.F_FENDER_BOOKING_FILTER: return {...state, fgender: action.payload}

        default: return state;
    }
}

export const fmedicalBookingFilterAction = payload => (dispatch => dispatch({ type: actionType.F_MEDICAL_BOOKING_FILTER, payload }))
export const tsizeBookingFilterAction = payload => (dispatch => dispatch({ type: actionType.F_TSIZE_BOOKING_FILTER, payload }))
export const ssizeBookingFilterAction = payload => (dispatch => dispatch({ type: actionType.F_SSIZE_BOOKING_FILTER, payload }))
export const fallergyBookingFilterAction = payload => (dispatch => dispatch({ type: actionType.F_FALLERGY_BOOKING_FILTER, payload }))
export const fageBookingFilterAction = payload => (dispatch => dispatch({ type: actionType.F_AGE_BOOKING_FILTER, payload }))
export const fgenderBookingFilterAction = payload => (dispatch => dispatch({ type: actionType.F_FENDER_BOOKING_FILTER, payload }))


export default allFilterStateReducer;