import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Empty } from 'antd';

import { bookingListService } from '../../services/programService';
import { ProgramLoader} from '../reuse/SVG';
import BookingCard from './BookingCard';
import Select from 'react-select';
import PaginatedComponent from '../common/Pagination/PaginationComponent';

const Bookings = props => {
    const [loader, setLoader] = useState(false);
    const [bookings, setBookings] = useState([]);
    const [bookingType, setBookingType] = useState(2);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [isUpcomming, setIsUpcomming] = useState(0);


    const getBookingList = () => {
        setLoader(true);

        let query = `?page=${page - 1}&limit=${limit}`;

        if(bookingType == 3){
            query += `&isCancelled=1`
        }
        if(isUpcomming == 1){
            query += `&bookingType=1`
        }

        if(isUpcomming == 2){
            query += `&bookingType=2`
        }
        bookingListService(query).then(res => {
            setLoader(false);
            if(res?.data?.statusCode){
                window.scrollTo(0, 0);
                setBookings(res.data.responseData.result);
                setTotalCount(res.data.responseData.totalCount);
            }
        })
    }


    useEffect(() => {
        getBookingList();
    }, [bookingType, page, isUpcomming])

    




    return(
        <div className="content_wrapper booking_page">
            <div className="d-flex justify-content-between">
                <h2>Bookings</h2>
                <Link className="btn btn-secondary rounded-0" to={{pathname: '/calendar', state: {"type": 3 }}}><i className="icon-calender-2" /> Calendar</Link>
            </div>


            <div className="d-flex justify-content-between mt-3 mb-4">
                <div className="">
                    <button onClick={() => (setBookingType(2), setBookings([]), setPage(1))} className={`mr-2 btn btn-outline-dark ${bookingType == 2 ? 'active' : ''}`}>All</button>
                    {/* <button onClick={() => (setBookingType(1), setBookings([]), setPage(1))} className={`mr-2 btn btn-outline-dark ${bookingType == 1 ? 'active' : ''}`}>Past</button> */}
                    <button onClick={() => (setBookingType(3), setBookings([]), setPage(1))} className={`mr-2 btn btn-outline-dark ${bookingType == 3 ? 'active' : ''}`}>Cancelled</button>
                </div>

                <div className="filter_by_">
                    <Select
                        placeholder="Filter By"
                    
                        classNamePrefix="cm_select"
                        className={`form-control`}
                        onChange={e => setIsUpcomming(e.value)}
                        options={[
                            {label: 'Upcoming', value: 1},
                            {label: 'Past', value: 2},
                        ]}>
                    </Select>
                </div>
            </div>

            {loader ? <div className="page_sm_loader"><ProgramLoader /></div> : ''}


            {bookings.length ?
                bookings.map(booking => <BookingCard data={booking} key={booking._id} bookingType={bookingType} />) : loader ? '' : <div className="cm_empty mt-5"><Empty description="Booking Not Found" /> </div>
            }

            {
                bookings.length ? <div className="d-flex justify-content-center mt-5">
                <PaginatedComponent
                    activePage={page}
                    itemsCountPerPage={limit}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={4}
                    onPageChange={setPage}/>
            </div>  : ''
            }

        </div>
    )
}

export default Bookings;
