import React, {useState, useEffect, useRef} from 'react';
import { Link, Redirect, useHistory  } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { TimePicker, notification } from 'antd'
import {
    CompositeDecorator,
    Editor,
    EditorState,
    RichUtils,
    convertToRaw
} from 'draft-js';

import {campersignin,campersignout } from '../../services/directorServices'
import { messageProgramSingleService } from '../../services/programService';
import { RoundLoader } from '../reuse/SVG';
import { bookingUserChatAction } from '../../store/chat';
import { CustomDatePicker } from '../reuse/FormControls';
import { stateToHTML } from "draft-js-export-html";
import { convertTimeToCurrentTimeZone, getprogramdatesinCart } from "../../helper";



const getSelectedDates = (_all, _sel) => {
    const _res = _all.filter(el => _sel.find((id) => el._id === id));
    return _res.map(i => (
    <span className='cm_selected_date' key={i._id}>
        {getprogramdatesinCart(i.startDate, i.endDate)}
    </span>
    ));
}


const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
}


const MyLink = props => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={url}>
            {props.children}
        </a>
    );
};



const CamperCard = props => {
    let history = useHistory();
    const myRef = useRef();
    const [actionloading, setActionLoading]     = useState(false);
    const [persnaolstatus, setPersnaolstatus]   = useState();
    const [msgModal, setMsgModal] = useState(false);

    const [showURLInput, setShowURLInput] = useState(false);
    const [urlValue, setUrlValue] = useState('');
    const [messageErr, setMessageErr] = useState('');
    const [subject, setSubject] = useState('');
    const [messageType, setMessageType] = useState([]);
    const [messageTypeErr, setMessageTypeErr] = useState('');
    const [saveAsDefault, setSaveAsDefault] = useState(false);
    const [msgDate, setMsgDate] = useState();
    const [msgTime, setMsgTime] = useState();
    const [msgDateErr, setMsgDateErr] = useState('');
    const [msgTimeErr, setMsgTimeErr] = useState('');
    const [loader, setLoader] = useState(false);
    const decorator = new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: MyLink,
        },
    ]);

    const [editorState, setEditorState] = useState(EditorState.createEmpty(decorator));

    

    useEffect(() => {
        setPersnaolstatus(props.CamperDetails.signInStatus)
    }, [props.CamperDetails])

    const singInsignOutAction =(actionType, camperId) =>{
        let params ={"programId": props.CamperDetails.programId, camperId}
        setActionLoading(true);
        if(actionType==='signIn')
        {
            campersignin(params).then(res=>{
                setActionLoading(false);
                if(res?.data?.statusCode)
                {
                    setPersnaolstatus(true);
                    notification.open({
                        description:
                          'Participant Signed In Successfully',
                      }); 
                }
                else
                {
                    notification.open({
                        description:
                          'Please try after some time',
                      });
                }
            })
        }
        else
        {
            campersignout(params).then(res=>{
                setActionLoading(false);
                if(res?.data?.statusCode)
                {
                    setPersnaolstatus(false);
                    notification.open({
                        description:
                          'Participant Signed Out Successfully',
                      });
                }
                else
                {
                    notification.open({
                        description:
                          'Please try after some time',
                      });

                }
            })   
        }
    }


    const handleChat = item => {
        let obj = {
            profileImage: item.profileImage,
            _id: item.userId,
            name: item.userName,
            type: 1
        }

        props.bookingUserChatAction(obj);
        history.push(`/inbox`)
        return <Redirect to="/inbox" />

    }

    

    const promptForLink = e => {
        e.preventDefault();
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = editorState.getSelection().getStartKey();
            const startOffset = editorState.getSelection().getStartOffset();
            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
            let url = '';
            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey);
                url = linkInstance.getData().url;
            }
            setShowURLInput(true);
            setUrlValue(url);
        }
    }



    const removeLink = e => {
        e.preventDefault();
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            setEditorState(RichUtils.toggleLink(editorState, selection, null))
        }
    }




    const confirmLink = e => {
        e.preventDefault();
        const contentState = editorState.getCurrentContent();

        const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            { url: urlValue }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        // Apply entity
        let nextEditorState = EditorState.set(editorState,
            { currentContent: contentStateWithEntity }
        );

        // Apply selection
        nextEditorState = RichUtils.toggleLink(nextEditorState,
            nextEditorState.getSelection(), entityKey
        );

        setEditorState(nextEditorState);
        setShowURLInput(false);
        setUrlValue('');

    }


    const handleScheduleDate = e => {
        setMsgDateErr('');
        setMsgDate(moment.utc(e._d).format('MM/DD/YYYY'))
    }


    const handleScheduleTime = e => {
        setMsgTimeErr('');
        setMsgTime(moment(e._d).format('h:mm a'))
    }




    const onLinkInputKeyDown = e => { if (e.which === 13) { confirmLink(e); } }


    const handleTypeChange = e => {
        let tempVal = parseInt(e.target.value)
        setMessageTypeErr('');
        let tempMessageType = [...messageType];

        if(tempMessageType.includes(tempVal)){
            let index = tempMessageType.indexOf(tempVal);
            tempMessageType.splice(index, 1);
            setMessageType(tempMessageType);
        }else{
            tempMessageType.push(tempVal);
            setMessageType(tempMessageType);
        }
    }


    
    const closeModal = () => {
        setMsgModal(false);
        setSubject('');
        setMsgDate('');
        setMsgTime('');
        setLoader(false);
    }


    const sendMessage = () => {
        setMsgModal(true);
        // let query = `?programId=${_id}`;
        // isMessageProgramExistService(query).then(res => {
        //     if(res?.data?.statusCode){
        //        setMessageType(res?.data?.responseData?.messageType || []);
        //        setSaveAsDefault(res?.data?.responseData?.saveAsDefault || false)
        //     }
        // })
    }




    const handleValidate = () => {
        let validate = true;

        if(messageType.length === 0){
            validate = false;
            setMessageTypeErr('Message type is required');
        }

        const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
        const rowValue = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

        if (rowValue.length <= 1) {
            setMessageErr('Message is required');
            validate = false
        }

        return validate
    }




    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            setLoader(true);
            let params = {
                userId: props.CamperDetails.userId,
                programId: props.programId,
                messageType: JSON.stringify(messageType),
                saveAsDefault: saveAsDefault ? 1 : 0,
                subject,
                message: stateToHTML(editorState.getCurrentContent()),
                scheduleDateTime: msgDate && msgTime ? new Date(`${msgDate} ${msgTime}`).getTime() : msgDate ? new Date(`${msgDate}`).getTime() : new Date(`${msgTime}`).getTime(),
            }

            messageProgramSingleService(params).then(res => {
                if(res?.data?.statusCode){
                    closeModal();
                    notification.open({
                        description:
                            'Message Save Successfully',
                    });
                }

                setLoader(false);
            })
        }
        

    }


   
    const getGender = (gender) => {
        switch (gender){
            case 1:
                return 'M';
            case 2:
                return 'F';
            case 3:
                return 'O'
            default:
                return "";

        }
    }

    
    return(
        <div className={`d-flex align-items-center camper_card ${(props?.CamperDetails.status === 2 || props?.CamperDetails.status === 4)? 'has_canceled' : 
                ''} ${(props?.CamperDetails.status === 3 || props?.CamperDetails.status === 4) ? 'has_refunded' : ''}`}>
            {props?.CamperDetails.status === 2 ? <span className='has_canceled_text'>Canceled</span> : ''}
            {props?.CamperDetails.status === 3 ? <span className='has_canceled_text'>Refunded</span> : ''}
            {( props?.CamperDetails.status === 4) ? <span className='has_canceled_text'><span className='text-danger'>Canceled</span> & Refunded</span> : ''}

            <div className="cm_count">{props?.CamperDetails?.bookingSerial}</div>
        <div className="mr-3 mt-2">
            <img className="hide-image " src={props.CamperDetails.profileImage !== '' ? props.CamperDetails.profileImage : require('../../assets/img/user.png')} alt=""/> 
        </div> 

        <div className="mt-2 camper-card-font">
            {
                props?.userProfile?.UserType === 4 ? 
                <div className="link_color_black">
                
                <div className="d-flex infos_icons">
                    {props?.CamperDetails?.gardianPdf ? <div className="chcek_icon" /> : ''}
                    <b>Name: {props.CamperDetails.camperName}</b>
                    (<span>
                        {getGender(props.CamperDetails.gender)}
                        {props.CamperDetails.medical === 'None' || props.CamperDetails.medical ==='"None"' ? '' : <i className="icon-hospital" />}
                        {props.CamperDetails.allergy === 'None' || props.CamperDetails.allergy ==='"None"' ? '' : <i className="icon-fish" />}
                        {props.CamperDetails.allergyDeadly === 1 ? <i className="icon-deadly-allergy" /> : ''}
                        {props.CamperDetails.allowDocumentCamper === 1 ? <i className="icon-handshake" /> : ''}
                    </span>
                    )
                </div>
                <div className='d-flex flex-column flex-md-row justify-content-between mb-2'>
                    <span className='mr-2'>Age: {props.CamperDetails.age} </span>
                    <span className='mr-2'> Booking Number: {props.CamperDetails.bookingNumber} </span>
                    <span className='mr-2'> Booking Date: {convertTimeToCurrentTimeZone(moment.utc(props.CamperDetails.bookingDate), props.userProfile.timezone, 'MM/DD/YYYY')} </span>
                </div>            
               
            
            </div>
            :
            <Link className="link_color_black" to={`/bookings/${props.CamperDetails.bookingId}/${props.CamperDetails.camperId}/${props.CamperDetails.programId}`}>
                
                <div className="d-flex infos_icons">
                    {props?.CamperDetails?.gardianPdf ? <div className="chcek_icon" /> : ''}
                    <b>Name: {props.CamperDetails.camperName}</b>
                    (<span>
                        {getGender(props.CamperDetails.gender)}
                        {props.CamperDetails.medical === 'None' || props.CamperDetails.medical ==='"None"' ? '' : <i className="icon-hospital" />}
                        {props.CamperDetails.allergy === 'None' || props.CamperDetails.allergy ==='"None"' ? '' : <i className="icon-fish" />}
                        {props.CamperDetails.allergyDeadly === 1 ? <i className="icon-deadly-allergy" /> : ''}
                        {props.CamperDetails.allowDocumentCamper === 1 ? <i className="icon-handshake" /> : ''}
                    </span>
                    )
                </div>
                <div className='d-flex flex-column flex-md-row justify-content-between mb-2'>
                    <span className='mr-2'>Age: {props.CamperDetails.age} </span>
                    <span className='mr-2'> Booking Number: {props.CamperDetails.bookingNumber} </span>
                    <span className='mr-2'> Booking Date: {convertTimeToCurrentTimeZone(moment.utc(props.CamperDetails.bookingDate), props.userProfile.timezone, 'MM/DD/YYYY')} </span>
                </div>
            </Link>

            }

{props?.CamperDetails?.selectedDates?.length ? <p className='mb-0 mt-3'>Program Dates: {getSelectedDates(props.additionalDates, props.CamperDetails.selectedDates)}</p> : ''}
            
        </div> 
        <div className="ml-auto" data-persnaolstatus={persnaolstatus}>
        {props?.userProfile?.UserType === 4 && !props.messageCamper ? '' : <button onClick={sendMessage} className="btn btn-secondary btn_sm mr-2 mb-1">Message</button>}
                    
        {
             actionloading ? <div className="camper_actions_counsloer"><span className="min_loader"><RoundLoader /></span></div> :
             persnaolstatus ? <button onClick={()=>{singInsignOutAction('signOut', props.CamperDetails.camperId)}} className="btn btn-danger btn_sm mb-1">Sign Out </button>:
             <button onClick={()=>{singInsignOutAction('signIn',props.CamperDetails.camperId)}} className="btn btn-success btn_sm mb-1">Sign In </button>
        }  
        </div>










        <Modal
                show={msgModal}
                animation={false}
                dialogClassName="msg_modal"
                size="lg"
                centered
            >
                <Modal.Body>
                    <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                    
                    <form className="p-4 pt-5" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6 _border_right">
                                <div className="form-group mt-3">
                                    <p className="mt-3">Message Type</p>
                                    <div className="check_box_wrapper cm_dark_check mb-3">
                                        <input
                                            type="checkbox"
                                            checked={messageType.includes(1)}
                                            name="1"
                                            onChange={handleTypeChange}
                                            value="1" />
                                        <span className="check_box"><span></span> Phone Text</span>
                                    </div>
                                    <div className="check_box_wrapper cm_dark_check mb-3">
                                    <input
                                            type="checkbox"
                                            name="2"
                                            checked={messageType.includes(2)}
                                            onChange={handleTypeChange}
                                            value="2" />
                                        <span className="check_box"><span></span> App Message</span>
                                    </div>
                                    <div className="check_box_wrapper cm_dark_check mb-3">
                                    <input
                                            type="checkbox"
                                            name="3"
                                            checked={messageType.includes(3)}
                                            onChange={handleTypeChange}
                                            value="3" />
                                        <span className="check_box"><span></span> Email</span>
                                    </div>
                                    <div className="check_box_wrapper cm_dark_check mb-3">
                                        <input
                                            type="checkbox"
                                            name="saveAsDefault"
                                            checked={saveAsDefault} onChange={e => setSaveAsDefault(e.target.checked)} />
                                        <span className="check_box"><span></span> Save above as default</span>
                                    </div>


                                    {messageTypeErr ? <span className="err_msg">{messageTypeErr}</span> : ''}
                                    
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mt-3">Schedule Send for Later</p>


                                <div className="form-group">
                                    <label>Date</label>
                                    <CustomDatePicker
                                        error={msgDateErr}
                                        disabledDate={current => current && current < moment.utc(new Date().getTime() - 86400000 )}
                                        change={handleScheduleDate} />
                                </div>

                                <div className="form-group">
                                    <label>Time</label>
                                    <TimePicker
                                        use12Hours
                                        format="h:mm a"
                                        onChange={handleScheduleTime}
                                        className={`form-control ${msgTimeErr ? 'is-invalid' : ''}`}/>
                                    {msgTimeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{msgTimeErr}</Tooltip>}><span className="error_tootltip_label" /></OverlayTrigger> : ''}
                                </div>
                            </div>
                        </div>


                        {
                            messageType.includes(3) ? <div className="form-group mt-4">
                            <label>Subject</label>
                            <textarea value={subject} onChange={e => setSubject(e.target.value)} className="form-control"></textarea>
                        </div> : ''
                        }
                        



                        <div className={`form-group editer_wrapper`}>

                            <label>Message</label>

                            <div className="editer_btns">
                                <button className="btn btn-sm btn-secondary pl-4 pr-4" type="button" onMouseDown={promptForLink}>Add Link</button>
                                <button className="btn btn-sm btn-info ml-3 pl-4 pr-4" type="button" onMouseDown={removeLink}>Remove Link</button>
                            </div>

                            <Editor
                                editorState={editorState}
                                onChange={e => (setEditorState(e), setMessageErr(''))}
                                placeholder="Write Description here…"
                                ref={myRef}
                            />

                            {messageErr ? <span className="err_msg">{messageErr}</span> : ''}


                            <Modal
                                centered
                                dialogClassName="remove_header_line"
                                show={showURLInput}
                                onHide={() => setShowURLInput(false)}>
                                <Modal.Body>

                                    <span className="modal_close" onClick={() => setShowURLInput(false)}><i className="icon-cross" /></span>
                                    <div className="p-5">
                                        <label>Type or paste your link in the text box</label>
                                        <input
                                            onChange={e => setUrlValue(e.target.value)}
                                            type="text"
                                            className="form-control"
                                            value={urlValue}
                                            placeholder="https://www.example.com/"
                                            onKeyDown={onLinkInputKeyDown}
                                        />
                                        <button disabled={urlValue ? false : true} className="btn btn-primary mt-4 btn-block" onMouseDown={confirmLink}> Confirm </button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>

                        <div className="text-center">
                            <button disabled={loader} type="submit" className="btn btn-primary">Send</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>







    </div>
    )
}


const mapStateToProps = state => {
    let { userProfile } = state.user;
    return{
        userProfile
    }
}



const mapDispatchToProps = dispatch => ({
    bookingUserChatAction: params => dispatch(bookingUserChatAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CamperCard);