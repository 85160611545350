import React, { useState } from 'react';
import Pagination from 'react-js-pagination';

const PaginatedComponent = ({activePage, itemsCountPerPage, totalItemsCount, onPageChange, pageRangeDisplayed }) => {
  const [inputPage, setInputPage] = useState("");
  const [isError, setIsError]   =   useState(false)

  const handlePageChange = (pageNumber) => {
    onPageChange(pageNumber);
    window.scrollTo({top: 0,behavior: 'smooth'})
  };

  const handleInputChange = (event) => {
    setIsError(false);
    const value = event.target.value;
    if (/^\d*$/.test(value)) { // Ensure input is numeric
      setInputPage(value);
    }
  };

  const handlePageInputSubmit = () => {
    const pageNumber = Number(inputPage);
    if (pageNumber > 0 && pageNumber <= Math.ceil(totalItemsCount / itemsCountPerPage)) {
      onPageChange(pageNumber);
      window.scrollTo({top: 0,behavior: 'smooth'})
    }else{
      setIsError(true)
    }
  };

  return (
    <div>
      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={pageRangeDisplayed}
        onChange={handlePageChange}
      />
          <div className="input-group search_wrap ml-3">
          <i className="icon-search" />
            <input 
              type="number"
              value={inputPage}
              onChange={handleInputChange}
              placeholder="Go to page"
            />
            <div className="input-group-append">
                <button 
                    className="btn btn-outline-primary" 
                    type="button"
                    onClick={handlePageInputSubmit}
                  >
                    Go
                </button>
            </div>
          </div>
      {isError && <span className="invalid-feedback">Page Not Found</span>}
    </div>
  );
};

export default PaginatedComponent;