import React, { useReducer, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom'

import { changeAuthModal, resendotpEmailModal, changeRoleAction } from '../../store/ui';
import { signupAction, clearErrorMsgAction, fastSignupAction } from '../../store/user';
import { isValidEmail, isValidPassword, titleCase } from '../../helper';
import StaffSignUp from '../../assets/img/staffSignUp.png';
import SignupBg from '../../assets/img/signup-bg.jpg';


const Signup = props => {
    const [onboardingForm, setOnboardingForm] = useState(false);
    const [userInput, setUserInput] = useReducer((state, newState) => ({...state, ...newState}),
        {
            name: '',
            lastName: '',
            email: '',
            campCode: '',
            password: '',
            confirmPassword: '',
            acceptCondition: false,
            sendMeEmail: true
        }
    );

    const [error, setError] = useReducer((state, newState) => ({...state, ...newState}),
        {
            name: '',
            lastName: '',
            email: '',
            password: '',
            campCode: '',
            confirmPassword: '',
            acceptCondition: ''
        }
    );


    const handleChange = e => {
        if(e.target.name == 'name'){
            setUserInput({[e.target.name]: titleCase(e.target.value)});          
            setError({[e.target.name]: ''});
        }else{
            setUserInput({[e.target.name]: e.target.value});
            setError({[e.target.name]: ''});
        }
        
    }


    const handleValidate = () => {
        let validate = true;
        let { name, lastName, email, campCode } = userInput;


        if(name == ''){
            setError({name: 'First name is required'});
            validate = false;
        }else if(name.length > 50){
            setError({name: 'First name is to large max limit 50'});
            validate = false;
        }

        if(lastName == ''){
            setError({lastName: 'Last name is required'});
            validate = false;
        }else if(lastName.length > 50){
            setError({lastName: 'Last name is to large max limit 50'});
            validate = false;
        }

        if(email == ''){
            setError({email: 'Email is required'});
            validate = false
        }else if(!isValidEmail(email)){
            setError({email: 'Email not valid'});
            validate = false
        }

        
        if(props.isDirecterAuth){
            if(campCode == ''){
                setError({campCode: 'Camp code is required'});
                validate = false
            }
        }

        return validate;
    }


    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            let { name, email, campCode, lastName } = userInput;
            let params = {
                deviceID: 'campsez',
                deviceToken: 'campsez',
                deviceTypeID: '3',
                UserType: props.isDirecterAuth ? 2 : 1,
                name,
                lastName,
                email,
                campCode
            }
            let providerId = localStorage.getItem('PROVIDER_ID');
            if(providerId){
                params.providerId = providerId;
            }
            props.fastSignupAction(params);
            props.resendotpEmailModal(email);
        }
    }



    useEffect(() => props.clearErrorMsgAction, [])

    return(
        <div className="row no-gutters d-flex align-items-center">
            <div className="col-lg-6 hide_in_mob">
                <img className={props.isDirecterAuth ? '' : 'normal'} src={ props.isDirecterAuth ? StaffSignUp : SignupBg} />
            </div>

            <div className="col-lg-6 pb-5 d-flex align-items-center">
                <form onSubmit={handleSubmit}>

                    

                    <h3 className="text-center mb-4">Sign Up</h3>

                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="First Name"
                            className={`form-control ${error.name ? 'is-invalid' : ''}`}
                            name="name"
                            value={userInput.name}
                            onChange={handleChange} />
                        
                        {error.name ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.name}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                    </div>

                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Last Name"
                            className={`form-control ${error.lastName ? 'is-invalid' : ''}`}
                            name="lastName"
                            value={userInput.lastName}
                            onChange={handleChange} />
                        
                        {error.lastName ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.lastName}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                    </div>

                    <div className="form-group">
                        <input type="email" name="email" value="" style={{display: 'none'}} />
                        <input
                            type="email"
                            placeholder="Email"
                            className={`form-control ${error.email ? 'is-invalid' : ''}`}
                            name="email"
                            autocomplete="nope"
                            value={userInput.email}
                            onChange={handleChange} />
                        
                        {error.email ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.email}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                    </div>

                    

                    

                  

                    {
                        props.isDirecterAuth ? <div className="form-group">
                        <input
                            type="text"
                            placeholder="Org Code"
                            className={`form-control ${error.campCode ? 'is-invalid' : ''}`}
                            name="campCode"
                            value={userInput.campCode}
                            onChange={handleChange} />
                             {error.campCode ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.campCode}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        
                            
                    </div> : ''
                    }

                    <p className={`m-0 ${error.acceptCondition ? 'is-invalid' : ''}`}>
                        <div className="check_box_wrapper cm_dark_check pl-0">
                            <span className="check_box"><small>By signing up, I agree to the <b><Link className="color_red" target="_blank" to="/terms-and-conditions">Terms & Conditions</Link></b> and <b><Link className="color_red" target="_blank" to="/privacy-policy">Privacy Statement</Link></b></small></span>
                        </div>
                    </p>   
                           

                    {props.error && <p className="error_msg">{props.error}</p>}

                    <button
                        type="submit"
                        disabled={props.loading}
                        className={`btn btn-primary rounded-0 btn-block mt-3 btn_anim ${props.loading ? 'show_anim' : ''}`}>{props.loading ? 'Loading...' : 'Sign Up'}</button>
                    
                        <span onClick={props.changeRoleAction} className="btn btn-primary rounded-0 btn-block mt-3">{props.isDirecterAuth ? 'Sign Up as Attendee?' : 'Sign Up as Staff Personnel?'}</span>
                </form>
                <p className="change_modal">Already have an account? <span className="cm_link_red" onClick={() => props.changeAuthModal(1)}>Sign In</span></p>
            </div>

        </div>
    )
}



const mapStateToProps = state => {
    let { loading, error } = state.user;
    let { isDirecterAuth } = state.ui;
    return {
        loading,
        isDirecterAuth,
        error
    };
}
  
const mapDispatchToProps = dispatch => ({
    changeAuthModal: params => dispatch(changeAuthModal(params)),
    signupAction: params => dispatch(signupAction(params)),
    resendotpEmailModal: params => dispatch(resendotpEmailModal(params)),
    clearErrorMsgAction: () => dispatch(clearErrorMsgAction()),
    changeRoleAction: () => dispatch(changeRoleAction()),
    fastSignupAction: params => dispatch(fastSignupAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Signup);