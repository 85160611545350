import React, {  useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { DatePicker, notification } from 'antd';
import { CSVLink } from 'react-csv';
import { useHistory } from "react-router-dom";

import { counselordetail, directorprograms, directorprogramstatus } from '../../services/directorServices';
import { getAllPrgramWithId, uploadExcelService } from '../../services/programService';
import { childAction, sProgramsAction } from '../../store/program';
import { ProgramLoader } from '../reuse/SVG';
import { dProgramModalAction } from '../../store/ui';
import { filterCabinParamsAction } from '../../store/cabin';
import CampCard from './CampCard';
import { removeTime3, dateFormat2, filterSingle, getProgramType, getExperience, getBool, ageArr } from '../../helper';
import { CustomNumber, CustomSelect } from '../reuse/FormControls';
import PaginatedComponent from '../common/Pagination/PaginationComponent';



const titles = [
    'Camp Program',
    'Program Code',
    'No. of Available slots',
    'Non-refundable registration fee',
    'Date Early Bird Registration is Open',
    'Date Early Bird Registration is Closed',
    'Early Bird Price',
    'Date Standard Registration Is Open',
    'Date Standard Registration is Closed',
    'Standard Price',
    'Date Late Registration is Open',
    'Date Late Registration is Closed',
    'Late Registration Price',
    'Program Category',
    'Program Sub Category',
    'Street Address',
    'City',
    'State',
    'Zip Code',
    'Gender',
    'Program Type',
    'Age Group',
    'Grade',
    'GradeExperience Level',
    'Start Date',
    'End Date',
    'Check-In Time',
    'Check-Out Time',
    'Hours of Attendance',
    'Meals',
    'T-shirt',
    'Equipment Needed',
    'Facility',
    'Aftercare',
    'Travel',
    'Scholarships',
    'Discounts',
    'Important Information Notes',
    'Year Revenue To Date',
    'EZ Pro Rating',
    'Revenue From Booking Fees',
    'Question 1',
    'Question 2',
    'Question 3',
    //'Personnel',
    'Program Introduction',
    'About Program',
    'Program Instructions',
    'Disclaimer Statement',
];


const DirectorCamp = props => {
    const history = useHistory();
    const [campListType, setCampListType]               = useState(1);
    const [campersInCabinModal, setCampersInCabinModal] = useState(false);
    const [directorPrograms, setDirectorPrograms]       = useState([]);
    const [count, setCount]                             = useState(0);
    const [loading, setLoading]                         = useState(true);
    const [loader, setLoader]                           = useState(false);
    const [roundLoading, setRoundLoading]               = useState(false);    
    const [pageNo, setPageNo]                           = useState(1);    
    const [search, setSearch]                           = useState(''); 
    const [years, setYears]                             = useState([new Date().getFullYear()]);  
    const [selectYear, setSelectYear]                   = useState('');
    const [campDate, setCampDate]                       = useState('');
    const [selectDateModal, setSelectDateModal]         = useState(false);
    const [prgramsCSV, setProgramCSV]                   = useState([]);
    const [groupCount, setGroupCount]                   = useState('');
    const [groupCountErr, setGroupCountErr]             = useState('');
    const [groupByGender, setGroupByGender]             = useState(false);
    const [groupByAge, setGroupByAge]                   = useState(false);
    const [groupByGrade, setGroupByGrade]               = useState(false);
    const [ageDropDown, setAgeDropDown]                 = useState([]);
    const [ageTemp, setAgeTemp]                         = useState([]);
    const [age, setAge]                                 = useState([]);
    const [grade, setGrade]                             = useState([]);
    const [gradeTemp, setGradeTemp]                     = useState([]);
    const [gender, setGender]                           = useState([]);
    const [gradeDropDown, setGradeDropDown]             = useState([]);
    const [currentProgramId, setCurrentProgramId]       = useState('');
    const [permissions, setPermissions]                 = useState({});
    const [excelLoader, setExcelLoader]                 = useState(false);
    const [isSearchToched, setIsSearchToched]           = useState(false);


    useEffect(() => {
        if(props?.userProfile?.UserType == 4){
            counselordetail(`/?counselorId=${props.userProfile._id}`).then((res) => {
                if(res.data.statusCode == 1){
                    setPermissions(res.data.responseData.result.permissions || {}) 
                }
            })
        }
    }, [props.userProfile])



    const handleAddYear = () => {
        if(years.length <= 7){
            let lastYear = years[years.length - 1];
            setYears([...years, lastYear + 1])
        } 
    }



    const setCampersInCabin = program => {
        let tempArr = [];
        for(let i = program.minAge; i <= program.maxAge; i++ ){
            tempArr.push(i);
        }
        setAgeDropDown(tempArr);
        setGradeDropDown(program.grade);
        setCampersInCabinModal(true);
        setCurrentProgramId(program._id);
    }

    const addMoreAgeGroup = () => {
        if(ageTemp.length){
            let selectAge = [...age, ageTemp.map(i => i.value)]
            setAge(selectAge);
            setAgeTemp([]);
            let tempArr = [].concat(...selectAge);
            let tempAgeDrop = ageDropDown.filter(item => tempArr.indexOf(item) === -1);
            setAgeDropDown(tempAgeDrop);
        }
    }

    const addMoreGradeGroup = () => {
        if(gradeTemp.length){
            let selectGrade = [...grade, gradeTemp.map(i => i.value)]
            setGrade(selectGrade);
            setGradeTemp([]);
            let tempArr = [].concat(...selectGrade);
            let tempGradeDrop = gradeDropDown.filter(item => tempArr.indexOf(item) === -1);
            setGradeDropDown(tempGradeDrop);
        }
    }



    const getDirectorprograms = () => {
        setLoading(true);
        props.childAction();
        let params = `?campCode=${props.campCode}&type=${campListType}&pageNo=${pageNo - 1}&limit=10&search=${search}&year=${selectYear}&campDate=${campDate}`
        directorprograms(params).then((res) => {
            if(res?.data?.statusCode){ 
                setDirectorPrograms(res.data.responseData.result);
                setCount(res.data.responseData.count);
                setLoading(false);
            }else{
                setLoading(false);
            }
        })
    }

    useEffect(()=>{
        getDirectorprograms();
        if(campListType == 2){
            setYears([new Date().getFullYear() - 2, new Date().getFullYear() - 1, new Date().getFullYear()]) 
        }else{
            setYears([new Date().getFullYear()]) 
        }

    },[campListType, pageNo, selectYear, campDate])


    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            if(isSearchToched){
                getDirectorprograms();
            }
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [search])


    useEffect(()=>{
        if(props.isChildAction){
            getDirectorprograms();
        }

        getAllPrgramWithId(`?campCode=${props.campCode}`).then(res => {
            if(res?.data?.statusCode){
                props.sProgramsAction(res.data.responseData.result)
            }
        })
       
    },[props.isChildAction])





    useEffect(() => {
        if(directorPrograms.length){
        let tempPrograms = directorPrograms.map(program => [
            program.programName,
            program.programCode,
            program.noOfSlots,
            program.registerNonRefundFee ? `$${program.registerNonRefundFee}` : 'N/A',
            program.dateEarlyRegisterOpen ? dateFormat2(program.dateEarlyRegisterOpen) : 'N/A',
            program.dateEarlyRegisterClosed ? dateFormat2(program.dateEarlyRegisterClosed) : 'N/A',
            program.earlyBirdPrice ? `$${program.earlyBirdPrice}` : 'N/A',
            dateFormat2(program.registerOpenDate),
            dateFormat2(program.registerCloseDate),
            program.registrationFee,
            program.dateLateRegisterOpen ? dateFormat2(program.dateLateRegisterOpen) : 'N/A',
            program.dateLateRegisterClosed ? dateFormat2(program.dateLateRegisterClosed) : 'N/A',
            program.lateRegistrationFee ? `$${program.lateRegistrationFee}` : 'N/A',
            program.categoryName ? program.categoryName : 'N/A',
            program?.subCategory?.length ? program?.subCategory?.map(item => `${item.name} `) : '',
            program.addressLine1,
            program.city,
            program.state,
            program.zipCode,
            program.gender == 1 ? 'Male' : program.gender == 2 ? 'Female' : program.gender == 3 ? 'Non-Binary' : '',
            getProgramType(program.programType),
            `${program.minAge} - ${program.maxAge}`,
            program.grade,
            program.expLevel ? getExperience(program.expLevel) : '',
            dateFormat2(program.startDate),
            dateFormat2(program.endDate),
            program.checkInTime,
            program.checkOutTime,
            program.attendanceHour,
            program.meals.map(i => `${i}, `),
            getBool(program.tshirt),
            program.equipment,
            program.facility,
            getBool(program.afterCare),
            getBool(program.travel),
            getBool(program.scholarships),
            `${program.discountType == 1 ? '$' : ''}${program.discount}${program.discountType == 0 ? '%' : ''}`,
            program.impNotes ? program.impNotes : '',
            program.yearRevenue ? `$${program.yearRevenue}` : 'N/A',
            program.ratingCount,
            program.bookingFeesRevenue,
            program.question1 ? program.question1 : 'N/A',
            program.question2 ? program.question2 : 'N/A',
            program.question3 ? program.question3 : 'N/A',
            program.programIntroduction ? program.programIntroduction : 'N/A',
            program.aboutProgram ? program.aboutProgram : 'N/A',
            program.programInstruction ? program.programInstruction : 'N/A',
            program.disclaimer ? program.disclaimer : 'N/A'                                  
        ])

        tempPrograms.unshift(titles);
        setProgramCSV(tempPrograms)
    }
    }, [directorPrograms.length])



    const callActions = () => {
        getDirectorprograms();
    }



    const setProgramStatus =(programID, status, programIndex) =>{
        setRoundLoading(true);
        let programList = [...directorPrograms];
        let programstatus = status == 1 ? 2 : 1
        let params ={"programId": programID, "status": programstatus}
        directorprogramstatus(params).then((res)=>{
            if(res?.data?.statusCode)
            {
                programList[programIndex].status=programstatus;
                setDirectorPrograms(programList);
                setRoundLoading(false);
            }
            else
            {
                programList[programIndex].status=status;
                setDirectorPrograms(programList);
                setRoundLoading(false);
            }
        })
    }




    const handlePageChange = page => {
        setPageNo(page);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }



    const handleTypeChange = val => {
        setPageNo(1);
        setCampListType(val);
    }


    const handleYearChange = year => {
        setPageNo(1);
        setSelectYear(year);
        setCampDate('');
    }

    const handleDateChange = e => {
        setSelectYear('');
        setCampDate(removeTime3(e._d.getTime()));
        setSelectDateModal(false);
    }

    const closeModal = () => {
        setCampersInCabinModal(false);
        setGroupByAge(false);
        setGroupByGrade(false);
        setAgeDropDown([]);
        setGradeDropDown([]);
        setGradeTemp([]);
        setAgeTemp([]);
        setGrade([]);
        setAge([]);
        setGroupCount('');

    }


    const handleCabinSubmit = e => {
        e.preventDefault();

        let params = {
            groupCount,
            gender: groupByGender ? 1 : 0
        };

        if(groupByAge){
            params.age = age
        }

        if(groupByGrade){
            params.grade = grade
        }


        if(groupCount){
            props.filterCabinParamsAction(params);
            history.push(`/cabin-organizer/${currentProgramId}`)

        }else {
            setGroupCountErr('Group count is required')
        }
    }




    const handleExcelFileUpload = e => {
        let file = e.target.files[0];
        let params = new FormData();
        params.append('excelFile', file);


        setExcelLoader(true);

        uploadExcelService(params).then(res => {
            setExcelLoader(false);
            if(res?.data?.statusCode){
                getDirectorprograms();
                notification.open({
                    message: 'Program',
                    description: `Program created successfully.`
                });
            }else{
                notification.open({
                    message: 'Program',
                    description: res.data.error.responseMessage
                });
            }
            
        })
    }

    return(
        <div className="body_container pb-4 director_wrapper">
            <div className="container">
                
                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Program Information</h4>
                    {(props?.userProfile?.UserType == 4 || props?.userProfile?.UserType == 2) ? props?.userProfile?.UserType == 4 && !permissions.createProgram ? '' : <div className="btn_group">
                        <button className="btn btn-primary" onClick={() => props.dProgramModalAction('')}>Add programs</button>
                    </div> : ''}
                 
                    
                </div>



                <div className="btn_group text-center">
                    <button onClick={() => {handleTypeChange(1); setPageNo(1)}} className={`btn fix_btn btn-outline-primary ${campListType == 1 ? 'active' : ''}`} >Upcoming</button>
                    <button onClick={() => {handleTypeChange(2); setPageNo(1)}} className={`btn fix_btn btn-outline-primary ml-3 ${campListType == 2 ? 'active' : ''}`}>Past</button>
                </div>


                <div className="partner-form search_wrap">
                    <i className="icon-search" />
                    <input
                        type="text"
                        className="form-control form-control-lg"
                        value={search}
                        onChange={e => (setSearch(e.target.value), setIsSearchToched(true))}
                        placeholder="Search" />
                </div>



                <div className="d-flex justify-content-between table_header">
                    <div>
                        {
                            years.map(item => <button key={item} onClick={() => handleYearChange(item)} className={`btn btn-outline-dark mr-3 ${selectYear === item ? 'active' : ''}`}>{item}</button>)
                        }  
                    </div>

                    <div className="d-flex align-items-center">
                        <button className="btn btn-primary mr-3" disabled={years.length <= 7 ? false : true} onClick={handleAddYear}>Add Year</button>
                        <button className="btn btn-outline-danger" onClick={() => setSelectDateModal(true)}><i className="icon-calender" /> Select Date</button>
                    </div>
                </div>
                

                <div className="downloads_btns">
                    <a href="https://campsez-dev.s3.us-east-2.amazonaws.com/file_1613627395668_DirectorProgram.xlsx" download className="btn btn-outline-danger"><i className="icon-download" /> Download blank excel template</a>
                    <CSVLink className="btn btn-outline-danger" data={prgramsCSV}><i className="icon-upload-2" /> Download Excel</CSVLink>
                    <button className="btn btn-outline-danger" onClick={() => window.print()}><i className="icon-print" /> Print</button>
                    <div className={`btn btn-primary cm_excel_upload btn_anim ${excelLoader ? 'show_anim' : ''}`}>
                        <i className="icon-upload" />
                        <input
                            type="file"
                            onChange={handleExcelFileUpload}
                            value=""
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> Upload Excel</div>
                </div>


               {
                   loading ? <div className="program_loader"><ProgramLoader /></div> : 
                   directorPrograms.length > 0 ?  
                   <div className="camp_list" id="section-to-print">
                    {
                        directorPrograms.map((program,index)=>(
                            <CampCard
                                key={program._id}
                                ProgramIndex={index}
                                Roundloading={roundLoading}
                                CampDetails={program}
                                setProgramStatus={setProgramStatus}
                                setCampersInCabin={() => setCampersInCabin(program)}
                                callActions={callActions}
                                campListType={campListType}
                                editProgram={permissions.editProgram} />

                            
                        ))
                    } 



                    <Modal
                        dialogClassName="pickup_modal"
                        show={campersInCabinModal}
                        centered >
                        
                        <Modal.Body>
                        <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                        <h5 className="text-center mt-4 mb-4">Group Organizer</h5>
                        <form onSubmit={handleCabinSubmit}>
                            <div className="form-group">
                                <label>How many participants would you like in each group?</label>
                                <CustomNumber
                                    value={groupCount}
                                    min={0}
                                    precision={0}
                                    step={1}
                                    error={groupCountErr}
                                    change={e => (setGroupCount(e), setGroupCountErr(''))}
                                    pHolder="Enter" />
                            </div>

                            <div className="form-group">
                                <div className="check_box_wrapper cm_dark_check">
                                    <input
                                        type="checkbox"
                                        onChange={() => setGroupByGender(!groupByGender)}
                                        chacked={groupByGender} />
                                    <span className="check_box"><span></span> Do you want group by gender?</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="check_box_wrapper cm_dark_check">
                                    <input
                                    type="checkbox"
                                    checked={groupByAge}
                                    onChange={() => (setGroupByAge(!groupByAge), setGroupByGrade(false))}
                                    chacked={groupByAge} />
                                    <span className="check_box"><span></span> Do you want group by age?</span>
                                </div>
                            </div>

                            {
                               groupByAge ? <div style={{position: 'relative', zIndex: '999'}}>
                                   <div className="form-group">
                                    <label>Create age groups</label>
                                    <div className="drop_chip">{age.map((item, ind) => <span key={ind}>{item.map(i => <span key={i}>{i}</span>)} </span>)}</div>
                                    <CustomSelect
                                        isMulti
                                        change={e => setAgeTemp(e?e:[])}
                                        value={ageTemp}
                                        options={ageDropDown.map(item => ({value: item, label: item}))}
                                        closeMenuOnSelect={false} />

                                        {ageTemp.length ? <div className="text-right mt-3"><span onClick={addMoreAgeGroup} className="btn btn-primary btn-sm">Add group</span></div> : ''}
                                        
                                </div>
                               </div> : '' 
                            }


                            <div className="form-group">
                                <div className="check_box_wrapper cm_dark_check">
                                    <input
                                    type="checkbox"
                                    checked={groupByGrade}
                                    onChange={() => (setGroupByGrade(!groupByGrade), setGroupByAge(false))}
                                    chacked={groupByGrade} />
                                    <span className="check_box"><span></span> Do you want group by grade?</span>
                                </div>
                            </div>

                            {
                               groupByGrade ? <div className="">
                                   <div className="form-group">
                                    <label>Create grade groups</label>
                                    <div className="drop_chip">{grade.map(item => <span>{item.map(i => <span>{i}</span>)} </span>)}</div>
                                    <CustomSelect
                                        isMulti
                                        change={e => setGradeTemp(e)}
                                        value={gradeTemp}
                                        options={gradeDropDown.map(item => ({value: item, label: item}))}
                                        closeMenuOnSelect={false} />
                                    
                                    {gradeTemp.length ? <div className="text-right mt-3"><span onClick={addMoreGradeGroup} className="btn btn-primary btn-sm">Add group</span></div> : ''}
                                </div>
                               </div> : '' 
                            }

                            <button type="submit" className="btn btn-primary btn-block rounded-0">Submit</button>
                        </form>
                        </Modal.Body>
                    </Modal>  
                   
                  
                  </div>
                   :
                   <div className="camp_list">
                       <div className="d-flex align-items-center justify-content-between dir_camp_card">
                       <p>No Program Found</p>
                       </div>
                    </div> 
               } 


               {
                    directorPrograms.length ? 
                    <div className="d-flex justify-content-center mt-5">
                    <PaginatedComponent
                        activePage={pageNo}
                        itemsCountPerPage={10}
                        totalItemsCount={count}
                        pageRangeDisplayed={4}
                        onPageChange={handlePageChange}/>
                </div>  : ''
                }    
                        
            </div>

            



            <Modal
                show={selectDateModal}
                dialogClassName="remove_header_line"
                onHide={() => setSelectDateModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DatePicker onChange={handleDateChange} style={{width: '100%'}} />
                </Modal.Body>
            </Modal>


        </div>
    )
}


const mapStateToProps = state => {
    let campCode = state.user.userProfile.campCode;
    let { isChildAction } = state.program;
    let { userProfile } = state.user
    
    return {
        state,
        campCode,
        isChildAction,
        userProfile
    };
}
  
const mapDispatchToProps = dispatch => ({
    dProgramModalAction: () => dispatch(dProgramModalAction()),
    filterCabinParamsAction: params => dispatch(filterCabinParamsAction(params)),
    sProgramsAction: params => dispatch(sProgramsAction(params)),
    childAction: () => dispatch(childAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DirectorCamp);


