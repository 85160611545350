import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import settings from '../../config/settings';


const Registrationsuccess = () => {
    const location = useLocation();
    const data = location.state;
    return(
        <div className="body_container">
                {settings.api.gogleTagManagerUrl && data?.isSportsInternationalCamp &&
                    <iframe
                        src="https://footballcamps.com/registrationsuccess/"
                        style={{ display: 'none' }} 
                        title="Background Webpage"
                    />
                }
                 <div className="container booking_success">
                     <img src={require('../../assets/img/booking-done.png')} />
                     <h2>Congrats!</h2>
                     <p>Please add the <b>free “CampsEZ” mobile app,</b> on the Apple or Android store. Follow the links or search “CampsEZ” on the stores. This will help the program providers keep you updated of news you need to know and if any change occur.</p>
                     <div className="apps_links mt-4 mb-4">
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.vic.campsez"><img src={require('../../assets/img/google-play.png')} /></a>
                        <a target="_blank" href="https://apps.apple.com/us/app/campsez/id1521620775"><img src={require('../../assets/img/app-store.png')} /></a>
                    </div>
                     <p>Congratulations your registration has been confirmed. A confirmation email has been sent to you. If you do not receive this email within 24 hrs please contact us at the phone number or email at the top left of this page</p>
                     
                    <Link className="btn btn-primary rounded-0 pl-4 pr-4 mt-5" to="/camps">Continue Exploring</Link>
                 </div>

                 <Helmet>
                    <script>
                    {`
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '160201611266653');
                        fbq('track', 'PageView');
                    `}
                    </script>
      </Helmet>
        </div>
    )
}

export default Registrationsuccess;