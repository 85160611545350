import React, {  useEffect } from 'react';
import { connect } from 'react-redux';
import { Empty } from 'antd';
import { toggleMapViewModal } from '../../store/ui';
import { filterProgramsAction, programLoaderActiveAction } from '../../store/program';
import { fFilterTypeAction, fPageNoAction,clearAllFitlerAction } from '../../store/pFilter';
import CampCard from './CampCard';
import MainFilter from '../home/MainFilter';
import Filters from './Filters';
import { ProgramLoader } from '../reuse/SVG';
import PaginatedComponent from '../common/Pagination/PaginationComponent';


const SpecialDeals = props => {


    useEffect(() => {
        props.programLoaderActiveAction()
        props.clearAllFitlerAction();

        return () => {
            props.clearAllFitlerAction();
          } 
    }, [])



    useEffect(() => {
        let params = { specialDeals: true }
        props.filterProgramsAction(params);
    }, [props.filterType, props.pageNo])


    const handleFilterType = val => {
        props.fFilterTypeAction(val);
    }

    const handlePageChange = page => {
        props.fPageNoAction(page);
    }

    return(
        <div className="body_container">

            <div className="container">
                <div className="d-flex align-items-center justify-content-between section_header">
                    <h2 className="m-0">Featured Special Deals</h2>

                    <div><button disabled={props.programs.length ? false : true} onClick={props.toggleMapViewModal} title={props.programs.length ? '' : 'No program available'} className="btn btn-secondary pl-5 pr-5"><i className="icon-map" /> Map View</button></div>
                </div>
            </div>




            <div className="container">
                <div className="row">


                    <div className="col-lg-4">
                        <MainFilter hideCategory={true} />
                        <Filters />
                    </div>


                    <div className="col-lg-8">
                        <div className="cat_btn_group">
                            <span onClick={() => handleFilterType('')} className={`mr-2 btn btn-outline-dark ${props.filterType == '' ? 'active' : ''}`}>All</span>
                            <span onClick={() => handleFilterType(1)} className={`mr-2 btn btn-outline-dark ${props.filterType == 1 ? 'active' : ''}`}>Popular</span>
                            <span onClick={() => handleFilterType(2)} className={`mr-2 btn btn-outline-dark ${props.filterType == 2 ? 'active' : ''}`}>Nearby</span>
                            <span onClick={() => handleFilterType(3)} className={`mr-2 btn btn-outline-dark ${props.filterType == 3 ? 'active' : ''}`}>EZ Pro Rating</span>
                            <span onClick={() => handleFilterType(4)} className={`mr-2 btn btn-outline-dark ${props.filterType == 4 ? 'active' : ''}`}>Featured</span>
                        </div>


        

                        <div className="camps_list" id="camps_lists">
                            {
                                props.loader ? 
                                    <div className="program_loader"><ProgramLoader /></div> 
                                    :
                                    props.programs.length ? props.programs.map(program => <CampCard isSD={true} key={program._id} program={program} />) : <div className="cm_empty"><Empty description="Please broaden your search by entering fewer fields in the search box." /> </div>
                            }

                        </div>

                        {
                            props.programs.length ? 
                            <div className="d-flex justify-content-center mt-5">
                                <PaginatedComponent
                                    activePage={props.pageNo}
                                    itemsCountPerPage={props.limit}
                                    totalItemsCount={props.totalCount}
                                    pageRangeDisplayed={4}
                                    onPageChange={handlePageChange}/>
                            </div>  : ''
                        }


                    </div>
                </div>
            </div>




        </div>
    )
}

/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let {programs, loader, totalCount} = state.program;
    let { filterType, pageNo, limit } = state.pFilter;
    return {
        loader,
        programs,
        filterType,
        pageNo,
        limit,
        totalCount
    };
}
  
const mapDispatchToProps = dispatch => ({
    toggleMapViewModal: () => dispatch(toggleMapViewModal()),
    programLoaderActiveAction: () => dispatch(programLoaderActiveAction()),
    filterProgramsAction: params => dispatch(filterProgramsAction(params)),
    fFilterTypeAction: params => dispatch(fFilterTypeAction(params)),
    fPageNoAction: params => dispatch(fPageNoAction(params)),
    clearAllFitlerAction: () => dispatch(clearAllFitlerAction()),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(SpecialDeals);
