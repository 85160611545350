import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import Tab from '../../assets/img/tab.png';
import Robot from '../../assets/img/robot.png';
import AddCarsaul3 from '../../assets/img/add-carsaul-3.png';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};


const Card = props => (
    <div className="cm_card">
        <div className="img_wrap text-center">
            <img className="img-fluid" src={props.data.img} />
            <div className="text_wrapper">
                <h4 className="pre-line white" >{props.data.text}</h4>
            </div>
        </div>
    </div>
)


const AdvertisingCarousel = props => {
    return(
        <div className="container ">
            {props.isFeatured ? <p className="text-center cm_color_white">Get inspiration for your next activity</p> : ''}


            <Slider {...settings}>
                {loop.map(item => <Card key={item.index} {...props} data={item} />)}
            </Slider>
        </div>

    )
}

export default AdvertisingCarousel;

const loop = [
    {
        index: 1,
        text:"Display \n Advertising",
        img: Tab
    },

    {
        index: 2,
        text:"Creative \n Partnerships",
        img: Robot
    },

    {
        index: 3,
        text:"Sponsored \n Listings",
        img: AddCarsaul3
    },

    {
        index: 1,
        text:"Display \n Advertising",
        img:Tab
    },

    {
        index: 2,
        text:"Creative \n Partnerships",
        img:Robot
    },

    {
        index: 3,
        text:"Sponsored \n Listings",
        img:AddCarsaul3
    }

]