import React, {Fragment, useState} from 'react';
import {Link} from 'react-router-dom';
import { editcounselor, userStatusChangeService } from '../../services/directorServices'
import { notification } from 'antd';
import { RoundLoader } from '../reuse/SVG';
import { Modal } from 'react-bootstrap';
import { convertDateToCustomFormat } from '../../helper';

const CounselorCard = (props) => {
    const {_id, name, profileImage, status, counselorDetails }                    = props.Details;
    const [allowedfeed, setAllowedFeed]                         = useState(props.Details.counselorAllowFeed)
    const [actionloading, setActionLoading]                     = useState(false);
    const [deletecounsoler, setDeleteCounsoler]                 = useState(false);
    const [removecounsolerfolder, setRemovecounsolerfolder]     = useState(false);
    const [cStatus, setCStatus]                                 = useState(status);


    const handelFeedAction = type => {
            setActionLoading(true);
            let form_data = new FormData();
            form_data.append('counselorId',props.Details._id)

            if(type==1){
                form_data.append('counselorAllowFeed',1)
            }else{
                form_data.append('counselorAllowFeed',2)
            }

            editcounselor(form_data).then(res=>{
                if(res?.data?.statusCode){
                    if(type==1){
                        setAllowedFeed(1)
                    }else{
                        setAllowedFeed(2)
                    }
                notification.open({
                    description:
                      'Personnel feeds setting updated',
                });
                  setActionLoading(false);
                }
                else{
                    setActionLoading(false);
                    notification.open({
                        description:
                          'Please try after sometime',
                      });  
                }  
                 
            })
    }


    const handleStatusChange = () => {
        let params = {userId: _id, status: cStatus == 1 ? 2 : 1}
        setCStatus(cStatus == 1 ? 2 : 1)
        userStatusChangeService(params) 
    }

    


    return(
        <div className="d-flex align-items-center counselor_card">


<Modal
            show={deletecounsoler}
            animation={false}
            dialogClassName="folder_model"
            size="lg"
            centered
            >
            <Modal.Body>
                <span className="modal_close" onClick={()=>{setDeleteCounsoler(false)}}><i className="icon-cross" /></span>
                <div className="text-center p-5 pickup_modal_body">
                    <p>Are you sure you want to delete this personnel</p>
                </div>
                <div className="text-center p-5"> 
                <button  onClick={()=>{setDeleteCounsoler(false)}} style={{width: '120px'}} className="btn btn-light rounded-0">No</button>
                <button onClick={()=>{props.DeletecounselorAction(_id); setDeleteCounsoler(false)}}   style={{width: '120px'}} className="btn btn-primary ml-3">Yes</button>
                </div>
            </Modal.Body>
            </Modal>


            <Modal
            show={removecounsolerfolder}
            animation={false}
            dialogClassName="folder_model"
            size="lg"
            centered
            >
            <Modal.Body>
                <span className="modal_close" onClick={()=>{setRemovecounsolerfolder(false)}}><i className="icon-cross" /></span>
                <div className="text-center p-5 pickup_modal_body">
                    <p>Are you sure you want to remove this personnel From Folder</p>
                </div>
                <div className="text-center p-5"> 
                <button  onClick={()=>{setRemovecounsolerfolder(false)}} style={{width: '120px'}} className="btn btn-light rounded-0">No</button>
                <button onClick={()=>{props.removeFromFolderAction(_id); setRemovecounsolerfolder(false)}}   style={{width: '120px'}} className="btn btn-primary ml-3">Yes</button>
                </div>
            </Modal.Body>
            </Modal>            
 
                <div className="mr-3">
                    <Link to={`/personnel/${_id}`}> <img src={profileImage ? profileImage :require('../../assets/img/camps-user.png')} /></Link>
                </div> 


                <div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>        
                    <h4> <Link to={`/personnel/${_id}`}>{name}</Link>
                        <div className="cm_swich_wrap">
                            <input onChange={handleStatusChange} checked={cStatus == 1 ? true : false} type="checkbox" />
                            <span />
                        </div>
                    </h4>
                    <span style={{
                        marginLeft:"10%",
                    }}>{props.Details?.lastLoginTime ? convertDateToCustomFormat(props.Details.lastLoginTime)  : "--/--/---- --:--"}</span>
                </div>
                    <div className="uploaded__test">
                        <p>Signature 
                        {
                            counselorDetails?.signatureImg ? 
                            <svg viewBox="0 0 367.805 367.805"><path fill="#3BB54A" d="M183.903,0.001c101.566,0,183.902,82.336,183.902,183.902s-82.336,183.902-183.902,183.902 S0.001,285.469,0.001,183.903l0,0C-0.288,82.625,81.579,0.29,182.856,0.001C183.205,0,183.554,0,183.903,0.001z"/><polygon fill="#D4E1F4" points="285.78,133.225 155.168,263.837 82.025,191.217 111.805,161.96 155.168,204.801 256.001,103.968" /></svg>
                            : <svg viewBox="0 0 512 512"><path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="#f44336"/><path d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" fill="#fafafa"/></svg>
                        }
                        </p>
                        <p>Training Guide
                        {
                            counselorDetails?.readTrainingGuide == 1 ? 
                            <svg viewBox="0 0 367.805 367.805"><path fill="#3BB54A" d="M183.903,0.001c101.566,0,183.902,82.336,183.902,183.902s-82.336,183.902-183.902,183.902 S0.001,285.469,0.001,183.903l0,0C-0.288,82.625,81.579,0.29,182.856,0.001C183.205,0,183.554,0,183.903,0.001z"/><polygon fill="#D4E1F4" points="285.78,133.225 155.168,263.837 82.025,191.217 111.805,161.96 155.168,204.801 256.001,103.968" /></svg>
                            : <svg viewBox="0 0 512 512"><path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="#f44336"/><path d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" fill="#fafafa"/></svg>
                        } 
                        </p>
                    </div>
                    <div>Allowed to post on Feeds
                        <div className="inline_ans">
                            {
                                actionloading ? (
                                    <div className="feed_actions_counsloer">
                                        <span className="min_loader">
                                            <RoundLoader />
                                        </span>
                                    </div>
                                ) : (
                                    <Fragment>   
                                        <div className="check_box_wrapper cm_dark_check">
                                            <input
                                                onChange={() => handelFeedAction(1)}
                                                type="radio"
                                                checked={allowedfeed == 1 ? 'checked' : ''} />
                                            <span className="check_box"><span></span>Yes</span>
                                        </div>
                                        <div className="check_box_wrapper cm_dark_check">
                                            <input
                                                type="radio"
                                                onChange={() => handelFeedAction(2)}
                                                checked={allowedfeed == 2 ? 'checked' : ''} />
                                            <span className="check_box"><span></span>No</span>
                                        </div>
                                    </Fragment>
                                )    
                            }
                        </div>
                    </div>
                </div>
                   
                <div className="ml-auto text-right">
                    {
                        props.removeFromFolderAction ? <button onClick={()=>{setRemovecounsolerfolder(true)}} className="btn btn-primary mb-1">Remove From Folder</button>:''
                    }
                    <Link className="btn btn-primary"
                        to={{     
                            pathname: '/inbox',
                            user:{_id, name, profileImage,  "type":1},
                           
                        }} >Message</Link>
                </div> 

               { props.removeFromFolderAction ? '' : <div className="remove_counselor"><i onClick={()=>{setDeleteCounsoler(true)}} className="icon-delete" /></div>} 
        </div>
    )   
}

export default CounselorCard;