import React, {useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { compose, withProps } from 'recompose';
import { Modal } from 'react-bootstrap';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import moment from 'moment';
import { Redirect } from "react-router-dom";
import { viewProgramDetail } from '../../services/programService';
import { gradeArr2, getExperience, getProgramType, getFacility, dateFormatfordays, getHtml, urlify } from '../../helper';
import { LogoWhite, ProgramLoader,CardLoader } from '../reuse/SVG';
import { toggleMapViewModal } from '../../store/ui';

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};


const MapComponent = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCdDH9dkoLecpVX7gNRSkYMMV1eUfQ7VE0&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `180px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )((props) =>
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{lng: props.geoLocation ? props.geoLocation[0] : 0, lat: props.geoLocation ? props.geoLocation[1] : 0 }}
      >
            <Marker
              position={{lng: props.geoLocation ? props.geoLocation[0] : 0, lat: props.geoLocation ? props.geoLocation[1] : 0 }}
              icon={{
                url: require('../../assets/img/mapIcon.svg')
                }}
            >
            </Marker>
    </GoogleMap> 
  );


const CampDetail = props => {

    const[loader, setLoader]                                = useState(true);
    const[programactionloader, setProgramActionLoader]      = useState(false);
    const[numberofCampers, setNumberOfCampers]              = useState(1);
    const[addtocartModal, setaddtocartModal]                = useState(false);
    const[campDetails, setCampDetails]                      = useState([]);
    const[discountAmount, setDiscountAmount]                = useState();
    const[campPrograms, setCampPrograms]                    = useState([]);
    const[additionalExp, setAdditionalExp]                  = useState();
    const[redirect, setRedirect]                            = useState(false);
    const[isFavouritecamp, setIsFavourite]                  = useState();
    const[isFollowed, setIsFollowed]                        = useState();
    const[btnloder, setBtnLoader]                           = useState(false);
    const[isAddedToCart, setIsAddedToCart]                  = useState(false);
    const[isConfirmed, setIsConfirmed]                      = useState(false);


    useEffect(() => {
        if(props.mapViewModal){
            props.toggleMapViewModal()
        }
    }, [])


    useEffect(() => {
        let userId = props.userID;
        let params ='';
       if(userId){
          params = `?userId=${userId}&programId=${props.match.params.id}`;    
       }else{
        params = `?programId=${props.match.params.id}`; 
       }

        let program_details = viewProgramDetail(params);
        program_details.then((res)=>{
            setLoader(false)  
            if(res?.data?.statusCode){
                setCampDetails(res.data.responseData.result.programData);
                setCampPrograms(res.data.responseData.result.campPrograms);
                setIsFavourite(res.data.responseData.result.programData.isFavourite)
                setIsFollowed(res.data.responseData.result.programData.isFollowed)

                let registrationStataus = 'booking';
                
                let registerOpenDate = res.data.responseData.result.programData.registerOpenDate
                let registrationCloseDate = res.data.responseData.result.programData.registerCloseDate 
                
                let dateEarlyRegisterOpen = res.data.responseData.result.programData.dateEarlyRegisterOpen;
                let dateEarlyRegisterClosed = res.data.responseData.result.programData.dateEarlyRegisterClosed;
                
                let dateLateRegisterOpen = res.data.responseData.result.programData.dateLateRegisterOpen;
                let dateLateRegisterClosed = res.data.responseData.result.programData.dateLateRegisterClosed;
                
                let time = new Date();
                let todaydate = time.getTime();

                if(dateEarlyRegisterOpen!==undefined && todaydate<dateEarlyRegisterOpen)
                {
                    registrationStataus = 'commingsoon';
                }else{
                    if(todaydate<dateEarlyRegisterOpen)
                    {
                        registrationStataus = 'commingsoon'; 
                    }
                    if(todaydate > dateEarlyRegisterClosed && todaydate<registerOpenDate)
                    {
                        registrationStataus = 'commingsoon';  
                    }
                }

                if(dateLateRegisterClosed!==undefined && todaydate > dateLateRegisterClosed)
                {
                    registrationStataus = 'closed';  
                }
                else
                {
                    if(todaydate > dateLateRegisterClosed)
                    {
                        registrationStataus = 'closed'; 
                    }
                    if(todaydate < dateLateRegisterOpen && todaydate > registrationCloseDate)
                    {
                        registrationStataus = 'commingsoon';    
                    }  
                }
                



                let tax = res.data.responseData.result.programData.tax;
                let registrationfee = res.data.responseData.result.programData.registrationFee;
                let convenienceFee = res.data.responseData.result.programData.convenienceFee;
                let discounttype = res.data.responseData.result.programData.discountType;
                let discountamount = res.data.responseData.result.programData.discount;
                let campamount = '';
                let taxamont  = res.data.responseData.result.programData.taxPrice;
                if(discounttype==1)
                {
                    campamount = registrationfee + discountamount  - taxamont - convenienceFee;
                    
                }
                else{
                    campamount = registrationfee + discountamount  - taxamont - convenienceFee;
                    discountamount = registrationfee*discountamount/100;
                }
                if(discountamount!==0)
                {
                    setDiscountAmount(discountamount.toFixed());
                }
               
               
                if(res.data.responseData.result.programData.isCartConfirmed){
                    setIsConfirmed(true)   
                }
                if(res.data.responseData.result.programData.isAddedToCart){
                    setIsAddedToCart(true)
                }
                let additionexp = res.data.responseData.result.programData.additionalExp;
                
                let additionalobj ={}
                if(additionexp !== undefined)
                {
                additionexp.map((item,index) => {
                    let key = index;
                    additionalobj[key] = {"_id": item._id, "quantity":0, "price":item.price,"title": item.title}
                    if(item.description !== undefined)
                    {
                        additionalobj[key].description = item.description;   
                    }
                })
                setAdditionalExp(additionalobj);
                }
                }            
        })

       
       
    }, [])

     let { preList } = props;

    /***Additional Exp***/
    const addAdditionalExp = (additonexpID, index, type) =>{
        let additionalExplist = {...additionalExp};
        if(type=="increment")
        {
            let prequantity = additionalExplist[index].quantity;
            additionalExplist[index].quantity = prequantity+1;
        }
        else
        {
            let prequantity = additionalExplist[index].quantity;
            if(prequantity!==0)
            {
                additionalExplist[index].quantity = prequantity-1;
            }
        }
        setAdditionalExp(additionalExplist);
    }
  
  


    if (redirect)
    return (<Redirect to='/cart' />)
  

    return(
        
        <div className="body_container program_page">

            {loader ? <div className="page_loader"><ProgramLoader /></div> : ''}

            <div className="container">
                <Modal
                    dialogClassName="h_auto"
                    show={addtocartModal}
                    animation={false}
                    centered
                >
                <Modal.Body>
                    <div className="p-5">
                        <h3 className="text-center mb-4">Success!</h3>
                        <p className="text-center">Successfully added to cart </p>
                        <button onClick={()=>setaddtocartModal(false)}className="btn btn-primary btn-block mt-2">Ok</button>
                    </div>
                </Modal.Body>
                </Modal>


                <div className="d-flex justify-content-between program_head">
                    <div>
                    <h2><img src={campDetails.campLogo} />{campDetails.campName} - {campDetails.programName}</h2>
                    </div>

                </div>


                <div className="row">
                    <div className="col-lg-8 prog_main">

                        <div className="prog_media prog_media_desk">
                       
                        {programactionloader ? <CardLoader/>: ''}
                            {campDetails.programImages && campDetails.programImages.length ? <Slider {...settings}>
                                                {campDetails.programImages.filter(i => !i.viewType).map(item => (
                                                        <div className="prog_slide_img" key={item._id}>
                                                            <img src={item.url} className="img-fluid" />
                                                        </div>
                                                    ))}
                                                </Slider> : <div className="no_img"><LogoWhite /></div>
                            }                  
                        </div>


                        <div className="prog_media prog_media_mob">
                       
                        {programactionloader ? <CardLoader/>: ''}
                            {campDetails.programImages && campDetails.programImages.length ? <Slider {...settings}>
                                                    {campDetails.programImages.filter(i => i.viewType).map(item => (
                                                        <div className="prog_slide_img" key={item._id}>
                                                            <img src={item.url} className="img-fluid" />
                                                        </div>
                                                    ))}
                                                </Slider> : <div className="no_img"><LogoWhite /></div>
                            }                  
                        </div>
                        <div className="d-flex justify-content-between mt-3 mb-2 prog_d">
                            <h3>
                                {moment.utc(campDetails.startDate).format('MMMM')} {moment.utc(campDetails.startDate).format('DD')}-{moment.utc(campDetails.endDate).format('DD')} ({dateFormatfordays(campDetails.startDate, campDetails.endDate)})
                            </h3>


                            
                            <div className="prog_price">
                                <h3><b>{campDetails !==undefined && campDetails.registrationFee ? `Price : $${campDetails.registrationFee.toFixed(2)}` : 'Free'}</b></h3>
                                

                            </div>
                        </div>

                        <p>{getHtml(campDetails.aboutProgram)}</p>



                    </div>
                    <div className="col-lg-4">
                        {campDetails.isOnline ? 
                            (<h2 className="cm_on_line">Online Program</h2>)
                            :
                            (<div className="camp_detail_map">
                                { campDetails.geoLocation ? <MapComponent geoLocation={campDetails.geoLocation} {...props} /> : ''}
                                
                                <div className="camp_detail_address">
                                    {campDetails.city ? <p className="m-0">{`${campDetails.addressLine1} ${campDetails.city} ${campDetails.state}`}</p> : ''}
                                </div>
                            </div>)}
                        

                        <h3>Sample Schedule</h3>

                        <div className="daily_schedule">
                            <ul>{campDetails.schedule && campDetails.schedule.length ? campDetails.schedule.map(item => (
                                <li key={item.time}><b>{item.time}</b> {item.activity}</li> 
                            )) : <li>Not updated</li>}
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="mt-4 mb-4 program_detail">
                    <h3>Program Details</h3>
                    <div className="row mt-3">
                        <div className="col-lg-4">
                        {campDetails.districtName ? <p><b>District Name: </b>{campDetails.districtName}</p> : ''}
                            <p><b>Age Group: </b>{campDetails.minAge} - {campDetails.maxAge}</p>
                            <p><b>Sex: </b> 
                                        {campDetails.gender == 1 ? 'Male' : ''}
                                        {campDetails.gender == 2 ? 'Female' : ''}
                                        {campDetails.gender == 3 ? 'Co-Ed' : ''}</p>
                            <p className="last_dot"><b>Grade: </b> {campDetails.grade && gradeArr2.filter(el => campDetails.grade.find(e => el.value == e )).sort((a, b) => a.count - b.count).map(item => <Fragment key={item.value}>{item.value}<span>,</span> </Fragment>)}</p>
                            <p className="last_dot"><b>Meals Provided:</b> {campDetails.meals && campDetails.meals.length ? campDetails.meals.map(item => <Fragment key={item}>{item ? item : 'None'}<span>,</span> </Fragment>) : 'None'}</p>
                        </div>
                        <div className="col-lg-4 cm_bordered">
                            <p><b>Check in Time: </b>{campDetails.checkInTime}</p>
                            <p><b>Check out Time: </b>{campDetails.checkOutTime}</p>
                            <p><b>Type: </b>{campDetails.programType ? getProgramType(campDetails.programType) : ''}</p>
                            
                        </div>
                        <div className="col-lg-4">
                            <p><b>Experience: </b>{campDetails.expLevel ? getExperience(campDetails.expLevel) : ''}</p>
                            <p><b>Category: </b>{campDetails.categoryName}</p>
                            <p><b>Travel: </b>{campDetails.travel == 1 ? '' : 'Not'} Included</p>
                            <p><b>T-Shirt Included: </b>{campDetails.tShirt == 1 ? 'Yes' : 'No'}</p>
                            <p><b>Program Code: </b>{campDetails.programCode}</p>
                        </div>
                    </div>
                    <p>{getHtml(campDetails.programIntroduction)}</p>
                  

                </div>



    
           


                <div className="sm_section mt-5">
                    <h3>Instruction, Equipment & Facility</h3>
                    <ul>
                        <li>Instruction :<p className="pl-4">{getHtml(campDetails.programInstruction)}</p></li>
                        <li>Equipment Needed : <p className="pl-4">{campDetails.equipment}</p></li>
                        <li>Facility : <p className="pl-4">{campDetails.facility ? getFacility(campDetails.facility) : ''}</p></li>
                    </ul>
                </div>

                <div className="sm_section">
                    <h3 className="mb-3">Important Notes</h3>
                    <p>{getHtml(campDetails.impNotes)}</p>
                </div>

                {campDetails.travel == 1 ?
                            <div className="sm_section">
                                <h3 className="mb-3">Travel Info </h3>
                                <p>{getHtml(campDetails.travelInfo)}</p>
                            </div>:''
                        }


                {campDetails.additionalExp !== undefined && campDetails.additionalExp.length > 0 ?
                <div className="sm_section">
                    <h3>Additional Experience</h3>
                    {
                        campDetails.additionalExp ? campDetails.additionalExp.map((item, index) => (
                            <div style={{color: item.color}} className="additionalExp" key={item._id}>
                                <h5 style={{color: item.color}}>{getHtml(item.title)}</h5>
                                <p className="mb-2"><b style={{color: item.color}}>Price :</b> ${item.price}</p>
                                <p><div><b style={{color: item.color}}>Description</b></div> {getHtml(urlify(item.description))}</p>
                            </div>
                        )) : ''
                    }
                </div>:''
                }


                        
    

            </div>



        </div>

   )
}




/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { programDetail, preList } = state.program;
    let { mapViewModal } = state.ui;
    let { loggedIn } = state.user;
    let userID =false;

    if(state.user.userProfile!== undefined)
    {
        userID = state.user.userProfile._id;
    }
    return {
        mapViewModal,
        programDetail,
        preList,
        loggedIn,
        userID
    };
}


const mapDispatchToProps = dispatch => ({
    toggleMapViewModal: () => dispatch(toggleMapViewModal()),
});

  
export default connect(mapStateToProps, mapDispatchToProps)(CampDetail);
