import React, {useState, useEffect} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from "react-router-dom";
import {notification} from 'antd';
import {getUserRequestList, acceptrejectfriendrequest} from '../../../services/userServices'
import { ProgramLoader, RoundLoader } from '../../reuse/SVG';

const FriendRequests = props => {

    const [requestList, setRequestList]         = useState([]);
    const [loading, setLoading]                 = useState(true);   
    const [acceptloder, setAcceptLoder]         = useState(false);   
    const [rejectLoder, setRejectLoder]         = useState(false);   
    const [selectedCamper, setSelectedCamper]   = useState('');    

    useEffect(()=>{
        let params ='/?limit=10'
        getUserRequestList(params).then((res)=>{
           if(res?.data?.statusCode)
           {
            setRequestList(res.data.responseData.result);
            setLoading(false)
           }
           else
           {
            setLoading(false)
           }
        })
    },[])



    const acceptReject =(friendId, type) =>{
        let params2 ='/?limit=10'
        setSelectedCamper(friendId);
        if(type==1)
        {
            setAcceptLoder(true);
            let params={"friendId": friendId, "action":1}
            acceptrejectfriendrequest(params).then((res)=>{
                setAcceptLoder(false);
                if(res?.data?.statusCode)
                {
                  
                let friend_list = [...requestList];
                let freindIndex = friend_list.findIndex(camper => camper._id == friendId)
                friend_list[freindIndex].accepted ='Yes';
                setRequestList(friend_list);
                }else
                {
                    
                }
            })

            
        }
        else
        {
           
            let params={"friendId": friendId, "action":0}
            acceptrejectfriendrequest(params).then((res)=>{
                setRejectLoder(false);
                getUserRequestList(params2).then((res)=>{
                    if(res?.data?.statusCode)
                    {
                     setRejectLoder(false);
                     setRequestList(res.data.responseData.result);
                    }
                 })
                
            })
        }
    }

    
    return(
        <div className="card mb-4 pro_wrapper">                
            <div className="row">
                <div className="col-lg-12">
                    <h4>Friend Requests</h4>
                    {loading ? <div className="program_loader"><ProgramLoader /></div> : 
                      
                        <ul className="campers_list friend_list">
                            
                            {
                                requestList.length > 0 ?
                                requestList.map((camper)=>(
                                    <li key={camper._id} >
                                    <img src={camper.profileImage ? camper.profileImage : require('../../../assets/img/user.png')} />
                                    <p><b>Name: </b>{camper.name}</p>
                                    <div>

                                    {
                                        camper.accepted == 'Yes' ? <span className="cm_color_green">Friends</span>
                                        : 
                                        <React.Fragment>      
                                        {
                                        selectedCamper==camper._id && acceptloder ? <span className="min_loader text-right"><RoundLoader /></span> :  <button onClick={()=>{acceptReject(camper._id, 1)}} className="btn btn-primary rounded-0">Accept</button>  
                                        }
                                        {   
                                        selectedCamper==camper._id && rejectLoder ? <span className="min_loader text-right"><RoundLoader /></span> : <button onClick={()=>{acceptReject(camper._id, 0)}}  className="btn btn-primary rounded-0 ml-2">Cancel</button>
                                        }
                                        </React.Fragment>
                                    }    

                                    </div>
                                    </li>
                                ))

                                : 
                                <li>No Friend Request</li>
                            }


                        </ul>
                    }
                </div>
            </div>
        </div>
    )
}

export default FriendRequests;