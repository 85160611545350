import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { passwordSuccessModalAction } from '../../store/ui';
import { Link } from 'react-router-dom';


const PasswordSuccessModal = props => {

    return(
        <Modal
            dialogClassName="h_auto"
            show={props.passwordSuccessModal}
            animation={false}
            centered
        >
            <Modal.Body>
                <div className="p-5">
                    <h3 className="text-center mb-4">Success!</h3>
                    <p className="text-center">Password successfully updated!</p>
                    <Link className="btn btn-primary btn-block mt-4" onClick={() => props.passwordSuccessModalAction()} to='/'>Back to Sign In</Link>
                </div>
            </Modal.Body>
        </Modal>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { passwordSuccessModal } = state.ui
    return {
        passwordSuccessModal
    };
}
  
const mapDispatchToProps = dispatch => ({
    passwordSuccessModalAction: () => dispatch(passwordSuccessModalAction()),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(PasswordSuccessModal);