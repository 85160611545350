import { Empty } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { giftCardList } from '../../services/giftCardService'
import { ProgramLoader } from '../reuse/SVG';



const GiftCards = props => {
    const [giftCards, setGiftCards] = useState([]);
    const [loader, setLoader] = useState(true);

    const getGiftCards = () => {
        giftCardList().then(res => {
            if(res?.data?.statusCode){
                setGiftCards(res.data.responseData.result)
            }
            setLoader(false)
        })
    }


    useEffect(() => {
        getGiftCards();
    }, [])


    return(
        <div className="container">
            {loader ? <div className="page_loader"><ProgramLoader /></div> : ''}
                <div className="d-flex align-items-center justify-content-between section_header">
                    <h2>Gift Cards</h2>
                </div>


                <div className="row mt-4 mb-5">
                    {
                        giftCards.length ? giftCards.map(giftCard => (
                            <div className="col-lg-4 col-md-6" key={giftCard._id}>
                                <div className="gift_card" style={{backgroundImage: `url(${giftCard.giftcardimage})`}}>
                                    <Link to={`/gift-cards/${giftCard._id}`}>
                                        <img src={require('../../assets/img/logo-mini.svg')} className="logo_min" />
                                        <img src={require('../../assets/img/campsEZ-logo-white.svg')} className="logo_white" />
                                        <p>eGift Card</p>
                                        <h4>{giftCard.giftcardName}</h4>
                                        <div className="gift_card_price">$<h2>{giftCard.giftcardValue}</h2></div>
                                    </Link>
                                </div>
                            </div>
                        )) : <div className="cm_empty m-auto"><Empty description="Gift Card Not Found" /> </div>
                    }
                </div>

               
        </div>
    )
}

export default GiftCards;