import ApiInstance from './intercepter';
import API from '../config/api';
import { resHandle } from '../helper';


ApiInstance.defaults.headers.common['accessToken'] = localStorage.getItem('accessToken');
ApiInstance.defaults.headers.common['Authorization'] = `${API.AUTH}`;


export const signup = params => ApiInstance.post(API.USER_SIGNUP, params);

export const fastSignup = params => ApiInstance.post(API.USER_FAST_SIGNUP, params);


export const verifyemailotp = params => ApiInstance.post(API.VERIFY_EMAIL_OTP, params).then(res => {
    let { status, data } = resHandle(res);
    if(status){
        ApiInstance.defaults.headers.common['accessToken'] = `${data.accessToken}`;
        ApiInstance.defaults.headers.common['Authorization'] = `${API.AUTH}`;
    }
    return res;
});

export const verifyResetToken = params => ApiInstance.post(API.VERIFY_RESET_TOKEN, params).then(res => {
    let { status, data } = resHandle(res);
    return status;
});

export const verifyFastOtp = params => ApiInstance.post(API.VERIFY_FAST_EMAIL_OTP, params);


export const forgotPassword = params => ApiInstance.post(API.FORGOT_PASSWORD, params);


export const resetPassword = params => ApiInstance.post(API.RESET_PASSWORD, params);


export const resendOtp = params => ApiInstance.post(API.RESEND_OTP, params).then(res => {
    let { status, data } = resHandle(res);
    if(status){
        ApiInstance.defaults.headers.common['accessToken'] = `${data.accessToken}`;
        ApiInstance.defaults.headers.common['Authorization'] = `${API.AUTH}`;
    }
    return res;
});


export const login = params => ApiInstance.post(API.USER_LOGIN, params).then(res => {
    let { status, data } = resHandle(res);
    if(status){
        ApiInstance.defaults.headers.common['accessToken'] = `${data.accessToken}`;
        ApiInstance.defaults.headers.common['Authorization'] = `${API.AUTH}`;
    }
    return res;
});

export const fastLogin = params => ApiInstance.post(API.USER_FAST_LOGIN, params).then(res => {
    let { status, data } = resHandle(res);
    // if(status){
    //     ApiInstance.defaults.headers.common['accessToken'] = `${data.accessToken}`;
    //     ApiInstance.defaults.headers.common['Authorization'] = `${API.AUTH}`;
    // }
    return res;
});


export const logout = () => ApiInstance.post(API.USER_LOGOUT);


