import * as actionType from './actionsTypes';


const initialState = {
    openActivity: ''
}


/************************************************************************************/
// Program Reducer
/************************************************************************************/


const calendarReducer = (state = initialState, action) => {

    switch (action.type) {


        case actionType.OPEN_ACTIVITY:
            return {
                ...state,
                openActivity: action.payload
            }


       
            
        default:
            return state
    }
}




/************************************************************************************/
// All Program Actions
/************************************************************************************/


export const openActivityAction = payload => (dispatch => dispatch({ type: actionType.OPEN_ACTIVITY, payload }))

export default calendarReducer;