import React, { useState, useEffect } from 'react';
import { DatePicker, Row, Col } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';

const { MonthPicker } = DatePicker;

const CustomMonthPicker = ({handleDateChange}) => {
  const [value, setValue] = useState(null); // Moment value for the selected month/year
  const [inputValue, setInputValue] = useState(''); // Value for the manual input
  const [calendarOpen, setCalendarOpen] = useState(false); // State to control the visibility of the calendar

  const handleChange = (date, dateString) => {
    setValue(date); // Set the moment value
    setInputValue(dateString); 
    setCalendarOpen(false); 
    handleDateChange(date)
  };

  // Handle manual input change (MM/YY format)
  const handleInputChange = (e) => {
    let inputValue = e.target.value;
    const inputNumber = e.target.value.replace(/\D/g, "");
    const formattedNumber = inputNumber.replace(/(\d{2})(\d{1,2})?/, (_, mm, yy) => {
      return yy ? `${mm}/${yy}` : mm;
    });
    setInputValue(formattedNumber);
    

    // If input is valid, update value
    if (/^\d{0,2}\/?\d{0,2}$/.test(inputValue)) {
      const parsedDate = moment(inputValue, 'MM/YY', true);
      if (parsedDate.isValid()) {
        setValue(parsedDate);
        handleDateChange(parsedDate)
      }
    }
    
  };

  // Handle the blur event
  const handleBlur = () => {
    if (inputValue && !moment(inputValue, 'MM/YY', true).isValid()) {
      // Show error or do any necessary validation when input loses focus
    }
  };

  // Handle clicking the calendar icon to open/close the calendar
  const handleCalendarIconClick = () => {
    setCalendarOpen(true); // Open the calendar when the icon is clicked
  };

  // Sync input field when value changes
  useEffect(() => {
    if (value) {
      setInputValue(value.format('MM/YY')); // Sync input with the moment date value
    }
  }, [value]);

  return (
      <Row gutter={20}>
        <Col span={12}>
          <input
            type="text"
            className="form-control"
            placeholder="MM/YY"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleBlur}
            style={{ width: '100%' }}
            maxLength={5}
          />
        </Col>
        <Col span={6} style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
          <CalendarOutlined
            onClick={handleCalendarIconClick}
            style={{ fontSize: '20px', cursor: 'pointer' }}
          />
          {calendarOpen && (
            <MonthPicker
              value={value} 
              onChange={handleChange} 
              open={calendarOpen} 
              onOpenChange={(open) => setCalendarOpen(open)} 
              style={{
                position: 'absolute',
                top: '30px', 
                left: '0', 
                zIndex: 1000, 
              }} 
            />
          )}
        </Col>
      </Row>
  );
};

export default CustomMonthPicker;