import ApiInstance from './intercepter';
import API from '../config/api';
export const programListdata = params => ApiInstance.get(API.PROGRAM_LIST + '/?isSearched=0');
export const programSearchListdata = params => ApiInstance.get(API.PROGRAM_LIST+params);
export const weekListdata = params => ApiInstance.get(API.WEEKDATA_LIST+'/?stamp='+params);
export const camplockdata = params => ApiInstance.post(API.API_LOCK_URL , params);
export const campunlockdata = params => ApiInstance.post(API.API_UNLOCK_URL, params);
export const campremove = params => ApiInstance.post(API.API_REMOVE_URL, params);
export const campfavourite = params => ApiInstance.post(API.API_FAVOURITE_URL, params);
export const autoscheduleraddtocart = params => ApiInstance.post(API.AUTOSCHEDULER_CART, params);
