import React from 'react'
import GapMedicalInsuranceComponent from './GapMedicalInsuranceComponent'

const MedicalInsuranceComponent = ({ item,camperId, camperDetails, userName, userEmail, zipCode, startDate, endDate, insuranceState, userId, userLastName }) => {
    return (
        <GapMedicalInsuranceComponent
            key={`gap-medical-insurance-offer-${item._id}-${camperId}`}
            customer-state={insuranceState}
            customer-email-address={userEmail}
            customer-first-name={userName.split(' ')[0]}
            customer-last-name={userName.split(' ').length > 1 ? userName.split(' ')[0] : userLastName}
            customer-city="NA"
            customer-address="NA"
            customer-postal-code={zipCode}
            data-context-name={item.campName}
            data-context-name-id={camperId}
            data-context-event={item.programName}
            data-context-event-id={item.programId}
            coverage-start-date={startDate}
            coverage-end-date={endDate}
            participant-first-name={camperDetails.firstName}
            participant-last-name={camperDetails.lastName}
            participant-street-address="NA"
            participant-state={insuranceState}
            participant-birth-date={new Date(camperDetails.dob).toISOString().substring(0, 10)}
            programId={item.programId}
            campCode={item.campCode}
            userId={userId}
            coverage-context-description={`${item.campName} - ${item.programName}`}
        />
    )
}

export default MedicalInsuranceComponent
