import React, { useState, useEffect, Fragment } from 'react';
import { Calendar, TimePicker, DatePicker, notification } from 'antd';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';

import { CustomSelect, InputError } from '../reuse/FormControls';
import {
    counselorlist,
    addScheduleService,
    getScheduleService,
    singleScheduleDeleteService,
    scheduleDeleteService,
    scheduleCommitService,
    scheduleEditService,
    removeStaffService
} from '../../services/directorServices';
import { removeTime2, resHandle } from '../../helper';
import { Link } from 'react-router-dom';



const Schedule = props => {
    const [cmModal, setCmModal] = useState(false);
    const [cmViewModal, setViewCmModal] = useState(false);
    const [list, setList] = useState([]);
    const [staffList, setStaffList] = useState([]);
    const [isCopy, setIsCopy] = useState(false);
    const [copyDate, setCopyDate] = useState('');
    const [commitLoader, setCommitLoader] = useState(false);

    const [cmDate, setCmDate] = useState(new Date());
    //const [cmDate, setCmDate] = useState(moment().format('MM-DD-YYYY'));
    const [title, setTitle] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [desc, setDesc] = useState('');
    const [location, setLocation] = useState('');
    const [staff, setStaff] = useState();
    const [scheduleDate, setScheduleDate] = useState('');
    const [scheduleArr, setScheduleArr] = useState([]);


    const [titleErr, setTitleErr] = useState('');
    const [startTimeErr, setStartTimeErr] = useState('');
    const [endTimeErr, setEndTimeErr] = useState('');
    const [cmSingleEditModal, setCmSingleEditModal] = useState(false);
    const [editSId, setEditSId] = useState('');
    const [commitData, setCommitData] = useState('');


    let { UserType, campCode } = props.userProfile;



    const getSchedule = () => {
        let query = `?monthDate=${moment(cmDate).format('MM-DD-YYYY')}&campCode=${campCode}`;
        setList([]);
        getScheduleService(query).then(res => {
            let {status, data} = resHandle(res);
            if(status){
              
                if(data.result.length){
                    let temp = data.result.map(i => ({
                        ...i,
                        date: moment(i.date).format('MM-DD-YYYY')
                    }))
                    setList(temp.reverse())
                }else{
                    setList([]);
                }
             
            }
        })
    }


    useEffect(() => {
        getSchedule();
    }, [cmDate])




      const handleSelect = (e) => {
        let isView = list.filter(i => i.date == moment(removeTime2(e._d)).format('MM-DD-YYYY'));
 
        if(isView.length){
            setViewCmModal(true);
            setScheduleArr(isView);
            setScheduleDate(moment(e._d).format('MM-DD-YYYY'));
        }else {
            setCmModal(true);
            setScheduleDate(moment(e._d).format('MM-DD-YYYY')); 
        }

      }


      const handleClose = () => {
          setCmModal(false);
          setViewCmModal(false);
          setScheduleArr([]);
          setCopyDate('');
          setIsCopy(false);
          setTitle('')
          setStartTime('')
          setEndTime('')
          setDesc('')
          setLocation('')
          setStaff([])
          setEditSId('')
          setTitleErr('')
          setStartTimeErr('')
          setEndTimeErr('')
          setCmSingleEditModal(false)
      }

      const handleClose2 = () => {
        setCmSingleEditModal(false);
        setViewCmModal(false);
    }



      

      const getActiveClass = (data, commitedBy) => {
          let staffId = props.userProfile._id;
          
          if(data && data.length){
              let isHas = data.filter(i => i._id == staffId);
              if(isHas.length){
                  return 'cm_gold_color'
              }else{
                if(commitedBy){
                  return commitedBy === staffId ? 'self_asign' : 'cm_gold_color'
                }
            }
          }else{
            if(commitedBy){
              return commitedBy === staffId ? 'self_asign' : 'cm_gold_color'
            }
        }
         
      }



    const  disabledDate = (current) => {
        const listData = list.filter(i => i.date == moment(removeTime2(current._d)).format('MM-DD-YYYY'));
        if(current && current < moment().startOf('day')){
            if(listData.length == 0){
                return current && current < moment().startOf('day');
                return true;
            }else {
                return false
            }
        }else {
            return false
        }
        
        
    }




      const dateCellRender = (d) => {
        const listData = list.filter(i => i.date == moment(removeTime2(d._d)).format('MM-DD-YYYY'));
        return listData.length ? <div className="cal_box bg_dark" onClick={() => handleSelect(d)}>
        {listData.map(item => (
          <Fragment>
              {props.userProfile.UserType == 4 ?
                  <div key={item._id} className={getActiveClass(item.staffData, item.commitedBy)}>
                  {item.title}
                </div>
                :
                <div key={item._id}>
                  {item.title}
                </div>
              }
          </Fragment>
        ))}
      </div> : UserType == 4 ? '' : <div className="cal_box" onClick={() => handleSelect(d)}></div>
        

      }


      const getCounselorlist = () => {
        let params = `/`
            counselorlist(params).then(res => {
                let { status, data } = resHandle(res)
                if(status){   
                    setStaffList(data.result.counselor || []);
                }
            })
    }
    

    useEffect(()=>{
        getCounselorlist();
    },[]) 



    const handleValidate = () => {
        let validate = true;

        if(title.trim() == '' || title == 'undefined'){
            setTitleErr('Title is required');
            validate = false;
        }

        if(startTime == '' || startTime == 'undefined'){
            setStartTimeErr('Start time is required');
            validate = false;
        }

        if(endTime == '' || endTime == 'undefined'){
            setEndTimeErr('End time is required');
            validate = false;
        }

        return validate;
    }


    const handleAddMor = () => {
        setViewCmModal(false);
        setCmModal(true);
    }



    const addMore = () => {

        if(handleValidate()){
            let tempStaff = [];
            if(staff && staff.length){
                tempStaff = staff.map(i => i._id)
            }
            let params = {
                title,
                startTime,
                endTime,
                desc,
                location,
                staff: tempStaff,
                date: scheduleDate
            }
            

            let temp = [...scheduleArr];
            temp.push(params);
            setScheduleArr(temp);

            setTitle('')
            setStartTime('')
            setEndTime('')
            setDesc('')
            setLocation('')
            setStaff([])
        }

    }

    const handleSubmit = e => {
        e.preventDefault();
        handleClose();
        let temp = scheduleArr.filter(i => !i._id);
        let params = {
            scheduleData: JSON.stringify(temp),
            campCode
        }

        addScheduleService(params).then(res => {
            let {status, data} = resHandle(res);
            if(status){
                getSchedule();
                notification.open({
                    description: 'Schedule added successfully',
                });
            }
        })
    }


    const handleCopyDay = () => {
        let temp = scheduleArr.map(i => ({
            title: i.title,
            startTime: i.startTime,
            endTime: i.endTime,
            desc: i.desc,
            location: i.location,
            staff: i.staffData.length ? i.staffData.map(i => i._id) : [],
            date: copyDate 
        }))

        let params = {
            scheduleData: JSON.stringify(temp),
            campCode
        }
        handleClose();
        addScheduleService(params).then(res => {
            let {status, data} = resHandle(res);
            if(status){
                getSchedule();
                notification.open({
                    description: 'Copied Successfully',
                });
            }
        })

    }



    const handleSingleDelete = id => {
        let temp = [...scheduleArr];
        let result = temp.filter(i => i._id != id);
        setScheduleArr(result);

        let params = {
            requestId: id
        }

        singleScheduleDeleteService(params).then(res => {
            getSchedule();
            notification.open({
                description: 'Schedule deleted successfully',
            });
        })
    }

    const handleDeleteAll = () => {
        let params = {
            date: scheduleDate
        }
        handleClose();
        scheduleDeleteService(params).then(res => {
            getSchedule();
        })
    }


    const handleDelete = (item, ind) => {
        if(item._id){
            handleSingleDelete(item._id);
        }else{
            let temp = [...scheduleArr];
            temp.splice(ind, 1);
            setScheduleArr(temp);
        }
    }



    const handleCommit = item => {
        let params = {
            requestId: item._id
        }
        setCommitLoader(true);
        scheduleCommitService(params).then(res => {
            setCommitLoader(false);
            handleClose();
            getSchedule();
        })
    }


    const handleEditSingle = data => {
        setEditSId(data._id);
        setCmModal(false);
        setCmSingleEditModal(true);
        setViewCmModal(false);
        setTitle(data.title);
        setStartTime(data.startTime);
        setEndTime(data.endTime);
        setLocation(data.location);
        setDesc(data.desc);

        if(data.staffData.length){
            let temp = data.staffData.map(i => ({...i, label: i.name || i.email, value: i._id}))
            setStaff(temp)
        }

        setCommitData(data.commitData._id ? data.commitData : '');

    }




    const handleSubmit2 = e => {
        e.preventDefault();

        if(handleValidate()){
            let tempStaff = [];
            if(staff && staff.length){
                tempStaff = staff.map(i => i._id)
            }
            let params = {
                title,
                startTime,
                endTime,
                desc,
                location,
                staff: tempStaff,
                date: scheduleDate,
                requestId: editSId,
                campCode
            }

            handleClose();
            scheduleEditService(params).then(res => {
                let {status, data} = resHandle(res);
                if(status){
                    getSchedule();
                    getSchedule();
                    notification.open({
                        description: 'Schedule updated successfully',
                    });
                }
            })
        }
    }


    const handleDateChange = btn => {
        let temp =  moment(cmDate).subtract(btn == 1 ? 1 : -1, 'months').endOf('month');
        setCmDate(temp);
    }


    const handleDeleteCommitted = () => {
        setCommitData('');
        let params = {
            requestId: editSId
        }

        removeStaffService(params).then(res => {
            let {status, data} = resHandle(res);
            if(status){
                setEditSId('')
                getSchedule();
            }
        })
    }


    // const getDisabledTime = (_startTime) => {
    //     if(_startTime){
    //         let _hours = _startTime.split(':')[0]
    //         let _minutes = _startTime.split(':')[1].split(' ')[0];

    //         let _minutes_list = [];
    //         for (let i = 0; i <= _minutes; i++) {
    //             _minutes_list.push(i);
    //         }
    //         let _hours_list = [];
    //         for (let i = 1; i + (parseInt(_minutes) > 40 ? 0 : 1) <= _hours; i++) {
    //             _hours_list.push(i);
    //         }
    //         return {
    //             hours: _hours_list,
    //             minutes: _minutes_list
    
    //         }
    //     }
        
    // }



    const handleEndTimeSelect = e => {
        let _startTime = new Date(`01/01/2011 ${startTime}`).getTime();
        let _endTime = new Date(`01/01/2011 ${moment(e._d).format('h:mm a')}`).getTime();

        if(_startTime >= _endTime){
            setEndTimeErr('End time must be greater than start time');
            setEndTime('');
        }else{
            setEndTime(moment(e._d).format('h:mm a'));
            setEndTimeErr('');
        }
    }




    return(
        <div className="body_container pb-4">

            <Modal
                show={cmModal}
                animation={false}
                dialogClassName="schedule_modal"
                size="lg"
                centered>

                <Modal.Header>
                    <h3>Add Schedule</h3>
                    <div className='s_date'>{scheduleDate}</div>
                    <span className="modal_close" onClick={handleClose}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>

                    {scheduleArr.length ? <div>
                        {scheduleArr.map((item, i) => (
                            <div key={i} className="cm_sch_card">
                                <span className="icon-delete" onClick={() => handleDelete(item, i)} />
                                <h4>{item.title}</h4>
                                <div className='cm_sch_card_time'>
                                    <span>Start Time: {item.startTime}</span>
                                    <span>End Time: {item.endTime}</span>
                                </div>
                            </div>
                        ))}
                    </div> : ''}

                    
                    <form onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-lg-12 form-group'>
                                <label>Title*</label>
                                <input
                                    type='text'
                                    value={title}
                                    onChange={e => (setTitle(e.target.value), setTitleErr(''))}
                                    className={`form-control ${titleErr ? 'is-invalid' : ''}`} />
                                    <InputError  error={titleErr} />
                            </div>

                     
                            <div className="col-lg-6 form-group">
                                <label>Start Time*</label>
                                <TimePicker
                                    showNow={false}
                                    minuteStep={15}
                                    popupClassName="hide_space_"
                                    use12Hours format="h:mm a"
                                    value={startTime ? moment(startTime, 'h:mm a') : ''}
                                    onSelect={e => (setStartTime(moment(e._d).format('h:mm a')), setStartTimeErr(''), setEndTime(''))}
                                    className={`form-control ${startTimeErr ? 'is-invalid' : ''}`} />
                                    <InputError  error={startTimeErr} />
                            </div>


                        <div className="col-lg-6 form-group">
                            <label>End Time*</label>
                            <TimePicker
                                    disabled={!startTime}
                                    minuteStep={15}
                                    // disabledHours={() => startTime ? getDisabledTime(startTime).hours : []}
                                    // disabledMinutes={() => startTime ? endTime ? [] : getDisabledTime(startTime).minutes : []}
                                    showNow={false}
                                    popupClassName="hide_space_"
                                    use12Hours format="h:mm a"
                                    value={endTime ? moment(endTime, 'h:mm a') : ''}
                                    //onSelect={e => (setEndTime(moment(e._d).format('h:mm a')), setEndTimeErr(''))}
                                    onSelect={handleEndTimeSelect}
                                    className={`form-control ${endTimeErr ? 'is-invalid' : ''}`} />
                                    <InputError  error={endTimeErr} />
                   
                        </div>

                        <div className="col-md-12 form-group">
                            <label>Assign Staff</label>
                            <CustomSelect
                                value={staff}
                                isMulti
                                pHolder="Select a Staff"
                                change={e => setStaff(e)}
                                options={staffList.map(i => ({...i, value: i._id, label: i.name || i.email}))} />
                        </div>

                        <div className='col-lg-12 form-group'>
                            <label>Location</label>
                            <input
                                type='text'
                                value={location}
                                onChange={e => setLocation(e.target.value)}
                                className='form-control' />
                        </div>

                        

                        <div className='col-lg-12 form-group'>
                            <label>Description</label>
                            <textarea
                                value={desc}
                                onChange={e => setDesc(e.target.value)}
                                className='form-control' />
                        </div>

                        <div className='col-lg-12 d-flex justify-content-center'>
                            <span onClick={addMore} className='btn btn-secondary pl-4 pr-4'>Add</span>

                            {scheduleArr.length ? <button type="submit" className='btn btn-primary ml-3 pl-5 pr-5'>Save</button> : ''}
                        </div>


                        </div>
                    </form>
                </Modal.Body>
            </Modal>





            <Modal
                show={cmViewModal}
                animation={false}
                dialogClassName="schedule_modal"
                size="lg"
                centered>
                    <Modal.Header>
                    <h3>Schedule Detail</h3>
                    <div className='s_date'>{scheduleDate}</div>
                    <span className="modal_close" onClick={handleClose}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>

                    {scheduleArr.length > 1 ? (
                        <div className='text-right'>
                            {UserType == 4 ? '' : <span className='btn btn-primary sh_del' onClick={handleDeleteAll}><span className="icon-delete" /> Delete All</span>}
                        </div>
                    ) : ''}
                    
                    {scheduleArr.length ? <div>
                        {scheduleArr.map((item, i) => (
                            <div key={i} className="cm_sch_card">
                                <h4>{item.title}</h4>
                                <div className='cm_sch_action'>
                                    {UserType == 4 ? '' : <span className="icon-pancil" onClick={() => handleEditSingle(item)} />}
                                    {UserType == 4 ? item.commitedBy == props.userProfile._id ? <span className="btn btn-primary btn-sm">Committed</span> : <span onClick={() => commitLoader ? null : handleCommit(item)} className="btn btn-secondary btn-sm">Commit</span> : <span className="icon-delete" onClick={() => handleSingleDelete(item._id)} />}
                                    
                                    
                                </div>
                                <div className='cm_sch_card_time'>
                                    <span><b>Start Time:</b> {item.startTime}</span>
                                    <span><b>End Time:</b> {item.endTime}</span>
                                </div>
                                <div className='cm_sch_card_d'>
                                    {item.location ? <div><b>Location:</b> {item.location}</div> : ''}
                                    {item.desc ? <div><b>Description:</b> {item.desc}</div> : ''}
                                </div>
                                
                                {item?.staffData?.length ? <Fragment>
                                    <h6>Assigned</h6>
                                    <div className='cm_stf_wrapper'>
                                    {item.staffData.map(stf => <div className='cm_stf'>
                                        {UserType == 4 ? <span>
                                            <img src={stf.profileImage ? stf.profileImage : require('../../assets/img/camps-user.png')} />
                                            <small>{stf.name || stf.email}</small>
                                        </span> :
                                        <Link to={`/personnel/${stf._id}`}>
                                        <img src={stf.profileImage ? stf.profileImage : require('../../assets/img/camps-user.png')} />
                                        <small>{stf.name || stf.email}</small>
                                    </Link>
                                    }
                                        
                                    </div>)}
                                </div>
                                </Fragment> : ''}


                                {/* {UserType == 4 ? '' : item?.commitData?._id ? <Fragment>
                                    <h6 className='mt-4'>Committed By</h6>
                                    <div className='cm_stf_wrapper'>
                                        <div className='cm_stf'>
                                            <Link to={`/personnel/${item?.commitData._id}`}>
                                                <img src={item?.commitData.profileImage ? item?.commitData.profileImage : require('../../assets/img/camps-user.png')} />
                                                <small>{item?.commitData.name || item?.commitData.email}</small>
                                            </Link>
                                        </div>
                                    </div>
                                </Fragment> : ''} */}


                                {UserType == 4 ? '' : item?.commitData?.length ? <Fragment>
                                <h6 className='mt-4'>Committed By</h6>
                                    <div>
                                        {item?.commitData.map(itm => <div className='d-inline-block'>
                                            <div className='cm_stf_wrapper'>
                                                <div className='cm_stf'>
                                                    <Link to={`/personnel/${itm._id}`}>
                                                        <img src={itm.profileImage ? itm.profileImage : require('../../assets/img/camps-user.png')} />
                                                        <small>{itm.name || itm.email}</small>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>)}
                                    </div>
                                    
                                </Fragment> : ''}

                            </div>
                        ))}
                    </div> : ''}

                    {UserType == 4 ? '' : <div className='col-lg-12 d-flex justify-content-center'>
                        
                        {isCopy ? <div className='copy_day'>
                            <DatePicker
                                defaultPickerValue={moment(scheduleDate)}
                                onChange={e => setCopyDate(moment(e._d).format('MM-DD-YYYY'))} />
                            {copyDate ? <span onClick={handleCopyDay} className='btn btn-primary btn-sm'>Copy</span> : ''}
                            
                        </div> : <Fragment>
                            <span onClick={handleAddMor} className='btn btn-secondary pl-4 pr-4'>Add More</span>
                            <span onClick={() => setIsCopy(true)} className='btn btn-primary ml-3 pl-5 pr-5'>Copy Day</span>
                        </Fragment>
                        }
                        
                    </div>}

                    
                </Modal.Body>
            </Modal>



            <div className='container'>
                <div className="page_card_header border-0 m-0">
                    <h4>{UserType == 4 ? 'My Calendar' : 'Schedule Management'}</h4>
                </div>
            </div>
            <div className='container schedule_wrapper_container'>
                <div className='schedule_wrapper'>
                    <div className='calendar_month'>{moment(cmDate).format('MMMM YYYY')}</div>
                    <div className='calendar_action'>
                        <span onClick={() => handleDateChange(1)} className='calendar_prev'>Previous</span>
                        <span onClick={() => handleDateChange(2)} className='calendar_next'>Next</span>
                    </div>
                    

                    <Calendar
                        mode="month"
                        value={moment(cmDate)}
                        disabledDate={disabledDate}
                        onPanelChange={e => setCmDate(moment(e._d).format('MM-DD-YYYY'))}
                        dateCellRender={dateCellRender} />
                </div>
            </div> 















            <Modal
                show={cmSingleEditModal}
                animation={false}
                dialogClassName="schedule_modal"
                size="lg"
                centered>

                <Modal.Header>
                    <h3>Edit Schedule</h3>
                    <div className='s_date'>{scheduleDate}</div>
                    <span className="modal_close" onClick={handleClose2}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>

                    
                    <form onSubmit={handleSubmit2}>
                        <div className='row'>
                            <div className='col-lg-12 form-group'>
                                <label>Title*</label>
                                <input
                                    type='text'
                                    value={title}
                                    onChange={e => (setTitle(e.target.value), setTitleErr(''))}
                                    className={`form-control ${titleErr ? 'is-invalid' : ''}`} />
                                    <InputError  error={titleErr} />
                            </div>

                     
                            <div className="col-lg-6 form-group">
                                <label>Start Time*</label>
                                <TimePicker
                                    minuteStep={15}
                                    popupClassName="hide_space_"
                                    use12Hours format="h:mm a"
                                    value={startTime ? moment(startTime, 'h:mm a') : ''}
                                    onSelect={e => (setStartTime(moment(e._d).format('h:mm a')), setStartTimeErr(''))}
                                    className={`form-control ${startTimeErr ? 'is-invalid' : ''}`} />
                                    <InputError  error={startTimeErr} />
                            </div>


                        <div className="col-lg-6 form-group">
                            <label>End Time*</label>
                            <TimePicker
                                minuteStep={15}
                                popupClassName="hide_space_"
                                use12Hours format="h:mm a"
                                value={endTime ? moment(endTime, 'h:mm a') : ''}
                                onSelect={e => (setEndTime(moment(e._d).format('h:mm a')), setEndTimeErr(''))}
                                className={`form-control ${endTimeErr ? 'is-invalid' : ''}`} />
                                <InputError  error={endTimeErr} />
                   
                        </div>

                        <div className="col-md-12 form-group">
                            <label>Assign Staff</label>
                            <CustomSelect
                                value={staff}
                                isMulti
                                pHolder="Select a Staff"
                                change={e => setStaff(e)}
                                options={staffList.map(i => ({...i, value: i._id, label: i.name || i.email}))} />
                        </div>
                        {commitData ? <div className="col-md-12 form-group">
                            <label>Committed By</label>
                            <div className='cm_stf_wrapper'>
                                <div className='cm_stf'>
                                    <div className='cm_stf_delete'><i className='icon-cross' onClick={handleDeleteCommitted} /></div>
                                    <span>
                                        <img src={commitData.profileImage ? commitData.profileImage : require('../../assets/img/camps-user.png')} />
                                        <small>{commitData.name || commitData.email}</small>
                                    </span>
                                </div>
                            </div>
                        </div> : ''}
                        

                        <div className='col-lg-12 form-group'>
                            <label>Location</label>
                            <input
                                type='text'
                                value={location}
                                onChange={e => setLocation(e.target.value)}
                                className='form-control' />
                        </div>

                        

                        <div className='col-lg-12 form-group'>
                            <label>Description</label>
                            <textarea
                                value={desc}
                                onChange={e => setDesc(e.target.value)}
                                className='form-control' />
                        </div>

                        <div className='col-lg-12 d-flex justify-content-center'>
                            <button type="submit" className='btn btn-primary ml-3 pl-5 pr-5'>Save</button>
                        </div>


                        </div>
                    </form>
                </Modal.Body>
            </Modal>












        </div>
    )
}


const mapStateToProps = state => {
    let { userProfile } = state.user;
    return{
        userProfile
    }
}

export default connect(mapStateToProps)(Schedule);