///Authorized Pickup Services Here
import ApiInstance from './intercepter';
import API from '../config/api';
export const pickupOtp = (params) => ApiInstance.post(API.PICKUP_OTP, params);
export const verifypickupotp = (params) => ApiInstance.post(API.VERIFY_PICKUP_OTP, params);
export const campsezUserList = params => ApiInstance.get(API.CAMPSEZ_USER_LIST + params);
export const bookingCamperList = (params) => ApiInstance.get(API.BOOKING_CAMPER_LIST, params);
export const pickup = (params) => ApiInstance.post(API.PICK_UP, params);
export const pickupList = () => ApiInstance.get(API.PICK_UP_LIST);
export const deleteAuthorizedPickup = (params) => ApiInstance.delete(API.DELETE_PICK_UP+params);
export const editauthpickup = (params) => ApiInstance.put(API.EDIT_PICKUP, params);