
import React, { Fragment, useEffect, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import { DatePicker, InputNumber, Empty, notification } from 'antd';

import { programTypes, dateFormat, removeTime003 } from '../../helper';

import { addCouponService, getCouponsListService, couponStatusChange, couponDeleteService } from '../../services/otherService';
import { getAllPrgramWithId } from '../../services/programService';
import { getCampDetailService } from '../../services/userServices';
import { MiniLoader, ProgramLoader } from '../reuse/SVG';
import moment from 'moment';
import PaginatedComponent from '../common/Pagination/PaginationComponent';

const { RangePicker } = DatePicker;

const couponType = [
    {value: 0, label: 'Percentage'},
    {value: 1, label: 'Amount'},
]

const Coupan = props => {

    const [couponModal, setCouponModal] = useState(false);
    const [couponList, setCouponList] = useState([]);
    const [totalCount, settotalCount] = useState(0);
    const [mainLoader, setMainLoader] = useState(false);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [isCouponView, setIsCouponView] = useState(false);
    const [programList, setProgramList] = useState([]);
    const [subCatList, setSubCatList] = useState([]);
    const [miniLoader, setMiniLoader] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const [campId, setCampId] = useState(false);
    const [subcategoryName, setSubcategoryName] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [programNames, setProgramNames] = useState([]);
    const [currentId, setCurrentId] = useState('');
    const [confirmModal, setConfirmModal] = useState(false);


    const [couponName, setCouponName] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [subcategoryId, setSubcategoryId] = useState('');
    const [userCode, setUserCode] = useState('');
    const [programType, setProgramType] = useState('');
    const [programId, setProgramId] = useState([]);
    const [additionalExp, setAdditionalExp] = useState([]);
    const [additionalExpList, setAdditionalExpList] = useState([]);
    const [type, setType] = useState('');
    const [value, setValue] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isPrivate, setIsPrivate] = useState(1);
    const [noOfTimesUsed, setNoOfTimesUsed] = useState('');
    const [refund, setRefund] = useState('');

    const [couponNameErr, setCouponNameErr] = useState('');
    const [couponCodeErr, setCouponCodeErr] = useState('');
    const [categoryIdErr, setCategoryIdErr] = useState('');
    const [subcategoryIdErr, setSubcategoryIdErr] = useState('');
    const [programTypeErr, setProgramTypeErr] = useState('');
    const [typeErr, setTypeErr] = useState('');
    const [valueErr, setValueErr] = useState('');
    const [startDateErr, setStartDateErr] = useState('');
    const [endDateErr, setEndDateErr] = useState('');
    const [noOfTimesUsedErr, setNoOfTimesUsedErr] = useState('');


    const limit = 10;


    useEffect(()=>{
        if(props.campCode){
            getAllPrgramWithId(`?campCode=${props.campCode}`).then(res => {
                if(res?.data?.statusCode){
                    setProgramList(res.data.responseData.result)
                }
            })

            getCampDetailService(`?campCode=${props?.campCode}`).then(res => {
                if(res?.data?.statusCode){
                    setCampId(res.data.responseData.result?._id);
                }
            })
        }
   
       
    },[props.campCode])



    const getCouponsList = () => {
        let query = `?limit=${limit}&page=${page - 1}&campCode=${props.campCode}&search=${search}`;
        setMainLoader(true);
        getCouponsListService(query).then(res => {
            setMainLoader(false);
            if(res?.data?.statusCode){
               setCouponList(res.data.responseData.result || []);
               settotalCount(res.data.responseData.count)
            }
        })
    }


    useEffect(() => {
        if(props.campCode){
            getCouponsList();
        }

    }, [props.campCode, search, page])


    const closeModal = () => {
        setIsCouponView(false);
        setCouponModal(false);
        setCouponName('');
        setCouponCode('');
        setCategoryId('');
        setSubcategoryId('');
        setUserCode('');
        setProgramType('');
        setProgramId([]);
        setType('');
        setValue('');
        setStartDate('');
        setEndDate('');
        setIsPrivate(1);
        setNoOfTimesUsed('');
        setRefund(0);
        setProgramId([]);
        setCouponNameErr('');
        setCouponCodeErr('');
        setCategoryIdErr('');
        setSubcategoryIdErr('');
        setProgramTypeErr('');
        setTypeErr('');
        setValueErr('');
        setStartDateErr('');
        setEndDateErr('');
        setNoOfTimesUsedErr('');
        setSubcategoryName('');
        setCategoryName('');
        setProgramNames('');
        setCurrentId('');
        setConfirmModal(false);
        setAdditionalExpList([]);
        setAdditionalExp([]);
    }

    const handleViewChange = item => {
        setIsCouponView(true);
        setCouponModal(true);

        setCouponName(item.couponName);
        setCouponCode(item.couponCode);
        setCategoryName(item.category);
        setSubcategoryName(item.subCategoryName);
        setUserCode(item.userCode);

        let _programType = programTypes.find(i => i.value == item.programType);
        setProgramType(_programType);

        if(item.programname.length){
            setProgramNames(item.programname);
        }

        setType(item.type);
        setValue(item.value);
        setStartDate(item.startDate);
        setEndDate(item.endDate);
        setIsPrivate(item.isPrivate);
        setNoOfTimesUsed(item.noOfTimesUsed);
        setRefund(item.refund);

    }





    const handleDateChange = e => {
        setStartDate(removeTime003(e[0]._d));
        setEndDate(removeTime003(e[1]._d));
        setStartDateErr('');
        setEndDateErr('')

    }

    const handleCatChange = (e) => {
        setCategoryId(e);
        setCategoryIdErr('');
        const subCatArr = props.category.find(el => el._id == e.value).subCategory;
        setSubCatList(subCatArr);
    }


    useEffect(() => {
        if(props.subcategories){
            setSubCatList(props.subcategories);
        }
    }, [props.subcategories])






  const handleValidate = () => {
    let validate = true;

    if(couponName == '' || couponName == 'undefined' || couponName == null){
        setCouponNameErr('Coupon name is required');
        validate = false
    }

    if(couponCode == '' || couponCode == 'undefined' || couponCode == null){
        setCouponCodeErr('Coupon code is required');
        validate = false
    }

    if(noOfTimesUsed == '' || noOfTimesUsed == 'undefined' || noOfTimesUsed == null){
        setNoOfTimesUsedErr('Possible Usage is required');
        validate = false
    }

    if(value == '' || value == 'undefined' || value == null){
        setValueErr('value is required');
        validate = false
    }

    if(startDate == '' || startDate == 'undefined' || startDate == null){
        setStartDateErr('Start date is required');
        validate = false
    }

    if(endDate == '' || endDate == 'undefined' || endDate == null){
        setEndDateErr('End date is required');
        validate = false
    }

    return validate;
}


  const handleSubmit = e => {
    e.preventDefault();
    
    if(handleValidate()){
     setBtnLoader(true);
      let _programId = [];

      
      let params = {
        campCode: props.campCode,
        campId: JSON.stringify([campId]),
        couponName,
        couponCode,
        categoryId: categoryId.value,
        subcategoryId: subcategoryId.value,
        userCode,
        programType: programType ? programType.value : '',
        type: type ? type?.value : 0,
        value,
        startDate: moment(startDate).format('MM-DD-YYYY'),
        endDate: moment(endDate).format('MM-DD-YYYY'),
        noOfTimesUsed,
        refund,
        isPrivate
      }

    if(additionalExp.length){
        params.additionalExp = JSON.stringify(additionalExp.map(j => j.value))
    }

    params.programId = JSON.stringify(programId.map(i => i.value));

      addCouponService(params).then(res => {
        setBtnLoader(false);
        if(res?.data?.statusCode) {
            closeModal();
            setPage(1);
            getCouponsList();
        }
      })    
    }

  }


  const changeStatus = couponCode => {
    setMiniLoader({[couponCode._id]: true});

    let params = {
        couponId: couponCode._id,
        status: couponCode.status == 0 ? '1' : '0' 
    }

    couponStatusChange(params).then(status => {
        setMiniLoader('');
        getCouponsList();
        notification.open({
            message: 'Coupon Status',
            description: `Coupon status change successfully to  ${couponCode.status == 0 ? 'Active' : 'Inactive'}`
        });
    }); 
}



const handleConfirmModal = currentId => {
    setCurrentId(currentId);
    setConfirmModal(!confirmModal);
}


const handleDeleteCoupon = () => {
    let params = { couponId: currentId }
    couponDeleteService(params).then(status => {
        closeModal();
        getCouponsList(0)
    }); 
}


useEffect(() => {
    if(programId == null || programId == 'undefined' || programId.length == 0){
        setAdditionalExpList([]);
    }else{
        let _programId = programId.map(j => j.additionalExp);
        setAdditionalExpList(_programId.flat());
    }
}, [programId])




    return (
        <div className='coupon_table_wrapper'>


    <Modal show={confirmModal} centered>
        <Modal.Header>
        <Modal.Title>Confirmation</Modal.Title>
        <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
        </Modal.Header>
        <Modal.Body>
            <p className="text-center">Are you sure you want to delete?</p>
            <div className="d-flex justify-content-center mt-5 mb-4">
                <button onClick={closeModal} className="btn btn-light mr-4 pl-5 pr-5">No</button>
                <button className="btn btn-primary pl-5 pr-5" onClick={handleDeleteCoupon}>Yes</button>
            </div>
        </Modal.Body>
    </Modal>


        <Modal
            size="lg"
            show={couponModal}
            animation={false}
            centered
            >
            <Modal.Header>
                <Modal.Title>Add Coupon Code</Modal.Title>
                <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
            </Modal.Header>

            <Modal.Body>
                <form className="input_space_form" onSubmit={handleSubmit}>




                <div className="form-group">
                    <label>Coupon Name*</label>
                    {isCouponView ? <p className="inputs_view">{couponName}</p> :
                    <Fragment>
                    <input
                        type="text"
                        placeholder="Coupon Name"
                        className={`form-control ${couponNameErr ? 'is-invalid' : ''}`}
                        name="couponName"
                        value={couponName}
                        onChange={e => (setCouponName(e.target.value), setCouponNameErr(''))}
                    />
                    {couponNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{couponNameErr}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
                    </Fragment>}
                </div>




                <div className="form-group">
                    <label>Coupon Code*</label>
                    {isCouponView ? <p className="inputs_view">{couponCode}</p> :
                    <Fragment>
                    <input
                        type="text"
                        placeholder="Coupon Code"
                        className={`form-control ${couponCodeErr ? 'is-invalid' : ''}`}
                        name="couponCode"
                        value={couponCode}
                        onChange={e => (setCouponCode(e.target.value), setCouponCodeErr(''))}
                    />
                    {couponCodeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{couponCodeErr}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
                    </Fragment>} 
                </div>




                <div className="row">
                    <div className="col-md-6 form-group">
                    <label>Coupon type</label>
                    {isCouponView ? <p className="inputs_view">{type ===1 ? 'Amount' : 'Percentage'}</p> :
                    <Fragment>
                        <Select
                        placeholder="Select a type"
                        onChange={e => (setType(e), setTypeErr(''))}
                        classNamePrefix="cm_select"
                        className="form-control"
                        value={type}
                        options={couponType}/>
                    </Fragment>} 
                    </div>



                    <div className="col-md-6 form-group">
                    <label>Value*</label>
                    {isCouponView ? <p className="inputs_view">{value}</p> :
                    <Fragment>
                        <InputNumber
                            disabled={!type}
                        onChange={e => (setValue(e), setValueErr(''))}
                        defaultValue={value}
                        placeholder="Value"
                        min={0}
                        max={type.value == 1 ? Number.MAX_VALUE : 100}
                        className={`form-control ${valueErr ? 'is-invalid' : ''}`} />
                        {valueErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{valueErr}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
                    </Fragment>}
                    </div>
                </div>



                <div className="form-group">
                    <label>Start date - End date*</label>
                    {isCouponView ? <p className="inputs_view">From {dateFormat(startDate)} - to {dateFormat(endDate)}</p> :
                    <Fragment>
                    <RangePicker
                        disabledDate={d => d.isSameOrBefore(dateFormat(new Date().getTime()))}
                        className={`form-control ${endDateErr ? 'is-invalid' : ''}`}
                        onChange={handleDateChange}
                    />
                    {endDateErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{endDateErr}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
                    </Fragment>}
                </div>



                    

                    <div className="form-group">
                        <label>Program Code(s)</label> 
                        {isCouponView ? <p className="inputs_view">{programNames.length ? programNames.join(', ') : 'N/A'}</p> :  
                        <Select
                            isMulti
                            closeMenuOnSelect={false}
                            classNamePrefix="cm_select"
                            className="form-control"
                            value={programId || []}
                            onChange={e => setProgramId(e)}
                            options={programList.map(item => ({...item, value: item._id, label: item.programName}))} />}
                    </div>



                    {
                        isCouponView ? '' :  
                        <div className="form-group">
                            <label>additional Experiences</label> 
                            <Select
                                isMulti
                                classNamePrefix="cm_select"
                                className="form-control"
                                onChange={e => setAdditionalExp(e)}
                                options={additionalExpList?.map(item => ({value: item._id, label: item.title}))} />
                        </div>
                    }

                    




                <div className="row">

                    <div className="col-md-6 form-group">
                    <label>Category</label>
                    {isCouponView ? <p className="inputs_view">{categoryName ? categoryName : 'N/A'}</p> :
                    <Select
                        onChange={handleCatChange}
                        options={props.category.map(item => ({value: item._id, label: item.name}))} />}
                    </div>




                    <div className="col-md-6 form-group">
                    <label>Sub Category</label>
                        {isCouponView ? <p className="inputs_view">{subcategoryName ? subcategoryName : 'N/A'} </p> :
                    <Select
                        onChange={e => (setSubcategoryId(e), setSubcategoryIdErr(''))}
                        options={subCatList.map(item => ({value: item._id, label: item.name}))} />}
                    </div>

                    
                </div>



                <div className="form-group">
                    <label>Program Type</label>
                    {isCouponView ? <p className="inputs_view">{programType ? programType.label : 'N/A'}</p> :  
                    <Select
                        placeholder="Program type"
                        onChange={e => (setProgramType(e), setProgramTypeErr(''))}
                        classNamePrefix="cm_select"
                        className="form-control"
                        options={programTypes}
                    />}
                </div>



                
                <div className="row">

                    <div className="form-group col-md-6">
                    <label>User Code</label>
                    {isCouponView ? <p className="inputs_view">{userCode ? userCode : 'N/A'}</p> :  
                    <input
                        type="text"
                        placeholder="User Code"
                        className="form-control"
                        onChange={e => setUserCode(e.target.value)} />}
                    </div>




                    <div className="form-group col-md-6">
                    <label>Possible Usage*</label>
                    {isCouponView ? <p className="inputs_view">{noOfTimesUsed}</p> :
                    <Fragment>
                        <InputNumber
                        onChange={e => (setNoOfTimesUsed(e), setNoOfTimesUsedErr(''))}
                        placeholder="Value"
                        min={0}
                        className={`form-control ${noOfTimesUsedErr  ? 'is-invalid' : ''}`} />
                        {noOfTimesUsedErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{noOfTimesUsedErr}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
                    </Fragment>}
                    </div>


                </div>


                


                <div className="row">

                    <div className="col-md-6 form-group m-0">
                        <p className={isCouponView ? 'inputs_view' : ''}>Private {isCouponView ? <div className="check_wrapper ml-3"><input type="checkbox" checked={isPrivate} /><span /></div> :
                        <div className="check_wrapper_2 ml-3 mt-2">
                        <input
                            checked={isPrivate}
                            type="checkbox"
                            onClick={e => setIsPrivate(isPrivate == 1 ? 0 : 1)} />
                        <span />
                        </div>}  
                        </p>
                    </div>




                    <div className="col-md-6 form-group m-0">
                    <p className={isCouponView ? 'inputs_view' : ''}>Refund {isCouponView ? <div className="check_wrapper ml-3"><input type="checkbox" checked={refund} /><span /></div> :
                    
                        <div className="check_wrapper_2 ml-3 mt-2">
                        <input
                            checked={refund}
                            type="checkbox"
                            onClick={e => setRefund(refund == 1 ? 0 : 1)}/>
                        <span />
                        </div>}
                    </p>
                    </div>

                    
                </div>


                <div className="d-flex justify-content-center mt-4 pb-4">
                    <button onClick={closeModal} className="btn btn-light pl-5 pr-5">Cancel</button>
                    {isCouponView ? '' : <button disabled={btnLoader} type="submit" className="btn btn-primary ml-4 pl-5 pr-5">Submit</button>}
                
                </div>
                </form>
            </Modal.Body>
            </Modal>





           <div className='d-flex align-items-center justify-content-between mb-4'>
            <div className=''>
                <div className="search_wrap">
                    <i className="icon-search" />
                    <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Search"
                        value={search}
                        onChange={e => setSearch(e.target.value)} />
                </div>
            </div>
            <div className=''>
                <span className='btn btn-primary' onClick={() => setCouponModal(true)}>Create Coupon Code</span>
            </div>
           </div>




           <div className="table_responsive_wrap mt-4 text-center">
            
            <div className="table-responsive table_for_coupons">
            
                <table className="table table-bordered table-striped no_t_texts">

                    <thead>
                        <tr>
                            <th><span>S.No</span></th>
                            <th><span>Action</span></th>
                            <th><span className="t_text">Camp Name</span></th>
                            <th><span className="t_text">Program Name</span></th>
                            <th><span className="t_text">Coupon Name</span></th>
                            <th><span className="t_text">Coupon Code</span></th>
                            <th><span className="t_text">Program Type</span></th>
                            <th><span className="t_text">Category</span></th>
                            <th><span className="t_text">Sub Category</span></th>
                            <th><span className="t_text">Type</span></th>
                            <th><span className="t_text">Value</span></th>
                            <th><span className="t_text">Start Date</span></th>
                            <th><span className="t_text">End Date</span></th>
                            <th><span className="t_text">Possible Usage</span></th>
                            <th><span className="t_text">Number Redeemed</span></th>
                            <th><span className="t_text">Private Coupon Code</span></th>
                            <th><span className="t_text">Refund</span></th>
                            <th><span className="t_text">User Code</span></th>
                            <th><span className="t_text">User Name</span></th>
                        </tr>
                    </thead>

                    <tbody>
                        {couponList.length ? couponList.map((item, i) => (
                        <tr key={item._id}>
                            <td><span>{((i + 1) + ((page - 1) * limit))}</span></td>
                            <td>
                                <span className='coupon_actions'>
                                    <i className="icon-eye" onClick={() => handleViewChange(item)} />
                                    <i className="icon-delete" onClick={() => handleConfirmModal(item._id)} />
                                    <div className="cm_swich_wrap">
                                        {miniLoader[item._id] ? <div className="mini_loader"><MiniLoader /></div> : <React.Fragment><input type="checkbox" checked={item.status} onChange={() => changeStatus(item)} /><span /></React.Fragment>}
                                    </div>
                                </span>
                            </td>
                            <td><span className="t_text">{item.camps[0]}</span></td>
                            <td><span className="t_text text-wrap" style={{minWidth: '300px'}}><p className="last_dot">{item?.programname?.length ? item.programname.map(item => <Fragment key={item}>{item}<span>,</span> </Fragment>) : ''}</p></span></td>
                            <td><span className="t_text">{item.couponName}</span></td>
                            <td><span className="t_text">{item.couponCode}</span></td>
                            <td><span className="t_text">{item.programType && programTypes[item.programType - 1].label}</span></td>
                            <td><span className="t_text">{item.category}</span></td>
                            <td><span className="t_input last_dot">{item.subCategoryName}</span></td> 
                            <td><span className="t_text">{item.type ? 'Amount' : 'Percentage'}</span></td>
                            <td><span className="t_text">{item.type ? '$' : ''}{item.value}{item.type ? '' : '%'}</span></td>
                            <td><span className="t_text">{dateFormat(item.startDate)}</span></td>
                            <td><span className="t_text">{dateFormat(item.endDate)}</span></td>
                            <td><span className="t_text">{item.noOfTimesUsed}</span></td>
                            <td><span className="t_text">{item.couponUsedByUser}</span></td>
                            <td>
                                <span className="t_text">
                                    <div className="check_wrapper">
                                        <input type="checkbox" checked={item.isPrivate} /><span />
                                    </div>
                                </span>
                            </td>
                            <td>
                                <span className="t_text">
                                    <div className="check_wrapper">
                                        <input type="checkbox" checked={item.refund} /><span />
                                    </div>
                                </span>
                            </td>
                            <td><span className="t_text">{item.userCode}</span></td>
                            <td><span className="t_text">{item.userName ? item.userName : '---'}</span></td>
                        </tr>
                        )) : ''
                    }
                        
                    
                    </tbody>
                </table>

                
            </div>

            {
                mainLoader ? <div className='program_loader'><ProgramLoader /></div> : couponList.length ? '' : <div className="mt-4 text-center cm_empty"><Empty description="Data Not Found" /></div>
            }
            
        </div>

        {couponList.length ?  
        <div className="d-flex justify-content-center mt-5">
            <PaginatedComponent
                activePage={page}
                itemsCountPerPage={limit}
                totalItemsCount={totalCount}
                pageRangeDisplayed={3}
                onPageChange={setPage}/>
        </div> : ''

        }





        </div>
    )

}


const mapStateToProps = state => {
    let { userProfile } = state.user;
    let { preList } = state.program;

    let { campCode } = userProfile;
    let { category, subcategory } = preList;

    return {
        campCode,
        category,
        subcategories: subcategory,
    };
}


export default connect(mapStateToProps)(Coupan);
