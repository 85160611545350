import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { programDatesAction } from '../../store/ui';
import { getprogramdatesinCart } from '../../helper';

const ViewProgramDatesModal = props => {
    return(
        <Modal
            dialogClassName="h_auto program_date_model"
            show={props?.porgramDates?.length}
            animation={false}
            centered
        >
            <Modal.Body>
            <span className="modal_close" onClick={() => props.programDatesAction('')}><i className="icon-cross" /></span>
            <h3 className="text-center mt-3 mb-0">Program Dates</h3>

            <div className='participant_count'>
                    {props.porgramDates.map(item => (
                        <Fragment>
                            <span className={`cm_selected_date`} key={item._id}>
                                {getprogramdatesinCart(item.startDate, item.endDate)}
                                <b title='Number of Slots'>{item.slots}</b>
                            </span>
                            <br />
                        </Fragment>
                    ))}

                </div>
            </Modal.Body>
        </Modal>
    )
}


const mapStateToProps = state => {
    let { porgramDates } = state.ui
    return {
        porgramDates
    };
}
  
const mapDispatchToProps = dispatch => ({
    programDatesAction: (params) => dispatch(programDatesAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(ViewProgramDatesModal);