import React, { useEffect, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input/input';
import { DatePicker, notification } from 'antd';
import Select from 'react-select';

import { allergyList, genders2, gendersTwo, getgendersdisplay, gradeArr, groupCodeGenerate, medicalList, removeTime, resHandle, shortsAndTshirtSizes, _calculateAge, provinceList } from '../../helper';
import moment from 'moment';
import { participantAdd, deleteCamper, uploadMedia } from '../../services/campersServices';
import { MiniLoader, PDFIcon } from '../reuse/SVG';
import ConfirmModal from '../modals/ConfirmModal';
import EditCamperModal from './EditCamperModal';
import {InputTextError } from '../reuse/FormControls';

const dateFormatNew = 'MM/DD/YYYY';

const CamperInfo = props => {
    const [campers, setCampers] = useState([]);
    const [addCamperModal, setAddCamperModal] = useState(false);
    const [newCampers, setNewCampers] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [btnloader, setBtnloader] = useState(false);
    const [errormodal, setErrorModel] = useState(false);
    const [errIndexCamper, setErrIndexCamper] = useState(0);
    const [confirmModal, setConfirmModal] = useState(false);
    const [currentId, setCurrentId] = useState('');
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [activeCamper, setActiveCamper] = useState({});
    const [miniLoader, setMiniLoader] = useState({});

    useEffect(() => {
        if(props?.data?._id){
            setCampers(props.data.campersData || []);
        }

    }, [props?.data])


    const handleClose = () => {
        setAddCamperModal(false);
        setNewCampers([]);
    }

    const handleAddNewCamperModal = () => {
        let newCamper = {
            activityInterest: [],
            address: '',
            allergy: '',
            allergyDeadly: '',
            allowDocumentCamper: '',
            apartment: '',
            camperId: props.match.params.id,
            city: '',
            currentGrade: '',
            dob: '',
            firstName: '',
            gender: '',
            group: '',
            groupcode: groupCodeGenerate(),
            lastName: '',
            medical: '',
            medicalDocument: '',
            medicalDocumentLoader: '',
            medicalInsuranceCompany: '',
            medicationsTaken: '',
            participantBringingOwnMedication: '',
            participantReceiveCounterMedication: '',
            permissionEmergencyMedication: '',
            physicianAddress: '',
            policyNumber: '',
            primaryPhysician: '',
            profileImage: '',
            shortsSize: '',
            state: '',
            tshirtSize: '',
            zipCode: '',
        }

        let temp = [...newCampers];
        temp.push(newCamper);
        setNewCampers(temp);
        setAddCamperModal(true);
    }


    const handleChange = (e, i) => {
        let { name, value } = e.target;
        if(value != undefined && value != null){
            let temp = [...newCampers];
            temp[i][name] = value;
            temp[i][`${name}Err`] = '';
            setNewCampers(temp);
        } 
    }

    const handleRadioChange = (e, i) => {
        let { name, value } = e.target;
            let temp = [...newCampers];
            temp[i][name] = value;
            setNewCampers(temp);
    }


    const handlePhoneChange = (e, b, i) => {
        if(e != undefined && e != null){
            let temp = [...newCampers];
            temp[i][b] = e;
            setNewCampers(temp);
        } 
    }


    const handleCheckBoxChange = (e, i, b) => {
        let { name } = e.target;
        let temp = [...newCampers];
            temp[i][name] = b;
            temp[i][`${name}Err`] = '';
            setNewCampers(temp);
    }


    const handleDateChange = (e, b, i) => {
        if(e != undefined && e != null){
            let temp = [...newCampers];
            temp[i][b] = new Date(removeTime(e._d)).getTime();
            temp[i][`${b}Err`] = '';
            setNewCampers(temp);
        } 
    }

    const handleSelectChange = (e, b, i) => {
        if(e != undefined && e != null){
            let temp = [...newCampers];
            temp[i][b] = e.value;
            temp[i][`${b}Err`] = '';
            setNewCampers(temp);
        } 
    }

    const handleMultiSelectChange = (e, b, i) => {
        let temp = [...newCampers];
        if(e != undefined && e != null){
            let isNone = e.find(m => m.value == 'None');
            if(isNone){
                temp[i][b] = [isNone.value];
                if(b == 'allergy'){
                    temp[i].allergyDeadly = '2'; 
                }
            }else{
                temp[i][b] = e.map(m => m.value);
            }
        }else{
            temp[i][b] = []; 
        }
        temp[i][`${b}Err`] = '';
        setNewCampers(temp);
    }


    const uploadHippaCompliance = (e, b, i, d) => {
        const data = new FormData();
        data.append('file', e.target.files[0]);
        let temp = [...newCampers];
        temp[i][d] = true;
        setNewCampers(temp);
        uploadMedia(data).then(res => {
            if(res.data.responseData.result.fileUrl != undefined){
                let temp = [...newCampers];
                temp[i][b] = res.data.responseData.result.fileUrl;
                temp[i][d] = false;
                setNewCampers(temp);
            }
        })
    }

    const handleFileChange = (e, i) => {
        const data = new FormData();
        data.append('file', e.target.files[0]);
        setMiniLoader({[i]: true});
        uploadMedia(data).then(res => {
            if(res.data.responseData.result.fileUrl != undefined){
                let temp = [...newCampers];
                temp[i].profileImage = res.data.responseData.result.fileUrl;
                setNewCampers(temp);
                setMiniLoader({[i]: false});
            }
        })
    }  




    const handleRemoveMedicalDocument = (b, i) => {
        let temp = [...newCampers];
        temp[i][b] = '';
        setNewCampers(temp);
    }


    const  disabledDate = (current) => {
        return current && (current > moment().startOf('day') || current < moment(`${new Date().getFullYear() - 100}-10-23`).startOf('day'));
    }


    const handleSubmit = () => {
        
        let errors = newCampers.map((item, ind) => {
            let errObj = {};

            if (item.firstName == '')        {errObj.firstNameErr = 'First name is required'}
            if (item.lastName == '')         {errObj.lastNameErr = 'Last name is required'}
            if (item.dob == '')              {errObj.dobErr = 'Date of birth is required'}
            if (item.currentGrade == '')     {errObj.currentGradeErr = 'Grade is required'}
            if (item.gender == '')           {errObj.genderErr = 'Gender is required'}
            if (item.address == '')          {errObj.addressErr = 'Address is required'}
            if (item.city == '')             {errObj.cityErr = 'City is required'}
            if (item.zipCode == '')          {errObj.zipCodeErr = 'Zip code is required'}
            if (item.zipCode?.length < 5)    {errObj.zipCodeErr = 'The zip code must be at least 5 digits long.'}
            if (item.state == '')            {errObj.stateErr = 'State is required'}
            if (item.shortsSize == '')       {errObj.shortsSizeErr = 'Shorts size is required'}
            if (item.tshirtSize == '')       {errObj.tshirtSizeErr = 'Tshirt size is required'}
            if (item.medical?.length == 0)   {errObj.medicalErr = 'Medical is required'}
            if (item.allergy?.length == 0)   {errObj.allergyErr = 'Allergy is required'}
            if (item.allergyDeadly == '')    {errObj.allergyDeadlyErr = 'Allergy deadly is required'}


            let isValidate = false;

            if(Object.keys(errObj).length === 0){
                isValidate = true
            }
            errObj.isValidate = isValidate;

            return {...item, ...errObj}
        })
        
        let finalValidCheck = errors.filter(i => i.isValidate == false);


        if(finalValidCheck.length == 0){
            setBtnloader(true);
            let finalCampers = newCampers.map(i => ({
                ...i,
                allergy: JSON.stringify(i.allergy),
                medical: JSON.stringify(i.medical),
            }))

            let params = {deviceTypeID:3, participants: JSON.stringify(finalCampers)}
            participantAdd(params).then(res => {
                setBtnloader(false);
                let { status, data } = resHandle(res);
                   if(status){
                    setAddCamperModal(false);
                    setNewCampers([]);
                    setActiveIndex(0);
                    props.callback();
                   }
                
                notification.open({
                    message: 'Participants',
                    description:
                      'Participants added successfully',
                  });
            })
        }else{
            let _errIndexCamper = ''
            for (let i = 0; i < errors.length; i++) {
                if(errors[i].isValidate == false){
                    _errIndexCamper = i;
                    break;
                }
              }

              if(_errIndexCamper != ''){
                setErrIndexCamper(_errIndexCamper)
              }

            setNewCampers(errors);
            setErrorModel(true);
        }
        
     }


     const handleDelete = () => {
        let query = `/?camperId=${currentId}`;
        deleteCamper(query).then(res => {
            setConfirmModal(false);
            let { status, data } = resHandle(res);
            if(status){
                props.callback();
            }
        })
     }


     const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        props.callback();
     }


     const handleRemove = ind => {
        let _newCampers = [...newCampers];
        _newCampers.splice(ind, 1);
        setNewCampers(_newCampers);
        setActiveIndex(ind - 1)
     }





    return(
        <div className="mt-3 ml-3 mr-3">
            <div className='d-flex align-items-center justify-content-between mb-4'>
                <h4>Participant(s)</h4>
                <button className='btn btn-primary' onClick={handleAddNewCamperModal}>Add new participant</button>
            </div>

            {isEditModalOpen ? <EditCamperModal
                isOpen={isEditModalOpen}
                data={activeCamper}
                closeEditModal={handleCloseEditModal} /> : ''}


            


            <ConfirmModal
                show={confirmModal}
                hideModal={() => setConfirmModal(false)}
                msg="Are you sure you want to delete?"
                action={handleDelete} />

            <ul className='campers_list'>
                {
                    campers.map(item => (
                        <li key={item._id}>
                            <img src={item.profileImage || require('../../assets/img/user.png')} />
                            <div>
                                <p>{item.firstName} {item.lastName}</p>
                                <p><b>Group code: </b>{item.groupcode} <b>Age: </b> {_calculateAge(item.dob)} <b>Sex: </b> {getgendersdisplay(item.gender)}</p>
                            </div>
                            <div>
                                <i className="icon-pancil" onClick={() => (setActiveCamper(item), setIsEditModalOpen(true))} />
                                <i className="icon-delete" onClick={() => (setConfirmModal(true), setCurrentId(item._id))}  />
                            </div>
                        </li>
                    ))
                }
            </ul>




            <Modal
                show={addCamperModal}
                size="lg"
                dialogClassName="remove_header_line"
                centered>
                <Modal.Header>
                <Modal.Title>Add Participants</Modal.Title>
                <span className="modal_close" onClick={handleClose}><i className="icon-cross" /></span>
                </Modal.Header>
                <Modal.Body>

                <Modal
                    className="cm_error_modal"
                    show={errormodal}
                    animation={false}
                    centered
                    >
                    <Modal.Body>
                        <div className="p-5">
                            <h3 className="text-center mb-4">Error</h3>
                            Please Fill All Required Fields for Participant {errIndexCamper + 1}
                            <button  onClick={() => setErrorModel(false)} className="btn btn-primary btn-block mt-2">Ok</button>
                        </div>
                    </Modal.Body>
                </Modal>

                    <div id="cm_scrollInTo">
                        <span className="btn color_primary p-0" onClick={handleAddNewCamperModal}><i className="icon-plus" /> Add more participants</span>
                    </div>  

                    <div className="mt-3 mb-3 bottom_border">
                        {newCampers.map((item, i) => <span key={i} className={`mr-2 mb-2 btn btn-outline-dark rounded-0 ${activeIndex == i ? 'cm_active' : ''}`} onClick={() => setActiveIndex(i)}>Participant {i + 1}</span>)}                    
                    </div>

                    {
                        newCampers.map((item, i) => (
                            <div key={i} style={{display: activeIndex == i ? "block" : "none"}}>
                                <div className='spaceform'>

                                <div className="profile_upload">
                                    {item.profileImage ? <img src={item.profileImage} /> : ''}
                                    <input onChange={e => handleFileChange(e, i)} value="" type="file" accept="image/x-png,image/gif,image/jpeg"/>
                                    <i className="icon-camera-plus" />
                                    {miniLoader[i] ? <div className="mini_loader"><MiniLoader /></div> : <i className="icon-camera-plus" />}
                                </div>


                                    <div className='row'>

                                    <div className="col-lg-5 d-flex align-items-center mb-4">
                                        <label style={{minWidth: '110px'}}>Participant :</label>
                                        <input
                                            disabled
                                            value={item.groupcode}
                                            type="text"
                                            className='form-control'
                                            placeholder="Group Code" />   
                                    </div> 

                                    <div className="col-lg-7 d-flex align-items-end justify-content-end mb-4">
                                        {newCampers.length > 1 ? <span onClick={() => handleRemove(i)} className='btn btn-dark'>Delete</span>  : ''}   
                                    </div>

                                    <div className='col-md-12'></div>


                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>First Name</label>
                                                <input
                                                    value={item.firstName}
                                                    onChange={e => handleChange(e, i)}
                                                    type='text'
                                                    name="firstName"
                                                    className={`form-control ${ item.firstNameErr ? 'is-invalid' : '' }`} />

                                                {item.firstNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{item.firstNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>
                                        </div>

                                       


                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Last Name</label>
                                                <input
                                                    value={item.lastName}
                                                    onChange={e => handleChange(e, i)}
                                                    type='text'
                                                    name="lastName"
                                                    className={`form-control ${ item.lastNameErr ? 'is-invalid' : '' }`} />

                                                {item.lastNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{item.lastNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>
                                        </div>


                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Date of Birth</label>
                                                <DatePicker
                                                    disabledDate={disabledDate}
                                                    value={item.dob ? moment.utc(parseInt(item.dob)): ''}
                                                    className={`form-control ${ item.dobErr ? 'is-invalid' : '' }`}
                                                    placeholder="Date of Birth"
                                                    format={dateFormatNew}
                                                    onChange={e => handleDateChange(e, 'dob', i)} />

                                                {item.dobErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{item.dobErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>
                                        </div>


                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Select Current Grade As of Today</label>
                                                <Select
                                                    placeholder="Select shorts size"
                                                    onChange={e => handleSelectChange(e, 'currentGrade', i)}
                                                    classNamePrefix="cm_select"
                                                    value={item.currentGrade ? {label: item.currentGrade, value: item.currentGrade} : ''}
                                                    options={gradeArr.map(i => ({value: i, label: i}))}
                                                    className={`form-control ${item.currentGradeErr ? 'is-invalid' : ''}`}
                                                />

                                                {item.currentGradeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{item.currentGradeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>
                                        </div>


                                        <div className="col-md-12 d-flex cm_gendr pro_wrapper">
                                            <label>Sex</label>
                                            <ul className="d-flex">
                                                {
                                                    gendersTwo.map((itm, ind) => (
                                                        <li className="mr-2 check_box_wrapper" key={ind}>
                                                            <input
                                                                type="checkbox"
                                                                name="gender"
                                                                checked={item.gender == itm.value ? 'checked': ''}
                                                                value={item.gender}
                                                                onChange={e => handleCheckBoxChange(e, i, itm.value)}
                                                            />
                                                            <span className="check_box"><span /> {itm.label}</span> 
                                                        </li>
                                                    ))
                                                }
                                            </ul>

                                            <div>{item.genderErr ? <span className='cm_error'>{item.genderErr}</span> : ''}</div>
                                        </div> 


                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Street Address</label>
                                                <input
                                                    value={item.address}
                                                    onChange={e => handleChange(e, i)}
                                                    type='text'
                                                    name="address"
                                                    className={`form-control ${ item.addressErr ? 'is-invalid' : '' }`} />

                                                    {item.addressErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{item.addressErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>
                                        </div>


                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Apt./Suite/Unit [optional]</label>
                                                <input
                                                    value={item.apartment}
                                                    onChange={e => handleChange(e, i)}
                                                    type='text'
                                                    name="apartment"
                                                    className={`form-control ${ item.apartmentErr ? 'is-invalid' : '' }`} />

                                                    {item.apartmentErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{item.apartmentErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>
                                        </div>


                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>City</label>
                                                <input
                                                    value={item.city}
                                                    onChange={e => handleChange(e, i)}
                                                    type='text'
                                                    name="city"
                                                    className={`form-control ${ item.cityErr ? 'is-invalid' : '' }`} />

                                                    {item.cityErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{item.cityErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>
                                        </div>


                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Zip Code</label>
                                                 <input
                                                    type="number"
                                                    value={item.zipCode}
                                                    onChange={e => handleChange(e, i)}
                                                    name="zipCode"
                                                    error={item.zipCodeErr}
                                                    errorDisplayType={"text"} 
                                                    className={`form-control ${ item.zipCodeErr ? 'is-invalid' : '' }`} />
                                                    
                                                {item.zipCodeErr && <InputTextError error={item.zipCodeErr}/>}
                                            </div>
                                        </div>


                                        <div className='col-md-12'>
                                            <div className='form-group'>
                                                <label>State/Province</label>

                                                <select
                                                    value={item.state}
                                                    onChange={e => handleChange(e, i)}
                                                    name="state"
                                                    className={`form-control ${ item.stateErr ? 'is-invalid' : '' }`} >

                                                    <option value="">Select State/Province</option>   
                                                        {provinceList.map(item => (
                                                            <option key={item.value} value={item.value}>
                                                            {item.value} - {item.label}
                                                            </option>
                                                        ))}
                                                </select>

                                                {item.stateErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{item.stateErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>
                                        </div>


                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>T-shirt Size</label>
                                                <Select
                                                    placeholder="Select t-shirt size"
                                                    onChange={e => handleSelectChange(e, 'tshirtSize', i)}
                                                    classNamePrefix="cm_select"
                                                    className={`form-control ${item.tshirtSizeErr ? 'is-invalid' : ''}`}
                                                    value={item.tshirtSize ? {label: item.tshirtSize, value: item.tshirtSize} : ''}
                                                    options={shortsAndTshirtSizes.map(i => ({value: i, label: i}))} />

                                                {item.tshirtSizeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{item.tshirtSizeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>
                                        </div>


                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Shorts Size</label>
                                                <Select
                                                    placeholder="Select shorts size"
                                                    onChange={e => handleSelectChange(e, 'shortsSize', i)}
                                                    classNamePrefix="cm_select"
                                                    className={`form-control ${item.shortsSizeErr ? 'is-invalid' : ''}`}
                                                    value={item.shortsSize ? {label: item.shortsSize, value: item.shortsSize} : ''}
                                                    options={shortsAndTshirtSizes.map(i => ({value: i, label: i}))} />

                                                {item.shortsSizeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{item.shortsSizeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>
                                        </div>


                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Medical</label>
                                                <Select
                                                    isMulti
                                                    placeholder="Select medical"
                                                    onChange={e => handleMultiSelectChange(e, 'medical', i)}
                                                    classNamePrefix="cm_select"
                                                    value={item.medical ? item.medical.map(i => ({value: i, label: i})) : []}
                                                    options={medicalList.map(i => ({value: i, label: i}))}
                                                    className={`form-control ${item.medicalErr ? 'is-invalid' : ''}`}
                                                />

                                                {item.medicalErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{item.medicalErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                            </div>
                                        </div>



                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Allergy</label>
                                                <Select
                                                    isMulti
                                                    placeholder="Select allergy"
                                                    onChange={e => handleMultiSelectChange(e, 'allergy', i)}
                                                    classNamePrefix="cm_select"
                                                    value={item.allergy ? item.allergy.map(i => ({value: i, label: i})) : []}
                                                    options={allergyList.map(i => ({value: i, label: i}))}
                                                    className={`form-control ${item.allergyErr ? 'is-invalid' : ''}`}
                                                />

                                                {item.allergyErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{item.allergyErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>
                                        </div>


                                        <div className="col-md-12 d-flex cm_gendr pro_wrapper">
                                            <label>Allergy is deadly</label>
                                            <ul className="d-flex">
                                                <li className="mr-2 check_box_wrapper">
                                                    <input
                                                        onChange={e => handleRadioChange(e, i)}
                                                        type="radio"
                                                        value={1}
                                                        checked={item.allergyDeadly == 1}
                                                        name="allergyDeadly" />
                                                    <span className="check_box"><span /> Yes</span> 
                                                </li>
                                                <li className="mr-2 check_box_wrapper">
                                                    <input
                                                        onChange={e => handleRadioChange(e, i)}
                                                        type="radio"
                                                        value={2}
                                                        checked={item.allergyDeadly == 2}
                                                        name="allergyDeadly" />
                                                    <span className="check_box"><span /> No</span> 
                                                </li>
                                            </ul>
                                        </div> 


                                        <div className='col-md-12'>
                                            <h5>Insurance Information:</h5>
                                        </div>


                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Medical Insurance Company</label>
                                                <input
                                                    value={item.medicalInsuranceCompany}
                                                    onChange={e => handleChange(e, i)}
                                                    type='text'
                                                    name="medicalInsuranceCompany"
                                                    className='form-control' />
                                            </div>
                                        </div>


                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Policy Number</label>
                                                <input
                                                    value={item.policyNumber}
                                                    onChange={e => handleChange(e, i)}
                                                    type='text'
                                                    name="policyNumber"
                                                    className='form-control' />
                                            </div>
                                        </div>


                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Group #</label>
                                                <input
                                                    value={item.group}
                                                    onChange={e => handleChange(e, i)}
                                                    type='text'
                                                    name="group"
                                                    className='form-control' />
                                            </div>
                                        </div>


                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Primary Physician</label>
                                                <input
                                                    value={item.primaryPhysician}
                                                    onChange={e => handleChange(e, i)}
                                                    type='text'
                                                    name="primaryPhysician"
                                                    className='form-control' />
                                            </div>
                                        </div>


                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Physician Address</label>
                                                <input
                                                    value={item.physicianAddress}
                                                    onChange={e => handleChange(e, i)}
                                                    type='text'
                                                    name="physicianAddress"
                                                    className='form-control' />
                                            </div>
                                        </div>


                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Physician Phone #</label>
                                                <PhoneInput
                                                    country="US"
                                                    placeholder="Phone number"
                                                    className={`form-control`}
                                                    onChange={e => handlePhoneChange(e, 'physicianPhone', i)}
                                                    maxLength={16}
                                                    value={item.physicianPhone}
                                                    name="phone" />
                                            </div>
                                        </div>


                                        <div className='col-md-12'>
                                            <div className='form-group'>
                                                <label>Please list any medications taken(please include dosage & timing) or any other special assistance necessary to attend a program. (Leave blank if none)</label>
                                                <textarea
                                                    value={item.medicationsTaken}
                                                    onChange={e => handleChange(e, i)}
                                                    name="medicationsTaken"
                                                    className='form-control' />
                                            </div>
                                        </div>


                                        <div className="col-md-12 form-group">
                                            <label>Parent Permission for EMERGENCY MEDICATIONS: (rescue inhaler, glucagon, epinephrine)</label>
                                        </div>


                                        <div className="col-md-12 cm_gendr pro_wrapper">
                                            <label>Participant has permission to carry his/her own emergency medication, or inhaler:</label>
                                            <ul className="d-flex">
                                                <li className="mr-2 check_box_wrapper">
                                                    <input
                                                        onChange={e => handleRadioChange(e, i)}
                                                        type="radio"
                                                        value={1}
                                                        checked={item.permissionEmergencyMedication == 1}
                                                        name="permissionEmergencyMedication" />
                                                    <span className="check_box"><span /> Yes</span> 
                                                </li>
                                                <li className="mr-2 check_box_wrapper">
                                                    <input
                                                        onChange={e => handleRadioChange(e, i)}
                                                        type="radio"
                                                        value={2}
                                                        checked={item.permissionEmergencyMedication == 2}
                                                        name="permissionEmergencyMedication" />
                                                    <span className="check_box"><span /> No</span> 
                                                </li>
                                            </ul>
                                        </div> 



                                        <div className="col-md-12 cm_gendr pro_wrapper">
                                            <label>May the participant receive over-the counter medication:</label>
                                            <ul className="d-flex">
                                                <li className="mr-2 check_box_wrapper">
                                                    <input
                                                        onChange={e => handleRadioChange(e, i)}
                                                        type="radio"
                                                        value={1}
                                                        checked={item.participantReceiveCounterMedication == 1}
                                                        name="participantReceiveCounterMedication" />
                                                    <span className="check_box"><span /> Yes</span> 
                                                </li>
                                                <li className="mr-2 check_box_wrapper">
                                                    <input
                                                        onChange={e => handleRadioChange(e, i)}
                                                        type="radio"
                                                        value={2}
                                                        checked={item.participantReceiveCounterMedication == 2}
                                                        name="participantReceiveCounterMedication" />
                                                    <span className="check_box"><span /> No</span> 
                                                </li>
                                            </ul>
                                        </div> 



                                        <div className="col-md-12 cm_gendr pro_wrapper">
                                            <label>Will the participant be bringing their own medication, or an inhaler to a program: </label>
                                            <ul className="d-flex">
                                                <li className="mr-2 check_box_wrapper">
                                                    <input
                                                        onChange={e => handleRadioChange(e, i)}
                                                        type="radio"
                                                        value={1}
                                                        checked={item.participantBringingOwnMedication == 1}
                                                        name="participantBringingOwnMedication" />
                                                    <span className="check_box"><span /> Yes</span> 
                                                </li>
                                                <li className="mr-2 check_box_wrapper">
                                                    <input
                                                        onChange={e => handleRadioChange(e, i)}
                                                        type="radio"
                                                        value={2}
                                                        checked={item.participantBringingOwnMedication == 2}
                                                        name="participantBringingOwnMedication" />
                                                    <span className="check_box"><span /> No</span> 
                                                </li>
                                            </ul>
                                        </div> 


                                        <div className="col-md-12 form-group">
                                            <label>If the Participant is bringing medication please note:</label>
                                            <label>All Medication must be sent in the original container and must state the current instructions for dispensing.  All medications must be turned in when attendee is dropped off, if permission is not granted above for them to carry it.</label> 
                                        </div> 


                                        <div className="col-md-12 cm_gendr pro_wrapper">
                                            <label>Service Provider Staff may document participant with digital and film photography and/or video.</label>
                                            <ul className="d-flex">
                                                <li className="mr-2 check_box_wrapper">
                                                    <input
                                                        onChange={e => handleRadioChange(e, i)}
                                                        type="radio"
                                                        value={1}
                                                        checked={item.allowDocumentCamper == 1}
                                                        name="allowDocumentCamper" />
                                                    <span className="check_box"><span /> Yes</span> 
                                                </li>
                                                <li className="mr-2 check_box_wrapper">
                                                    <input
                                                        onChange={e => handleRadioChange(e, i)}
                                                        type="radio"
                                                        value={2}
                                                        checked={item.allowDocumentCamper == 2}
                                                        name="allowDocumentCamper" />
                                                    <span className="check_box"><span /> No</span> 
                                                </li>
                                            </ul>
                                        </div> 


                                        <div className="col-md-12 form-group">
                                            <label>Please attach any accompanying documents that you feel are necessary for attendance (Additional Medical document etc).</label>
                                            <div className={`attach_file_btn  btn_anim ${item.medicalDocumentLoader ? 'show_anim' : ''}`}>
                                                Attach PDF 
                                                <input
                                                    type="file"
                                                    value=""
                                                    accept="application/pdf"
                                                    onChange={e => uploadHippaCompliance(e,'medicalDocument', i, 'medicalDocumentLoader')} />
                                            </div>

                                            {
                                                item.medicalDocument ? <div className="cm_pdf_icon"><PDFIcon /><i className="icon-cross" onClick={() => handleRemoveMedicalDocument('medicalDocument', i)} /> <p>{item.medicalDocument ? item.medicalDocument.slice(item.medicalDocument.lastIndexOf('_') + 1) : ''}</p></div> : ''  
                                            }
                                                                          
                                        </div>

                                    


                                    </div>
                                </div>
                            </div>
                        ))
                    }


                    <div className='text-center'>
                        <button
                            disabled={btnloader}
                            onClick={handleSubmit}
                            className={`btn btn-primary rounded-0 ${btnloader ? 'btnLoader' : ''}`}>Save</button>
                    </div>

                    
                    
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CamperInfo;