import ApiInstance from './intercepter';
import API from '../config/api';

export const createProductService = params => ApiInstance.post(API.CREATE_PRODUCT, params);

export const editProductService = params => ApiInstance.post(API.EDIT_PRODUCT, params);

export const deleteProductService = params => ApiInstance.delete(API.PRODUCT_DELETE + params);

export const addProductIntoCartService = params => ApiInstance.post(API.ADD_PRODUCT_IN_TO_CART, params);

export const getProductsService = query => ApiInstance.get(API.LIST_PRODUCT + query);

export const removeItemFromProgramCart = params => ApiInstance.delete(API.REMOVE_ITEM_FROM_PROGRAM_CART + params);

export const getProductsCartCountService = () => ApiInstance.get(API.GET_PRODUCT_CART_COUNT);

export const getOrdersService = query => ApiInstance.get(API.GET_ORDER_PRODUCTS + query);

export const getPendingPaymentService = query => ApiInstance.get(API.PENDING_AMOUNT_LIST + query);

export const getPaidAmountRecipt = query => ApiInstance.get(API.PAID_AMOUNT_RECEIPTS + query);

export const getCombinedPendingAmountList = query => ApiInstance.get(API.COMBINED_PENDING_AMOUNT_LIST + query);


export const deductPaylaterAmountService = params => ApiInstance.post(API.DEDUCT_PAY_LATER_AMOUNT, params);

export const addProductWithProgramService = params => ApiInstance.post(API.ADD_PRODUCT_WITH_PROGRAM,  params);

export const itemCategoryAddService = params => ApiInstance.post(API.ITEM_CATEGORY_ADD, params);

export const getItemCategoryService = query => ApiInstance.get(API.GET_ITEM_CATEGORY + query);

export const getUserBookedCampsService = query => ApiInstance.get(API.GET_USER_BOOKED_CAMPS + query);

export const deleteProductCatService = params => ApiInstance.delete(API.DELETE_PRODUCT_CAT, {data: params});

export const getProductDeailsService = query => ApiInstance.get(API.PRODUCT_DETAIL + query);


