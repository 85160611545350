import React, { useState, Fragment, useEffect } from 'react';
import { getInboxService, starConversationService } from '../../services/inboxService';
import { Empty, notification } from 'antd';
import { connect } from 'react-redux';
import { activeUserChatWindowAction, getChatUserListCalbackAction } from '../../store/inbox';
import { getHtml, removeHtmlTags } from '../../helper';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { Col, Container, Row } from 'react-bootstrap';
import { ProgramLoader } from '../reuse/SVG';
import { unreadCountAction } from '../../store/chat';


const findIsStarred = (item, userId) => { 
    if(item.hasOwnProperty('starredMsg')){
        if(item?.starredMsg?.length)  return  item.starredMsg.find(i => i.userId !== userId).isStarred;
        return false;
    }
    return false; 
};
const ChatUserList = props => {
    const [list, setList] = useState([]);
    const [search, setSearch] = useState('');
    const [chatTab, setChatTab] = useState(1);
    const [loader, setLoader] = useState(false);
    const userId = props.userProfile?._id

    const getList = () => {
        setLoader(true)
        let query = `?search=${search}`
        getInboxService(query).then(res => {
            props.getChatUserListCalbackAction(0);
            
            if(res?.data?.statusCode){
                if(chatTab === 3){
                    const starredMsg = res.data.responseData.result.filter(item => findIsStarred(item, userId));
                    setList(starredMsg);
                }else if(chatTab === 2){
                    const unreadMessage = res.data.responseData.result.filter(item => {
                        if(item.hasOwnProperty('unReadCount')){
                            return item.unReadCount.count !==0
                        }
                        return false;
                    })
                    setList(unreadMessage);
                }else{
                    setList(res.data.responseData.result)
                }
                props.unreadCountAction();
                setLoader(false)
            }
        }).catch(error => {
            setLoader(false)
            console.log(error)
        }).finally(() => {
            setLoader(false)
        })
    }

    useEffect(() => {
        getList()
    }, [search, chatTab, props.getChatUserListCalback]);


    const handleClick = (item) => {
        props.activeUserChatWindowAction(item);
    }
    const toggleStar = (e, item) => {
        let isStarred = !findIsStarred(item, userId);
        e.stopPropagation()
        const params = {
            groupId: item.groupId,
            isStarred,
            userId, 
        }
        starConversationService(params).then(res => {
            if(res?.data?.statusCode){
                notification.open({
                    description: `⭐ Successfuly ${isStarred ? "starred" : "un-starred"} conversation for ${item?.userId[0]?.name}.`,
                });
                getList();
            }else{
                notification.open({
                    description: `Something went wrong please try again.`,
                });
            }
            
        })
      };
    return(
        <div className="chat_user_list_wrapper">

                <div className="contact_search">
                    <i className="icon-search" />
                    <input
                        type="text"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        placeholder="Search" />
                </div>

            {/* <div className='me_pro_user'>
                <img src={props?.userProfile?.profileImage || require('../../assets/img/user.png')} />
                <p>{props?.userProfile?.name}</p>
            </div> */}

            <div className='chat_tabs'>
                <span className={chatTab ===  1 ? 'active' : ''} onClick={() => setChatTab(1)}>All</span>
                <span className={chatTab === 2 ? 'active' : ''} onClick={() => setChatTab(2)}>Unread</span>
                <span className={chatTab === 3 ? 'active' : ''} onClick={() => setChatTab(3)}>Starred</span>
            </div>
            {loader ? 
                <div 
                    className="program_loader d-flex align-items-center justify-content-center"
                    style={{ height: '100%' }}
                >
                    <div>
                        <ProgramLoader />
                    </div>
                </div> :
                <ul>
                {
                    list.length ? list.map(item => (
                        <li key={item.groupId} className={`d-flex align-items-center ${item.groupId === props?.activeUserChatWindow?.groupId ? 'active' : ''}`} onClick={() => handleClick(item)}>
                            {item.type === 1 ? item.userId?.length ? <Fragment>
                                <img className="chat_user_img" src={item.userId[0].profileImage ? item.userId[0].profileImage : require('../../assets/img/user.png')}  alt=""/>
                                <Container>
                                    <Row className='d-flex flex-row justify-content-between'>
                                        <Col  style={{ width: '20px'}}>
                                                <span  
                                                    className="d-inline-block text-truncate"
                                                    style={{ maxWidth: '75px', whiteSpace: 'nowrap', overflow: 'hidden' }}
                                                >
                                                    {item.userId[0].name}
                                                </span>
                                        </Col>
                                        <Col>
                                            <span onClick={(e) => toggleStar(e, item)} style={{ cursor: 'pointer' }}>
                                                {findIsStarred(item, userId) ? <AiFillStar color="#841f15" size={24}/> : <AiOutlineStar size={24}/>}
                                            </span>
                                            {item.count ? <span className="msg_count mr-3">{item.count}</span> : <span></span>}
                                        </Col>    
                                    </Row>
                                    <Row className='ml-1'>
                                        <small>{removeHtmlTags(item.lastMessage)}</small>
                                    </Row>        
                                </Container>
                            </Fragment> : '' : ''}

                            {item.type === 2 ? <Fragment>
                                <img className="chat_user_img" src={item.groupImage ? item.groupImage : require('../../assets/img/user.png')}  alt=""/>
                            <p>
                                {item.groupName}
                                <br />
                                <small>{removeHtmlTags(item.lastMessage)}</small>
                            </p>
                            
                            </Fragment> : ''}

                
                        </li>
                    )) : <li className="cm_empty"><Empty description="Data Not Found" /></li>
                }
                </ul>
            }
        </div>
    )
}



const mapStateToProps = state => {
    let { userProfile } = state.user;
    let { getChatUserListCalback, activeUserChatWindow } = state.inbox;
    return {
        userProfile,
        getChatUserListCalback,
        activeUserChatWindow
    };
}
  
const mapDispatchToProps = dispatch => ({
    getChatUserListCalbackAction: params => dispatch(getChatUserListCalbackAction(params)),
    activeUserChatWindowAction: params => dispatch(activeUserChatWindowAction(params)),
    unreadCountAction: () => dispatch(unreadCountAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatUserList);