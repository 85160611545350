import React, {useState, useEffect, Fragment} from 'react';
import { viewProgramDetail } from '../../services/programService';
import { getCartItemsNonLoginUser, removeFromCartNonLoginUser } from '../../services/programService';
import { ProgramLoader } from '../reuse/SVG';
import Cartitems from './cartItems';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {getitemsincart} from '../../store/cart'

const NotLoginUserCart = (props) => {

    const[cartItems, setCartItems]                  = useState([]);
    const[loader, setloader]                        = useState(true);
    const[isDetailsConfirmed, setDetailsConfirmed]  = useState(0);

/***********Get item from local storge***************/

useEffect(() => {
    getCartItemsNonLoginUser().then((res)=>{
        if(res.data.statusCode==0)
        {
            setCartItems([]);
            setloader(false);       
        }
        else
        {
        setCartItems(res.data.responseData.result);
        let cartprograms = res.data.responseData.result;
        let detailsconfrimedCount = isDetailsConfirmed;
        cartprograms.map(items => {
            if(items.detailConfirmed==1)
            {
                detailsconfrimedCount++;
                setDetailsConfirmed(detailsconfrimedCount);
            }
        })
        setloader(false);
        }
    })
},[])  


const removeFromCart = async (cartId) =>{
    const removeItem =await removeFromCartNonLoginUser(`/?cartId=${cartId}`);
    try{
        let cartprducts = [...cartItems];
        let newcat = cartprducts.filter(cartitem => cartitem._id != cartId);
        setCartItems(newcat);
        getCartItemsNonLoginUser().then(res=>{
            if(res?.data?.statusCode)
            {
                props.getitemsincart(res.data.responseData.result.length)
            }
        })
    }
    catch(err){} 
}


return(
    <Fragment>
        {
                <Fragment>
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-between section_header">
                            <h2>Your Programs</h2>
                        </div>
                    </div>

                    <div className="container cart_staps">
                        <p><span>Step 1: </span>Click the "Confirm Details" Button and Enter Participant Information for Each Program Below or if you have participant details already saved, simply add the participant without having to spend time re-entering the information. </p>
                        <p><span>Step 2: </span>Repeat this process once for each Participant/program as needed.</p>
                        <p><span>Step 3: </span>Click "PROCEED TO PAYMENT" -&gt; Once you've Finished Adding Participants to the selected programs</p>
                    </div> 
                </Fragment>
            
        }

        {
            loader ?  <div className="program_loader"><ProgramLoader /></div> :
            cartItems.length > 0 ?
            cartItems.map((cartitem,i) => (
                    <Cartitems key={i} Item={cartitem} RemoveFromCart ={removeFromCart} />  
        ))
        :
        <div className="no_cart_item"><h3>Cart is Empty</h3></div>
        } 
              
              {
            isDetailsConfirmed >0 ? 
            <Link className="btn btn-primary rounded-0 ml-2 mb-2"   to={
                {     
                    pathname: '/payment-confirmation'
                }
            } >Proceed to Payment </Link> : ''
        } 
    </Fragment>
)
}

const mapDispatchToProps = dispatch => ({
    getitemsincart: (cartitems) => dispatch(getitemsincart(cartitems)),
});


export default connect(null, mapDispatchToProps)(NotLoginUserCart);