import React, {useState, useEffect}  from 'react';
import { connect } from 'react-redux';
import { togglePickupUserModalAction, pickupTabAction } from '../../store/pickup';
import CampsEZUser from './CampsEZUser';
import NonUser from './NonUser';
import { Redirect, Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import {pickupOtp, verifypickupotp, pickupList, deleteAuthorizedPickup} from '../../services/authrisedPickupServeice';
import { ProgramLoader } from '../reuse/SVG';
import { notification } from 'antd';
import EditPickup from './editPickup';
import bannerImg from '../../assets/img/pickup-banner.png'

const Pickup = props => {
    
    const [authrisedToPickup, setAuthrisedToPickup]   = useState(false)
    const [minutes, setMinutes ]                      = useState(2);
    const [seconds, setSeconds]                       = useState(0);
    const [otp, setOtp]                               = useState('');
    const [isActive, setIsActive]                     = useState(false);
    const [otpError, setOtpError]                     = useState('');
    const [pickupUserList, setPickupUserList]         = useState([]);
    const [resendpassword, setResendPassword]         = useState(false);
    const [loading, setLoading]                       = useState(true);
    const [pickuppopup, setPickuppopup]               = useState(false);
    const [deletePopup, setDeletePopup]               = useState(false);
    const [deletepickupId, setDeletePickupId]         = useState(false);
    const [editMode, setEditMode]                     = useState(false);    
    const [editPickupIndex, setEditPickupIndex]       = useState();
    const [editAction, setEditAction]                 = useState(false); 


useEffect(()=>{
    pickupList().then((res)=>{
        if(res?.data?.statusCode)
        {
            setPickupUserList(res.data.responseData.result)
        }
        setLoading(false)
    })

},[])


    useEffect(() => {
        let interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (!isActive && seconds === 0) {
                if (!isActive && minutes === 0) {
                    clearInterval(interval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
          }, 1000);

        return () => clearInterval(interval);
      });



      const handleChange = otp => {
        setOtp(otp);
        setOtpError('');
    };

    const reSendOTP = () => {
        pickupOtp({"email": props.email}).then((res)=>{
            setResendPassword(true)
        })
        reset()
      }
      function reset() {
        setSeconds(0);
        setMinutes(2)
      }
      
      const handleValidate = () => {
        let validate = true;
        if(otp == ''){
            setOtpError('otp is required');
            validate = false;

        }
        if(otp.toString().length != 4){
            setOtpError('otp is require length');
            validate = false
        }

        return validate;
    }

    
    const handelSubmit = (e) =>{
        if(handleValidate()){
            verifypickupotp({"otp": otp}).then((res)=>{
               if(res.data.error) {  setOtpError('Invalid Otp');}
               if(res?.data?.statusCode) 
                {
                   setAuthrisedToPickup(true); 
                   if(!editAction){props.togglePickupUserModalAction();} 
                   setPickuppopup(false); 
                   localStorage.setItem('authForPickup', true)
                }
            })
        }
        e.preventDefault();
    } 

    const addauthrizedPickup = () =>{
        const auth_for_pickup = localStorage.getItem('authForPickup');
        if(auth_for_pickup)
        { 
            setAuthrisedToPickup(true)
            props.togglePickupUserModalAction();
        }
        else
        {
            setPickuppopup(true);
            pickupOtp({"email": props.email}).then((res)=>{

            })
            setEditAction(true)
        }        
       
    }

    const editfunctionality =(i) =>{
        const auth_for_pickup = localStorage.getItem('authForPickup');
        if(auth_for_pickup)
        { 
            setEditMode(true); 
            setEditPickupIndex(i)
        }
        else
        {
            setPickuppopup(true);
            pickupOtp({"email": props.email}).then((res)=>{

            })
            setEditAction(true)

        }  
    }

    const deletePickup =()=>{
        let params = `/?pickupId=${deletepickupId}`;
        deleteAuthorizedPickup(params).then(res=>{
            if(res?.data?.statusCode)
            { 
                pickupList().then((res)=>{
                    if(res?.data?.statusCode)
                    {
                        setPickupUserList(res.data.responseData.result)
                    }
                    setLoading(false)
                })  
                notification.open({
                    description:
                      'Pickup Deleted Successfully',
                  });
                  setDeletePopup(false);
            }
        })
    } 


    const deletefunctionality =(id) =>{
        const auth_for_pickup = localStorage.getItem('authForPickup');
        if(auth_for_pickup)
        { 
            setDeletePopup(true); 
            setDeletePickupId(id)
        }
        else
        {
            setPickuppopup(true);
            pickupOtp({"email": props.email}).then((res)=>{
                
            })
            setEditAction(true)

        }  
    }

    return(
        <div className="body_container">
                
            <Modal
            show={deletePopup}
            animation={false}
            dialogClassName="folder_model"
            size="lg"
            centered
            >
            <Modal.Body>
                <span className="modal_close" onClick={()=>{setDeletePopup(false)}}><i className="icon-cross" /></span>
                <div className="text-center p-5 pickup_modal_body">
                    <p>Are you sure you want to delete this Pickup</p>
                </div>
                <div className="text-center p-5"> 
                <button  onClick={()=>{setDeletePickupId(''); setDeletePopup(false)}} style={{width: '120px'}} className="btn btn-light rounded-0">No</button>
                <button  onClick={deletePickup} style={{width: '120px'}} className="btn btn-primary ml-3">Yes</button>
                </div>
            </Modal.Body>
            </Modal>
                
                
                <Modal
                dialogClassName="text-center otp_modal"
                show={pickuppopup}
                animation={false}
                centered
                >
                <Modal.Body>
                <span className="modal_close">   <i onClick={()=>{setPickuppopup(false); reset()}} className="icon-cross" />  </span>
                <form className="p-5" onSubmit={handelSubmit}>
                    <h3>Please Enter OTP</h3>
                    <p>We have sent a One Time Password (OTP) to your Email</p>
                    <div className="color-primary otp_timer">0{minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</div>

                    <div className="otp_inputs">
                        <OtpInput
                            onChange={handleChange}
                            isInputNum={true}
                            shouldAutoFocus={true}
                            name="otp"
                            value={otp}
                            numInputs={4}
                            separator={''}
                        />
                        <div className="invalid-feedback">{otpError}</div>
                    </div>

                    <p>Didn't receive the OTP? <span onClick={reSendOTP} className="pointer color-primary">Resend</span></p>
                {resendpassword && <span>Password sent Please check you email</span>}
                    <button
                        type="submit"
                        disabled={props.loading}
                        className={`btn btn-primary rounded-0 btn-block mt-3 btn_anim ${props.loading ? 'show_anim' : ''}`}>{props.loading ? 'Loading...' : 'Verify'}</button>

                    {props.error && <p className="error_msg">{props.error}</p>}
                </form>
                </Modal.Body>
                </Modal>

            <div className="cm_banner_wrapper">
                <img src={bannerImg} />
                <div className="cm_banner_text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h2>Authorized <br />Pickup</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
            {loading ? <div className="program_loader"><ProgramLoader /></div> : 


         editMode ? 
          <EditPickup pickupUser={pickupUserList[editPickupIndex]}/>
         :
         props.pickupTab === 1 ? '' :
            <div className="row">
                    <div className="col-lg-2" />
                    <div className="col-lg-8 text-center mb-5 pb-5 mt-5 pt-5">
                       {
                           pickupUserList.length > 0 ? 
                           <ul className="campers_list">
                           {
                               pickupUserList.map((campers, i) => (
                                   <li key={campers._id}>
                                       <div className="d-flex" style={{minWidth: 'calc(100% - 60px)'}}>
                                           <img src={campers.profileImage ? campers.profileImage : require('../../assets/img/user.png')}/>
                                           <div className="text-left pl-3 w-100">
                                           <h5 className="mb-0">{`${campers.firstName} ${campers.lastName}`}</h5>

                                            {campers?.campers.length ? <div className="mt-2">
                                                        {campers?.campers.map(item => <div className="row mb-1">
                                                                                        <div className="col-6"><b>Participant</b>: {item.camperName}</div>
                                                                                        <div className="col-6"><b>Relationship</b>: {item.relationship}</div>
                                                                                        </div>)}
                                                  
                                                
                                                </div> : ''}
                                            </div>
                                       </div>
                                       <div>
                                       <i onClick={()=>{ editfunctionality(i)}} className="icon-pancil"></i>    
                                       <i onClick={()=>{deletefunctionality(campers._id)}} className="icon-delete" />
                                       </div>                  

                                  </li>            
                               ))
                           }
                           </ul>
                           :
                           <React.Fragment>
                           <img src={require('../../assets/img/pickup-img.png')} />
                           <h3 className="mt-5 mb-4 color_primary">Add Authorized Pickup</h3>
                           <p>There is no Authorized pickup added right now.</p>
                           </React.Fragment>
                       }   
                            <button onClick={()=>{addauthrizedPickup()}} className="btn btn-primary pl-5 pr-5 mt-4 rounded-0">Add Authorized Pickup</button>  
                       
                    </div>
                </div>
             }

                                        {props.pickupTab === 1 ? <div className="pickup_user_wrapper"><CampsEZUser /></div>  : ''}
                                        {props.pickupTab === 2 ? <Redirect to="/pickup/add-non-user" /> : ''}

            </div>
        </div>
    )
}

const mapStateToProps = state => {
    let { pickupTab } = state.pickup;
    let email = state.user.userProfile.email; 
    return {
        pickupTab,
        email
    };
}
  
const mapDispatchToProps = dispatch => ({
    togglePickupUserModalAction: () => dispatch(togglePickupUserModalAction()),
    pickupTabAction: params => dispatch(pickupTabAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Pickup);

