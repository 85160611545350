import React, {useState, useEffect} from 'react';
import CampData from './CampData';
import {programDates} from '../../helper';
import { connect } from 'react-redux';
import { ProgramLoader } from '../reuse/SVG';
import { Empty } from 'antd';

const CampCard = props => {
    const[loader, setLoader] = useState();
    const {Weekstart, Weekend, Camps, Weeknumber, WeekIndex, findNew, handelCampsAction} = props;
    useEffect( () => {
        setLoader(false);
    }, [props])
    return (
     <React.Fragment>
          <div className="row mb-3">
          <div className="col-lg-9">
                <h3 style={{marginLeft: '-30px'}}>Week {`${WeekIndex}: ${programDates(Weekstart, Weekend)}`}</h3>    
          </div>
          {
             Camps.length > 0 &&  
           <div className="col-lg-3 text-right">
               <button className="btn btn-outline-danger" onClick={ () => { findNew(WeekIndex-1, Weekend); setLoader(true); }} >Find New</button>
          </div> 
            }          

           </div> 
            {
                loader ? <div className="program_loader"><ProgramLoader /></div> :
                Camps.length > 0 ? Camps.map( (camp ,index) => (<CampData key={ camp.programId } Details={ camp} WeekmainIndex={WeekIndex-1} Campnum={index+1} handelCampsAction={(weekid,campid,status,type)=>props.handelCampsAction(weekid,campid,status,type)}/>  ) )
                :
                (<div className="cm_empty"><Empty description="No Programs Found" /> </div>)
            }
    </React.Fragment>
)
}
const mapStateToProps = state => {
    let {loader} =  state.autoScheduler;
    return {
        loader
    };
}    
export default connect(mapStateToProps,null)(CampCard);