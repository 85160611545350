import React, {useState, useEffect} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from "react-router-dom";
import {notification} from 'antd';
import FriendRequests from './notifications/freindrequest'


const Notification = props => {

    return(
        <div className="card mb-4 pro_wrapper">
            <h4 className="text-center mb-5">Notifications</h4>
                
            <div className="row">
                <div className="col-lg-12">
                    <FriendRequests />

                </div>
            </div>
        </div>
    )
}

export default Notification;