export const development = {
    lsCampCode_: '5edVW8x',
    api: {
        mode: 'cors',
        viClientId: 'live_OS3ZRXTLX720CFG3PYVR9NW7AC4RODA9',
        mySecretKey: 'Hb@DOQQY!Y|8ljgp',
        url: 'https://devapi.campsez.com/api/v1/',
        socketurl: 'https://devsocket.campsez.com',
        AUTH: 'Basic Y2FtcHNlel9hZG1pbjpjYW1wc2V6QGRldg==',
        GOOGLE_KEY: 'AIzaSyCdDH9dkoLecpVX7gNRSkYMMV1eUfQ7VE0',
        SITE_URL: 'https://devweb.campsez.com/',
        districtCamp: '6241cff5868f5261b4c64293',
        sportInternationalCamp: '66597ae3cdb15446ff03ddde',
        gogleTagManagerUrl:false,
    }

}

export const local = {
    lsCampCode_: '5edVW8x',
    api: {
        viClientId: 'live_OS3ZRXTLX720CFG3PYVR9NW7AC4RODA9',
        mySecretKey: 'Hb@DOQQY!Y|8ljgp',
        mode: 'cors',
        url: 'http://localhost:5001/api/v1/',
        socketurl: 'http://localhost:4000',
        AUTH: 'Basic Y2FtcDpjYW1w',
        GOOGLE_KEY: 'AIzaSyCdDH9dkoLecpVX7gNRSkYMMV1eUfQ7VE0',
        SITE_URL: 'http://localhost:3000',
        districtCamp: '6241cff5868f5261b4c64293',
        sportInternationalCamp: '66597ae3cdb15446ff03ddde',
    }
}

export const prod = {
    lsCampCode_: '5edVW8x',
    api: {
        mode: 'cors',
        viClientId: 'live_OS3ZRXTLX720CFG3PYVR9NW7AC4RODA9',
        mySecretKey: 'Hb@DOQQY!Y|8ljgp',
        url: 'https://api.campsez.com/api/v1/',
        socketurl: 'https://socket.campsez.com',
        AUTH: 'Basic Y2FtcHNlel9hdXRoOjJrTmxZejVCbUg=',
        SITE_URL: 'https://campsez.com/',
        GOOGLE_KEY: 'AIzaSyCdDH9dkoLecpVX7gNRSkYMMV1eUfQ7VE0',
        districtCamp: '61689494218cfc630c5394b2',
        sportInternationalCamp: '64e647f1e0b09dbf3c390fd2',
        gogleTagManagerUrl:true,
    }
}