import React from 'react';
import { Link, useLocation, Redirect } from 'react-router-dom';


const useQuery = () => new URLSearchParams(useLocation().search);

const Welcome = props => {
    let query = useQuery();

    if(query.get("ProgramDetail")){
        return <Redirect to={`/camps/${query.get("ProgramDetail")}`} />
    }else if(query.get("Booking")){
        return <Redirect to={`/booking/${query.get("Booking")}/${query.get("ProgramId")}`} />
    }else if(query.get("Feed")){
        return <Redirect to={{
                                pathname: "/feeds",
                                state: { feedId: query.get("Feed")}
                            }} />
    }
    else {
        return ''
    }

    
    
}


export default Welcome;