import React, {useState, useEffect} from 'react';
import CamperForm from './Camperform';
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { connect } from "react-redux";

import { getCartItemsNonLoginUser} from '../../../services/programService';
import {participantAdd} from '../../../services/campersServices';

import {removeTime, groupCodeGenerate} from '../../../helper';


const Campers = (props) =>{
    let location                                                = useLocation();
    const [numberofcampers, setNumberofCampers]                 = useState([])
    const [counter, setCounter]                                 = useState(0);
    const [activeIndex, setActiveIndex]                         = useState(0);
    const [fielderros, setfiledErrors]                          = useState([])
    const [errormodal, setErrorModel]                           = useState(false)
    const [nonloginuseconfirm, setNonloginuseConfirm]           = useState(false);
    const [additionalexp, setAddtionalExp]                      = useState([]);
    const [additionalexperrModel, setAddtionalExpErrModel]      = useState(false);
    const [camperAddtionalExp, setCamperAddtionalexp]           = useState();
    const [camperActivityInrest, setCamperActivityIntrest]      = useState();
    const [programGender, setProgramGender]                     = useState();
    const [minAge, setMinAge]                                   = useState();
    const [maxAge, setMaxAge]                                   = useState();      
    const [questionsans, setQuestionAns]                        = useState([]);
    const [groupcodearr, setGroupCodeArr]                       = useState([])    
    const { register, handleSubmit, errors, control }            = useForm();
    
  
    useEffect(() => {
        let campersarr =[];
        let camparra = {};
        let groupcodear =  [...groupcodearr];

        let camperactvivityintrest ={};
        for(var i =0; i<location.state.numbderofCampers; i++)
        {
            campersarr.push(i);
            let key  = i
            camparra[key] = {'additionalExperience':[]};  
            //camperactvivityintrest[key]= {'activityInterest':[]}
            groupcodear.push(groupCodeGenerate());
            
        }



        setGroupCodeArr(groupcodear); 
        setNumberofCampers(campersarr)
        setCamperAddtionalexp(camparra);
        setCamperActivityIntrest(camperactvivityintrest);
        setCounter(1);
       
        getCartItemsNonLoginUser().then((res)=>{
            if(res.data.statusCode==0){
               // setCartItems([]);      
            }else{
                let cartitemsdetail = res.data.responseData.result.filter(item => item._id == location.state.cart_id); 
                setProgramGender(cartitemsdetail[0].gender);
                setMinAge(cartitemsdetail[0].minAge);
                setMaxAge(cartitemsdetail[0].maxAge);
                let questions = cartitemsdetail[0].questions;
                if(cartitemsdetail[0].additionalExperience!==undefined && cartitemsdetail[0].additionalExperience.length)
                {
                    setAddtionalExp(cartitemsdetail[0].additionalExperience);
                }
                if(questions !== undefined && questions.length>0)
                {
                    let questionsarr = [...questionsans];
                    questions.map(ques=>{
                        let qu = { "questionId": ques._id, "question": ques.title, "answer":''}
                        questionsarr.push(qu);
                    })
                    setQuestionAns(questionsarr); 
                }
            }
        });    
       
        // let cartdata =  JSON.parse(localStorage.getItem('cartitems'));
        //if(cartdata)
        //{
          //  let cartitemDetails = cartdata.filter(cartitems => cartitems.programId ==location.state.cart_id);
           // if(cartitemDetails[0].additionalExperience!==undefined)
            //{
              //  setAddtionalExp(cartitemDetails[0].additionalExperience);
           // }
       // }
    },[])



  
  const onSubmit =  async data => { 
     let field_errors  = [];
     let participantlist = data.participants;
     var i=0;
     participantlist.map((item) =>{

        if(item.firstName == ''){
            let errname = `camper${i}firstName`;    
            field_errors.push(errname)
        }

        if(item.lastName == ''){
            let errname = `camper${i}lastName`;    
            field_errors.push(errname)
        }

        if(item.dob == '' || item.dob == 'undefined' || item.dob == null){
            let errname = `camper${i}dob`;    
            field_errors.push(errname)
        }else{
            participantlist[i].dob = new Date(removeTime(item.dob._d)).getTime();
        }

        if(item.currentGrade == ''){
            let errname = `camper${i}currentGrade`;    
            field_errors.push(errname)
        } 

        if(item.gender == ''){
            let errname = `camper${i}gender`;    
            field_errors.push(errname)
        }

        if(item.address.trim() == ''){
            let errname = `camper${i}address`;    
            field_errors.push(errname)
        }   
 
        if(item.city.trim() == ''){
            let errname = `camper${i}city`;    
            field_errors.push(errname)
        }

        if(item.zipCode.trim() == ''){
            let errname = `camper${i}zipCode`;    
            field_errors.push(errname)
        }

        if(item.state == ''){
            let errname = `camper${i}state`;    
            field_errors.push(errname)
        }

        if(item.tshirtSize == ''){
            let errname = `camper${i}tshirtSize`;    
            field_errors.push(errname)
        }          
        
        if(item.shortsSize == ''){
            let errname = `camper${i}shortsSize`;    
            field_errors.push(errname)
        }

        if(item.medical === undefined) {
            let errname = `camper${i}medical`;    
            field_errors.push(errname)
        }else{
            participantlist[i].medical = JSON.stringify(item.medical);
        }

        if(item.dietaryGuidelines == undefined){
            let errname = `camper${i}dietaryGuidelines`;    
            field_errors.push(errname)
        }else{
            participantlist[i].dietaryGuidelines = JSON.stringify(item.dietaryGuidelines);
        }

        if(item.dietReligious == ''){
            let errname = `camper${i}dietReligious`;    
            field_errors.push(errname)
        }

        if(item.allergy == undefined){
            let errname = `camper${i}allergy`;    
            field_errors.push(errname)
        }else{
            participantlist[i].allergy = JSON.stringify(item.allergy); 
        }
        
        if(item.allergyDeadly == ''){
            let errname = `camper${i}allergyDeadly`;    
            field_errors.push(errname)
        }
        
        if(item.medicalInsuranceCompany == ''){
            let errname = `camper${i}medicalInsuranceCompany`;    
            field_errors.push(errname)
        }
        
        if(item.policyNumber == ''){
            let errname = `camper${i}policyNumber`;    
            field_errors.push(errname)
        }
        
        if(item.group == ''){
            let errname = `camper${i}group`;    
            field_errors.push(errname)
        } 
        
        if(item.primaryPhysician == ''){
            let errname = `camper${i}primaryPhysician`;    
            field_errors.push(errname)
        } 
        
        if(item.physicianAddress == ''){
            let errname = `camper${i}physicianAddress`;    
            field_errors.push(errname)
        } 
        
        if(item.physicianPhone == ''){
            let errname = `camper${i}physicianPhone`;    
            field_errors.push(errname)
        } 

        if(item.medicationsTaken == ''){
            let errname = `camper${i}medicationsTaken`;    
            field_errors.push(errname)
        }         
        
        if(item.allowDocumentCamper == ''){
            let errname = `camper${i}allowDocumentCamper`;    
            field_errors.push(errname)
        } 

        // if(JSON.parse(item.activityInterest).length == 0){
        //   delete participantlist[i].activityInterest;
        // }else{
        //     participantlist[i].activityInterest = JSON.parse(item.activityInterest); 
        // }

        participantlist[i].additionalExperience = JSON.stringify(camperAddtionalExp[i].additionalExperience);
        i++;
     })
  
      setfiledErrors(field_errors);

      if(field_errors.length > 0){
        setErrorModel(true);
      }else{ 
        let params = {deviceTypeID:3, participants:JSON.stringify(participantlist)}


        let participantadded = await participantAdd(params)  
         try{
            setNonloginuseConfirm(true);
         }catch(err){  }  
      }
  }


const Addtonalexptocamper =(event,addtionexpId,camperIndex,quantity) =>{
    let camperAddexp = {...camperAddtionalExp};
    let addtionalexpdetails = additionalexp.filter(item=> item._id == addtionexpId)
    let price = addtionalexpdetails[0].price;
    let _id = addtionexpId;
    if(event.target.checked)
    {
            if(camperAddexp[camperIndex].additionalExperience.length>0)
            {
                camperAddexp[camperIndex].additionalExperience.push({_id,addtionexpId,quantity, price, "numberOfBooking":quantity})
            }
            else
            {

                camperAddexp[camperIndex].additionalExperience =[{_id,addtionexpId,quantity, price, "numberOfBooking":quantity}];
            }
            setCamperAddtionalexp(camperAddexp);   
    }
    else
    {
        let additionalind = camperAddexp[camperIndex].additionalExperience.findIndex(item => item.addtionexpId == addtionexpId);
        camperAddexp[camperIndex].additionalExperience.splice(additionalind, 1);
        setCamperAddtionalexp(camperAddexp);  
    }   
}


const addAdditionalExp = (additonexpId,camperindex,type) =>{
    let camperAddexp = {...camperAddtionalExp};
    let additionalexpindex = camperAddexp[camperindex].additionalExperience.findIndex(item => item.addtionexpId == additonexpId);
    if(type=='increment')
    {
        camperAddexp[camperindex].additionalExperience[additionalexpindex].quantity++
        camperAddexp[camperindex].additionalExperience[additionalexpindex].numberOfBooking++
    }
    else
    {
      if(camperAddexp[camperindex].additionalExperience[additionalexpindex].quantity!==1)
      {
        camperAddexp[camperindex].additionalExperience[additionalexpindex].quantity--
        camperAddexp[camperindex].additionalExperience[additionalexpindex].numberOfBooking--
      }
    }  
    setCamperAddtionalexp(camperAddexp);     
}



return(

<div className="body_container"> 
    <div className="container">

        <Modal
            dialogClassName="h_auto"
            show={errormodal}
            animation={false}
            centered
            >
            <Modal.Body>
                <div className="p-5">
                    <h3 className="text-center mb-4">Error</h3>
                    Please fill all the required fields  
                    <button  onClick={() => {setErrorModel(false)}} className="btn btn-primary btn-block mt-2">Ok</button>
                </div>
            </Modal.Body>
        </Modal>

        <Modal
            dialogClassName="h_auto"
            show={nonloginuseconfirm}
            animation={false}
            centered
            >
            <Modal.Body>
                <div className="p-5">
                    <h3 className="text-center mb-4">Success</h3>
                   Participants added successfully
                   <Link className="btn btn-primary btn-block mt-2" to={
                                {     
                                    pathname: '/confirmdetails',
                                    state:{"numbderofCampers":location.state.numbderofCampers, "cart_id": location.state.cart_id}
                                }
                                } > ok </Link>
                </div>
            </Modal.Body>
        </Modal>

        <Modal
            dialogClassName="h_auto"
            show={additionalexperrModel}
            animation={false}
            centered
            >
            <Modal.Body>
                <div className="p-5">
                    <h3 className="text-center mb-4">Error</h3>
                     You Forget to add Additional Experiences
                     <button  onClick={() => {setAddtionalExpErrModel(false)}} className="btn btn-primary btn-block mt-2">Ok</button>
                </div>
            </Modal.Body>
        </Modal>



        <form onSubmit={handleSubmit(onSubmit)}>
            
            <div className="card mb-4 mt-4">
                <h4>Add Participant(s)</h4>
                
                <div className="mt-3 mb-3 bottom_border">
                    {
                        numberofcampers.map((item,i) =>(
                        <span className="mr-2 btn btn-outline-dark rounded-0"  onClick={() => {setActiveIndex(item)}}>Camper {i+1}</span>
                        ))
                    }                    
                </div>
                    {
                        numberofcampers.map((index, i) => {
                            return (
                            <div className="camperformwapper" key={index} test={i} activein={activeIndex} style={{display: activeIndex == index ? "block" : "none"}} > 
                                    <CamperForm
                                        Errors={fielderros}
                                        key={index}
                                        ActiveIndex={activeIndex}
                                        FormIndex={i}
                                        refprops={register}
                                        Additionalexp={additionalexp}
                                        CamperAddtionalexp={camperAddtionalExp}
                                        addAdditionalExp={addAdditionalExp}
                                        Control={control}
                                        Questionsans={questionsans}
                                        Addtonalexptocamper={Addtonalexptocamper}
                                        ProgramGender={programGender}
                                        MinAge={minAge}
                                        MaxAge={maxAge}
                                        GroupCode={groupcodearr[i]}/>   
                                </div>
                            )
                         })
                    }


                    {/* {
                        numberofcampers.map((index, i) => {
                            return (
                            <div className="camperformwapper" key={index}> 
                                <ParticipentFrom />   
                            </div>
                            )
                         })
                    } */}
                    
                     
            </div>
            {
                counter >0 &&
                <div className="col-sm-12 text-center mb-4">
                <Link style={{width: '130px'}} className="btn btn-light rounded-0 mr-2" to={
                {     
                    pathname: '/confirmdetails',
                    state:{"numbderofCampers":location.state.numbderofCampers, "cart_id": location.state.cart_id}
                }
                } >Cancel</Link>
                <button style={{minWidth: '130px'}} className="btn btn-primary rounded-0">Save</button>
            </div>
            }
        </form>

    </div>
</div>


)

}

const mapStateToProps = state => {
    let { loggedIn } = state.user;
    return {
        loggedIn
    };
}

export default connect(mapStateToProps)(Campers);