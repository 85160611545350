import React, { Fragment } from 'react';
import { OverlayTrigger, Tooltip} from 'react-bootstrap';
import Select from 'react-select';
import { DatePicker, InputNumber, TimePicker, notification } from 'antd';


const { RangePicker } = DatePicker;




export const InputError = p => p.error ? <OverlayTrigger placement="left" overlay={<Tooltip>{p.error}</Tooltip>}><span className="error_tootltip_label" /></OverlayTrigger> : '';

export const InputTextError = p => p.error ? <span className='text-danger'><small>{p.error}</small></span> : '';





export const CustomText = p => (
    <Fragment>
        <input
            type="text"
            name={p.name}
            value={p.value}
            onChange={p.change}
            placeholder={p.pHolder}
            className={`form-control ${p.error ? 'is-invalid' : ''}`} />
        <InputError error={p.error} />
    </Fragment>
);







export const CustomTextarea = p => (
    <Fragment>
        <textarea
            name={p.name}
            value={p.value}
            onChange={p.change}
            placeholder={p.pHolder}
            className={`form-control rounded ${p.error ? 'is-invalid' : ''}`} />
        <InputError error={p.error} />
    </Fragment>
);








export const CustomNumber = p => (
    <Fragment>
        <InputNumber
            onChange={p.change}
            value={p.value}
            placeholder={p.pHolder}
            className={`form-control ${p.error ? 'is-invalid' : ''}`}
            {...p} />
        <InputError error={p.error} />
    </Fragment>
);







export const CustomDateRange = p => (
    <Fragment>
        <RangePicker
            onChange={p.change}
            className={`form-control ${p.error ? 'is-invalid' : ''}`}
            {...p} />
        <InputError error={p.error} />
    </Fragment>
);





export const CustomDatePicker = p => (
    <Fragment>
        <DatePicker
            onChange={p.change}
            className={`form-control ${p.error ? 'is-invalid' : ''}`}
            {...p} />
        <InputError error={p.error} />
    </Fragment>
);








export const CustomSelect = p => (
    <Fragment>
        <Select
            placeholder={p.pHolder}
            onChange={p.change}
            classNamePrefix="cm_select"
            className={`form-control ${p.error ? 'is-invalid' : ''}`}
            {...p} />
        <InputError error={p.error} />
    </Fragment>
);